import GenereteImageActionTypes from "./actions-types";

const initialState = {
    currentGenerete: { urlImage: null, typeArt: null }
}

const genereteReducer = (state = initialState, action) => {
    switch (action.type) {
        case GenereteImageActionTypes.GENERETE:
            return { ...state, currentGenerete: action.payload };
        default:
            return state;
    }
};

export default genereteReducer;