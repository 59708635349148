import React from 'react';
import { useState, useEffect } from 'react';
import './updatePlan.css'
import api from '../../service/api';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import botijoes from '../../image/botijoes.png'
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { Theme, useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FaRegCopy } from "react-icons/fa";
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { CurrencyInput } from 'react-currency-mask';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";

const UpdatePlan = () => {
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    let { plan } = useParams()
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [accessEmail, setAccessEmail] = useState('');
    const [accessPass, setAccessPass] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [logoLogin, setLogoLogin] = useState();
    const [stap, setStap] = useState(0);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);
    const [name, setName] = useState('')
    const [revenda, setRevenda] = useState('')
    const [cnpjAssa, setCnpjAssa] = useState('')
    const [moduleSelected, setModuleSelected] = useState('');
    const [paymentForm, setPaymentForm] = useState('')
    // let logoLogin = '';
    const { currentTheme } = useSelector(rootReducer => rootReducer.configurationReducer);
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const [height, setHeight] = React.useState('100vh')
    const [divFormStepHeight, setDivFormStepHeight] = React.useState('70vh')
    const [pTop, setPTop] = React.useState(0);
    const [resellerClass, setResellerClass] = useState('')
    const [typeGas, setTypeGas] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [idAssas, setIdAssas] = useState('');
    const [cep, setCep] = useState('');
    const [address, setAddress] = useState('');
    const [number, setNumber] = useState('');
    const [phone, setPhone] = useState('');
    const [numberCard, setNumberCard] = useState('');
    const [nameCard, setNameCard] = useState('');
    const [cvvCard, setCvvCard] = useState('')
    const [validateCard, setValidateCard] = useState('')
    const [complementAddress, setComplementAddress] = useState('')
    const [cpfCnpj, setCpfCnpj] = useState('')
    const [qrcodeCopyPaste, setQrcodeCopyPaste] = useState('')
    const [alertModal, setAlertModal] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('error');
    const [cnpjValidated, setCnpjValidated] = useState(false)
    const [userReseller, setUserReseller] = useState([]);
    const [cnpjReseller, setCnpjReseller] = useState(false)
    const [emailReseller, setEmailReseller] = useState(false)
    const [codeBar, setCodeBar] = useState('')
    const [parc, setParc] = useState('')
    const [array3month, setArray3Month] = useState([1, 2, 3]);
    const [array1year, setArray1year] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
    const [custumerId, setCustumerId] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [confirmPayment, setConfirmPayment] = useState(false)
    const [db, setDb] = useState('')
    const [idReseller, setIdReseller] = useState('')

    var arrayList = [
        'Pedidos no ZAP',
        'Gestão pedidos do ZAP Fácil',
        'Automações no ZAP',
        'Minhas artes Gerar e baixar 30 artes/mês',
        'Todos os treinamentos',
        'Acesso ao APP Tech (Aplicativo entregador)',
        'App Gás no Clique (Aplicativo cliente)',
        'Cupon APP/Whats',
        'Aniversáriantes do mês',
        'Minha revenda no Google',
        'Raio X das redes sociais',
        'Painel de Gestão',
        'Caixa do Entregador',
        'Onde está o entregador',
        'Meu anúncio no google',
        '1 consultoria mensal INDIVIDUALIZADA'
    ];

    var arrayList2 = [
        'Pedidos no ZAP',
        'Gestão pedidos do ZAP Fácil',
        'Automações no ZAP',
        'Minhas artes Gerar e baixar 30 artes/mês',
        'Todos os treinamentos',
        'Acesso ao APP Tech (Aplicativo entregador)',
        'App Gás no Clique (Aplicativo cliente)',
        'Cupon APP/Whats',
        'Aniversáriantes do mês',
        'Minha revenda no Google',
        'Raio X das redes sociais',
        'Painel de Gestão',
        'Caixa do Entregador',
        'Onde está o entregador',
        'Meu anúncio no google'
    ];

    const names = [
        'Crédito',
        'Boleto',
        'PIX'
    ];

    useEffect(() => {
        api.get('/TodosUsuariosRevenda')
            .then(response => {
                response.data.map(key => {
                    if (key.db_name == currentUser.db) {
                        setCnpjAssa(key.cnpj)
                        setName(key.name)
                        setEmail(key.email)
                        setDb(key.db_name)
                        setIdReseller(key.id)
                    }
                })
            })
    }, [])

    const handleCEP = (e) => {
        e.target.value = e.target.value.replace(/^(\d{5})(\d)/, "$1-$2")
        if (e.target.value.length == 9) {
            const cep = e.target.value.replace(/\D/g, '');
            setCep(cep)
            fetch(`https://viacep.com.br/ws/${cep}/json/`)
                .then(res => res.json()).then(data => {
                    setAddress(data.logradouro)
                })
        }
    }

    const handleMaskCpfCnpj = (e) => {
        // e.target.value = e.target.value.replace(/\D/g, "")
        // e.target.value = e.target.value.replace(/(\d{2})(\d)/, "$1.$2")
        // e.target.value = e.target.value.replace(/(\d{3})(\d)/, "$1.$2")
        // e.target.value = e.target.value.replace(/(\d{3})(\d)/, "$1.$2")
        // e.target.value = e.target.value.replace(/(\d{4})(\d)/, "$1/$2")
        // e.target.value = e.target.value.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        e.target.value = cnpj.format(e.target.value)
        setCnpjValidated(cnpj.isValid(e.target.value))
        setCnpjAssa(e.target.value.replace(/\D/g, ""))
    }

    const handleMaskCpfCnpj2 = (e) => {
        if (e.target.value.length <= 14) {
            e.target.value = e.target.value.replace(/\D/g, "")
            e.target.value = e.target.value.replace(/(\d{3})(\d)/, "$1.$2")
            e.target.value = e.target.value.replace(/(\d{3})(\d)/, "$1.$2")
            e.target.value = e.target.value.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
            setCpfCnpj(e.target.value.replace(/\D/g, ""))
        } else if (e.target.value.length >= 14) {
            e.target.value = e.target.value.replace(/\D/g, "")
            e.target.value = e.target.value.replace(/(\d{2})(\d)/, "$1.$2")
            e.target.value = e.target.value.replace(/(\d{3})(\d)/, "$1.$2")
            e.target.value = e.target.value.replace(/(\d{3})(\d)/, "$1.$2")
            e.target.value = e.target.value.replace(/(\d{4})(\d)/, "$1/$2")
            e.target.value = e.target.value.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
            setCpfCnpj(e.target.value.replace(/\D/g, ""))
        }
    }

    const handleMaskPhone = (e) => {
        if (e.target.value.length <= 14) {
            e.target.value = e.target.value.replace(/\D/g, "")
            e.target.value = e.target.value.replace(/^(\d\d)(\d)/g, "($1) $2")
            e.target.value = e.target.value.replace(/(\d{4})(\d)/, "$1-$2")
            setPhone(e.target.value.replace(/\D/g, ""))
        } else {
            e.target.value = e.target.value.replace(/\D/g, "")
            e.target.value = e.target.value.replace(/^(\d\d)(\d)/g, "($1) $2")
            e.target.value = e.target.value.replace(/(\d{5})(\d)/, "$1-$2")
            setPhone(e.target.value.replace(/\D/g, ""))
        }
    }

    const handleMaskWhatsapp = (e) => {
        e.target.value = e.target.value.replace(/\D/g, "")
        e.target.value = e.target.value.replace(/^(\d\d)(\d)/g, "($1) $2")
        e.target.value = e.target.value.replace(/(\d{5})(\d)/, "$1-$2")
        setWhatsapp(e.target.value.replace(/\D/g, ""))
    }

    const handleMaskCard = (e) => {
        e.target.value = e.target.value.replace(/\D/g, "")
        e.target.value = e.target.value.replace(/(\d{4})(\d)/, "$1.$2")
        e.target.value = e.target.value.replace(/(\d{4})(\d)/, "$1.$2")
        e.target.value = e.target.value.replace(/(\d{4})(\d)/, "$1.$2")
        e.target.value = e.target.value.replace(/(\d{4})(\d)/, "$1.$2")
        setNumberCard(e.target.value.replace(/\D/g, ""))
    }

    const handleMaskValidateCard = (e) => {
        e.target.value = e.target.value.replace(/\D/g, "")
        e.target.value = e.target.value.replace(/(\d{2})(\d)/, "$1/$2")
        setValidateCard(e.target.value)
    }

    const handlePayAsaas = async (type) => {
        if (type == 'PIX') {
            let total = 0;
            if (plan == 'trimestral') {
                total = 897
            } else if (plan == 'anual') {
                total = 2388
            }
            await api.post('/PagamentoAsaas', {
                type: "pix",
                cnpj: cnpjAssa,
                name: name,
                total: total,
                parc: 1
            }).then(response => {
                setCustumerId(response.data.custumer_id)
                setQrcodeCopyPaste(response.data.qrCodeCopyPaste)
                var image = document.getElementById('qrcodeImg');
                image.src = "data:image/gif;base64," + response.data.qrCodeImage;

                image.width = 300;
                image.height = 300;
            })
        } else if (type == 'Crédito') {
            setOpen(true)
            let total = 0;
            let module = '';
            if (plan == 'trimestral') {
                total = 897
                module = '3month'
            } else if (plan == 'anual') {
                total = 2388
                module = '1year'
            }
            await api.post('/PagamentoAsaas', {
                type: "credito",
                cnpj: cnpjAssa,
                name: name,
                email: email,
                nameCard: nameCard,
                cnpjAsaas: cpfCnpj,
                numberCard: numberCard,
                validateCard: validateCard,
                cvvCard: cvvCard,
                number: number,
                cep: cep,
                phone: phone,
                total: total,
                parc: parc
            }).then(response => {
                setOpen(false)
                var status = response.data.status
                if (status == 'CONFIRMED') {
                    api.put('AtualizarUsuarioRevenda', {
                        id: idReseller,
                        module: module
                    })
                        .then(response => {
                            setAlertModal(true)
                            setMessageAlert('Pagamento efeutoado com sucesso')
                            setTypeAlert('success')
                            navigate("/plano-atualizado");
                            setTimeout(() => {
                                setAlertModal(false)
                            }, 3000)
                        })
                } else {
                    setAlertModal(true)
                    setMessageAlert('Problema ao efetuar pagamento')
                    setTypeAlert('error')

                    setTimeout(() => {
                        setAlertModal(false)
                    }, 3000)
                }

            })
        } else if (type == 'Boleto') {
            let total = 0;
            if (plan == 'trimestral') {
                total = 897
            } else if (plan == 'anual') {
                total = 2388
            }
            await api.post('/PagamentoAsaas', {
                type: "boleto",
                cnpj: cnpjAssa,
                name: name,
                total: total
            }).then(response => {
                setCustumerId(response.data.custumer_id)
                setCodeBar(response.data.codigoBarra)
            })
        }

    }

    function copiarLink() {
        let textoCopiado = document.getElementsByClassName("inputQrcodeCopyPaste")[0].childNodes[1].childNodes[0].value;
        let btnCopy = document.getElementById("btnCopy");
        let btnCopyValue = document.getElementById("btnCopyValue");

        navigator.clipboard.writeText(textoCopiado).then(() => {
            setAlertModal(true)
            setMessageAlert('Texto copiado!')
            setTypeAlert('success')

            setTimeout(() => {
                setAlertModal(false)
            }, 3000)
        })
    }

    function copiarLink2() {
        let textoCopiado = document.getElementsByClassName("inputCodeBar")[0].childNodes[1].childNodes[0].value;
        let btnCopy = document.getElementById("btnCopy");
        let btnCopyValue = document.getElementById("btnCopyValue");

        navigator.clipboard.writeText(textoCopiado).then(() => {
            setAlertModal(true)
            setMessageAlert('Texto copiado!')
            setTypeAlert('success')

            setTimeout(() => {
                setAlertModal(false)
            }, 3000)
        })
    }

    const [open, setOpen] = useState(false);
    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <>
            <Loading />
            <Stack className='alertModal' sx={alertModal ? { display: 'block' } : { display: 'none' }} spacing={2}>
                <Alert variant="filled" severity={typeAlert}>{messageAlert}</Alert>
            </Stack>
            <div className='containerUpdatePlan'>
                <div className='containerDetaisPaymentUpdatePlan'>
                    <div className='divDetaisUpdatePlan'>
                        <span className='titleUpdatePlan'>Você escolheu o plano <span className='planSelecetedUpdatePlan'>{plan.toUpperCase()}</span>:</span>
                        <span className='subTitleUpdatePlan'>A partir de agora o seu plano conta com:</span>
                        {plan == 'anual' ? (
                            <ul className='listUpdatePlan'>
                                {arrayList.map(key => {
                                    return (
                                        <li className='liUpdatePlan'>{key}</li>
                                    )
                                })}
                            </ul>
                        ) : (
                            <ul className='listUpdatePlan'>
                                {arrayList2.map(key => {
                                    return (
                                        <li className='liUpdatePlan'>{key}</li>
                                    )
                                })}
                            </ul>
                        )}

                    </div>
                    <div className='divPaymentUpdatePlan'>
                        <span className='titlePaymentUpdatePlan'>Efetue o pagamento</span>
                        <div className='divAsaasUpdatePlan'>
                            <div className='divPaymentForm' id='divPaymentForm' >
                                <FormControl sx={{ m: 1 }} className='selectUpdatePlan'>
                                    <InputLabel id="demo-multiple-name-label">Forma de pagamento</InputLabel>
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        value={paymentForm}
                                        onChange={e => {
                                            if (e.target.value == 'Débito' || e.target.value == 'Crédito') {
                                                if (window.innerHeight <= 844) {
                                                    setHeight(window.innerHeight + 1000)
                                                    setDivFormStepHeight('160vh')
                                                    setPTop(370)
                                                } else {
                                                    setHeight(window.innerHeight + 500)
                                                    setDivFormStepHeight('117vh')
                                                    setPTop(0)
                                                }
                                            } else {
                                                if (e.target.value == 'PIX') {
                                                    handlePayAsaas(e.target.value)
                                                }
                                                if (e.target.value == 'Boleto') {
                                                    handlePayAsaas(e.target.value)
                                                }
                                                setHeight('100vh')
                                                setDivFormStepHeight('70vh')
                                                setPTop(0)
                                            }
                                            setPaymentForm(e.target.value)
                                        }}
                                    >
                                        {names.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <div style={paymentForm == 'Crédito' ? { display: 'flex', flexDirection: 'column' } : paymentForm == 'Débito' ? { display: 'flex', flexDirection: 'column' } : { display: 'none' }}>
                                    <FormControl sx={{ m: 1 }} className='selectUpdatePlan'>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Nome impresso no cartão"
                                            className='divInputUpdatePlan'
                                            onChange={e => setNameCard(e.target.value)}
                                            style={{ margin: 0, width: '100%' }}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ m: 1 }} className='selectUpdatePlan'>
                                        {plan == 'trimestral' ? (
                                            <>
                                                <InputLabel id="demo-multiple-name-label">Quantida de parcelas</InputLabel>
                                                <Select
                                                    required
                                                    labelId="demo-multiple-name-label"
                                                    id="demo-multiple-name"
                                                    value={parc}
                                                    onChange={e => {
                                                        setParc(e.target.value)
                                                    }}
                                                >
                                                    {array3month.map(key => {
                                                        var total3month = 1197;
                                                        var totalParc = total3month / key;
                                                        return (
                                                            <MenuItem
                                                                key={key}
                                                                value={key}
                                                            >
                                                                <span>{key}x de </span> <CurrencyInput
                                                                    style={{ border: 'none', background: 'transparent', cursor: 'auto' }}
                                                                    defaultValue={totalParc}
                                                                    onChangeValue={(event, originalValue, maskedValue) => {
                                                                        // console.log(event, originalValue, maskedValue);
                                                                    }}
                                                                    onClick={e => setParc(key)}
                                                                    disabled
                                                                />
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </>

                                        ) : plan == 'anual' ? (
                                            <>
                                                <InputLabel id="demo-multiple-name-label">Quantida de parcelas</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-name-label"
                                                    id="demo-multiple-name"
                                                    value={parc}
                                                    onChange={e => {
                                                        setParc(e.target.value)
                                                    }}
                                                >
                                                    {array1year.map(key => {
                                                        var total1month = 3588;
                                                        var totalParc = total1month / key;
                                                        return (
                                                            <MenuItem
                                                                key={key}
                                                                value={key}
                                                            >
                                                                <span>{key}x de </span> <CurrencyInput
                                                                    style={{ border: 'none', background: 'transparent', cursor: 'auto' }}
                                                                    defaultValue={totalParc}
                                                                    onChangeValue={(event, originalValue, maskedValue) => {
                                                                        // console.log(event, originalValue, maskedValue);
                                                                    }}
                                                                    disabled
                                                                />
                                                            </MenuItem>

                                                        )
                                                    })}
                                                </Select>
                                            </>
                                        ) : ('')}

                                    </FormControl>
                                    <FormControl sx={{ m: 1 }} className='selectUpdatePlan'>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Número do cartão"
                                            className='divInputUpdatePlan'
                                            onChange={handleMaskCard}
                                            style={{ margin: 0, width: '100%' }}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ m: 1 }} className='selectUpdatePlan' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Validade do cartão"
                                            className='divInputUpdatePlan'
                                            onChange={handleMaskValidateCard}
                                            style={{ margin: 0, width: '45%' }}
                                        />
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="CVV"
                                            className='divInputUpdatePlan'
                                            onChange={e => setCvvCard(e.target.value)}
                                            style={{ margin: 0, width: '45%' }}
                                        />
                                    </FormControl>
                                    <span style={{ color: '#1A1B39', fontSize: 20, fontWeight: 400, margin: 10 }}>Dados do dono do cartão</span>
                                    <FormControl sx={{ m: 1 }} className='selectUpdatePlan'>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="CPF ou CNPJ do dono"
                                            className='divInputUpdatePlan'
                                            onChange={handleMaskCpfCnpj2}
                                            style={{ margin: 0, width: '100%' }}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ m: 1 }} className='selectUpdatePlan'>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="CEP"
                                            className='divInputUpdatePlan'
                                            onChange={handleCEP}
                                            style={{ margin: 0, width: '100%' }}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ m: 1 }} className='selectUpdatePlan' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Endereço"
                                            className='divInputUpdatePlan'
                                            onChange={e => setAddress(e.target.value)}
                                            value={address}
                                            style={{ margin: 0, width: '65%' }}
                                        />
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Número"
                                            className='divInputUpdatePlan'
                                            onChange={e => setNumber(e.target.value)}
                                            style={{ margin: 0, width: '30%' }}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ m: 1 }} className='selectUpdatePlan'>
                                        <TextField
                                            id="outlined-required"
                                            label="Complemento"
                                            className='divInputUpdatePlan'
                                            onChange={e => setComplementAddress(e.target.value)}
                                            style={{ margin: 0, width: '100%' }}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ m: 1 }} className='selectUpdatePlan'>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Telefone de contato"
                                            className='divInputUpdatePlan'
                                            onChange={handleMaskPhone}
                                            style={{ margin: 0, width: '100%' }}
                                        />
                                    </FormControl>
                                </div>
                                <div style={paymentForm == 'PIX' ? { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' } : { display: 'none' }}>
                                    <img id="qrcodeImg" />
                                    {qrcodeCopyPaste !== '' ? (
                                        <FormControl sx={{ m: 1 }} className='selectUpdatePlan' style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                                            <TextField
                                                id="outlined-required inputQrcodeCopyPaste"
                                                label="PIX copia e cola"
                                                className='inputQrcodeCopyPaste'
                                                value={qrcodeCopyPaste}
                                                style={{ margin: 0, width: '100%' }}
                                            />
                                            <button className='btnCopyLink' onClick={() => { copiarLink() }}>
                                                <FaRegCopy className='icoCopyLink' />
                                            </button>
                                        </FormControl>
                                    ) : (<Loading />)}
                                </div>
                                <div style={paymentForm == 'Boleto' ? { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 20 } : { display: 'none' }}>
                                    {codeBar !== '' ? (
                                        <FormControl sx={{ m: 1 }} className='selectUpdatePlan' style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                                            <TextField
                                                id="outlined-required inputQrcodeCopyPaste"
                                                label="Código de barras"
                                                className='inputCodeBar'
                                                value={codeBar}
                                                style={{ margin: 0, width: '100%' }}
                                            />
                                            <button className='btnCopyLink' onClick={() => { copiarLink2() }}>
                                                <FaRegCopy className='icoCopyLink' />
                                            </button>
                                        </FormControl>
                                    ) : (<Loading />)}
                                </div>
                            </div>
                        </div>
                        {confirmPayment || paymentForm == 'Crédito' ? (
                            <button className='btnPayAsaasUpdatePlan' onClick={e => handlePayAsaas(paymentForm)}>Confirmar Update</button>
                        ) : (<div></div>)}

                    </div>

                </div>
                <div className='divFooterUpdatePlan'>
                    <img src={botijoes} className='imgFooterUpdatePlan' />
                    <span className='spanFooterUpdatePlan'>Aproveite todas as novas possibilidades</span>
                </div>
            </div>
        </>
    );
}

export default UpdatePlan