import UserActionTypes from "./actions-types"

export const userLogin = (payload) => ({
    type: UserActionTypes.LOGIN,
    payload
})

export const userLogout = (payload) => ({
    type: UserActionTypes.LOGOUT,
})
