import React from 'react';
import { useState, useEffect } from 'react';
import './motoristaTech.css'
import img1 from '../../image/motoristateche.jpg'
import img2 from '../../image/motoristatechbaixo.jpg'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Modal2 from '@mui/material/Modal';
import warning from '../../image/warning.png'
import trimestral from '../../image/trimestral.png'
import anual from '../../image/anual_.png'
import Box from '@mui/material/Box';
import api from '../../service/api';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

const MotoristaTech = () => {
    const [btnSolicitatiion, setBtnSolicitation] = useState(false)
    const [modalAvisoPlan, setModalAvisoPlan] = React.useState(false)
    const [typePlan, setTypePlan] = React.useState('free')
    const [nameReseller, setNameReseller] = useState('')
    const [show, setShow] = useState(false);
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);

    const handleTermClick = (e) => {
        console.log(e)
    }

    const navigate = useNavigate();

    React.useEffect(() => {
        api
            .get("/TodosUsuariosRevenda")
            .then((response) => {
                response.data.map(key => {
                    if (key['db_name'] == currentUser.db) {
                        setTypePlan(key['module'])
                        setNameReseller(key['reseller_name'])
                    }
                })
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    const style = {
        position: 'relative',
        top: '50%',
        left: '50%',
        width: 607,
        maxHeight: 660,
        borderRadius: "39px !important",
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
    };

    function ModalAvisoPlan(props) {
        return (
            <Modal2
                open={modalAvisoPlan}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='boxModalRaioX'>
                    <div style={{ position: 'absolute', zIndex: 9, right: '-17px', top: '-10px' }}>
                        <button style={{ position: 'relative', background: 'red', color: 'white', fontWeight: 900, borderRadius: 25, width: 35, height: 35 }} onClick={() => setModalAvisoPlan(false)}>X</button>
                    </div>
                    <img src={warning} className='imgModalValidatorPage' />
                    <div className='contectModalValidatorPage'>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 32, color: '#25368C', fontWeight: 900, lineHeight: 1 }}>
                            Essa função não está disponível no plano gratuito
                        </span>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 28, fontWeight: 600, color: 'black', width: '98%', lineHeight: 1, marginTop: 10 }}>
                            Escolha um plano e desbloqueie diversas funções da plataforma
                        </span>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end', flexDirection: 'row', marginTop: 10, width: '83%' }}>
                            <img src={trimestral} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/trimestral")} />
                            <img src={anual} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/anual")} />
                        </div>
                    </div>
                </Box>
            </Modal2>
        )
    }

    return (
        <>
            <ModalAvisoPlan />
            <div className='containerSolicitationClub'>
                <div className='divImgSolicitatation'>
                    <img src={img1} className='img1Solicitation' />
                </div>
                <div className='divVantagensSolicitation'>
                    <a href='https://clubedorevendedordegas.com.br/aplicativo-motorista-tech/' target='_blank' className='btnVatagensSolicitation'>Ver vantagens</a>
                </div>
                <div className='divContenctSolicitation'>
                    <img src={img2} className='img2Solicitation' />
                </div>
                <div className='divTermsSolicitation'>
                    <a href=' https://clubedorevendedordegas.com.br/termos-de-uso-app-motorista-tech-e-onde-esta-o-entregador/' target='_blank' className='termsLinkSolicitation'>Termos de uso</a>
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="terms"
                                control={<Checkbox className='checkboxFormatedSolicitation' />}
                                label="Li e aceito os termos"
                                labelPlacement="terms"
                                className='ckeckboxTermsSolicitation'
                                onChange={e => {
                                    setBtnSolicitation(e.target.checked)
                                }}
                            />
                        </FormGroup>
                    </FormControl>
                </div>
                <div className='divBtnSolicitation' style={btnSolicitatiion ? { display: 'block' } : { display: 'none' }}>
                    <button className='btnSolicitation' onClick={e => {
                        if (typePlan !== 'free') {
                            window.open(`https://api.whatsapp.com/send?phone=5511972413559&text=Ol%C3%A1,%20gostaria%20de%20solicitar%20o%20aplicativo%20Motorista%20Tech!%0A${nameReseller.replace(' ', '%20')}`, '_blank');
                        } else {
                            setModalAvisoPlan(true)
                        }
                    }}>Solicitar agora</button>
                </div>
            </div>
        </>
    );
}

export default MotoristaTech