import { useState } from "react";
import React from 'react'
import './nfce.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import Form from 'react-bootstrap/Form';
import TableNfce from '../TableNfce'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import axios from "axios";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { letterSpacing } from "@mui/system";
import { useSelector, useDispatch } from 'react-redux';

const Nfce = () => {

    const [provider, setProvider] = React.useState([]);
    const [optionsProducts, setOptionsProducts] = React.useState([]);
    const [product, setProduct] = React.useState([]);
    const [cepApi, setCepApi] = React.useState([]);
    const [clientPhysic, setClientPhysic] = React.useState([]);
    const [clientBusiness, setClientBusiness] = React.useState([]);
    const [physicRows, setPhysicRows] = React.useState([]);
    const [businessRows, setBusinessRows] = React.useState([]);
    const [filteredData, setFilteredData] = React.useState([]);
    const [nextStatus, setNextStatus] = React.useState(false)
    const [nextAddress, setNextAddress] = React.useState(false)

    let cnpj = '';
    let razaoSocial = '';
    let logradouro = '';
    let num = '';
    let bairro = '';
    let cidade = '';
    let estado = '';
    let email = '';
    let tipoLogradouro = '';
    let sumTotal = 0;
    let aVista = '';
    let meio = '';
    let cep = '';
    let ibge = '';

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const animatedComponents = makeAnimated();

    let productValue = '';
    let productName = [];

    const productHandler = e => {
        var divProducSelected1 = document.getElementById('productSelect1')
        var divProducSelected2 = document.getElementById('productSelect2')
        var divProducSelected3 = document.getElementById('productSelect3')
        var labelProducSelected1 = document.getElementById('labelProductSelect1')
        var labelProducSelected2 = document.getElementById('labelProductSelect2')
        var labelProducSelected3 = document.getElementById('labelProductSelect3')
        var options = e;
        var value = [];
        var name = [];

        for (var i = 0, l = options.length; i < l; i++) {
            value.push(options[i].value);
            name.push(options[i].label)
            if (i == 0) {
                divProducSelected1.style.display = "block"
                divProducSelected2.style.display = "none"
                divProducSelected3.style.display = "none"
                labelProducSelected1.textContent = options[i].label + ":"
            }
            if (i == 1) {
                divProducSelected2.style.display = "block"
                divProducSelected3.style.display = "none"
                labelProducSelected2.textContent = options[i].label + ":"
            }
            if (i == 2) {
                divProducSelected3.style.display = "block"
                labelProducSelected3.textContent = options[i].label + ":"
            }
        }
        if (options.length == 0) {
            divProducSelected1.style.display = "none"
        }

        productValue = value;
        productName = name

        var total = [];
        let sumTotal = 0;
        for (var i = 0, l = productValue.length; i < l; i++) {
            product.map(key => {
                if (key['id'] == productValue[i]) {
                    total.push(key['value'])
                }
            })
        }
        for (let i = 0; i < total.length; i++) {
            sumTotal += total[i]
        }

    }
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    React.useEffect(() => {
        api
            .get("/TodosClientesFisicos"+ '/' + currentUser.db)
            .then((response) => {
                setClientPhysic(response.data);
                setPhysicRows(response.data.map(key => ({
                    id: key['id'], typeClient: "fisico", cliente: key['name'], endereco: key['street'] + ", " + key['number'] + " - " + key['neighborhood'] + " - " + key['city'] + "/" + key['state'], telefone: key['phone'], dataUltimaCompra: key['id'], cnpj: key['cpf']
                })))
                setNextStatus(true)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    React.useEffect(() => {
        api
            .get("/TodosClientesJuridicos"+ '/' + currentUser.db)
            .then((response) => {
                setClientBusiness(response.data)
                setBusinessRows(response.data.map(key => ({
                    id: key['id'], typeClient: "juridico", cliente: key['name'], endereco: key['street'] + ", " + key['number'] + " - " + key['neighborhood'] + " - " + key['city'] + "/" + key['state'], telefone: key['phone'], dataUltimaCompra: key['id'], cnpj: key['cnpj']
                })))
                setFilteredData(physicRows.concat(businessRows));
                setNextAddress(true);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, [nextStatus]);

    var rows1 = physicRows.concat(businessRows);
    React.useEffect(() => {
        api
            .get("/TodosFornecedores"+ '/' + currentUser.db)
            .then((response) => {
                setProvider(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    React.useEffect(() => {
        api
            .get("/TodosProdutos" + '/' + currentUser.db)
            .then((response) => {
                setProduct(response.data);
                setOptionsProducts(response.data.map(key => ({
                    value: key['id'],
                    label: key['name']
                })))
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    React.useEffect(() => {
        rows1.map(key => {
            let logradouroSplit = key['street'];
            let estado = key['state'];
            let cidade = key['city'];
            let estadoApi = '';

            if (estado == "Acre") {
                estadoApi = "AC";
            } else if (estado == "Alagoas") {
                estadoApi = "AL";
            } else if (estado == "Amapá") {
                estadoApi = "AP";
            } else if (estado == "Amazonas") {
                estadoApi = "AM";
            } else if (estado == "Bahia") {
                estadoApi = "BA";
            } else if (estado == "Ceará") {
                estadoApi = "CE";
            } else if (estado == "Destrito Federal" || estado == "Brasília") {
                estadoApi = "DF";
            } else if (estado == "Espírito Santo") {
                estadoApi = "ES";
            } else if (estado == "Goiás") {
                estadoApi = "GO";
            } else if (estado == "Maranhão") {
                estadoApi = "MA";
            } else if (estado == "Mato Grosso") {
                estadoApi = "MT";
            } else if (estado == "Mato Grosso do Sul") {
                estadoApi = "MS";
            } else if (estado == "Minas Gerais") {
                estadoApi = "MG";
            } else if (estado == "Pará") {
                estadoApi = "PA";
            } else if (estado == "Paraíba") {
                estadoApi = "PB";
            } else if (estado == "Paraná") {
                estadoApi = "PR";
            } else if (estado == "Pernambuco") {
                estadoApi = "PE";
            } else if (estado == "Piauí") {
                estadoApi = "PI";
            } else if (estado == "Rio de Janeiro") {
                estadoApi = "RJ";
            } else if (estado == "Rio Grande do Norte") {
                estadoApi = "RN";
            } else if (estado == "Rio Grande do Sul") {
                estadoApi = "RS";
            } else if (estado == "Rondônia") {
                estadoApi = "RO";
            } else if (estado == "Roraima") {
                estadoApi = "RR";
            } else if (estado == "Santa Catarina") {
                estadoApi = "SC";
            } else if (estado == "São Paulo") {
                estadoApi = "SP";
            } else if (estado == "Sergipe") {
                estadoApi = "SE";
            } else if (estado == "Tocantins") {
                estadoApi = "TO";
            }

            axios
                .get("https://viacep.com.br/ws/" + estadoApi + "/" + cidade + "/" + logradouroSplit + "/json/")
                .then((response) => {
                    response.data.map(k => {
                        setCepApi([...cepApi, { id: key['id'], type: key['typeClient'], cep: k['cep'], ibge: k['ibge'], uf: k['uf'] }]);
                    })
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        })

    }, [nextAddress])

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        const productSelect1 = data.productSelect1;
        const productSelect2 = data.productSelect2;
        const productSelect3 = data.productSelect3;
        const bruto = '';
        const pis = '';
        const cofins = '';

        const idType = data.client;
        const idTypeSplit = idType.split('--');
        const id = idTypeSplit[0];
        const typeClient = idTypeSplit[1];

        if (data.aVista == 'yes') {
            aVista = true;
        } else {
            aVista = false;
        }

        if (data.meio == 'dinheiro') {
            meio = '01';
        } else if (data.meio == 'cheque') {
            meio = '02';
        } else if (data.meio == 'credito') {
            meio = '03';
        } else if (data.meio == 'debito') {
            meio = '04';
        } else if (data.meio == 'loja') {
            meio = '05';
        } else if (data.meio == 'alimentacao') {
            meio = '10';
        } else if (data.meio == 'refeicao') {
            meio = '11';
        } else if (data.meio == 'presente') {
            meio = '13';
        } else if (data.meio == 'boleto') {
            meio = '15';
        } else if (data.meio == 'deposito') {
            meio = '16';
        } else if (data.meio == 'pix') {
            meio = '17';
        } else if (data.meio == 'transferencia') {
            meio = '18';
        } else if (data.meio == 'fidelidade') {
            meio = '19';
        } else if (data.meio == 'semPagamento') {
            meio = '90';
        } else if (data.meio == 'outros') {
            meio = '99';
        }


        const headers = {
            'headers': {
                'x-api-key': '2da392a6-79d2-4304-a8b7-959572c7e44d'
            }
        }

        const itens = [];

        product.map(key => {
            productName.map(p => {
                if (key['name'] == p) {
                    let ncm = '';
                    let totalValue = '';
                    let valueProduct = '';
                    let splitName = p.split(" ");
                    let splitGas = splitName[0];
                    let qnt = 1;
                    let nItem = 1;

                    if (splitGas == 'Gás' || splitGas == 'Vasilhame') {
                        ncm = "27111910";
                    } else if (splitGas == 'Água' || splitGas == 'Galão') {
                        ncm = "2201";
                    } else {
                        ncm = "27111990";
                    }

                    if (p == productName[0]) {
                        qnt = Number(productSelect2);
                        nItem = 2;
                        let valueProductSplit = key['value'].split(",");
                        if (valueProductSplit[1] !== undefined) {
                            valueProduct = valueProductSplit[0] + "." + valueProductSplit[1];
                        } else {
                            valueProduct = valueProductSplit[0];
                        }
                        let multi = Number(valueProduct) * Number(productSelect1);
                        totalValue = multi;
                    } else if (p == productName[1]) {
                        qnt = Number(productSelect2);
                        nItem = 2;
                        let valueProductSplit = key['value'].split(",");
                        if (valueProductSplit[1] !== undefined) {
                            valueProduct = valueProductSplit[0] + "." + valueProductSplit[1];
                        } else {
                            valueProduct = valueProductSplit[0];
                        }
                        let multi = Number(valueProduct) * Number(productSelect2);
                        totalValue = multi;
                    } else if (p == productName[2]) {
                        qnt = Number(productSelect2);
                        nItem = 2;
                        let valueProductSplit = key['value'].split(",");
                        if (valueProductSplit[1] !== undefined) {
                            valueProduct = valueProductSplit[0] + "." + valueProductSplit[1];
                        } else {
                            valueProduct = valueProductSplit[0];
                        }
                        let multi = Number(valueProduct) * Number(productSelect3);
                        totalValue = multi;
                    }

                    let productNfce = {
                        "nItem": nItem,
                        "prod": {
                            "cProd": "5656",
                            "cEAN": "SEM GTIN",
                            "xProd": p,
                            "NCM": "00",
                            "CFOP": "5656",
                            "uCom": "kg",
                            "qCom": qnt,
                            "vUnCom": Number(valueProduct),
                            "vProd": Number(valueProduct),
                            "cEANTrib": "SEM GTIN",
                            "uTrib": "kg",
                            "qTrib": qnt,
                            "vUnTrib": Number(valueProduct),
                            "indTot": 0
                        },
                        "imposto": {
                            "ISSQN": {
                                "vBC": 0,
                                "vAliq": 0,
                                "vISSQN": 0,
                                "cMunFG": "3534401",
                                "cListServ": "07.21",
                                "indISS": 1,
                                "indIncentivo": 1
                            },
                            "PIS": {
                                "PISNT": {
                                    "CST": "04"
                                }
                            },
                            "COFINS": {
                                "COFINSNT": {
                                    "CST": "04"
                                }
                            }
                        }
                    };
                    itens.push(productNfce);
                    sumTotal += totalValue
                }
            })
        })

        rows1.map(key => {
            if (key['id'] == id) {
                if (key['typeClient'] == typeClient) {
                    if (key['typeClient'] == 'juridico') {
                        cnpj = key['cnpj'].replace(".", "").replace(".", "").replace("/", "").replace("-", "");
                    } else if (key['typeClient'] == 'fisico') {
                        cnpj = key['cpf'].replace(".", "").replace(".", "").replace("/", "").replace("-", "");
                    }

                    razaoSocial = key['client'];
                    email = key['email'];
                    let enderecoCompleto = key['endereco'].split(' - ');
                    let logradouroSplit = enderecoCompleto[0].split(', ');
                    logradouro = logradouroSplit[0];
                    num = logradouroSplit[1];
                    bairro = enderecoCompleto[1];
                    let logradouroTypeSplit = logradouroSplit[0].split(" ");
                    tipoLogradouro = logradouroTypeSplit[0];
                    let cidadeEstado = enderecoCompleto[2].split("/");
                    cidade = cidadeEstado[0];
                }
            }
        })

        let dest = [];

        if (typeClient == 'juridico') {
            dest = {
                "CNPJ": cnpj,
                "xNome": razaoSocial,
                "enderDest": {
                    "xLgr": logradouro,
                    "nro": num,
                    "xCpl": "0",
                    "xBairro": bairro,
                    "cMun": ibge,
                    "xMun": cidade,
                    "UF": "SP",
                    "xPais": "Brasil"
                },
                "indIEDest": 9,
                "IE": "120520998116",
                "IM": "string"
            }
        } else if (typeClient == 'fisico') {
            dest = {
                "CPF": cnpj,
                "xNome": razaoSocial,
                "enderDest": {
                    "xLgr": logradouro,
                    "nro": num,
                    "xCpl": "0",
                    "xBairro": bairro,
                    "cMun": ibge,
                    "xMun": cidade,
                    "UF": "SP",
                    "xPais": "Brasil"
                },
                "indIEDest": 9,
                "IE": "120520998116",
                "IM": "string"
            }
        }
        cepApi.map(key => {
            if (key['id'] == data.provider) {
                cep = key['cep'];
                ibge = key['ibge'];
                estado = key['uf'];
            }
        })
        var timestamp = new Date().getTime();
        var dateISO = new Date().toISOString();

        const dados = {
            "infNFe": {
                "versao": "4.00",
                "ide": {
                    "cUF": 35,
                    "natOp": "Venda",
                    "serie": 0,
                    "nNF": 6,
                    "dhEmi": dateISO,
                    "tpNF": 0,
                    "idDest": 1,
                    "cMunFG": "3550308",
                    "tpImp": 0,
                    "tpEmis": 1,
                    "finNFe": 1,
                    "indFinal": 1,
                    "indPres": 2,
                    "indIntermed": 0,
                    "procEmi": 0,
                    "verProc": "4.00"
                },
                "emit": {
                    "CNPJ": "28705068000195"
                },
                "dest": dest,
                "det": itens,
                "total": {
                    "ICMSTot": {
                        "vBC": 0,
                        "vICMS": 0,
                        "vICMSDeson": 0,
                        "vFCPUFDest": 0,
                        "vICMSUFDest": 0,
                        "vICMSUFRemet": 0,
                        "vFCP": 0,
                        "vBCST": 0,
                        "vST": 0,
                        "vFCPST": 0,
                        "vFCPSTRet": 0,
                        "qBCMono": 0,
                        "vICMSMono": 0,
                        "qBCMonoReten": 0,
                        "vICMSMonoReten": 0,
                        "qBCMonoRet": 0,
                        "vICMSMonoRet": 0,
                        "vProd": Number(sumTotal),
                        "vFrete": 0,
                        "vSeg": 0,
                        "vDesc": 0,
                        "vII": 0,
                        "vIPI": 0,
                        "vIPIDevol": 0,
                        "vPIS": 0,
                        "vCOFINS": 0,
                        "vOutro": 0,
                        "vNF": Number(sumTotal),
                        "vTotTrib": 0
                    }
                },
                "transp": {
                    "modFrete": 9
                },
                "pag": {
                    "detPag": [
                        {
                            "tPag": "01",
                            "vPag": 1
                        }
                    ]
                }
            },
            "ambiente": "homologacao",
            "referencia": cnpj + "--" + timestamp
        }

        // axios.post("https://api.sandbox.plugnotas.com.br/nfce", dados, headers);

        // setOpen(true)
        // setTimeout(function () {
        //     window.location.reload(1);
        // }, 5000);

    }

    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    function OffCanvasExample({ ...props }) {
        return (
            <>
                <Loading />
                <Button variant="primary" onClick={handleShow} className="me-2 button">
                    {'Nova Nota de Produto'}
                </Button>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header >
                        <Offcanvas.Title>Nova Nota de Produto</Offcanvas.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form className="input" onSubmit={submitForm}>
                            <div className="divCadastro">
                                <InputLabel htmlFor="modelVehicle">Cliente *</InputLabel>
                                <Form.Select
                                    name='client'
                                    className="inputCadastro"
                                    required
                                >
                                    <option value='' >Selecione um cliente</option>
                                    {rows1.map(k => {
                                        return (
                                            <option value={k['id'] + "--" + k['typeClient']}>{k['cliente']}</option>
                                        )
                                    })}

                                </Form.Select>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="modelVehicle">Produtos *</InputLabel>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    isMulti
                                    options={optionsProducts}
                                    name='product'
                                    onChange={productHandler}
                                    required
                                />
                                <div className="productSelect1" id="productSelect1" style={{ display: 'none' }}>
                                    <div className="divProduct">
                                        <label className="labelProductSelect1" id="labelProductSelect1"></label>
                                        <input
                                            type="number"
                                            name="productSelect1"
                                            id="inputProductSelect1"
                                            className="inputCadastro"
                                            defaultValue='1'
                                        />
                                    </div>
                                </div>
                                <div className="productSelect2" id="productSelect2" style={{ display: 'none' }}>
                                    <div className="divProduct">
                                        <label className="labelProductSelect2" id="labelProductSelect2">Tipo de cliente</label>
                                        <input
                                            type="number"
                                            name="productSelect2"
                                            id="inputProductSelect2"
                                            className="inputCadastro"
                                            defaultValue='1'
                                        />
                                    </div>
                                </div>
                                <div className="productSelect3" id="productSelect3" style={{ display: 'none' }}>
                                    <div className="divProduct">
                                        <label className="labelProductSelect3" id="labelProductSelect3"></label>
                                        <input
                                            name="productSelect3"
                                            id="inputProductSelect3"
                                            className="inputCadastro"
                                            defaultValue='1'
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="modelVehicle">Pagamento a vista *</InputLabel>
                                <Form.Select
                                    name='aVista'
                                    className="inputCadastro"
                                    required
                                >
                                    <option value='' >Selecione um opção</option>
                                    <option value='yes' >Sim</option>
                                    <option value='no' >Não</option>
                                </Form.Select>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="modelVehicle"> Forma de pagamento *</InputLabel>
                                <Form.Select
                                    name='meio'
                                    className="inputCadastro"
                                    required
                                >
                                    <option value='' >Selecione um opção</option>
                                    <option value='dinheiro' >Dinheiro</option>
                                    <option value='cheque' >Cheque</option>
                                    <option value='credito' >Cartão de Crédito</option>
                                    <option value='debito' >Cartão de Débito</option>
                                    <option value='loja' >Crédito Loja</option>
                                    <option value='alimentacao' >Vale Alimentação</option>
                                    <option value='refeicao' >Vale Refeição</option>
                                    <option value='presente' >Vale Presente</option>
                                    <option value='combustivel' >Vale Combustível</option>
                                    <option value='boleto' >Boleto Bancário</option>
                                    <option value='deposito' >Depósito Bancário</option>
                                    <option value='pix' >Pagamento Instantâneo (PIX)</option>
                                    <option value='transferencia' >Transferência bancária, Carteira Digital</option>
                                    <option value='fidelidade' >Programa de fidelidade, Cashback, Crédito Virtual</option>
                                    <option value='semPagamento' >Sem pagamento</option>
                                    <option value='outros' >Outros</option>
                                </Form.Select>
                            </div>

                            <div className="buttonLogin">
                                <button className="buttonSecundary" >
                                    Gerar NFCe
                                </button>
                            </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );

    }

    return (
        <>
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Notas do Consumidor</h5>
                    </div>
                    <div>
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                    </div>
                </div>
                <Card className="cardContainer"  style={{ width: '100%',  borderRadius: '10px', padding: '10px' }}>
                    <TableNfce />
                </Card>
            </div >
        </>
    )
}

export default Nfce