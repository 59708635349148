import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableProviders.css'
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'
import { useSelector, useDispatch } from 'react-redux';
import { TbEyeSearch } from "react-icons/tb";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'provider',
    numeric: false,
    disablePadding: false,
    label: 'Fornecedor',
  },
  {
    id: 'razao',
    numeric: false,
    disablePadding: false,
    label: 'Razão Social',
  },
  {
    id: 'cnpj',
    numeric: false,
    disablePadding: false,
    label: 'CNPJ',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'E-mail',
  },
  {
    id: 'inscricaoEstadual',
    numeric: false,
    disablePadding: false,
    label: 'Inscrição Estadual',
  },
  {
    id: 'inscricaoMunicipal',
    numeric: false,
    disablePadding: false,
    label: 'Inscrição Municipal',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Telefone',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Endereço',
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: false,
    label: 'Ações',
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className="colunmLabel"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([])
  const [clientBusiness, setClientBusiness] = React.useState([]);
  const [painelModal, setPainelModal] = React.useState(false);
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [text, setText] = React.useState();
  const [modalCancel, setModalCancel] = React.useState(false);
  const [cnpj, setCnpj] = React.useState('');
  const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
  let result = ''
  let valorResult = ''

  React.useEffect(() => {
    api
      .get("/TodosFornecedores" + '/' + currentUser.db)
      .then((response) => {
        setClientBusiness(response.data)
        setRows(response.data.map(key => ({
          id: key['id'], provider: key['name'], cnpj: key['cnpj'], razao: key['razao'], email: key['email'], inscricaoEstadual: key['inscricao_estadual'], inscricaoMunicipal: key['inscricao_municipal'], phone: key['phone'], address: key['address']
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleModalClose = () => {
    setPainelModal(false)
    setEditModal(false)
    setModalCancel(false)
    setText("");
  };

  const submitModalEdit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    let phone = data.telefoneProviderEdit.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");
    const dados = {
      id: idModal,
      name: data.nameProviderEdit,
      razao: data.razaoProviderEdit,
      cnpj: data.cnpjProviderEdit,
      address: data.enderecoProviderEdit,
      email: data.emailProviderEdit,
      inscricao_estadual: data.estadualProviderEdit,
      inscricao_municipal: data.municipalProviderEdit,
      phone: phone,
      obs: data.obsProviderEdit,
      db: currentUser.db
    }

    api.put("/AtualizarCadastroFornecedor", dados);

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const handleCancelOrdered = async (e) => {

    api.delete('/DeletarFornecedor/' + idModal + '/' + currentUser.db)

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalPainel(props) {
    return (
      <Modal
        show={painelModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Detalhes
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do fornecedor</h4>
          </div>
          {clientBusiness.map(key => {
            if (key['id'] == idModal) {
              return (
                <Table striped bordered hover className="tableProfile">
                  <tbody>
                    <tr>
                      <td className="labelConfirm">Fornecedor</td>
                      <td>{key['name']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Razão Social</td>
                      <td>{key['razao']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Inscrição Estadual</td>
                      <td>{key['inscricao_estadual']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Inscrição Municipal</td>
                      <td>{key['inscricao_municipal']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">CNPJ</td>
                      <td>
                        <InputMask className='inputMaskPainel' mask="99.999.999/9999-99" defaultValue={key['cnpj']} disabled />
                      </td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Endereço</td>
                      <td>{key['address']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">E-mail</td>
                      <td>{key['email']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Telefone</td>
                      <td> <InputMask className='inputMaskPainel' mask="(99) 9999-9999" defaultValue={key['phone']} disabled /></td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Observação</td>
                      <td>{key['obs']}</td>
                    </tr>
                  </tbody>
                </Table>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )

  }

  const handleMask = (e) => {
    let inputPhone = document.getElementById('telefoneProvider');
    let phoneMask = e.target.value
    phoneMask = phoneMask.replace(/\D/g, '')
    phoneMask = phoneMask.replace(/(\d{2})(\d)/, "($1) $2")
    phoneMask = phoneMask.replace(/(\d)(\d{4})$/, "$1-$2")

    inputPhone.value = phoneMask
  }

  function ModalEdit(props) {
    return (
      <Modal
        show={editModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Editar fornecedor
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do fornecedor</h4>
          </div>
          {clientBusiness.map(key => {
            if (key['id'] == idModal) {
              let phoneMask = key['phone']
              phoneMask = phoneMask.replace(/\D/g, '')
              phoneMask = phoneMask.replace(/(\d{2})(\d)/, "($1) $2")
              phoneMask = phoneMask.replace(/(\d)(\d{4})$/, "$1-$2")
              setCnpj(key['cnpj'])
              return (
                <form className="input" onSubmit={submitModalEdit}>
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Fornecedor:</td>
                        <td>
                          <input
                            required
                            name="nameProviderEdit"
                            className="inputCadastro"
                            defaultValue={key['name']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Razão Social:</td>
                        <td>
                          <input
                            required
                            name="razaoProviderEdit"
                            className="inputCadastro"
                            defaultValue={key['razao']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">CNPJ:</td>
                        <td>
                          <InputMask required className='inputCadastro' name="cnpjProviderEdit" mask="99.999.999/9999-99" defaultValue={key['cnpj']} />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Inscrição Estadual:</td>
                        <td>
                          <input

                            name="estadualProviderEdit"
                            className="inputCadastro"
                            defaultValue={key['inscricao_estadual']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Inscrição Municipal:</td>
                        <td>
                          <input

                            name="municipalProviderEdit"
                            className="inputCadastro"
                            defaultValue={key['inscricao_municipal']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Endereço</td>
                        <td>
                          <input
                            required
                            name="enderecoProviderEdit"
                            className="inputCadastro"
                            defaultValue={key['address']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">E-mail</td>
                        <td>
                          <input
                            required
                            name="emailProviderEdit"
                            className="inputCadastro"
                            defaultValue={key['email']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Telefone</td>
                        <td>
                          <input
                            required
                            id='telefoneProvider'
                            className='inputCadastro'
                            name="telefoneProviderEdit"
                            onChange={handleMask}
                            defaultValue={phoneMask}
                            maxlength="15"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Observação</td>
                        <td>
                          <Form.Control
                            className="textAreaForm"
                            as="textarea"
                            style={{ height: '100px' }}
                            name='obsProviderEdit'
                            value={key['obs']}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Atualizar fornecedor
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalCancelar(props) {
    return (
      <Modal
        show={modalCancel}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Excluir fornecedor
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Você tem certeza que deseja excluir o fornecedor selecionado?</h4>
          <div className="cancelOrder">
            <Button onClick={handleCancelOrdered} className="cancelButton true">Sim</Button>
            <Button onClick={handleModalClose} className="cancelButton false">Não</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  const [open, setOpen] = useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  const columns = [
    { title: "Fornecedor", field: "provider" },
    { title: "Razão Social", field: "razao" },
    { title: "CNPJ", field: "cnpj" },
    { title: "E-mail", field: 'email' },
    { title: "Inscrição Estadual", field: 'inscricaoEstadual' },
    { title: "Inscrição Municipal", field: 'inscricaoMunicipal' },
    { title: "Telefone", field: 'phone', render: (rowData) => <InputMask className='inputMaskPainel' mask={rowData.phone.length == 10 ? "(99) 9999-9999" : rowData.phone.length == 11 ? "(99) 9 9999-9999" : ""} defaultValue={rowData.phone} /> },
    { title: "Endereço", field: 'address' }
  ]

  rows.forEach(item => {
    if (item.inscricaoEstadual == '') {
      item.inscricaoEstadual = 'Não informado'
    }
    if (item.inscricaoMunicipal == '') {
      item.inscricaoMunicipal = 'Não Informado'
    }
  })

  return (
    <>
      <Loading />
      <ModalCancelar />
      <ModalPainel />
      <ModalEdit />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title=""
            columns={columns}
            data={rows}
            actions={[
              {
                icon: () => <TbEyeSearch className='perfilAction' />,
                tooltip: 'Detalhes',
                onClick: (event, rowData) => {
                  setPainelModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineEdit className='perfilAction' />,
                tooltip: 'Editar',
                onClick: (event, rowData) => {
                  setEditModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineDelete className='perfilAction' />,
                tooltip: 'Deletar',
                onClick: (event, rowData) => {
                  setModalCancel(true)
                  setIdModal(rowData.id)
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: false,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
          />
          {/* <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${row['id']}`;
                    let dateDelivery = '';
                    if (row.horarioDaEntrega !== null) {
                      dateDelivery = row.horarioDaEntrega
                    }
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row['id']}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="left"
                          className="clientTable"
                        >
                          {row.provider}
                        </TableCell>

                        <TableCell align="left">
                          {row.razao}
                        </TableCell>

                        <TableCell align="left">
                          <InputMask className='inputMaskPainel' mask="99.999.999/9999-99" value={row.cnpj} disabled />
                        </TableCell>

                        <TableCell align="left">
                          {row.email}
                        </TableCell>

                        <TableCell align="left" >
                          {row.inscricao_estadual}
                        </TableCell>

                        <TableCell align="left" >
                          {row.inscricao_municipal}
                        </TableCell>

                        <TableCell align="left" >
                        <InputMask className='inputMask' mask="(99) 9999-9999" value={row.phone} disabled="disabled" />
                        </TableCell>

                        <TableCell align="left" >
                          {row.address}
                        </TableCell>
                        
                        <TableCell align="left" >
                          <div className="buttonContainer">
                            <Tooltip title="Perfil" className='buttonActionTable' onClick={() => {
                              setPainelModal(true)
                              setIdModal(row.id)
                            }}>
                              <AiOutlineUser className='perfilAction' />
                            </Tooltip>
                            <Tooltip title="Editar" className='buttonActionTable' onClick={() => {
                              setEditModal(true)
                              setIdModal(row.id)
                              setText("Atualizar");
                            }}>
                              <AiOutlineEdit className='perfilAction' />
                            </Tooltip>
                            <Tooltip title="Deletar" className='buttonActionTable' onClick={() => {
                              setModalCancel(true)
                              setIdModal(row.id)
                              setText('Sim')
                            }}>
                              <AiOutlineDelete className='perfilAction' />
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
      </Box>
    </>
  );
}
