import { useState } from "react";
import React from 'react'
import './solicitations.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import TableSolicitation from '../TableSolicitation'
import InputLabel from '@mui/material/InputLabel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputMask from 'react-input-mask';
import { useSelector, useDispatch } from 'react-redux';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const Solicitations = () => {

    const [show, setShow] = useState(false);
    const [revenda, setRevenda] = useState('');
    const [tipoInstalacao, setTipoInstalacao] = useState('');
    const [distribuidora, setDistribuidora] = useState('')
    const [email, setEmail] = useState('')
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };

    return (
        <>
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Meu chamados</h5>
                    </div>
                    {/* <div>
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                    </div> */}
                </div>
                <Card className="cardContainer" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableSolicitation />
                </Card>
            </div >
        </>
    )
}

export default Solicitations