import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import api from "../../service/api";
import './tableNfe.css'
import { Modal } from "react-bootstrap";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineFilePdf, AiOutlineFileExcel } from 'react-icons/ai'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from 'react-redux';

export default function EnhancedTable() {
  const [rows, setRows] = React.useState([])
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [modalCancel, setModalCancel] = React.useState(false);
  const [providers, setProviders] = React.useState([]);

  const cnpj = '28705068000195';
  const ambiente = 'homologacao';
  const { currentAutentification } = useSelector(rootReducer => rootReducer.nuvemFiscalReducer);
  let token = '';
  if (currentAutentification !== undefined) {
    token = currentAutentification;
  }

  React.useEffect(() => {
    axios
      .get("https://api.nuvemfiscal.com.br/nfe?cpf_cnpj=" + cnpj + "&ambiente=" + ambiente + "&$top=100", {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .then((response) => {
        setRows(response.data.data.map(key => ({
          chave: key['chave'], dataEmissao: key['data_emissao'], id: key['autorizacao']['id'], status: key['status'], valor: key['valor_total'], numero: key['numero'], referencia: key['referencia']
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosFornecedores")
      .then((response) => {
        setProviders(response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  const handleModalClose = () => {
    setEditModal(false)
    setModalCancel(false)
  };

  const submitModalEdit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    let textoCorrecao = '';

    if (data.selectCorrecao == 'endereco') {
      textoCorrecao = 'Alterar o logradouro do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'razao') {
      textoCorrecao = 'Alterar a razão social do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'cpfCnpj') {
      textoCorrecao = 'Alterar o CPF ou CNPJ do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'telefone') {
      textoCorrecao = 'Alterar o telefone do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'email') {
      textoCorrecao = 'Alterar o e-mail do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'bairro') {
      textoCorrecao = 'Alterar o bairro do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'numero') {
      textoCorrecao = 'Alterar o numero do endereço do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'cep') {
      textoCorrecao = 'Alterar o CEP do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'cidade') {
      textoCorrecao = 'Alterar a cidade do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'estado') {
      textoCorrecao = 'Alterar o estado do Destinatário para ' + data.inputCorrecao;
    }

    const headers = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    const url = 'https://api.nuvemfiscal.com.br/nfe/' + idModal + '/cancelamento';

    axios.post(url, data, headers)

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 5000);
  }

  const handleCancelOrdered = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const headers = {
      headers: {
        'X-API-KEY': '2da392a6-79d2-4304-a8b7-959572c7e44d'
      }
    }

    const url = 'https://api.nuvemfiscal.com.br/nfe/' + idModal + '/cancelamento';

    axios.post(url, data, headers)

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalEdit(props) {
    return (
      <Modal
        show={editModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Solicitar alteração da nota
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <form className="input" onSubmit={submitModalEdit} enctype="multipart/form-data">
            <Table striped bordered hover className="tableProfile">
              <tbody>
                <tr>
                  <td className="labelConfirm">Correção:</td>
                  <td>
                    <Form.Select
                      required
                      name='selectCorrecao'
                      className="inputCadastro"
                    >
                      <option value='' >Selecione um opção</option>
                      <option value='endereco' >Alteração do endereço de entrega dos produtos</option>
                      <option value='bairro' >Alteração do bairro da entrega dos produtos</option>
                      <option value='numero' >Alteração do numero do edenreço de entrega dos produtos</option>
                      <option value='cep' >Alteração do CEP de entrega dos produtos</option>
                      <option value='cidade' >Alteração da cidade de entrega dos produtos</option>
                      <option value='estado' >Alteração do estado de entrega dos produtos</option>
                      <option value='razao' >Alteração do nome ou razão social do destinatário da nota fiscal</option>
                      <option value='cpfCnpj' >Alteração do CPF ou CNPJ do destinatário da nota fiscal</option>
                      <option value='telefone' >Alteração do telefone para contato com o destinatário da nota fiscal</option>
                      <option value='email' >Alteração do endereço de e-mail do destinatário da nota fiscal</option>
                    </Form.Select>
                  </td>
                  <td className="labelConfirm">Alterar para:</td>
                  <td>
                    <input
                      required
                      name="inputCorrecao"
                      className="inputCadastro"
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="buttonLogin">
              <button className="buttonSecundary" >
                Solicitar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalCancelar(props) {
    return (
      <Modal
        show={modalCancel}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Solicitar cancelamento da nota
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <form className="input" onSubmit={handleCancelOrdered} enctype="multipart/form-data">
            <Table striped bordered hover className="tableProfile">
              <tbody>
                <tr>
                  <td className="labelConfirm">Justificativa:</td>
                  <td>
                    <Form.Select
                      required
                      name='justificativa'
                      className="inputCadastro"
                    >
                      <option value='' >Selecione uma justificativa</option>
                      <option value='Erro na digitação do valor total da nota fiscal' >Erro na digitação do valor total da nota fiscal</option>
                      <option value='Produto entregue em desacordo com o pedido do cliente' >Produto entregue em desacordo com o pedido do cliente</option>
                      <option value='Duplicidade de emissão da nota fiscal' >Duplicidade de emissão da nota fiscal</option>
                      <option value='Insatisfação do cliente com os produtos ou serviços prestados' >Insatisfação do cliente com os produtos ou serviços prestados</option>
                      <option value='Produto danificado durante o transporte ou armazenamento' >Produto danificado durante o transporte ou armazenamento</option>
                    </Form.Select>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="buttonLogin">
              <button className="buttonSecundary" >
                Solicitar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  const [open, setOpen] = React.useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  const columns = [
    { title: "Data de Emissão", field: "dataEmissao" },
    { title: "Destinatario", field: 'destinatario' },
    { title: "Valor", field: 'valor' },
    { title: "Número", field: 'numero' },
    { title: "Status", field: 'status' },
  ]

  rows.forEach(item => {
    providers.map(key => {
      let cnpjProvider = key['cnpj'].replace(".", "").replace(".", "").replace("/", "").replace("-", "");
      if (cnpjProvider == item.referencia) {
        item.destinatario = key['name']
      }
    })
    var dateISO = new Date(item.dataEmissao);
    var dia = dateISO.getDate().toString();
    var diaF = (dia.length == 1) ? '0' + dia : dia;
    var mes = (dateISO.getMonth() + 1).toString();
    var mesF = (mes.length == 1) ? '0' + mes : mes;
    var anoF = dateISO.getFullYear();
    item.dataEmissao = diaF + "/" + mesF + "/" + anoF;

    if (typeof item.valor === 'number') {
      item.valor = 'R$ ' + item.valor.toFixed(2).replace(".", ",");
    }

    if (item.status == 'rejeitado') {
      item.status = 'Rejeitado';
    } else if (item.status == 'autorizado') {
      item.status = 'Autorizado';
    }

  })

  return (
    <>
      <Loading />
      <ModalCancelar />
      <ModalEdit />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title="Notas de Produtos"
            columns={columns}
            data={rows}
            actions={[
              {
                icon: () => <AiOutlineFilePdf className='perfilAction' />,
                tooltip: 'Gerar PDF',
                onClick: (event, rowData) => {
                  var request = new XMLHttpRequest();
                  request.open("GET", "https://api.nuvemfiscal.com.br/nfe/eventos/" + rowData.id + "/pdf", true);
                  request.setRequestHeader('Authorization', 'Bearer ' + token)
                  request.responseType = "blob";
                  request.onload = function (e) {
                    if (this.status === 200) {
                      // `blob` response
                      // create `objectURL` of `this.response` : `.pdf` as `Blob`
                      var file = window.URL.createObjectURL(this.response);
                      var a = document.createElement("a");
                      a.href = file;
                      a.download = this.response.name || "nfe-" + (+new Date());
                      document.body.appendChild(a);
                      a.click();
                      // remove `a` following `Save As` dialog, 
                      // `window` regains `focus`
                      // window.onfocus = function () {
                      //   document.body.removeChild(a)
                      // }
                    };
                  };
                  request.send();
                }
              },
              {
                icon: () => <AiOutlineFileExcel className='perfilAction' />,
                tooltip: 'Gerar XML',
                onClick: (event, rowData) => {
                  var request = new XMLHttpRequest();
                  request.open("GET", "https://api.nuvemfiscal.com.br/nfe/eventos/" + rowData.id + "/xml", true);
                  request.setRequestHeader('Authorization', 'Bearer ' + token)
                  request.responseType = "blob";
                  request.onload = function (e) {
                    if (this.status === 200) {
                      // `blob` response
                      // create `objectURL` of `this.response` : `.pdf` as `Blob`
                      var file = window.URL.createObjectURL(this.response);
                      var a = document.createElement("a");
                      a.href = file;
                      // a.target = '_blank';
                      a.download = this.response.name || "nfe-" + (+new Date());
                      document.body.appendChild(a);
                      a.click();
                      // remove `a` following `Save As` dialog, 
                      // `window` regains `focus`
                      // window.onfocus = function () {
                      //   document.body.removeChild(a)
                      // }
                    };
                  };
                  request.send();
                }
              },
              // {
              //   icon: () => <AiOutlineEdit className='perfilAction' />,
              //   tooltip: 'Editar',
              //   onClick: (event, rowData) => {
              //     setEditModal(true)
              //     setIdModal(rowData.id)
              //   }
              // },
              {
                icon: () => <AiOutlineDelete className='perfilAction' />,
                tooltip: 'Deletar',
                onClick: (event, rowData) => {
                  setModalCancel(true)
                  setIdModal(rowData.id)
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: false,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
          />
        </Paper>
      </Box>
    </>
  );
}
