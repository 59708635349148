import React from 'react'
import './index.css'
import Routes from './Routes'
import 'bootstrap/dist/css/bootstrap.min.css';
import Helmet from 'react-helmet'
import { createRoot } from 'react-dom/client';
import api from "./service/api";
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { configurationTheme } from './redux/configurationTheme/actions'
import { useSelector, useDispatch } from 'react-redux';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Login from './pages/Login';
import CreatePayReseller from './pages/CreatePayReseller/index.js';
import { useNavigate } from "react-router-dom";

const container = document.getElementById('root');
const root = createRoot(container);

const handleClick = (path) => {
  // const navigate = useNavigate();
  // useNavigate(path);
}





root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor} >
      <Helmet>
        <title>Clube do Gás</title>
        <meta name="description" content="Clube do Gás" />
        <meta property="og:title" content="Clube do Gás" />
        <meta property="og:description" content="Clube do Gás" />

        <meta property="og:image" content="https://api.clubedorevendedordegas.com.br/static/media/Prancheta_7.b38765c7e45aa758b8f0.png" />

      </Helmet>
      <Routes />
    </PersistGate>
  </Provider >
)
