import React from 'react';
import { useState, useEffect } from 'react';
import './mentoringVideo.css'
import {
    Box,
    Grid,
    Stack,
    Typography,
    Alert
} from '@mui/material';

import { Card } from "react-bootstrap";

import AnalyticEcommerce from '../../components/cards/statistics/AnalyticEcommerce';
import MainCard from '../../components/MainCard';
import api from '../../service/api';

import {
    AppCurrentVisits,
} from '../../sections/@dashboard/app';

import { useTheme } from '@mui/material/styles';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import InputLabel from '@mui/material/InputLabel';
import CurrencyInput from 'react-currency-input-field';
import anuncion from '../../image/anuncio.png';
import { set } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import icon from '../../image/truck.png';
import InputMask from 'react-input-mask';
import logo from '../../image/islagas.png'
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { configurationTheme } from '../../redux/configurationTheme/actions'
import image_dash from '../../image/image_dash.png'
import { CiCircleChevRight } from "react-icons/ci";
import painel from '../../image/painel.png'
import app_cliente from '../../image/app-cliente.png'
import app_motorista from '../../image/app-motorista.png'
import gerar_imagem from '../../image/gerar-imagem.png'
import google_meu_negocio from '../../image/google-meu-negocio.png'
import localizacaomotorista from '../../image/localizacaomotorista.png'
import redes_sociais from '../../image/redes-sociais.png'
import pedido_whatsapp from '../../image/whatsapp.png'
import relatorioIco from '../../image/relatorios.png';
import sandra_ellipse from '../../image/sandra_ellipse.png'
import { Link, useParams } from 'react-router-dom';
import YouTube from 'react-youtube';
import { usePagination } from '@mui/lab';

const MentoringVideo = () => {
    const theme = useTheme();

    const [ordered, setOrdered] = useState([]);
    const [saleChanell, setSaleChanell] = useState([]);
    const [driver, setDriver] = useState([]);
    const [cBusiness, setCBusiness] = useState([]);
    const [CategoryBusiness, setCategoryBusiness] = useState([]);
    const [payment, setPayment] = useState([]);
    const [sector, setSector] = useState([]);
    const [products, setProducts] = useState([]);
    const [plusGrafic, setPlusGrafic] = useState(false);
    const [plusGraficText, setplusGraficText] = useState('CARREGAR MAIS');
    const [driverLocalization, setDriverLocalization] = React.useState([]);
    const [localization, setLocalization] = React.useState([]);
    const [orderedTable, setOrderedTable] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [clientPhysicAll, setClientPhysicAll] = React.useState([]);
    const [clientBusinessAll, setClientBusinessAll] = React.useState([]);

    const [localizacao, setLocalizacao] = useState();
    const [notas, setNotas] = useState();
    const [geradorImagem, setGeradorImagem] = useState();
    const [whatsapp, setWhatsapp] = useState();
    const [relatorio, setRelatorio] = useState();
    const [appCliente, SetAppClient] = useState();
    const [marketing, setMarketing] = useState();
    const [eventTest, setEventTest] = useState();

    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    const [widthWindow, setWidthWindo] = React.useState(window.innerWidth)
    const [height, setHeight] = React.useState("500px");
    const [videoId, setVideoId] = useState('')
    const [videoTitle, setVideoTitle] = useState('')
    const [videoDescription, setVideoDescription] = useState('')
    const [comments, setComments] = useState([])
    const [textComment, setTextComment] = useState('')
    const [userName, setUserName] = useState('');
    const [alertModal, setAlertModal] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('error');

    let user_id = '';
    if (currentUser !== undefined) {
        user_id = currentUser.id;
    }


    setInterval(() => {
        if (widthWindow < 500) {
            setHeight('300px')
        }
    }, 1000);

    const opts = {
        height: height,
        width: '100%',
        playerVars: {
            autoplay: 0,
            enablejsapi: 1,
        },
    };

    const checkElapsedTime = (e) => {
        setEventTest(e.target)
    };

    const { id } = useParams()

    // const comments = [
    //     {
    //         name: 'José Carlos',
    //         comment: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    //     },
    //     {
    //         name: 'Maria Antonieta',
    //         comment: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    //     }
    // ]

    useEffect(() => {
        api
            .get('/Video/' + id)
            .then(response => {
                let splitVideoId = response.data.url_video.split('https://www.youtube.com/watch?v=')
                setVideoId(splitVideoId[1])
                setVideoTitle(response.data.title)
                setVideoDescription(response.data.description)
            })
    }, [])

    useEffect(() => {
        api
            .get('/VideoComentario/' + id)
            .then(response => {
                setComments(response.data)
            })
    }, [])

    const handleComments = () => {
        api
            .post('/CadastrarComentarioVideo', {
                video_id: id,
                user_name: userName,
                comment: textComment,
                status: 'em analise'
            }).then(response => {
                setComments([...comments, {
                    video_id: id,
                    user_name: userName,
                    comment: textComment,
                    status: 'em analise'
                }])
                setAlertModal(true)
                setMessageAlert('Comentário feito com sucesso')
                setTypeAlert('success')
                setTimeout(() => {
                    setAlertModal(false)
                }, 3000)
            })
        setTextComment('')
    }
    let db = 'clubeApi';
    if (currentUser !== undefined || currentUser !== null) {
        db = currentUser.db
    }
    useEffect(() => {
        if (currentUser.revenda) {
            api
                .get("/UsuarioRevenda/" + user_id + '/' + currentUser.db)
                .then((response) => {
                    setUserName(response.data[0].name)
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        } else {
            api
                .get("/Colaborador/" + user_id + '/' + currentUser.db)
                .then((response) => {
                    setUserName(response.data[0].name)
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        }

    }, []);

    return (
        <>
            <Stack className='alertModal' sx={alertModal ? { display: 'block' } : { display: 'none' }} spacing={2}>
                <Alert variant="filled" severity={typeAlert}>{messageAlert}</Alert>
            </Stack>
            <div className='dashClubContainer' style={{ padding: 0 }}>
                <div className='containerMentoriaVideoPage'>
                    <YouTube videoId={videoId} opts={opts} onReady={checkElapsedTime} className='youtubePlayer' />
                </div>
                <div className='divTitleVideoMentoriaVideo'>
                    <span className='spanTitleVideoMentoriaVideo' >{videoTitle}</span>
                </div>
                <div className='divDetailsVideoMentoria'>
                    <span className='spanComentaryVideoMentoria'>
                        {videoDescription}
                    </span>
                </div>
                <div className='separatorDivMentoringVideo'></div>
                {/* <div className='divComentaryVideoMentoria'>
                    <div className='divCometaryVideoMentoriaTitle'>
                        <span className='spanCometaryVideoMentoriaTitle'> Comentários</span>
                    </div>
                    {comments.map(key => {
                        if (key.video_id == 1) {
                            return (
                                <div className='divComentarys'>
                                    <span className='nameTitleComentary'>{key.user_name}</span>
                                    <span className='contenctComentary'>{key.comment}</span>
                                </div>
                            )
                        }
                    })}
                    <div className='divAddComentary'>
                        <textarea className='textareaComentario' value={textComment} onChange={e => setTextComment(e.target.value)} />
                        <div className='divBtnComentar'>
                            <button className='btnComentar' onClick={handleComments}>Comentar</button>
                        </div>
                    </div>
                </div> */}
            </div >
        </>

    );
}

export default MentoringVideo