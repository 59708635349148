import nuvemFiscalActionType from "./actions-types";

const initialState = {
    currentAutentification: null
}

const nuvemFiscalReducer = (state = initialState, action) => {
    switch (action.type) {
        case nuvemFiscalActionType.AUTENTIFICATION:
            return { ...state, currentAutentification: action.payload };
        default:
            return state;
    }
};

export default nuvemFiscalReducer;