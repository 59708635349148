import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableDebt.css'
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete, AiOutlineDollar } from 'react-icons/ai'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'
import { Checkbox, Select, MenuItem } from '@material-ui/core';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import { CurrencyInput } from 'react-currency-mask';
import { MTableBody } from "material-table";
import { TbEyeSearch } from "react-icons/tb";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Tipo',
  },
  {
    id: 'due_date',
    numeric: false,
    disablePadding: false,
    label: 'Data de vencimento',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Situação',
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: false,
    label: 'Ações',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className="colunmLabel"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([])
  const [collaborators, setCollaborators] = React.useState([]);
  const [painelModal, setPainelModal] = React.useState(false);
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [text, setText] = React.useState();
  const [modalCancel, setModalCancel] = React.useState(false);
  const [modalPayment, setModalPayment] = React.useState(false);
  const [salaryModal, setSalaryModal] = React.useState(false);
  const [category, setCategory] = React.useState([])
  const [filter, setFilter] = React.useState(true)
  const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
  const [filteredData, setFilteredData] = React.useState([])
  const [filterMonth, setFilterMonth] = React.useState(new Date().getMonth() + 1)
  const [filterYear, setFilterYear] = React.useState(new Date().getFullYear())
  const [providers, setProviders] = React.useState([])
  const [nameDebt, setNameDebt] = React.useState('');
  const [totalDebts, setTotalDebts] = React.useState(0);
  const [totalPay, setTotalPay] = React.useState(0)

  React.useEffect(() => {
    api
      .get("/TodasDespesas" + '/' + currentUser.db)
      .then((response) => {
        let array = []
        let arrayValue = [];
        let arrayValuePay = [];
        let dateMonth = new Date().getMonth() + 1;
        let dateYear = new Date().getFullYear();
        setRows(response.data.map(key => ({
          id: key['id'], name: key['name'], type: key['type'], provider: key['provider'], value: key['value'], due_date: key['due_date'], status: key['status'], category: key['category'], pay_date: key['pay_date'], pay_value: key['pay_value'], obs: key['obs']
        })))
        response.data.map(key => {
          let dateClosing = key['due_date'].split('-')
          let monthClosing = Number(dateClosing[1])
          let yearClosing = Number(dateClosing[0])
          if (monthClosing === dateMonth && yearClosing === dateYear) {
            array.push({
              id: key['id'], name: key['name'], type: key['type'], provider: key['provider'], value: key['value'], due_date: key['due_date'], status: key['status'], category: key['category'], pay_date: key['pay_date'], pay_value: key['pay_value'], obs: key['obs']
            })
            if (key['status'] == 'Pago') {
              arrayValuePay.push(key['value'].replace('R$', '').replace(' ', '').replace('.', '').replace(',', '.'))
            } else {
              arrayValue.push(key['value'].replace('R$', '').replace(' ', '').replace('.', '').replace(',', '.'))
            }

          }
        })
        setFilteredData(array)
        var sum = 0;

        for (var i = 0; i < arrayValue.length; i++) {
          sum += parseFloat(arrayValue[i]);
        }
        setTotalDebts(sum)

        var sumPay = 0;

        for (var i = 0; i < arrayValuePay.length; i++) {
          sumPay += parseFloat(arrayValuePay[i]);
        }
        setTotalPay(sumPay)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosFornecedores" + '/' + currentUser.db)
      .then((response) => {
        setProviders(response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    let array = []
    let arrayValue = [];
    let arrayValuePay = [];
    rows.map(k => {
      let dateClosing = k['due_date'].split('-')
      let monthClosingFilter = Number(dateClosing[1])
      let yearClossingFilter = Number(dateClosing[0])
      if (filterYear == yearClossingFilter) {
        if (filterMonth === monthClosingFilter) {
          array.push(k)
          if (k['status'] == 'Pago') {
            arrayValuePay.push(k['value'].replace('R$', '').replace(' ', '').replace('.', '').replace(',', '.'))
          } else {
            arrayValue.push(k['value'].replace('R$', '').replace(' ', '').replace('.', '').replace(',', '.'))
          }
        }
      }
    })
    setFilteredData(array)

    var sum = 0;

    for (var i = 0; i < arrayValue.length; i++) {
      sum += parseFloat(arrayValue[i]);
    }
    setTotalDebts(sum)

    var sumPay = 0.0;

    for (var i = 0; i < arrayValuePay.length; i++) {
      sumPay += parseFloat(arrayValuePay[i]);
    }
    setTotalPay(sumPay)
  }, [filterMonth, filterYear])
  // console.log(totalPay)
  const handleModalClose = () => {
    setPainelModal(false)
    setEditModal(false)
    setModalCancel(false)
    setSalaryModal(false)
    setModalPayment(false)
  };

  const submitModalEdit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    let categoryType = data.debtEditCategoryFixa !== '' ? data.debtEditCategoryFixa : data.debtEditCategoryVariavel

    let dados = '';

    let timestampPromocional = 0;

    var timeNow = new Date().valueOf();
    var dateFormatNow = new Date(timeNow);

    if (Number(data.promotionalDateProduct) !== 0) {
      timestampPromocional = dateFormatNow.setDate(dateFormatNow.getDate() + Number(data.promotionalDateProduct));
    }

    dados = {
      id: idModal,
      name: data.debtEditName,
      provider: data.debtEditFornecedor,
      category: categoryType,
      type: data.debtEditType,
      value: data.debtEditValue,
      due_date: data.debtEditDueDate,
      obs: data.debtEditObs,
      db: currentUser.db
    }

    api.put("/AtualizarDespesa", dados)

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const submitModalPayment = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    let dados = '';

    let date = new Date();

    dados = {
      id: idModal,
      pay_date: data.debtPayDateValue,
      status: 'Pago',
      db: currentUser.db
    }

    api.put("/AtualizarDespesa", dados)

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const handleCancelOrdered = async (e) => {

    api.delete('/DeletarDespesa/' + idModal + "/" + currentUser.db)

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const handleCancelOrderedRecorrente = async (e) => {
    rows.map(key => {
      if (key['name'] == nameDebt) {
        api.delete('/DeletarDespesa/' + key['id'] + "/" + currentUser.db)
      }
    })

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalPainel(props) {
    return (
      <Modal
        show={painelModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Detalhes
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Detalhes da despesa</h4>
          </div>
          {rows.map(key => {
            if (key['id'] == idModal) {
              let category = ''
              if (key['category'] == 'aguaEnergia') {
                category = 'Contas de água e de energia elétrica'
              } else if (key['category'] == 'aluguelCondominio') {
                category = 'Aluguel e condomínio'
              } else if (key['category'] == 'folhaPagamento') {
                category = 'Folha de pagamento'
              } else if (key['category'] == 'prolabore') {
                category = 'Pró-labore'
              } else if (key['category'] == 'telefoniaInternet') {
                category = 'Telefonia e internet'
              } else if (key['category'] == 'limpeza') {
                category = 'Material de limpeza'
              } else if (key['category'] == 'seguro') {
                category = 'Seguro'
              } else if (key['category'] == 'parcelaEmprestimo') {
                category = 'Parcela de empréstimos'
              } else if (key['category'] == 'cmv') {
                category = 'Custo de mercadoria vendida(CMV)'
              } else if (key['category'] == 'impostoTributoTaxa') {
                category = 'Impostos, tributos e taxas'
              } else if (key['category'] == 'comissoes') {
                category = 'Comissões'
              } else if (key['category'] == 'taxaCartao') {
                category = 'Taxa administrativa de cartões de crédito e débito'
              } else if (key['category'] == 'embalagem') {
                category = 'Embalagens'
              } else if (key['category'] == 'frete') {
                category = 'Frete'
              } else if (key['category'] == 'vigilanciaSeguranca') {
                category = 'Serviços de vigilância e segurança'
              }

              let provider = 'Não possui fornecedor'
              if (key['provider'] !== null) {
                providers.map(k => {
                  if (k['id'] == key['provider']) {
                    provider = k['name']
                  }
                })
              }

              let dateFormated = 'Não houve pagamento'
              if (key['pay_date'] !== null) {
                dateFormated = key['pay_date']
              }

              let valorFormated = 'Não houve pagamento'

              if (key['pay_value'] !== null) {
                let value = key['pay_value'].replace('R$', '').replace('.', '').replace(',', '.')
                valorFormated = parseFloat(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
              }

              return (
                <Table striped bordered hover className="tableProfile">
                  <tbody>
                    <tr>
                      <td className="labelConfirm">Nome da despesa</td>
                      <td>{key['name']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Tipo da despesa</td>
                      <td>{key['type'] == 'fixa' ? 'Despesa Fixa' : 'Despesa Variável'}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Categoria</td>
                      <td>{category}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Fornecedor</td>
                      <td>{provider}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Data de vencimento</td>
                      <td>{new Date(key['due_date']).toLocaleDateString("pt-br")}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Data de pagamento</td>
                      <td>{new Date(dateFormated).toLocaleDateString("pt-br")}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Valor</td>
                      <td>{key['value']}</td>
                    </tr>
                    {/* <tr>
                      <td className="labelConfirm">Valor pago</td>
                      <td>{valorFormated}</td>
                    </tr> */}
                    <tr>
                      <td className="labelConfirm">Situação</td>
                      <td>{key['status']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Observação</td>
                      <td>{key['obs']}</td>
                    </tr>
                  </tbody>
                </Table>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )

  }

  const handleTypeDebt = (type) => {
    const typeSelected = type.target.value
    const divFixa = document.getElementById("debtCategoryFixa");
    const divVariavel = document.getElementById("debtCategoryVariavel");
    const selectFixa = document.getElementsByName('debtCategoryFixa')[0]
    const selectVariavel = document.getElementsByName('debtCategoryVariavel')[0]

    if (typeSelected == 'fixa') {
      divFixa.style.display = 'block'
      divVariavel.style.display = 'none'
      selectVariavel.value = ''
    } else if (typeSelected == 'variavel') {
      divVariavel.style.display = 'block'
      divFixa.style.display = 'none'
      selectFixa.value = ''
    }
  }

  const handleFornecedor = (e) => {
    const response = e.target.value;
    const divFixa = document.getElementById("debtFornecedorDiv");
    const selectFornecedor = document.getElementsByName('debtFornecedor')[0]
    if (response == 'yes') {
      divFixa.style.display = 'block'
    } else if (response == 'no') {
      divFixa.style.display = 'none'
      selectFornecedor.value = ''
    }
  }

  function ModalEdit(props) {
    return (
      <Modal
        show={editModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Editar produto
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do produto</h4>
          </div>
          {rows.map(key => {
            if (key['id'] == idModal) {
              let paymentDate = 'Não houve pagamento'
              if (key['pay_date'] !== null) {
                paymentDate = key['pay_date']
              }
              return (
                <form className="input" onSubmit={submitModalEdit} enctype="multipart/form-data">
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Nome da despesa:</td>
                        <td>
                          <input
                            required
                            name="debtEditName"
                            className="inputCadastro"
                            defaultValue={key['name']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Tipo da despesa:</td>
                        <td>
                          <select className="inputCadastro" id="categoryProduct" name="debtEditType" onChange={handleTypeDebt}>
                            <option value="">Selecione um tipo de despesa</option>
                            <option value="fixa" selected={key['type'] == 'fixa' ? true : false}>Despesa Fixo</option>
                            <option value="variavel" selected={key['type'] == 'variavel' ? true : false}>Despesa Variável</option>
                          </select>
                        </td>
                      </tr>
                      <tr id="debtCategoryFixa" style={key['type'] == 'fixa' ? { display: 'table-row' } : { display: 'none' }} >
                        <td className="labelConfirm">Categoria da despesa:</td>
                        <td>
                          <select className="inputCadastro" id="categoryProduct" name="debtEditCategoryFixa">
                            <option value="">Selecione uma categoria de despesa</option>
                            <option value="aguaEnergia" selected={key['category'] == 'aguaEnergia' ? true : false}>Contas de água e de energia elétrica</option>
                            <option value="aluguelCondominio" selected={key['category'] == 'aluguelCondominio' ? true : false}>Aluguel e condomínio</option>
                            <option value="folhaPagamento" selected={key['category'] == 'folhaPagamento' ? true : false}>Folha de pagamento</option>
                            <option value="prolabore" selected={key['category'] == 'prolabore' ? true : false}>Pró-labore</option>
                            <option value="telefoniaInternet" selected={key['category'] == 'telefoniaInternet' ? true : false}>Telefonia e internet</option>
                            <option value="limpeza" selected={key['category'] == 'limpeza' ? true : false}>Material de limpeza</option>
                            <option value="seguro" selected={key['category'] == 'seguro' ? true : false}>Seguro</option>
                            <option value="parcelaEmprestimo" selected={key['category'] == 'parcelaEmprestimo' ? true : false}>Parcela de empréstimos</option>
                            <option value="vigilanciaSeguranca" selected={key['category'] == 'vigilanciaSeguranca' ? true : false}>Serviços de vigilância e segurança</option>
                          </select>
                        </td>
                      </tr>

                      <tr id="debtCategoryVariavel" style={key['type'] == 'variavel' ? { display: 'table-row' } : { display: 'none' }} >
                        <td className="labelConfirm">Categoria da despesa: </td>
                        <td>
                          <select className="inputCadastro" id="categoryProduct" name="debtEditCategoryVariavel" >
                            <option value="">Selecione uma categoria de despesa</option>
                            <option value="cmv" selected={key['category'] == 'cmv' ? true : false}>Custo de mercadoria vendida(CMV)</option>
                            <option value="impostoTributoTaxa" selected={key['category'] == 'impostoTributoTaxa' ? true : false}>Impostos, tributos e taxas</option>
                            <option value="comissoes" selected={key['category'] == 'comissoes' ? true : false}>Comissões</option>
                            <option value="taxaCartao" selected={key['category'] == 'taxaCartao' ? true : false}>Taxa administrativa de cartões de crédito e débito</option>
                            <option value="embalagem" selected={key['category'] == 'embalagem' ? true : false}>Embalagens</option>
                            <option value="frete" selected={key['category'] == 'frete' ? true : false}>Frete</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Possui fornecedor?:</td>
                        <td>
                          <select className="inputCadastro" id="categoryProduct" name="debtResponseFornecedor" onChange={handleFornecedor}>
                            <option value="">Selecione uma resposta</option>
                            <option value="yes" selected={key['provider'] !== null ? true : false}>Sim</option>
                            <option value="no" selected={key['provider'] == null ? true : false}>Não</option>
                          </select>
                        </td>
                      </tr>

                      <tr id="debtFornecedorDiv" style={key['provider'] !== null ? { display: 'table-row' } : { display: 'none' }} >
                        <td className="labelConfirm">Fornecedores:</td>
                        <td>
                          <select className="inputCadastro" id="categoryProduct" name="debtEditFornecedor" >
                            <option value="">Selecione um fornecedor</option>
                            {providers.map(k => {
                              return (
                                <option value={k['id']} selected={key['provider'] == k['id'] ? true : false}>{k['name']}</option>
                              )
                            })}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Data de Vencimento:</td>
                        <td>
                          <input
                            required
                            type='date'
                            name="debtEditDueDate"
                            className="inputCadastro"
                            defaultValue={key['due_date']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Data de Pagamento:</td>
                        <td>
                          <input
                            required
                            type='date'
                            name="debtEditPayDate"
                            className="inputCadastro"
                            defaultValue={paymentDate}
                            disabled
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Valor pago:</td>
                        <td>
                          <input
                            name="debtEditPayValue"
                            className="inputCadastro"
                            defaultValue={key['pay_value']}
                            disabled
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Valor:</td>
                        <td>
                          <input
                            name="debtEditValue"
                            className="inputCadastro"
                            defaultValue={key['value']}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className="labelConfirm">Observação:</td>
                        <td>
                          <Form.Control
                            className="inputCadastro"
                            as="textarea"
                            style={{ height: '100px' }}
                            name='debtEditObs'
                            defaultValue={key['obs']}
                          />
                        </td>
                      </tr>

                    </tbody>
                  </Table>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Atualizar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalCancelar(props) {
    return (
      <Modal
        show={modalCancel}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Excluir produto
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>O que você quer fazer?</h4>
          <div className="cancelOrder">
            <Button onClick={handleCancelOrdered} className="cancelButton true">Deletar apenas a do mês</Button>
            <Button onClick={handleCancelOrderedRecorrente} className="cancelButton false">Deletar todas repetições</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalPayment(props) {
    let valueDebt = '';
    rows.map(key => {
      if (key['id'] == idModal) {
        valueDebt = key['value']
      }
    })
    return (
      <Modal
        show={modalPayment}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Pagar despesa
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <form className="input" onSubmit={submitModalPayment} enctype="multipart/form-data">
            <div className="divCadastro">
              <InputLabel htmlFor="categoryProduct">Valor da despesa *</InputLabel>
              <input
                required
                id="categoryProduct"
                name="debtPaymentDebtValue"
                className="inputCadastro"
                value={valueDebt}
                disabled
              />
            </div>
            <div className="divCadastro">
              <InputLabel htmlFor="categoryProduct">Forma de pagamento *</InputLabel>
              <select className="inputCadastro" id="categoryProduct" name="debtPaymentType">
                <option value="">Selecione uma forma de pagamento</option>
                <option value="fixa">Débito</option>
                <option value="variavel">Crédito</option>
                <option value="variavel">PIX</option>
              </select>
            </div>
            <div className="divCadastro">
              <InputLabel htmlFor="categoryProduct">Data de pagamento *</InputLabel>
              <input
                required
                type='date'
                id="categoryProduct"
                name="debtPayDateValue"
                className="inputCadastro"
              />
            </div>
            <div className="buttonLogin">
              <button className="buttonSecundary" >
                Pagar despesa
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  const [open, setOpen] = React.useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }
  let promotionalValue = 'Sem valor promocional'
  var dateNow = new Date().getTime();

  const columns = [
    { title: "Nome", field: "name" },
    {
      title: "Tipo", field: "type", render: (rowData) => {
        return (
          <span>{rowData.type == 'fixa' ? 'Despesa Fixa' : 'Despesa Variável'}</span>
        )
      }
    },
    {
      title: "Categoria", field: "category", render: (rowData) => {
        let category = ''
        if (rowData.category == 'aguaEnergia') {
          category = 'Contas de água e de energia elétrica'
        } else if (rowData.category == 'aluguelCondominio') {
          category = 'Aluguel e condomínio'
        } else if (rowData.category == 'folhaPagamento') {
          category = 'Folha de pagamento'
        } else if (rowData.category == 'prolabore') {
          category = 'Pró-labore'
        } else if (rowData.category == 'telefoniaInternet') {
          category = 'Telefonia e internet'
        } else if (rowData.category == 'limpeza') {
          category = 'Material de limpeza'
        } else if (rowData.category == 'seguro') {
          category = 'Seguro'
        } else if (rowData.category == 'parcelaEmprestimo') {
          category = 'Parcela de empréstimos'
        } else if (rowData.category == 'cmv') {
          category = 'Custo de mercadoria vendida(CMV)'
        } else if (rowData.category == 'impostoTributoTaxa') {
          category = 'Impostos, tributos e taxas'
        } else if (rowData.category == 'comissoes') {
          category = 'Comissões'
        } else if (rowData.category == 'taxaCartao') {
          category = 'Taxa administrativa de cartões de crédito e débito'
        } else if (rowData.category == 'embalagem') {
          category = 'Embalagens'
        } else if (rowData.category == 'frete') {
          category = 'Frete'
        } else if (rowData.category == 'vigilanciaSeguranca') {
          category = 'Serviços de vigilância e segurança'
        }
        return (
          <span>{category}</span>
        )
      }
    },
    {
      title: "Fornecedor", field: "provider", render: (rowData) => {
        let provider = 'Não possui fornecedor'
        if (rowData.provider !== null) {
          providers.map(key => {
            if (key['id'] == rowData.provider) {
              provider = key['name']
            }
          })
        }
        return (
          <span>{provider}</span>
        )
      }
    },
    {
      title: "Data de vencimento", field: "due_date", render: (rowData) => {
        let date = new Date(rowData.due_date);
        let dateFormated = date.setDate(date.getDate() + 1)
        return (
          <span>{new Date(dateFormated).toLocaleDateString("pt-br")}</span>
        )
      }
    },
    {
      title: "Data de pagamento", field: "pay_date", render: (rowData) => {
        let valorFormated = 'Não houve pagamento'
        if (rowData.pay_date !== null) {
          valorFormated = rowData.pay_date
        }
        return (
          <span>{valorFormated}</span>
        )
      }
    },
    {
      title: "Valor", field: 'value', render: (rowData) => {
        let value = rowData.value
        return (
          <CurrencyInput
            style={{ border: 'none', background: 'transparent', cursor: 'auto' }}
            defaultValue={value}
            onChangeValue={(event, originalValue, maskedValue) => {
              // console.log(event, originalValue, maskedValue);
            }}
            disabled
          />
        )
      }
    },
    {
      title: "Situação", field: 'status', render: (rowData) => {
        return (
          <div style={rowData.status == 'À Vencer' ? { backgroundColor: '#ffff76', height: '5vh', display: 'flex', justifyContent: 'center', alignItems: 'center' } : rowData.status == 'Atrasado' ? { backgroundColor: '#ff3333', height: '5vh', display: 'flex', justifyContent: 'center', alignItems: 'center' } : rowData.status == 'Pago' ? { backgroundColor: 'green', height: '5vh', display: 'flex', justifyContent: 'center', alignItems: 'center' } : { backgroundColor: 'transparent', height: '5vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ fontWeight: 500 }}>{rowData.status}</span>
          </div>
        )
      }
    }
  ]

  return (
    <>
      <Loading />
      <ModalCancelar />
      <ModalPainel />
      <ModalEdit />
      <ModalPayment />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title=""
            columns={columns}
            data={filteredData}
            components={{
              Body: (props) => (
                <>
                  <MTableBody {...props} />
                  <tfoot>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={{ fontWeight: 900 }}>Despesas Pendentes</td>
                      <td>
                        {totalDebts == 0 ? (
                          <span>R$ 0</span>
                        ) : (
                          <CurrencyInput
                            style={{ border: 'none', background: 'transparent', cursor: 'auto' }}
                            defaultValue={totalDebts}
                            onChangeValue={(event, originalValue, maskedValue) => {
                              // console.log(event, originalValue, maskedValue);
                            }}
                            disabled
                          />
                        )}

                      </td>
                      <td style={{ fontWeight: 900 }}>Despesas Pagas</td>
                      <td>
                        {totalPay == 0 ? (
                          <span>R$ 0</span>
                        ) : (
                          <CurrencyInput
                            style={{ border: 'none', background: 'transparent', cursor: 'auto' }}
                            defaultValue={totalPay}
                            onChangeValue={(event, originalValue, maskedValue) => {
                              // console.log(event, originalValue, maskedValue);
                            }}
                            disabled
                          />
                        )}
                      </td>
                    </tr>
                  </tfoot>
                </>
              ),
            }}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: false,
              toolbarButtonAlignment: "left",
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
            actions={[
              {
                icon: () => <AiOutlineDollar className='perfilAction' />,
                tooltip: 'Pagar',
                onClick: (event, rowData) => {
                  setModalPayment(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <TbEyeSearch className='perfilAction' />,
                tooltip: 'Detalhes',
                onClick: (event, rowData) => {
                  setPainelModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineEdit className='perfilAction' />,
                tooltip: 'Editar',
                onClick: (event, rowData) => {
                  setEditModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineDelete className='perfilAction' />,
                tooltip: 'Deletar',
                onClick: (event, rowData) => {
                  setModalCancel(true)
                  setIdModal(rowData.id)
                  setNameDebt(rowData.name)
                }
              },
              {
                icon: () =>
                  <FormControl sx={{ m: 1, minWidth: 220 }}>
                    {/* <InputLabel id="filterDriver">Filtro por entregador</InputLabel> */}
                    <Select
                      labelId="filterDriver"
                      id="filterDriver"
                      style={{ width: 220 }}
                      value={filterMonth}
                      onChange={(e) => setFilterMonth(e.target.value)}
                    >
                      <MenuItem value={1}>Janeiro</MenuItem>
                      <MenuItem value={2}>Fevereiro</MenuItem>
                      <MenuItem value={3}>Março</MenuItem>
                      <MenuItem value={4}>Abril</MenuItem>
                      <MenuItem value={5}>Maio</MenuItem>
                      <MenuItem value={6}>Junho</MenuItem>
                      <MenuItem value={7}>Julho</MenuItem>
                      <MenuItem value={8}>Agosto</MenuItem>
                      <MenuItem value={9}>Setembro</MenuItem>
                      <MenuItem value={10}>Outubro</MenuItem>
                      <MenuItem value={11}>Novembro</MenuItem>
                      <MenuItem value={12}>Dezembro</MenuItem>

                    </Select>
                    <FormHelperText>Filtre por mês</FormHelperText>
                  </FormControl>,
                isFreeAction: true
              },
              {
                icon: () =>
                  <FormControl sx={{ m: 1, minWidth: 220 }}>
                    {/* <InputLabel id="filterDriver">Filtro por entregador</InputLabel> */}
                    <Select
                      labelId="filterDriver"
                      id="filterDriver"
                      style={{ width: 220 }}
                      value={filterYear}
                      onChange={(e) => setFilterYear(e.target.value)}
                    >
                      <MenuItem value={new Date().getFullYear()} selected>Atual</MenuItem>
                      <MenuItem value={2025}>2025</MenuItem>

                    </Select>
                    <FormHelperText>Filtre por ano</FormHelperText>
                  </FormControl>,
                isFreeAction: true
              }
            ]}
          />
        </Paper>
      </Box>
    </>
  );
}
