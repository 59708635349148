import { useState, useEffect } from "react";
import React from 'react'
import './birthdayPerson.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import Form from 'react-bootstrap/Form';
import TableBirthdayPerson from '../TableBirthdayPerson'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import InputMask from 'react-input-mask';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Modal } from "react-bootstrap";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from 'react-redux';

const BirthdayPerson = () => {
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    const [physicRows, setPhysicRows] = useState([]);
    const [businessRows, setBusinessRows] = useState([]);
    const [alert, setAlert] = useState(false);
    
    useEffect(() => {
        api
            .get("/TodosClientesFisicos" + '/' + currentUser.db)
            .then((response) => {
                setPhysicRows(response.data.map(key => ({
                    id: key['id'], cliente: key['name'], birthdate: key['birthdate'], player_id: key['player_id'], phone: key['phone']
                })))
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    useEffect(() => {
        api
            .get("/TodosClientesJuridicos" + '/' + currentUser.db)
            .then((response) => {
                setBusinessRows(response.data.map(key => ({
                    id: key['id'], cliente: key['name'], birthdate: key['birthdate'], player_id: key['player_id'], phone: key['phone']
                })))
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    const submitNotification = () => {
        var rows = [];
        physicRows.map(key => {
            if (key['birthdate'] !== null) {
                var date = key['birthdate'].split('/');
                var dateNow = new Date();
                var birthdayMonth = date[1];
                var monthNow = dateNow.getMonth() + 1;
                var dayNow = dateNow.getDay();
                var birthdayDay = date[0];

                if (monthNow == birthdayMonth) {
                    if (dayNow == birthdayDay) {
                        if (key['player_id'] !== null) {
                            rows.push(key['player_id']);
                        }
                    }
                }
            }
        })

        businessRows.map(key => {
            if (key['birthdate'] !== null) {
                var date = key['birthdate'].split('/');
                var dateNow = new Date();
                var birthdayMonth = date[1];
                var monthNow = dateNow.getMonth() + 1;
                var dayNow = dateNow.getDay();
                var birthdayDay = date[0];

                if (monthNow == birthdayMonth) {
                    if (dayNow == birthdayDay) {
                        if (key['player_id'] !== null) {
                            rows.push(key['player_id']);
                        }
                    }
                }
            }
        })

        const message = 'Parabéns a nossa equipe deseja muitas felicidades nessa data especial 🥳🥳'

        if (rows.length > 1) {
            rows.map(key => {
                api.post("/Notificar", {
                    app_id: "0eab63cd-71a3-48dc-b40c-7b1849c44f74",
                    contents: {
                        en: message
                    },
                    include_player_ids: [key],
                    db: currentUser.db
                }).then(response => {
                    // console.log(response)
                }).catch(err => {
                    console.log(err)
                })
            })


            setTimeout(function () {
                window.location.reload(1);
            }, 3000);
        } else {
            setAlert(true);
        }
    }

    // const submitMessageWhats = () => {

    //     var rows = [];

    //     physicRows.map(key => {
    //         if (key['birthdate'] !== null) {
    //             var date = key['birthdate'].split('/');
    //             var dateNow = new Date();
    //             var birthdayMonth = date[1];
    //             var monthNow = dateNow.getMonth() + 1;
    //             var dayNow = dateNow.getDay();
    //             var birthdayDay = date[0];

    //             if (monthNow == birthdayMonth) {
    //                 if (dayNow == birthdayDay) {
    //                     if (key['player_id'] !== null) {
    //                         rows.push(key['phone']);
    //                     }
    //                 }
    //             }
    //         }
    //     })

    //     businessRows.map(key => {
    //         if (key['birthdate'] !== null) {
    //             var date = key['birthdate'].split('/');
    //             var dateNow = new Date();
    //             var birthdayMonth = date[1];
    //             var monthNow = dateNow.getMonth() + 1;
    //             var dayNow = dateNow.getDay();
    //             var birthdayDay = date[0];

    //             if (monthNow == birthdayMonth) {
    //                 if (dayNow == birthdayDay) {
    //                     if (key['player_id'] !== null) {
    //                         rows.push(key['phone']);
    //                     }
    //                 }
    //             }
    //         }
    //     })


    //     const message = 'Parabéns a nossa equipe deseja muitas felicidades nessa data especial 🥳🥳'

    //     if (rows.length > 1) {
    //         rows.map(key => {

    //         })


    //         setTimeout(function () {
    //             window.location.reload(1);
    //         }, 3000);
    //     } else {
    //         console.log('Não possui player_id')
    //     }

    //     // window.open('https://web.whatsapp.com/send?phone=55' + whatsPhone + '&text=' + message, "minhaJanela", "height=1800,width=1800");

    //     setTimeout(function () {
    //         window.location.reload(1);
    //     }, 3000);
    // }


    return (
        <>
            {alert ? (
                <Alert severity="error">Os aniversariantes do dia não podem receber notificação, pois eles ainda não utilizaram o app</Alert>
            ) : ""}
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Aniversariantes do mês</h5>
                    </div>
                    {/* <div>
                        <Button variant="primary" onClick={() => { submitNotification() }} className="me-2 button" style={{ width: '200px' }}>
                            Notificar todos (APP)
                        </Button>
                    </div> */}
                </div>
                <Card className="cardContainer" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableBirthdayPerson />
                </Card>
            </div >
        </>
    )
}

export default BirthdayPerson