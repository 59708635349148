import { useState, useEffect } from "react";
import React from 'react'
import './profile.css';
import { Card } from "react-bootstrap";
import api from "../../service/api";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputLabel from '@mui/material/InputLabel';
import InputMask from 'react-input-mask';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';

const Profile = () => {
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    let user_id = '';
    if (currentUser !== undefined) {
        user_id = currentUser.id;
    }

    const [collaborator, setCollaborator] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        api
            .get("/TodosColaboradores/" + currentUser.db)
            .then((response) => {
                setCollaborator(response.data)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        let dados = '';
        let phone = data.celularCollaboratorProfile.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");
        if (data.imagePhoto.name !== '') {
            dados = {
                id: user_id,
                'image': data.imagePhoto,
                name: data.nameCollaboratorProfile,
                number: Number(data.numeroCollaboratorProfile),
                street: data.enderecoCollaboratorProfile,
                city: data.cidadeCollaboratorProfile,
                state: data.estadoCollaboratorProfile,
                neighborhood: data.bairroCollaboratorProfile,
                complement: data.complementoCollaboratorProfile,
                reference_point: data.referenciaCollaboratorProfile,
                cpf: data.cpfCollaboratorProfile,
                email: data.emailCollaboratorProfile,
                phone: phone,
                birthdate: data.dataNascimentoCollaboratorProfile,
                photo: "https://api.clubedorevendedordegas.com.br/files/collaborators/" + data.imagePhoto.name,
                db: currentUser.db
            }
        } else {
            dados = {
                id: user_id,
                name: data.nameCollaboratorProfile,
                number: Number(data.numeroCollaboratorProfile),
                street: data.enderecoCollaboratorProfile,
                city: data.cidadeCollaboratorProfile,
                state: data.estadoCollaboratorProfile,
                neighborhood: data.bairroCollaboratorProfile,
                complement: data.complementoCollaboratorProfile,
                reference_point: data.referenciaCollaboratorProfile,
                cpf: data.cpfCollaboratorProfile,
                email: data.emailCollaboratorProfile,
                phone: phone,
                birthdate: data.dataNascimentoCollaboratorProfile,
                db: currentUser.db
            }
        }

        api.put("/AtualizarCadastroColaborador", dados, {
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        });

        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 5000);
    }

    function mostraImagem(img) {
        if (img.files && img.files[0]) {
            var reader = new FileReader();
            var imagem = document.getElementById("imgProfile");
            reader.onload = function (e) {
                imagem.src = e.target.result;
            };

            reader.readAsDataURL(img.files[0]);
        }
    }

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <>
            <Loading />
            <div className='painelContainer'>
                <div className="titleAndButton">
                    <div>
                        <h5>Meu perfil</h5>
                    </div>
                </div>
                <Card className="cardContainerPainel" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    {collaborator.map(key => {
                        if (key['id'] == user_id) {
                            return (
                                <form className="input" onSubmit={submitForm}>
                                    <div className="divCadastro">
                                        <InputLabel htmlFor="nameCollaboratorProfile">Foto de perfil </InputLabel>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <div className="imgProfile">
                                                <img src={key['photo']} id="imgProfile" style={{ maxWidth: 300, maxHeight: 300, width: 'auto', height: 'auto' }} />
                                            </div>

                                            <input
                                                type="file"
                                                name="imagePhoto"
                                                onChange={(e) => {
                                                    mostraImagem(e.target)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="divCadastro">
                                        <InputLabel htmlFor="nameCollaboratorProfile">Nome *</InputLabel>
                                        <input
                                            required
                                            id="nameCollaboratorProfile"
                                            name="nameCollaboratorProfile"
                                            className="inputCadastro"
                                            defaultValue={key['name']}
                                        />
                                    </div>

                                    <div className="divCadastro">
                                        <InputLabel htmlFor="cpfCollaboratorProfile">CPF</InputLabel>
                                        <InputMask className="inputCadastro" id="cpfCollaboratorProfile" mask="999.999.999-**" name="cpfCollaboratorProfile" defaultValue={key['cpf']} />
                                    </div>
                                    <div className="divCadastro">
                                        <InputLabel htmlFor="enderecoCollaboratorProfile">Endereço *</InputLabel>
                                        <input
                                            required
                                            name="enderecoCollaboratorProfile"
                                            id="enderecoCollaboratorProfile"
                                            className="inputCadastro"
                                            defaultValue={key['street']}
                                        />
                                    </div>

                                    <div className="container2itens">
                                        <div className="itemDireitaProfile">
                                            <InputLabel htmlFor="numeroCollaboratorProfile">Número *</InputLabel>
                                            <input
                                                required
                                                name="numeroCollaboratorProfile"
                                                id="numeroCollaboratorProfile"
                                                className="inputCadastro"
                                                defaultValue={key['number']}
                                            />
                                        </div>
                                        <div className="itemEsquerdaProfile">
                                            <InputLabel htmlFor="bairroCollaboratorProfile">Bairro *</InputLabel>
                                            <input
                                                required
                                                name="bairroCollaboratorProfile"
                                                id="bairroCollaboratorProfile"
                                                className="inputCadastro"
                                                defaultValue={key['neighborhood']}
                                            />
                                        </div>
                                    </div>

                                    <div className="container2itens">
                                        <div className="itemDireitaProfile">
                                            <InputLabel htmlFor="cidadeCollaboratorProfile">Cidade *</InputLabel>
                                            <input
                                                required
                                                name="cidadeCollaboratorProfile"
                                                id="cidadeCollaboratorProfile"
                                                className="inputCadastro"
                                                defaultValue={key['city']}
                                            />
                                        </div>
                                        <div className="itemEsquerdaProfile">
                                            <InputLabel htmlFor="estadoCollaboratorProfile">Estado *</InputLabel>
                                            <input
                                                required
                                                name="estadoCollaboratorProfile"
                                                id="estadoCollaboratorProfile"
                                                className="inputCadastro"
                                                defaultValue={key['state']}
                                            />
                                        </div>
                                    </div>

                                    <div className="container2itens">
                                        <div className="itemDireitaProfile">
                                            <InputLabel htmlFor="complementoCollaboratorProfile">Complemento</InputLabel>
                                            <input
                                                name="complementoCollaboratorProfile"
                                                id="complementoCollaboratorProfile"
                                                className="inputCadastro"
                                                defaultValue={key['complement']}
                                            />
                                        </div>
                                        <div className="itemEsquerdaProfile">
                                            <InputLabel htmlFor="referenciaCollaboratorProfile">Ponto de referência</InputLabel>
                                            <input
                                                name="referenciaCollaboratorProfile"
                                                id="referenciaCollaboratorProfile"
                                                className="inputCadastro"
                                                defaultValue={key['reference_point']}
                                            />
                                        </div>
                                    </div>

                                    <div className="divCadastro">
                                        <InputLabel htmlFor="emailCollaboratorProfile">E-mail</InputLabel>
                                        <input
                                            name="emailCollaboratorProfile"
                                            id="emailCollaboratorProfile"
                                            className="inputCadastro"
                                            defaultValue={key['email']}
                                        />
                                    </div>
                                    <div className="divCadastro">
                                        <InputLabel htmlFor="celularCollaboratorProfile">Celular *</InputLabel>
                                        <InputMask required className='inputCadastro' id="celularCollaboratorProfile" mask="(99) 99999-9999" name="celularCollaboratorProfile" defaultValue={key['phone'] <= 10 ? '' : key['phone']} />
                                    </div>

                                    <div className="divCadastro">
                                        <InputLabel htmlFor="dataNascimentoCollaboratorProfile">Data de Nascimento</InputLabel>
                                        <InputMask className='inputCadastro' id="dataNascimentoCollaboratorProfile" mask="99/99/9999" name="dataNascimentoCollaboratorProfile" defaultValue={key['birthdate'] == 0 ? '' : key['birthdate']} />
                                    </div>

                                    <div className="buttonLogin">
                                        <button className="buttonSecundary" >
                                            Atualizar cadastro
                                        </button>
                                    </div>
                                </form>
                            )
                        }
                    })}
                </Card>
            </div >
        </>
    )
}

export default Profile