import { useState, useEffect } from "react";
import React from 'react'
import './configuration.css';
import { Card } from "react-bootstrap";
import api from "../../service/api";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputLabel from '@mui/material/InputLabel';
import InputMask from 'react-input-mask';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ColorPicker from 'react-pick-color';
import axios from "axios";
import { configurationTheme } from '../../redux/configurationTheme/actions';
import { useSelector, useDispatch } from 'react-redux';

const Configuration = () => {

    const [open, setOpen] = useState(false);
    const [color1, setColor1] = useState();
    const [color2, setColor2] = useState();
    const [loginLogo, setLoginLogo] = useState();
    const [menuLogo, setMenuLogo] = useState();
    const [inputFileName, setInputFileName] = useState("");
    const [inputFileName2, setInputFileName2] = useState("");
    const [refresh, setRefresh] = useState(false);

    const dispatch = useDispatch();

    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);

    useEffect(() => {
        api
            .get("/Configuracoes/" + currentUser.db)
            .then((response) => {
                setColor1(response.data[0].colorPrimary)
                setColor2(response.data[0].colorSecundary)
                setLoginLogo(response.data[0].logoLogin)
                setMenuLogo(response.data[0].logoMenu)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        let dados = {
        }

        if (inputFileName !== '' && inputFileName2 !== '') {
            dados = {
                id: 1,
                db: currentUser.db,
                'image[]': [inputFileName, inputFileName2],
                logoLogin: "https://api.clubedorevendedordegas.com.br/files/logo/" + data.logoLogin.name,
                logoMenu: "https://api.clubedorevendedordegas.com.br/files/logo/" + data.logoMenu.name,
                colorPrimary: color1,
                colorSecundary: color2
            }
            setLoginLogo("https://api.clubedorevendedordegas.com.br/files/logo/" + data.logoLogin.name);
            setMenuLogo("https://api.clubedorevendedordegas.com.br/files/logo/" + data.logoMenu.name)
        } else {
            dados = {
                id: 1,
                db: currentUser.db,
                colorPrimary: color1,
                colorSecundary: color2
            }
        }

        api.put("/AtualizarConfiguracoes", dados, {
            'headers': {
                'Content-Type': 'multipart/form-data'
            }
        })

        dispatch(configurationTheme({ logoLogin: loginLogo, logoMenu: menuLogo, colorPrimary: color1, colorSecundary: color2 }));

        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 5000);
    }

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    function mostraImagem(img) {
        if (img.files && img.files[0]) {
            var reader = new FileReader();
            var imagem = document.getElementById("logoLogin");
            reader.onload = function (e) {
                imagem.src = e.target.result;
            };

            reader.readAsDataURL(img.files[0]);
        }
    }

    function mostraImagem2(img) {
        if (img.files && img.files[0]) {
            var reader = new FileReader();
            var imagem = document.getElementById("logoMenu");
            reader.onload = function (e) {
                imagem.src = e.target.result;
            };

            reader.readAsDataURL(img.files[0]);
        }
    }

    return (
        <>
            <Loading />
            <div className='configContainer'>
                <div className="titleAndButton">
                    <div>
                        <h5>Configurações da ERP</h5>
                    </div>
                </div>
                <Card className="cardContainerConfig" style={{ width: '100%', borderRadius: '10px', padding: '10px', height: '100vh' }}>
                    <form className="input" onSubmit={submitForm}>
                        <div className="container2itens">
                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column' }}>
                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginLeft: 10 }}>Logo da tela de login </InputLabel>
                                <img src={loginLogo} className="imgProfile" id="logoLogin" />
                                <input
                                    type="file"
                                    name="logoLogin"
                                    style={{ marginLeft: 10 }}
                                    onChange={(e) => {
                                        setInputFileName(e.target.files[0])
                                        mostraImagem(e.target)
                                    }}
                                />
                                <InputLabel id="filtro-periudo" style={{ marginLeft: 10, marginTop: 10 }}>Tamanho recomendado da imagem é de 779 x 619</InputLabel>
                            </div>

                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column' }}>
                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginLeft: 10 }}>Logo do menu </InputLabel>
                                <img src={menuLogo} className="imgProfile" id="logoMenu" />
                                <input
                                    type="file"
                                    name="logoMenu"
                                    style={{ marginLeft: 10 }}
                                    onChange={(e) => {
                                        setInputFileName2(e.target.files[0])
                                        mostraImagem2(e.target)
                                    }}
                                />
                                <InputLabel id="filtro-periudo" style={{ marginLeft: 10, marginTop: 10 }}>Tamanho recomendado da imagem é de 345 x 284</InputLabel>
                            </div>
                        </div>

                        {/* <div className="container2itens" >
                            <div className="divCadastro" >
                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}>Cor principal</InputLabel>
                                <ColorPicker color={color1} onChange={color1 => setColor1(color1.hex)} />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}>Cor secundária </InputLabel>
                                <ColorPicker color={color2} onChange={color2 => setColor2(color2.hex)} />

                            </div>
                        </div> */}
                        <div className="buttonLogin" style={{ marginLeft: 10, marginTop: 10 }}>
                            <button className="buttonSecundary" >
                                Atualizar configurações
                            </button>
                        </div>
                    </form>
                </Card>
            </div >
        </>
    )
}

export default Configuration