import { useState, useEffect } from "react";
import React from 'react'
import './business.css';
import { Card, Table, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import TableClientBusiness from '../TableClientBusiness'
import InputLabel from '@mui/material/InputLabel';
import InputMask from 'react-input-mask';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const ClientBusiness = () => {

    const [text, setText] = useState();
    const [typeBusiness, setTypeBusiness] = useState([])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        setText('Criar cliente')
    };

    useEffect(() => {
        api
            .get("/TodasCategoriasComerciais")
            .then((response) => {
                setTypeBusiness(response.data)
            })

            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    const submitModalConfirm = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        let phone = data.telefoneBusiness.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");
        api.post("/CadastrarClienteJuridico", {
            commercial_type: Number(data.businessType),
            commercial_id: Number(data.idComercio),
            name: data.nameBusiness,
            razao: data.razaoSocial,
            cnpj: data.cnpj,
            street: data.enderecoBusiness,
            number: Number(data.numeroBusiness),
            city: data.cidadeBusiness,
            state: data.estadoBusiness,
            neighborhood: data.bairroBusiness,
            complement: data.complementoBusiness,
            reference_point: data.referenciaBusiness,
            email: data.emailBusiness,
            inscricao_estadual: data.inscricaoSocial,
            inscricao_municipal: data.inscricaoMunicipal,
            phone: phone,
            birthdate: data.dataNascimentoBusiness,
            address: data.enderecoBusiness,
            obs: data.obsBusiness
        });
        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 3000);
    }

    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    function OffCanvasExample({ ...props }) {
        return (
            <>
                <Loading />
                <Button variant="primary" onClick={handleShow} className="me-2 button">
                    {'Novo cliente'}
                </Button>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header>
                        <Offcanvas.Title>Novo cliente jurídico</Offcanvas.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form className="input" onSubmit={submitModalConfirm}>

                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">ID do comércio</InputLabel>
                                <input
                                    id="formatted-text-mask-input"
                                    name="idComercio"
                                    className="inputCadastro"
                                />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Nome *</InputLabel>
                                <input
                                    required
                                    id="formatted-text-mask-input"
                                    name="nameBusiness"
                                    className="inputCadastro"
                                />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Razão Social</InputLabel>
                                <input
                                    id="formatted-text-mask-input"
                                    name="razaoSocial"
                                    className="inputCadastro"
                                />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">CNPJ</InputLabel>
                                <InputMask className="inputCadastro" mask="99.999.999/9999-99" name="cnpj" />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">E-mail *</InputLabel>
                                <input
                                    id="formatted-text-mask-input"
                                    name="emailBusiness"
                                    className="inputCadastro"
                                />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Inscrição Social</InputLabel>
                                <input
                                    id="formatted-text-mask-input"
                                    name="inscricaoSocial"
                                    className="inputCadastro"
                                />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Inscrição Municipal</InputLabel>
                                <input
                                    id="formatted-text-mask-input"
                                    name="inscricaoMunicipal"
                                    className="inputCadastro"
                                />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Tipo de comércio</InputLabel>
                                <Form.Select
                                    name='businessType'
                                    required
                                >
                                    <option value=''>Selecione um tipo de comércio</option>
                                    {typeBusiness.map((key, value) => {
                                        return <option value={key['id']}>{key['name']}</option>
                                    })}
                                </Form.Select>
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Telefone *</InputLabel>
                                <InputMask required className='inputCadastro' mask="(99) 9999-9999" name="telefoneBusiness" />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Data de Nascimento</InputLabel>
                                <InputMask className='inputCadastro' mask="99/99/9999" name="dataNascimentoBusiness" />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Endereço *</InputLabel>
                                <input
                                    required
                                    name="enderecoBusiness"
                                    id="formatted-text-mask-input"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="container2itens">
                                <div className="itemDireita">
                                    <InputLabel htmlFor="formatted-text-mask-input">Número *</InputLabel>
                                    <input
                                        required
                                        name="numeroBusiness"
                                        id="formatted-text-mask-input"
                                        className="inputCadastro"
                                    />
                                </div>
                                <div className="itemEsquerda">
                                    <InputLabel htmlFor="formatted-text-mask-input">Bairro *</InputLabel>
                                    <input
                                        required
                                        name="bairroBusiness"
                                        id="formatted-text-mask-input"
                                        className="inputCadastro"
                                    />
                                </div>
                            </div>

                            <div className="container2itens">
                                <div className="itemDireita">
                                    <InputLabel htmlFor="formatted-text-mask-input">Cidade *</InputLabel>
                                    <input
                                        required
                                        name="cidadeBusiness"
                                        id="formatted-text-mask-input"
                                        className="inputCadastro"
                                        
                                    />
                                </div>
                                <div className="itemEsquerda">
                                    <InputLabel htmlFor="formatted-text-mask-input">Estado *</InputLabel>
                                    <input
                                        required
                                        name="estadoBusiness"
                                        id="formatted-text-mask-input"
                                        className="inputCadastro"
                                        
                                    />
                                </div>
                            </div>

                            <div className="container2itens">
                                <div className="itemDireita">
                                    <InputLabel htmlFor="formatted-text-mask-input">Complemento</InputLabel>
                                    <input
                                        name="complementoBusiness"
                                        id="formatted-text-mask-input"
                                        className="inputCadastro"
                                    />
                                </div>
                                <div className="itemEsquerda">
                                    <InputLabel htmlFor="formatted-text-mask-input">Ponto de referência</InputLabel>
                                    <input
                                        name="referenciaBusiness"
                                        id="formatted-text-mask-input"
                                        className="inputCadastro"
                                    />
                                </div>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Observação</InputLabel>
                                <Form.Control
                                    className="textAreaForm"
                                    as="textarea"
                                    style={{ height: '100px' }}
                                    name='obsBusiness'
                                />
                            </div>

                            <div className="buttonLogin">
                                <button className="buttonSecundary" >
                                    Cadastrar
                                </button>
                            </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );

    }

    return (
        <>
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Clientes Jurídicos</h5>
                    </div>
                    <div>
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                    </div>
                </div>
                <Card className="cardContainer" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableClientBusiness />
                </Card>
            </div >
        </>
    )
}

export default ClientBusiness