import React, { useState, useEffect } from 'react';
import './promotionPayReseller.css'
import { Card } from 'react-bootstrap'
import esta_pronto from '../../image/esta_pronto.png'
import api from '../../service/api'
import token from "../../service/autentification";
import { useSelector, useDispatch } from 'react-redux';
import { userLogin } from '../../redux/user/actions';
import { configurationTheme } from '../../redux/configurationTheme/actions'
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Theme, useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { autentificacao } from '../../redux/nuvemFiscal/actions';
import { useParams } from 'react-router-dom';
import { initMercadoPago, CardPayment } from '@mercadopago/sdk-react'
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { FaCheckCircle } from "react-icons/fa";
import Modal from '@mui/material/Modal';
import {
    Box
} from '@mui/material';
import { CiCircleChevRight } from "react-icons/ci";
import InputMask from 'react-input-mask';
import axios from 'axios';
import { set } from 'lodash';
import { FaRegCopy } from "react-icons/fa";
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { CurrencyInput } from 'react-currency-mask';

const PromotionPayReseller = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [accessEmail, setAccessEmail] = useState('');
    const [accessPass, setAccessPass] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [logoLogin, setLogoLogin] = useState();
    const [stap, setStap] = useState(0);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);
    const [name, setName] = useState('')
    const [revenda, setRevenda] = useState('')
    const [cnpjAssa, setCnpjAssa] = useState('')
    const [moduleSelected, setModuleSelected] = useState('');
    const [paymentForm, setPaymentForm] = useState('')
    // let logoLogin = '';
    const { currentTheme } = useSelector(rootReducer => rootReducer.configurationReducer);
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const [height, setHeight] = React.useState('100vh')
    const [divFormStepHeight, setDivFormStepHeight] = React.useState('70vh')
    const [divFormStepWidht, setDivFormStepWidht] = React.useState('45vw')
    const [pTop, setPTop] = React.useState(0);
    const [resellerClass, setResellerClass] = useState('')
    const [typeGas, setTypeGas] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [idAssas, setIdAssas] = useState('');
    const [cep, setCep] = useState('');
    const [address, setAddress] = useState('');
    const [number, setNumber] = useState('');
    const [phone, setPhone] = useState('');
    const [numberCard, setNumberCard] = useState('');
    const [nameCard, setNameCard] = useState('');
    const [cvvCard, setCvvCard] = useState('')
    const [validateCard, setValidateCard] = useState('')
    const [complementAddress, setComplementAddress] = useState('')
    const [cpfCnpj, setCpfCnpj] = useState('')
    const [qrcodeCopyPaste, setQrcodeCopyPaste] = useState('')
    const [alertModal, setAlertModal] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('error');
    const [cnpjValidated, setCnpjValidated] = useState(false)
    const [userReseller, setUserReseller] = useState([]);
    // const [cnpjReseller, setCnpjReseller] = useState('')
    // const [emailReseller, setEmailReseller] = useState('')
    const [codeBar, setCodeBar] = useState('')
    const [parc, setParc] = useState('')
    const [array3month, setArray3Month] = useState([1, 2, 3]);
    const [array1year, setArray1year] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
    const [custumerId, setCustumerId] = useState('')
    const [whatsapp, setWhatsapp] = useState('')

    let { plan } = useParams();

    useEffect(() => {
        if (window.innerWidth <= 1280 && window.innerHeight <= 720) {
            setHeight(window.innerHeight + 400)
            setDivFormStepHeight('120vh')
            setDivFormStepWidht('50vw')
        } else if (window.innerWidth <= 1280 && window.innerHeight <= 960) {
            setHeight(window.innerHeight + 100)
            setDivFormStepHeight('82vh')
            setDivFormStepWidht('50vw')
        } else if (window.innerWidth <= 1366 && window.innerHeight <= 768) {
            setHeight(window.innerHeight + 350)
            setDivFormStepHeight('115vh')
        } else if (window.innerWidth <= 1400 && window.innerHeight <= 900) {
            setHeight(window.innerHeight + 110)
            setDivFormStepHeight('94vh')
        } else if (window.innerWidth <= 1600 && window.innerHeight <= 900) {
            setHeight(window.innerHeight + 120)
            setDivFormStepHeight('94vh')
        } else if (window.innerWidth <= 1680 && window.innerHeight <= 1050) {
            setHeight(window.innerHeight + 120)
            setDivFormStepHeight('80vh')
        } else if (window.innerWidth <= 1980 && window.innerHeight <= 980) {
            setHeight(window.innerHeight + 120)
            setDivFormStepHeight('94vh')
        }
    }, [window.innerWidth, window.innerHeight])

    useEffect(() => {
        api.get('/TodosUsuariosRevenda')
            .then(response => {
                setUserReseller(response.data)
            }).catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (custumerId !== '') {
                axios.get(`/VerificarPagamentoAsaas/${custumerId}`)
                    .then(response => {
                        var result = response.data;
                        console.log(result)
                        if (result.data.status == "CONFIRMED" && result.data.billingType == "PIX") {
                            setStap(stap + 1)
                            setOpenModal(true)
                        }
                    }).catch(err => {
                        console.log(err)
                    })
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        const divMask0 = document.getElementById('maskDivModule0');
        const divMask1 = document.getElementById('maskDivModule1');
        const divMask2 = document.getElementById('maskDivModule2');
        const divForm = document.getElementById('divPaymentForm');
        if (plan !== undefined) {
            if (plan == '752266f8d19cd24841ae94dd64a400a65ba331e4') {
                setModuleSelected('free')
                divMask0.style.display = 'flex'
            } else if (plan == '28ce3c00f9b1d133ee8a338f7514b3e0fb57bb46') {
                setModuleSelected('1month')
                divMask1.style.display = 'flex'
                divForm.style.display = 'flex';
            } else if (plan == '05c6c03290233d9e2d4697292143c3d35f63a5ae') {
                setModuleSelected('3month')
                divMask2.style.display = 'flex'
                divForm.style.display = 'flex';
            } else if (plan == '59cdd14eae0be89291b543b0a006283ad6231e1c') {
                setModuleSelected('1year')
                divForm.style.display = 'flex';
            }
        }
    }, [])

    const handleChange = (event) => {
        const {
            target: { value },
        } = setPaymentForm;
        setPersonName(
            value,
        );
    };

    initMercadoPago('TEST-e1da3c86-9e3c-4c41-b363-f3ad15b769aa');

    function retira_acentos(str) {

        var com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";

        var sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
        let novastr = "";
        for (var i = 0; i < str.length; i++) {
            let troca = false;
            for (var a = 0; a < com_acento.length; a++) {
                if (str.substr(i, 1) == com_acento.substr(a, 1)) {
                    novastr += sem_acento.substr(a, 1);
                    troca = true;
                    break;
                }
            }
            if (troca == false) {
                novastr += str.substr(i, 1);
            }
        }
        return novastr;
    }

    useEffect(() => {
        if (stap == 2) {
            var dbNameRemove = retira_acentos(revenda.toLocaleLowerCase().replace('.', '_'));
            var dbNameSplit = dbNameRemove.split(' ');
            var dbName = validadeNomeRevenda(dbNameSplit.join('_'))
            // console.log(
            //         {name: name,
            //         email: email,
            //         password: password,
            //         reseller_name: revenda,
            //         db_name: dbName}
            // )

            api.post('/CadastrarServidorRevenda', {
                name: name,
                email: email,
                password: password,
                reseller_name: revenda,
                db_name: dbNameSplit.join('_'),
                module: moduleSelected,
                reseller_class: resellerClass,
                type_reseller: typeGas,
                whatsapp: whatsapp,
                custumer_id: custumerId,
                cnpj: cnpjAssa
            }).then(response => {
                // console.log('aqui 222')
            }).catch(err => {
                console.log(err)
            })

            axios.post('https://evolution.clubedorevendedordegas.com.br/instance/create', {
                instanceName: dbName,
            }, {
                'headers': {
                    apikey: 'B6D711FCDE4D4FD5936544120E713976'
                }
            })
                .then(response => {
                    console.log(response.data)
                }).catch(err => {
                    console.log(err)
                })
        }
    }, [stap])

    const dispatch = useDispatch();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const names = [
        'Crédito',
        // 'Boleto',
        // 'PIX'
    ];

    const classes = [
        "Classe I",
        "Classe II",
        "Classe III",
        "Classe IV",
        "Classe V",
        "Classe VI",
        "Classe VII",
        "Especial"
    ]

    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

    const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword3 = () => setShowPassword3((show) => !show);

    const handleMouseDownPassword3 = (event) => {
        event.preventDefault();
    };


    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#784af4',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#784af4',
            zIndex: 1,
            fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }));

    function QontoStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    }

    QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
    };

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 37,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor:
                    '#FAB63D',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor:
                    '#FAB63D',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 75,
        height: 75,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 10,
        ...(ownerState.active && {
            backgroundColor:
                '#FAB63D',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundColor:
                '#FAB63D',
        }),
    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            1: <SettingsIcon />,
            2: <GroupAddIcon />,
            3: <VideoLabelIcon />,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    ColorlibStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
        /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };

    const steps = ['Dados pessoas e acesso', 'Tipo de plano e pagamento', 'Acesso'];

    // useEffect(() => {
    //     if (currentTheme !== null) {
    //         setLogoLogin(currentTheme.logoMenu)
    //     } else {
    //         api
    //             .get("/Configuracoes")
    //             .then((response) => {
    //                 response.data.map(key => {
    //                     setLogoLogin(key['logoLogin']);
    //                     dispatch(configurationTheme({ logoLogin: key['logoLogin'], logoMenu: key['logoMenu'], colorPrimary: key['colorPrimary'], colorSecundary: key['colorSecundary'] }));
    //                 })
    //             })

    //             .catch((err) => {
    //                 console.error("ops! ocorreu um erro" + err);
    //             });
    //     }
    // }, []);

    const navigate = useNavigate();

    const submitForm = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post("/LoginColaborador", {
                email: email, password: password
            });
            const printAddress = async () => {
                const a = await token;
                dispatch(autentificacao(a))
            };
            printAddress();
            dispatch(userLogin({ token: response.data.token, location: '', id: response.data.id, typeCollaborator: response.data.type, db: response.data.db_name, revenda: response.data.revenda }))
            // dispatch(userLogin({ token: response.data.token, id: response.data.id, typeCollaborator: response.data.type, db: 'clubeApi', revenda: response.data.revenda }))
            navigate('/', { replace: true });
        } catch (err) {
            if (!err.response) {
                setErrMsg('Sem resposta do servidor!');
            } else if (err.response.status === 400) {
                setErrMsg('E-mail ou senha inválido');
            }
        }

    }

    var modules = []

    modules.push(
        {
            name: 'Plano gratuito',
            value: '00,00',
            vista: '',
            eco: '',
            obs: '',
            total: '0'
        },
        {
            name: 'Trimestral',
            value: '299,00',
            vista: '',
            // eco: 'Economize R$ 300,00',
            obs: '*valor equivalente por mês para assinatura anual',
            total: '897,00'
        },
        {
            name: 'Anual',
            value: '199,00',
            vista: '',
            eco: 'Economize R$ 1.200,00',
            obs: '*valor equivalente por mês para assinatura anual',
            total: '2388,00'
        })

    const initialization = {
        amount: 100,
    };

    const customization = {
        visual: {
            texts: {
                formTitle: "Pagamento no crédito ou débito",
                emailSectionTitle: "",
                installmentsSectionTitle: "",
                cardholderName: {
                    label: "Títular do cartão",
                    placeholder: "Digite o nome do títular como está no cartão",
                },
                email: {
                    label: "E-mail",
                    placeholder: "Digite seu e-mail",
                },
                cardholderIdentification: {
                    label: "Documento do títular",
                },
                cardNumber: {
                    label: "Número do cartão",
                },
                expirationDate: {
                    label: "Data de validade",
                },
                securityCode: {
                    label: "Código de segurança",
                },
                selectInstallments: "",
                selectIssuerBank: "",
                formSubmit: "Pagar",
            }
        }
    };


    const onSubmit = async (formData) => {
        // callback called when clicking on the submit data button
        // return new Promise((resolve, reject) => {
        //     fetch('/process_payment', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify(formData),
        //     })
        //         .then((response) => response.json())
        //         .then((response) => {
        //             // receive payment result
        //             resolve();
        //         })
        //         .catch((error) => {
        //             // handle error response when trying to create payment
        //             reject();
        //         });
        // });
    };


    const onError = async (error) => {
        // callback called for all Brick error cases
        console.log(error);
    };


    const onReady = async () => {
        /*
          Callback called when Brick is ready.
          Here you can hide loadings from your site, for example.
        */
    };

    const handleModuleCheck = (divModule) => {
        const divMask = document.getElementById(divModule);
        const divForm = document.getElementById('divPaymentForm');
        const divMask0 = document.getElementById('maskDivModule0');
        const divMask1 = document.getElementById('maskDivModule1');
        const divMask2 = document.getElementById('maskDivModule2');

        if (divMask.style.display == 'flex') {
            divMask.style.display = 'none'
            setModuleSelected('')
        } else if (divMask.style.display == 'none') {
            divMask.style.display = 'flex'
        }

        if (divModule == 'maskDivModule0' && divMask0.style.display == 'none') {
            divMask0.style.display = 'flex'
        } else if (divModule == 'maskDivModule1' && divMask1.style.display == 'none') {
            divMask1.style.display = 'flex'
        } else if (divModule == 'maskDivModule2' && divMask2.style.display == 'none') {
            divMask2.style.display = 'flex'
        }

        if (divModule == 'maskDivModule0') {
            divForm.style.display = 'none';
            divMask1.style.display = 'none'
            divMask2.style.display = 'none'
            document.getElementById('btnprosseguir').style.display = 'block'
            setModuleSelected('free')
        } else if (divModule == 'maskDivModule1') {
            divMask0.style.display = 'none'
            divMask2.style.display = 'none'
            divForm.style.display = 'flex';
            document.getElementById('btnprosseguir').style.display = 'none'
            setModuleSelected('3month')
        } else if (divModule == 'maskDivModule2') {
            divMask0.style.display = 'none'
            divMask1.style.display = 'none'
            divForm.style.display = 'flex';
            document.getElementById('btnprosseguir').style.display = 'none'
            setModuleSelected('1year')
        }
    }

    const gas = [
        'Consigaz',
        'Copagaz',
        'Independente',
        'Liquigás',
        'Nacional Gás',
        'NGC',
        'Ultragaz',
        'Servigás',
        'Supergasbras'
    ];

    const handleModuleCheckHover = (id) => {
        if (moduleSelected == '') {
            if (id == 'maskDivModule0') {
                document.getElementById('maskDivModule0').style.display = 'flex'
            } else if (id == 'maskDivModule1') {
                document.getElementById('maskDivModule1').style.display = 'flex'
            } else if (id == 'maskDivModule2') {
                document.getElementById('maskDivModule2').style.display = 'flex'
            } else {
                if (id == 'fora' && moduleSelected == '') {
                    document.getElementById('maskDivModule0').style.display = 'none'
                    document.getElementById('maskDivModule1').style.display = 'none'
                    document.getElementById('maskDivModule2').style.display = 'none'
                }
            }
        } else {
            // if (id == 'maskDivModule0' && moduleSelected !== 'free') {
            //     document.getElementById('maskDivModule0').style.display = 'flex'
            // } else if (id == 'maskDivModule1' && moduleSelected !== '1month') {
            //     document.getElementById('maskDivModule1').style.display = 'flex'
            // } else if (id == 'maskDivModule2' && moduleSelected !== '3month') {
            //     document.getElementById('maskDivModule2').style.display = 'flex'
            // } else if (id == 'maskDivModule3' && moduleSelected !== '1year') {
            //     document.getElementById('maskDivModule3').style.display = 'flex'
            // } else {
            //     if (moduleSelected !== 'free') {
            //         document.getElementById('maskDivModule1').style.display = 'none'
            //         document.getElementById('maskDivModule2').style.display = 'none'
            //         document.getElementById('maskDivModule3').style.display = 'none'
            //     } else if (moduleSelected !== '1month') {
            //         document.getElementById('maskDivModule0').style.display = 'none'
            //         document.getElementById('maskDivModule2').style.display = 'none'
            //         document.getElementById('maskDivModule3').style.display = 'none'
            //     } else if (moduleSelected !== '3month') {
            //         document.getElementById('maskDivModule0').style.display = 'none'
            //         document.getElementById('maskDivModule1').style.display = 'none'
            //         document.getElementById('maskDivModule3').style.display = 'none'
            //     } else if (moduleSelected !== '1year') {
            //         document.getElementById('maskDivModule0').style.display = 'none'
            //         document.getElementById('maskDivModule1').style.display = 'none'
            //         document.getElementById('maskDivModule2').style.display = 'none'
            //     }
            // }
        }
    }

    const style2 = {
        position: 'relative',
        top: '50%',
        left: '50%',
        width: '25vw',
        height: '10vw',
        borderRadius: "39px !important",
        transform: 'translate(-50%, -50%)',
        bgcolor: '#FFFFFF',
        boxShadow: 24,
        p: 2,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
    };

    function ModalEndPoint(props) {
        return (
            <Modal
                open={openModal}
                onClose={e => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <div className='contectModalGoogleNegocios'>
                        <span className='textContectModalGoogleNegocios'>
                            Parabéns!! Você completou seu cadastro
                        </span>
                        <div className='divBtnContectModalGoogleNegocios' style={{ width: "100%" }} >
                            <button className='btnContectModalGoogleNegocios' onClick={() => {
                                setOpenModal(false)
                            }}>Acessar minha conta</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        )
    }

    const handleCEP = (e) => {
        e.target.value = e.target.value.replace(/^(\d{5})(\d)/, "$1-$2")
        if (e.target.value.length == 9) {
            const cep = e.target.value.replace(/\D/g, '');
            setCep(cep)
            fetch(`https://viacep.com.br/ws/${cep}/json/`)
                .then(res => res.json()).then(data => {
                    setAddress(data.logradouro)
                })
        }
    }

    const handleMaskCpfCnpj = (e) => {
        e.target.value = e.target.value.replace(/\D/g, "")
        e.target.value = e.target.value.replace(/(\d{2})(\d)/, "$1.$2")
        e.target.value = e.target.value.replace(/(\d{3})(\d)/, "$1.$2")
        e.target.value = e.target.value.replace(/(\d{3})(\d)/, "$1.$2")
        e.target.value = e.target.value.replace(/(\d{4})(\d)/, "$1/$2")
        e.target.value = e.target.value.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        e.target.value = cnpj.format(e.target.value)
        setCnpjValidated(cnpj.isValid(e.target.value))
        setCnpjAssa(e.target.value.replace(/\D/g, ""))
    }

    const handleMaskCpfCnpj2 = (e) => {
        if (e.target.value.length <= 14) {
            e.target.value = e.target.value.replace(/\D/g, "")
            e.target.value = e.target.value.replace(/(\d{3})(\d)/, "$1.$2")
            e.target.value = e.target.value.replace(/(\d{3})(\d)/, "$1.$2")
            e.target.value = e.target.value.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
            setCpfCnpj(e.target.value.replace(/\D/g, ""))
        } else if (e.target.value.length >= 14) {
            e.target.value = e.target.value.replace(/\D/g, "")
            e.target.value = e.target.value.replace(/(\d{2})(\d)/, "$1.$2")
            e.target.value = e.target.value.replace(/(\d{3})(\d)/, "$1.$2")
            e.target.value = e.target.value.replace(/(\d{3})(\d)/, "$1.$2")
            e.target.value = e.target.value.replace(/(\d{4})(\d)/, "$1/$2")
            e.target.value = e.target.value.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
            setCpfCnpj(e.target.value.replace(/\D/g, ""))
        }
    }

    const handleMaskPhone = (e) => {
        if (e.target.value.length <= 14) {
            e.target.value = e.target.value.replace(/\D/g, "")
            e.target.value = e.target.value.replace(/^(\d\d)(\d)/g, "($1) $2")
            e.target.value = e.target.value.replace(/(\d{4})(\d)/, "$1-$2")
            setPhone(e.target.value.replace(/\D/g, ""))
        } else {
            e.target.value = e.target.value.replace(/\D/g, "")
            e.target.value = e.target.value.replace(/^(\d\d)(\d)/g, "($1) $2")
            e.target.value = e.target.value.replace(/(\d{5})(\d)/, "$1-$2")
            setPhone(e.target.value.replace(/\D/g, ""))
        }
    }

    const handleMaskWhatsapp = (e) => {
        e.target.value = e.target.value.replace(/\D/g, "")
        e.target.value = e.target.value.replace(/^(\d\d)(\d)/g, "($1) $2")
        e.target.value = e.target.value.replace(/(\d{5})(\d)/, "$1-$2")
        setWhatsapp(e.target.value.replace(/\D/g, ""))
    }

    const handleMaskCard = (e) => {
        e.target.value = e.target.value.replace(/\D/g, "")
        e.target.value = e.target.value.replace(/(\d{4})(\d)/, "$1.$2")
        e.target.value = e.target.value.replace(/(\d{4})(\d)/, "$1.$2")
        e.target.value = e.target.value.replace(/(\d{4})(\d)/, "$1.$2")
        e.target.value = e.target.value.replace(/(\d{4})(\d)/, "$1.$2")
        setNumberCard(e.target.value.replace(/\D/g, ""))
    }

    const handleMaskValidateCard = (e) => {
        e.target.value = e.target.value.replace(/\D/g, "")
        e.target.value = e.target.value.replace(/(\d{2})(\d)/, "$1/$2")
        setValidateCard(e.target.value)
    }

    const handlePayAsaas = async (type) => {
        if (type == 'PIX') {
            let total = 0;
            if (moduleSelected == '3month') {
                total = 1197
            } else if (moduleSelected == '1year') {
                total = 3588
            }
            await api.post('/PagamentoAsaas', {
                type: "pix",
                cnpj: cnpjAssa,
                name: name,
                total: total,
                parc: 1,
                whatsapp: whatsapp
            }).then(response => {
                setCustumerId(response.data.custumer_id)
                setQrcodeCopyPaste(response.data.qrCodeCopyPaste)
                var image = document.getElementById('qrcodeImg');
                image.src = "data:image/gif;base64," + response.data.qrCodeImage;

                image.width = 300;
                image.height = 300;

                setTimeout(() => {
                    api.post('/PagamentoPixVerificar', {
                        id: response.data.custumer_id
                    }).then(response => {
                        let status = response.data.status;
                        if (status == 'RECEIVED') {
                            setCustumerId(response.data.customer)
                            setAlertModal(true)
                            setMessageAlert('Pagamento efetuado com sucesso')
                            setTypeAlert('success')

                            setStap(stap + 1)
                            setOpenModal(true)

                            setTimeout(() => {
                                setAlertModal(false)
                            }, 20000)
                        }
                    })
                }, 10000)
            })
        } else if (type == 'Crédito') {
            let total = 0;
            if (moduleSelected == '3month') {
                total = 1197
            } else if (moduleSelected == '1year') {
                total = 3588
            }

            await api.post('/PagamentoAsaas', {
                type: "credito",
                cnpj: cnpjAssa,
                name: name,
                email: email,
                nameCard: nameCard,
                cnpjAsaas: cpfCnpj,
                numberCard: numberCard,
                validateCard: validateCard,
                cvvCard: cvvCard,
                number: number,
                cep: cep,
                phone: phone,
                total: total,
                parc: parc,
                whatsapp: whatsapp
            }).then(response => {
                setOpen(false)
                var status = response.data.status
                if (status == 'CONFIRMED') {
                    setCustumerId(response.data.customer)
                    setAlertModal(true)
                    setMessageAlert('Pagamento efetuado com sucesso')
                    setTypeAlert('success')

                    setStap(stap + 1)
                    setOpenModal(true)

                    setTimeout(() => {
                        setAlertModal(false)
                    }, 20000)
                } else {
                    setAlertModal(true)
                    setMessageAlert(response.data)
                    setTypeAlert('error')

                    setTimeout(() => {
                        setAlertModal(false)
                    }, 20000)
                }

            }).catch(err => {
                console.log(err)
            })
        } else if (type == 'Boleto') {
            let total = 0;
            if (moduleSelected == '3month') {
                total = 1197
            } else if (moduleSelected == '1year') {
                total = 3588
            }
            await api.post('/PagamentoAsaas', {
                type: "boleto",
                cnpj: cnpjAssa,
                name: name,
                total: total,
                whatsapp: whatsapp
            }).then(response => {
                setCustumerId(response.data.custumer_id)
                setCodeBar(response.data.codigoBarra)
            })
        }

    }

    function copiarLink() {
        let textoCopiado = document.getElementsByClassName("inputQrcodeCopyPaste")[0].childNodes[1].childNodes[0].value;
        let btnCopy = document.getElementById("btnCopy");
        let btnCopyValue = document.getElementById("btnCopyValue");

        navigator.clipboard.writeText(textoCopiado).then(() => {
            setAlertModal(true)
            setMessageAlert('Texto copiado!')
            setTypeAlert('success')

            setTimeout(() => {
                setAlertModal(false)
            }, 3000)
        })
    }

    function copiarLink2() {
        let textoCopiado = document.getElementsByClassName("inputCodeBar")[0].childNodes[1].childNodes[0].value;
        let btnCopy = document.getElementById("btnCopy");
        let btnCopyValue = document.getElementById("btnCopyValue");

        navigator.clipboard.writeText(textoCopiado).then(() => {
            setAlertModal(true)
            setMessageAlert('Texto copiado!')
            setTypeAlert('success')
            setStap(stap + 1)
            setTimeout(() => {
                setAlertModal(false)
            }, 3000)
        })
    }

    const [open, setOpen] = useState(false);
    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    function Loading2() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const validadeEmail = async (email) => {
        let result = ''
        await api.post('/ValidarEmailUsuarioRevenda', { email: email })
            .then(response => {
                result = response.data
            })

        return result
    }

    const validadeNomeRevenda = async (db_name) => {
        let result = ''
        await api.post('/ValidarNomeRevenda', { db_name: db_name })
            .then(response => {
                result = response.data
            })

        return result
    }

    const validadeCnpj = async (cnpj) => {
        let result = ''
        await api.post('/ValidarCnpjUsuarioRevenda', { cnpj: cnpj })
            .then(response => {
                result = response.data
            })
        return result
    }

    return (
        <>
            <ModalEndPoint />
            <Loading2 />
            <Stack className='alertModal' sx={alertModal ? { display: 'block' } : { display: 'none' }} spacing={2}>
                <Alert variant="filled" severity={typeAlert}>{messageAlert}</Alert>
            </Stack>
            <div className='contrateContainer' style={{ height: height }}>
                <div className='imgContainerContrate' style={{ height: height }}>
                    <img src={esta_pronto} className='imgEstaPronto' style={{ height: height }} />
                </div>
                <div className='formContainer' style={{ width: divFormStepWidht }}>
                    <Stack sx={{ width: '100%' }} spacing={4}>
                        <Stepper alternativeLabel activeStep={stap} connector={<ColorlibConnector />}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon} style={{ color: 'white' }}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Stack>
                    <div className='divFormStep' style={{ minHeight: divFormStepHeight }}>
                        <div className='formContract'>
                            <div className='divStapsContract' style={stap == 0 ? { display: 'flex' } : { display: 'none' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
                                    <span style={{ color: '#1A1B39', fontSize: 20, fontWeight: 400 }}>Informações de conta</span>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nome Completo"
                                        className='divInputEmail'
                                        onChange={e => setName(e.target.value)}
                                    />
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nome da Revenda"
                                        className='divInputEmail'
                                        onChange={e => setRevenda(e.target.value)}
                                    />
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="CNPJ"
                                        className='divInputEmail'
                                        onChange={handleMaskCpfCnpj}
                                        inputProps={{ maxLength: 14 }}
                                    />
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Whatsapp"
                                        className='divInputEmail'
                                        onChange={handleMaskWhatsapp}
                                        inputProps={{ maxLength: 15 }}
                                    />
                                    <FormControl sx={{ m: 1 }} className='selectFormPayment2' style={{ width: '38.7vw' }}>
                                        <InputLabel id="demo-multiple-name-label">Classe da sua revenda</InputLabel>
                                        <Select
                                            labelId="demo-multiple-name-label"
                                            id="demo-multiple-name"
                                            value={resellerClass}
                                            onChange={e => {
                                                setResellerClass(e.target.value)
                                            }}
                                        >
                                            {classes.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                >
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ m: 1 }} className='selectFormPayment2' style={{ width: '38.7vw' }}>
                                        <InputLabel id="demo-multiple-name-label">Tipo de revenda</InputLabel>
                                        <Select
                                            labelId="demo-multiple-name-label"
                                            id="demo-multiple-name"
                                            value={typeGas}
                                            onChange={e => {
                                                setTypeGas(e.target.value)
                                            }}
                                        >
                                            {gas.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                >
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
                                    <span style={{ color: '#1A1B39', fontSize: 20, fontWeight: 400 }}>Insira suas informações de acessos</span>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="E-mail"
                                        className='divInputEmail'
                                        inputProps={{
                                            type: "email",
                                        }}
                                        onChange={e => {
                                            setEmail(e.target.value)
                                        }}
                                    />
                                    <FormControl variant="outlined" className='inputPass inputPassLogin' >
                                        <InputLabel htmlFor="outlined-adornment-password" style={password == '' ? { top: 0 } : { top: 5 }}>Senha</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            className='divInputChat'
                                            type={showPassword3 ? 'text' : 'password'}
                                            style={{ height: 55, width: '100%', backgroundColor: 'white' }}
                                            onChange={e => setPassword(e.target.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword1}
                                                        onMouseDown={handleMouseDownPassword1}
                                                        edge="end"
                                                    >
                                                        {showPassword3 ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Senha"
                                            required
                                        />
                                    </FormControl>
                                    <FormControl variant="outlined" className='inputPass inputPassLogin' >
                                        <InputLabel htmlFor="outlined-adornment-password" style={password == '' ? { top: 0 } : { top: 5 }}>Confirmar senha</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            className='divInputChat'
                                            type={showPassword3 ? 'text' : 'password'}
                                            style={{ height: 55, width: '100%', backgroundColor: 'white' }}
                                            onChange={e => setPasswordConfirm(e.target.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword2}
                                                        onMouseDown={handleMouseDownPassword2}
                                                        edge="end"
                                                    >
                                                        {showPassword3 ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Senha"
                                            required
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className='divStapsContract' style={stap == 1 ? { display: 'flex', maxHeight: '109vh' } : { display: 'none' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
                                    <span style={{ color: '#1A1B39', fontSize: 20, fontWeight: 400, paddingTop: pTop }}>Escolha o seu plano </span>
                                    <div className='divModuleOptions'>
                                        {modules.map((key, index) => {
                                            var splitValue = key.value.split(',')
                                            return (
                                                <>
                                                    <div className='ballumModules' onClick={e => handleModuleCheck(`maskDivModule${index}`)} onMouseOverCapture={e => handleModuleCheckHover(`maskDivModule${index}`)} onMouseOut={e => handleModuleCheckHover('fora')}>
                                                        <div className='maskDivModule' id={`maskDivModule${index}`} style={{ display: 'none' }}><FaCheckCircle className='icoModuleCheck' /></div>
                                                        <span style={{ textAlign: 'center', fontSize: 18, color: '#1A1B39', fontWeight: 'bold' }}>{key.name}</span>
                                                        <span style={{ textAlign: 'center', fontSize: 14, color: '#55C100', fontWeight: 'bold' }}>Valor total R$ {key.total}</span>
                                                        <span style={{ textAlign: 'center', fontSize: 16, color: '#1A1B39' }}>R$ <span style={{ textAlign: 'center', fontSize: 35, color: '#1A1B39', fontWeight: 900 }}>{splitValue[0]}</span>,{splitValue[1]} / Mês* </span>
                                                        <span style={{ textAlign: 'center', fontSize: 14, color: '#666666', fontWeight: 'bold' }}>{key.vista}</span>
                                                        <span style={{ textAlign: 'center', fontSize: 14, color: '#55C100', fontWeight: 'bold' }}>{key.eco}</span>
                                                        <span style={{ textAlign: 'center', fontSize: 8, color: '#666666', fontWeight: 'bold' }}>{key.obs}</span>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center', margin: 10 }}>
                                    <div className='divPaymentForm' id='divPaymentForm' style={{ display: 'none', flexDirection: 'column', width: '100%' }}>
                                        <FormControl sx={{ m: 1 }} className='selectFormPayment'>
                                            <InputLabel id="demo-multiple-name-label">Forma de pagamento</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                value={paymentForm}
                                                onChange={e => {
                                                    if (e.target.value == 'Débito' || e.target.value == 'Crédito') {
                                                        if (window.innerWidth <= 1280 && window.innerHeight <= 720) {
                                                            setHeight(window.innerHeight + 700)
                                                            setDivFormStepHeight('178vh')
                                                            setPTop(370)
                                                        } else if (window.innerWidth <= 1280 && window.innerHeight <= 960) {
                                                            setHeight(window.innerHeight + 500)
                                                            setDivFormStepHeight('123vh')
                                                        } else if (window.innerWidth <= 1366 && window.innerHeight <= 768) {
                                                            setHeight(window.innerHeight + 700)
                                                            setDivFormStepHeight('170vh')
                                                            setPTop(370)
                                                        } else if (window.innerWidth <= 1400 && window.innerHeight <= 900) {
                                                            setHeight(window.innerHeight + 700)
                                                            setDivFormStepHeight('151vh')
                                                            setPTop(180)
                                                        } else if (window.innerWidth <= 1600 && window.innerHeight <= 900) {
                                                            setHeight(window.innerHeight + 500)
                                                            setDivFormStepHeight('130vh')
                                                        } else if (window.innerWidth <= 1680 && window.innerHeight <= 1050) {
                                                            setHeight(window.innerHeight + 400)
                                                            setDivFormStepHeight('119vh')
                                                        } else if (window.innerWidth <= 1980 && window.innerHeight <= 980) {
                                                            setHeight(window.innerHeight + 120)
                                                            setDivFormStepHeight('94vh')
                                                        } else if (window.innerHeight <= 844) {
                                                            setHeight(window.innerHeight + 1000)
                                                            setDivFormStepHeight('160vh')
                                                            setPTop(370)
                                                        } else {
                                                            setHeight(window.innerHeight + 500)
                                                            setDivFormStepHeight('117vh')
                                                            setPTop(0)
                                                        }
                                                    } else {
                                                        if (e.target.value == 'PIX') {
                                                            handlePayAsaas(e.target.value)
                                                        }
                                                        if (e.target.value == 'Boleto') {
                                                            handlePayAsaas(e.target.value)
                                                        }
                                                        setHeight('100vh')
                                                        setDivFormStepHeight('70vh')
                                                        setPTop(0)
                                                    }
                                                    setPaymentForm(e.target.value)
                                                }}
                                            >
                                                {names.map((name) => (
                                                    <MenuItem
                                                        key={name}
                                                        value={name}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <div style={paymentForm == 'Crédito' ? { display: 'flex', flexDirection: 'column' } : paymentForm == 'Débito' ? { display: 'flex', flexDirection: 'column' } : { display: 'none' }}>
                                            <FormControl sx={{ m: 1 }} className='inputFormPayment'>
                                                <TextField
                                                    required
                                                    id="outlined-required"
                                                    label="Nome impresso no cartão"
                                                    className='divInputEmail'
                                                    onChange={e => setNameCard(e.target.value)}
                                                    style={{ margin: 0, width: '100%' }}
                                                />
                                            </FormControl>
                                            <FormControl sx={{ m: 1 }} className='selectFormPayment'>
                                                {moduleSelected == '3month' ? (
                                                    <>
                                                        <InputLabel id="demo-multiple-name-label">Quantida de parcelas</InputLabel>
                                                        <Select
                                                            required
                                                            labelId="demo-multiple-name-label"
                                                            id="demo-multiple-name"
                                                            value={parc}
                                                            onChange={e => {
                                                                setParc(e.target.value)
                                                            }}
                                                        >
                                                            {array3month.map(key => {
                                                                var total3month = 897;
                                                                var totalParc = total3month / key;
                                                                return (
                                                                    <MenuItem
                                                                        key={key}
                                                                        value={key}
                                                                    >
                                                                        {key}x de <CurrencyInput
                                                                            style={{ border: 'none', background: 'transparent', cursor: 'auto' }}
                                                                            defaultValue={totalParc}
                                                                            onChangeValue={(event, originalValue, maskedValue) => {
                                                                                // console.log(event, originalValue, maskedValue);
                                                                            }}
                                                                            disabled
                                                                        />
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </>

                                                ) : moduleSelected == '1year' ? (
                                                    <>
                                                        <InputLabel id="demo-multiple-name-label">Quantida de parcelas</InputLabel>
                                                        <Select
                                                            labelId="demo-multiple-name-label"
                                                            id="demo-multiple-name"
                                                            value={parc}
                                                            onChange={e => {
                                                                setParc(e.target.value)
                                                            }}
                                                        >
                                                            {array1year.map(key => {
                                                                var total1month = 2388;
                                                                var totalParc = total1month / key;
                                                                return (
                                                                    <MenuItem
                                                                        key={key}
                                                                        value={key}
                                                                    >
                                                                        {key}x de <CurrencyInput
                                                                            style={{ border: 'none', background: 'transparent', cursor: 'auto' }}
                                                                            defaultValue={totalParc}
                                                                            onChangeValue={(event, originalValue, maskedValue) => {
                                                                                // console.log(event, originalValue, maskedValue);
                                                                            }}
                                                                            disabled
                                                                        />
                                                                    </MenuItem>

                                                                )
                                                            })}
                                                        </Select>
                                                    </>
                                                ) : ('')}

                                            </FormControl>
                                            <FormControl sx={{ m: 1 }} className='inputFormPayment'>
                                                <TextField
                                                    required
                                                    id="outlined-required"
                                                    label="Número do cartão"
                                                    className='divInputEmail'
                                                    onChange={handleMaskCard}
                                                    style={{ margin: 0, width: '100%' }}
                                                />
                                            </FormControl>
                                            <FormControl sx={{ m: 1 }} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className='inputFormPayment2'>
                                                <TextField
                                                    required
                                                    id="outlined-required"
                                                    label="Validade do cartão"
                                                    className='divInputEmail'
                                                    onChange={handleMaskValidateCard}
                                                    style={{ margin: 0, width: '45%' }}
                                                />
                                                <TextField
                                                    required
                                                    id="outlined-required"
                                                    label="CVV"
                                                    className='divInputEmail'
                                                    onChange={e => setCvvCard(e.target.value)}
                                                    style={{ margin: 0, width: '45%' }}
                                                />
                                            </FormControl>
                                            <span style={{ color: '#1A1B39', fontSize: 20, fontWeight: 400, margin: 10 }}>Dados do dono do cartão</span>
                                            <FormControl sx={{ m: 1 }} className='inputFormPayment'>
                                                <TextField
                                                    required
                                                    id="outlined-required"
                                                    label="CPF ou CNPJ do dono"
                                                    className='divInputEmail'
                                                    onChange={handleMaskCpfCnpj2}
                                                    style={{ margin: 0, width: '100%' }}
                                                />
                                            </FormControl>
                                            <FormControl sx={{ m: 1 }} className='inputFormPayment'>
                                                <TextField
                                                    required
                                                    id="outlined-required"
                                                    label="CEP"
                                                    className='divInputEmail'
                                                    onChange={handleCEP}
                                                    style={{ margin: 0, width: '100%' }}
                                                />
                                            </FormControl>
                                            <FormControl sx={{ m: 1 }} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className='inputFormPayment2'>
                                                <TextField
                                                    required
                                                    id="outlined-required"
                                                    label="Endereço"
                                                    className='divInputEmail'
                                                    onChange={e => setAddress(e.target.value)}
                                                    value={address}
                                                    style={{ margin: 0, width: '80%' }}
                                                />
                                                <TextField
                                                    required
                                                    id="outlined-required"
                                                    label="Número"
                                                    className='divInputEmail'
                                                    onChange={e => setNumber(e.target.value)}
                                                    style={{ margin: 0, width: '15%' }}
                                                />
                                            </FormControl>
                                            <FormControl sx={{ m: 1 }} className='inputFormPayment'>
                                                <TextField
                                                    id="outlined-required"
                                                    label="Complemento"
                                                    className='divInputEmail'
                                                    onChange={e => setComplementAddress(e.target.value)}
                                                    style={{ margin: 0, width: '100%' }}
                                                />
                                            </FormControl>
                                            <FormControl sx={{ m: 1 }} className='inputFormPayment'>
                                                <TextField
                                                    required
                                                    id="outlined-required"
                                                    label="Telefone de contato"
                                                    className='divInputEmail'
                                                    onChange={handleMaskPhone}
                                                    style={{ margin: 0, width: '100%' }}
                                                />
                                            </FormControl>
                                            <div className="buttonLogin" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <button id="buttonLogin" style={{ backgroundColor: '#FAB63D', color: 'white', fontWeight: 400 }} onClick={e => {
                                                    handlePayAsaas('Crédito')
                                                    setOpen(true)
                                                }

                                                }>
                                                    Efetuar pagamento
                                                </button>
                                            </div>
                                        </div>
                                        <div style={paymentForm == 'PIX' ? { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' } : { display: 'none' }}>
                                            <img id="qrcodeImg" />
                                            {qrcodeCopyPaste !== '' ? (
                                                <FormControl sx={{ m: 1 }} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                                                    <TextField
                                                        id="outlined-required inputQrcodeCopyPaste"
                                                        label="PIX copia e cola"
                                                        className='inputQrcodeCopyPaste'
                                                        value={qrcodeCopyPaste}
                                                        style={{ margin: 0, width: '100%' }}
                                                    />
                                                    <button className='btnCopyLink' onClick={() => { copiarLink() }}>
                                                        <FaRegCopy className='icoCopyLink' />
                                                    </button>
                                                </FormControl>
                                            ) : (<Loading />)}
                                        </div>
                                        <div style={paymentForm == 'Boleto' ? { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' } : { display: 'none' }}>
                                            {codeBar !== '' ? (
                                                <FormControl sx={{ m: 1 }} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                                                    <TextField
                                                        id="outlined-required inputQrcodeCopyPaste"
                                                        label="Código de barras"
                                                        className='inputCodeBar'
                                                        value={codeBar}
                                                        style={{ margin: 0, width: '100%' }}
                                                    />
                                                    <button className='btnCopyLink' onClick={() => { copiarLink2() }}>
                                                        <FaRegCopy className='icoCopyLink' />
                                                    </button>
                                                </FormControl>
                                            ) : (<Loading />)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='divStapsContract' style={stap == 2 ? { display: 'flex' } : { display: 'none' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
                                    <span style={{ color: '#1A1B39', fontSize: 20, fontWeight: 400 }}>Acesse o seu painel</span>
                                    <form onSubmit={submitForm} className="input">
                                        <input
                                            className="inputLog"
                                            aria-label="email"
                                            placeholder="E-mail"
                                            aria-describedby="inputGroup-sizing-sm"
                                            onChange={e => setAccessEmail(e.target.value)}
                                            name="email"
                                            value={email}
                                        />
                                        <input
                                            type='password'
                                            className="inputLog"
                                            placeholder="Senha"
                                            onChange={e => setAccessPass(e.target.value)}
                                            name="password"
                                            value={password}
                                        />
                                        <div className="buttonLogin" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <button id="buttonLogin" style={{ backgroundColor: '#FAB63D', color: 'black', fontWeight: 400 }}>
                                                Acessar
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className='btnFormContract' style={stap == 2 ? { display: 'none' } : { display: 'flex' }}>
                            {stap > 0 ? (
                                <button onClick={e => {
                                    setStap(stap - 1)
                                }} className='btnReturnContract'>Retornar</button>
                            ) : ('')}

                            <button id='btnprosseguir' style={stap == 1 ? { display: 'none' } : { display: 'block' }} onClick={e => {
                                if (stap == 0) {
                                    if (password == passwordConfirm) {
                                        if (name !== '' && revenda !== '' && cnpjAssa !== '' && email !== '') {
                                            if (name.split(' ').length == 1) {
                                                setAlertModal(true)
                                                setMessageAlert('Insira o nome valido ou completo')
                                                setTypeAlert('error')
                                                setTimeout(function () {
                                                    setAlertModal(false)
                                                }, 3000);
                                            } else {
                                                if (email.split('@').length == 1) {
                                                    setAlertModal(true)
                                                    setMessageAlert('Insira um e-mail valido')
                                                    setTypeAlert('error')
                                                    setTimeout(function () {
                                                        setAlertModal(false)
                                                    }, 3000);
                                                } else {
                                                    if (cnpjValidated) {
                                                        var resultEmail = validadeEmail(email)
                                                        var resultCnpj = validadeCnpj(cnpjAssa) 
                                                        let emailReseller = ''
                                                        let cnpjReseller = ''
                                                        resultEmail.then(emailF => {
                                                            emailReseller = emailF
                                                            resultCnpj.then(cnpjF => {
                                                                cnpjReseller = cnpjF

                                                                let valEmail = false;
                                                                let valCnpj = false;

                                                                if (cnpjReseller == null) {
                                                                    valCnpj = true
                                                                } else {
                                                                    setAlertModal(true)
                                                                    setMessageAlert('CNPJ já cadastrado')
                                                                    setTypeAlert('error')
                                                                    setTimeout(function () {
                                                                        setAlertModal(false)
                                                                    }, 3000);
                                                                }

                                                                if (emailReseller == null) {
                                                                    valEmail = true
                                                                } else {
                                                                    setAlertModal(true)
                                                                    setMessageAlert('E-mail já cadastrado')
                                                                    setTypeAlert('error')
                                                                    setTimeout(function () {
                                                                        setAlertModal(false)
                                                                    }, 3000);
                                                                }

                                                                if (valCnpj && valCnpj) {
                                                                    setStap(stap + 1)
                                                                }
                                                            })
                                                        })


                                                    } else {
                                                        setAlertModal(true)
                                                        setMessageAlert('CNPJ Invalido')
                                                        setTypeAlert('error')
                                                        setTimeout(function () {
                                                            setAlertModal(false)
                                                        }, 3000);
                                                    }
                                                }

                                            }
                                        } else {
                                            setAlertModal(true)
                                            setMessageAlert('Preencha todos os campos!')
                                            setTypeAlert('error')
                                            setTimeout(function () {
                                                setAlertModal(false)
                                            }, 3000);
                                        }
                                    } else {
                                        setAlertModal(true)
                                        setMessageAlert('Senha e confirmação de senha precisa ser iguais!')
                                        setTypeAlert('error')
                                        setTimeout(function () {
                                            setAlertModal(false)
                                        }, 3000);
                                    }
                                }

                                if (stap == 1) {
                                    setStap(stap + 1)
                                    handlePayAsaas(paymentForm)
                                    setOpenModal(true)
                                }


                            }} className='btnNextContract'>Prosseguir</button>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default PromotionPayReseller