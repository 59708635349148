import { useState } from "react";
import React from 'react'
import './categoryBusiness.css';
import { Card, Table, Button, Offcanvas, Modal } from "react-bootstrap";
import api from "../../service/api";
import TableCategoryBusiness from '../TableCategoryBusiness'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import { useSelector, useDispatch } from 'react-redux';
import Modal2 from '@mui/material/Modal';
import warning from '../../image/warning.png'
import trimestral from '../../image/trimestral.png'
import anual from '../../image/anual_.png'
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";

const CategoryBusiness = () => {

    const [show, setShow] = useState(false);
    const [modalAvisoPlan, setModalAvisoPlan] = React.useState(false)
    const [typePlan, setTypePlan] = React.useState('free')
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (typePlan !== 'free') {
            setShow(true)
        } else {
            setModalAvisoPlan(true)
        }
    };

    const navigate = useNavigate();

    React.useEffect(() => {
        api
            .get("/TodosUsuariosRevenda")
            .then((response) => {
                response.data.map(key => {
                    if (key['db_name'] == currentUser.db) {
                        setTypePlan(key['module'])
                    }
                })
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        const dados = {
            name: data.categoryBusiness,
            db: currentUser.db
        }

        api.post("/CadastrarCategoriaComercio", dados);

        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 3000);
    }

    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    function OffCanvasExample({ ...props }) {
        return (
            <>
                <Loading />
                <Button variant="primary" onClick={handleShow} className="me-2 button">
                    {'Novo categoria'}
                </Button>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header >
                        <Offcanvas.Title>Novo categoria de comércio</Offcanvas.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form className="input" onSubmit={submitForm}>
                            <div className="divCadastro">
                                <InputLabel htmlFor="categoryBusiness">Nome da categoria *</InputLabel>
                                <input
                                    required
                                    id="categoryBusiness"
                                    name="categoryBusiness"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="buttonLogin">
                                <button className="buttonSecundary" >
                                    Criar categoria
                                </button>
                            </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );

    }

    const style = {
        position: 'relative',
        top: '50%',
        left: '50%',
        width: 607,
        maxHeight: 660,
        borderRadius: "39px !important",
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
    };

    function ModalAvisoPlan(props) {
        return (
            <Modal2
                open={modalAvisoPlan}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='boxModalRaioX'>
                    <div style={{ position: 'absolute', zIndex: 9, right: '-17px', top: '-10px' }}>
                        <button style={{ position: 'relative', background: 'red', color: 'white', fontWeight: 900, borderRadius: 25, width: 35, height: 35 }} onClick={() => setModalAvisoPlan(false)}>X</button>
                    </div>
                    <img src={warning} className='imgModalValidatorPage' />
                    <div className='contectModalValidatorPage'>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 32, color: '#25368C', fontWeight: 900, lineHeight: 1 }}>
                            Essa função não está disponível no plano gratuito
                        </span>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 28, fontWeight: 600, color: 'black', width: '98%', lineHeight: 1, marginTop: 10 }}>
                            Escolha um plano e desbloqueie diversas funções da plataforma
                        </span>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end', flexDirection: 'row', marginTop: 10, width: '83%' }}>
                        <img src={trimestral} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/trimestral")} />
                        <img src={anual} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/anual")} />
                        </div>
                    </div>
                </Box>
            </Modal2>
        )
    }

    return (
        <>
            <ModalAvisoPlan />
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Categoria do comércio</h5>
                    </div>
                    <div>
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                    </div>
                </div>
                <Card className="cardContainer tableCategoryBusiness" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableCategoryBusiness />
                </Card>
            </div >
        </>
    )
}

export default CategoryBusiness