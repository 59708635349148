import { useState, useEffect } from "react";
import React from 'react'
import './ordered.css';
import { Card, Table, Button, Offcanvas, Modal } from "react-bootstrap";
import api from "../../service/api";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import TableOrdered from '../TableOrdered'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ConsoleSqlOutlined } from "@ant-design/icons";
import SearchInput from "../../components/SearchInput";
import { setTextRange } from "typescript";
import { AiOutlineSearch } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import ReactWhatsapp from 'react-whatsapp';
import axios from "axios";
import Modal2 from '@mui/material/Modal';
import warning from '../../image/warning.png'
import trimestral from '../../image/trimestral.png'
import anual from '../../image/anual_.png'
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { CurrencyInput } from 'react-currency-mask';
import { number } from "prop-types";

const Ordered = () => {

    const [payment, setPayment] = useState([]);
    const [product, setProduct] = useState([]);
    const [saleChanell, setSaleChanell] = useState([]);

    const [optionsProducts, setOptionsProducts] = useState([]);
    const [modalConfirmOrder, setModalConfirmOrder] = useState(false);
    const [orderedModal, setOrderedModal] = useState(false);
    const [clientValue, setClientValue] = useState();
    const [paymentValue, setPaymentValue] = useState();
    const [paymentValue2, setPaymentValue2] = useState();
    const [paymentValue3, setPaymentValue3] = useState();
    const [paymentValueTotal, setPaymentValueTotal] = useState()
    const [paymentValueTotal2, setPaymentValueTotal2] = useState()
    const [paymentValueTotal3, setPaymentValueTotal3] = useState()
    const [channelValue, setChannelValue] = useState();
    const [totalValue, setTotalValue] = useState();
    const [obsValue, setObsValue] = useState();
    const [pName, setPName] = useState();
    const [pValue, setPValue] = useState([]);
    const [clientPhysic, setClientPhysic] = useState([]);
    const [clientBusiness, setClientBusiness] = useState([]);
    const [physicSelected, setPhysicSelected] = useState(false);
    const [businessSelected, setBusinessSelected] = useState(false);
    const [obsPayment, setObsPayment] = useState();
    const [typeClient, setTypeClient] = useState();
    const [driver, setDriver] = useState([]);
    const [driverSelected, setDriverSelected] = useState();
    const [newName, setNewName] = useState("");
    const [newNumber, setNewNumber] = useState("");
    const [newBairro, setNewBairro] = useState("");
    const [newAddress, setNewAddress] = useState("");
    const [newId, setNewId] = useState("")
    const [descont, setDescont] = useState(0);
    const [clientPhysicDB, setClientePhysicDB] = useState([]);
    const [clientBusinessDB, setClientBusinessDB] = useState([]);
    const [busca, setBusca] = useState('');
    const [buscaClienteFisico, setBuscaClienteFisico] = useState([]);
    const [streetPhysic, setStreetPhysic] = useState("");
    const [ordered, setOrdered] = useState([]);
    const [historyOrder, setHistoryOrder] = useState(false);
    const [valueTotal, setValueTotal] = useState(0);
    const [clientName, setClientName] = useState('')
    const [clientAdress, setClientAdress] = useState('')
    const [typeSearch, setTypeSearch] = useState('fisico')
    const [phoneWhatsapp, setPhoneWhatsapp] = useState('');
    const [messageWhatsapp, setMessageWhatsapp] = useState('')
    const [typeBusiness, setTypeBusiness] = useState([])
    const [modalAvisoPlan, setModalAvisoPlan] = React.useState(false)
    const [typePlan, setTypePlan] = React.useState('free')
    const [showDataClientPhysic, setShowDataClientPhysic] = useState(false)
    const [showDataClientBusiness, setShowDataClientBusiness] = useState(false)

    const animatedComponents = makeAnimated();

    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    let idSearch = '';
    let typeCliente = ''
    let clientType = 'fisico';

    const [text, setText] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };
    const handleModalClose = () => {
        setModalConfirmOrder(false)
        setOrderedModal(false)
    };

    let productValue = '';
    let productName = [];
    var orderPhysic = [];
    let id = '';
    let valueOriginal = 0;
    const date = new Date()
    const todayDate = date.toLocaleDateString("pt-BR")
    const todayTime = date.toLocaleTimeString("pt-BR")
    const today = todayDate + " " + todayTime;

    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    let db = 'clubeApi';
    if (currentUser !== undefined || currentUser !== null) {
        db = currentUser.db
    }


    React.useEffect(() => {
        api
            .get("/TodosUsuariosRevenda")
            .then((response) => {
                response.data.map(key => {
                    if (key['db_name'] == currentUser.db) {
                        setTypePlan(key['module'])
                    }
                })
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    useEffect(() => {
        api
            .get("/TodasFormasPagamentos" + '/' + currentUser.db)
            .then((response) => {
                setPayment(response.data)
            })

            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    useEffect(() => {
        api
            .get("/TodasCategoriasComerciais" + '/' + currentUser.db)
            .then((response) => {
                setTypeBusiness(response.data)
            })

            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    useEffect(() => {
        api
            .get("/TodosClientesFisicos" + '/' + currentUser.db)
            .then((response) => {
                setClientPhysic(response.data.map(key => ({
                    value: key['id'],
                    label: key['name'] + " - " + key['street']
                })))
                setClientePhysicDB(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });

    }, []);

    useEffect(() => {
        api
            .get("/TodosClientesJuridicos" + '/' + currentUser.db)
            .then((response) => {
                setClientBusiness(response.data.map(key => ({
                    value: key['id'],
                    label: key['name'] + " - " + key['street']
                })))
                setClientBusinessDB(response.data)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });

    }, []);

    useEffect(() => {
        setDriver([])
        api
            .get("/TodosMotoristas" + '/' + currentUser.db)
            .then((response) => {
                var driversArray = []
                response.data.map(key => {
                    if (key['reseller_db'] == currentUser.db) {
                        driversArray.push(key)
                    }
                })
                setDriver(driversArray)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    useEffect(() => {
        api
            .get("/TodosProdutos" + '/' + currentUser.db)
            .then((response) => {
                setProduct(response.data);
                setOptionsProducts(response.data.map(key => ({
                    value: key['id'],
                    label: key['name']
                })))
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);
    useEffect(() => {
        api
            .get("/TodosPedidos2" + '/' + currentUser.db)
            .then((response) => {
                setOrdered(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);


    useEffect(() => {
        api
            .get("/TodosCanaisVendas" + '/' + currentUser.db)
            .then((response) => setSaleChanell(response.data))
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);


    const submitForm = (e) => {
        // var product = String(pValue.join())
        var obs = '';
        let message = '';
        if (obsPayment !== "") {
            obs = obsValue
        }
        let res = clientName + " / " + clientAdress;
        let idNew = clientValue;

        if (clientValue == 0 || clientValue == undefined) {
            idNew = newId
        }
        console.log(typeClient)
        if (typeClient == "fisico") {
            clientPhysicDB.map(key => {
                if (key['id'] == idNew) {
                    res = key['name'] + " / " + key['street'] + ", " + key['number'] + " - " + key['neighborhood']
                    setClientName(key['name'])
                    setClientAdress(key['street'] + ", " + key['number'] + " - " + key['neighborhood'])
                    if (obs == '') {
                        message =
                            `*Novo Pedido* 

*Cliente e Endereço:* ${key['name']} / ${key['street']}, ${key['number']} - ${key['neighborhood']} 
*Produtos:* ${pName} 
*Valor:* R$ ${totalValue}`;
                    } else {
                        message =
                            `*Novo Pedido* 

*Cliente e Endereço:* ${key['name']} / ${key['street']}, ${key['number']} - ${key['neighborhood']} 
*Produtos:* ${pName} 
*Valor:* R$ ${totalValue}
*Observação:* ${obs}`;
                    }
                }
            })
        } else if (typeClient == 'juridico') {
            clientBusinessDB.map(key => {
                if (key['id'] == idNew) {
                    res = key['name'] + " / " + key['street'] + ", " + key['number'] + " - " + key['neighborhood']
                    setClientName(key['name'])
                    setClientAdress(key['street'] + ", " + key['number'] + " - " + key['neighborhood'])
                    if (obs == '') {
                        message =
                            `*Novo Pedido* 

*Cliente e Endereço:* ${key['name']} / ${key['street']}, ${key['number']} - ${key['neighborhood']} 
*Produtos:* ${pName} 
*Valor:* R$ ${totalValue}`;
                    } else {
                        message =
                            `*Novo Pedido* 

*Cliente e Endereço:* ${key['name']} / ${key['street']}, ${key['number']} - ${key['neighborhood']} 
*Produtos:* ${pName} 
*Valor:* R$ ${totalValue}
*Observação:* ${obs}`;
                    }
                }
            })
        }

        let phoneDriver = ''
        driver.map(key => {
            if (key['id'] == driverSelected) {
                phoneDriver = key['phone']
            }
        })

        let whatsPhone = phoneDriver.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");
        let phoneSplit = phoneDriver.split()
        window.open('https://web.whatsapp.com/send?phone=55' + phoneDriver + '&text=' + message, "minhaJanela", "height=1800,width=1800");
        axios
            .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/${currentUser.db}`,
                {
                    number: "55" + whatsPhone + "@s.whatsapp.net",
                    textMessage: {
                        text: message
                    },
                    delay: 1200
                }, {
                headers: {
                    'Content-Type': 'application/json',
                    'apikey': 'B6D711FCDE4D4FD5936544120E713976'
                }
            })
            .then((response) => {
                console.log(response);
            })
            .catch((err) => {
                console.log(err)
            })

        api.post("/CadastrarPedido", {
            typeClient: typeClient,
            driver_id: Number(driverSelected),
            product_id: pValue.toString(),
            products: pName,
            client_id: idNew,
            payment_id: Number(paymentValue),
            payment_id2: Number(paymentValue2),
            payment_id3: Number(paymentValue3),
            payment_value: paymentValueTotal,
            payment_value2: paymentValueTotal2,
            payment_value3: paymentValueTotal3,
            sale_channel: Number(channelValue),
            order_date: today,
            obs: obs,
            total: totalValue,
            quick_client: res,
            status: "Entregador notificado",
            obsPayment: obsPayment,
            desconto: descont,
            db: currentUser.db
        }).then(response => {
            setMessageWhatsapp(message)
            setPhoneWhatsapp('55-' + phoneDriver)
            let name = ''
            let address = ''
            let number = '';
            let bairro = '';
            let paymentName = '';
            let whatsappFormated = '';

            if (typeClient == 'fisico') {
                clientPhysicDB.map(key => {
                    if (key['id'] == Number(idNew)) {
                        name = key['name'];
                        address = key['street'];
                        number = key['number'];
                        bairro = key['neighborhood']
                        whatsappFormated = key['phone']
                    }
                })
            } else if (typeClient == 'juridico') {
                clientBusinessDB.map(key => {
                    if (key['id'] == Number(idNew)) {
                        name = key['name'];
                        address = key['street'];
                        number = key['number'];
                        bairro = key['neighborhood']
                        whatsappFormated = key['phone']
                    }
                })
            }


            let messageClient = `Olá ${name}, o seu pedido foi feito com sucesso! \n \n Pedido nº ${response.data.id} \n \n Itens: ➡ ⁠ ${pName} \n \n 🏪 Entrega em: \n ${address},  ${number} - ${bairro} \n \n Total: R$ ${(totalValue - descont)} \n \n Obrigado pela preferência, se precisar de algo é só chamar! 😉`;

            axios
                .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/${currentUser.db}`,
                    {
                        number: "55" + whatsappFormated + "@s.whatsapp.net",
                        textMessage: {
                            text: messageClient
                        },
                        delay: 1200
                    }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'apikey': 'B6D711FCDE4D4FD5936544120E713976'
                    }
                })
                .then((response) => {
                    // console.log(response);
                })
                .catch((err) => {
                    console.log(err)
                })
            // console.log(response)
        }).catch(error => {
            // console.log(error)
        });
        setObsValue("")
        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 5000);

    }

    const submitModalConfirm = e => {
        e.preventDefault();
        setOrderedModal(false)
        setPName('')
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        var inputProduct1 = document.getElementById("inputProductSelect1").value
        var inputProduct2 = document.getElementById("inputProductSelect2").value
        var inputProduct3 = document.getElementById("inputProductSelect3").value
        var ulClientPhysic = document.getElementById('ulClientPhysic');
        var ulClientBusiness = document.getElementById('ulClientBusiness');
        var selectQuant = [];
        let resultSelect = '';
        let newClient = false;
        let newType = '';
        let newAddress1 = ''
        let newNumber1 = ''
        let newBairro1 = ''
        let newName1 = ''
        let newPhone = ''
        let desconto = ''
        let commercialType = ''
        typeCliente = document.getElementById('typeClient').value;

        var splitDesc = data.inputDesconto.replace('R$', '').replace('.', '').replace(',', '.');
        desconto = parseFloat(splitDesc);

        for (var i = 0, l = productName.length; i < l; i++) {
            if (i == 0) {
                selectQuant.push(inputProduct1 + "x" + " " + productName[i]);
            }
            if (i == 1) {
                selectQuant.push(inputProduct2 + "x" + " " + productName[i]);
            }
            if (i == 2) {
                selectQuant.push(inputProduct3 + "x" + " " + productName[i]);
            }
        }
        if (selectQuant.length == 1) {
            resultSelect = selectQuant.join(", ")
        } else {
            resultSelect = selectQuant.join(", ")
        }

        if (typeCliente == "fisico") {
            if (ulClientPhysic.innerHTML == 'Cliente não localizado') {
                newClient = true
                newType = 'fisico'
                newAddress1 = data.streetClientPhysic
                newNumber1 = data.numClientPhysic
                newBairro1 = data.bairroClientPhysic
                newName1 = data.nameClientPhysic
                newPhone = data.inputSearchPhysic
                setNewAddress(data.streetClientPhysic)
                setNewNumber(data.numClientPhysic)
                setNewBairro(data.bairroClientPhysic)
                setNewName(data.nameClientPhysic)
                setPhysicSelected(true)
                setBusinessSelected(false)
                setClientName(data.nameClientPhysic)
                setClientAdress(data.streetClientPhysic + ", " + data.numClientPhysic + " - " + data.bairroClientPhysic)
            }
        } else {
            if (ulClientBusiness.innerHTML == 'Cliente não localizado') {
                newClient = true
                newType = 'juridico'
                commercialType = data.typeClientBusiness
                newAddress1 = data.streetClientBusiness
                newNumber1 = data.numberClientBusiness
                newBairro1 = data.bairroClientBusiness
                newName1 = data.nameClientBusiness
                newPhone = data.inputSearchBusiness
                setNewAddress(data.streetClientBusiness)
                setNewNumber(data.numberClientBusiness)
                setNewBairro(data.bairroClientBusiness)
                setNewName(data.nameClientBusiness)
                setPhysicSelected(false)
                setBusinessSelected(true)
                setClientName(data.nameClientBusiness)
                setClientAdress(data.streetClientBusiness + ", " + data.numberClientBusiness + " - " + data.bairroClientBusiness)
            }
        }

        var total = [];
        let sum = productValue;
        // for (var i = 0, l = productValue.length; i < l; i++) {
        //     product.map(key => {
        //         if (key['id'] == productValue[i]) {
        //             let valueFormat = key['value'].replace('R$', '').replace('.', '').replace(',', '.');
        //             total.push(parseFloat(valueFormat))
        //         }
        //     })
        // }

        // for (let i = 0; i < total.length; i++) {
        //     if (i == 0) {
        //         let totalFormat = total[i];
        //         console.log(totalFormat)
        //         sum += parseFloat(totalFormat) * parseFloat(inputProduct1)
        //     }
        //     if (i == 1) {
        //         console.log(totalFormat2)
        //         let totalFormat2 = total[i];

        //         sum += parseFloat(totalFormat2) * parseFloat(inputProduct2)
        //     }
        //     if (i == 2) {
        //         console.log(totalFormat3)
        //         let totalFormat3 = total[i];

        //         sum += parseFloat(totalFormat3) * parseFloat(inputProduct3)
        //     }
        // }

        if (typeCliente == 'fisico') {
            setClientValue(idSearch);
            setTypeClient('fisico')
            setPhysicSelected(true)
            setBusinessSelected(false)
        } else if (typeCliente == 'juridico') {
            setClientValue(idSearch);
            setTypeClient('juridico')
            setPhysicSelected(false)
            setBusinessSelected(true)
        }

        if (desconto > 0) {
            sum = sum - desconto
        }

        var paymetValueInput = document.getElementById('paymetValueInput');
        setTotalValue(sum);
        setPName(resultSelect);
        setPValue(productValue);
        setPaymentValue(data.payment);
        setPaymentValue2(data.payment2);
        setPaymentValue3(data.payment3);
        setPaymentValueTotal(paymetValueInput.value);
        setPaymentValueTotal2(data.paymentValue2);
        setPaymentValueTotal3(data.paymentValue3);
        setChannelValue(data.channel);
        setModalConfirmOrder(true)
        if (driver.length > 1) {
            setDriverSelected(data.driverSelect)
        } else {
            setDriverSelected(driver[0].id)
        }

        setDescont(parseFloat(data.inputDesconto.replace('R$', '').replace('.', '').replace(',', '.')))

        if (data.inputDesconto == '') {
            setDescont(0)
        }
        setObsPayment(data.inputObsPayment)

        let trocoInt = ''
        var trocoSujo = data.obsTroco.split(',')
        if (trocoSujo.length > 1) {
            trocoInt = trocoSujo[0] + "." + trocoSujo[1]
        } else {
            trocoInt = trocoSujo;
        }
        var troco = Number(trocoInt) - Number(sum);
        let trocoValue = '';
        if (data.obsTroco !== "") {
            trocoValue = data.obs + " " + "Levar de troco R$ " + troco;
        } else {
            trocoValue = data.obs;
        }

        setObsValue(trocoValue)

        if (newClient == true) {
            if (newType == 'fisico') {
                setTypeClient('fisico')
                api.post("/CadastrarClienteFisico", {
                    name: newName1,
                    number: newNumber1,
                    street: newAddress1,
                    city: "Não Informado",
                    state: "Não Informado",
                    neighborhood: newBairro1,
                    phone: newPhone,
                    cep: data.cepClientPhysic,
                    db: currentUser.db
                }).then((response) => {
                    setNewId(response.data.id)
                });
            } else if (newType == 'juridico') {
                setTypeClient('juridico')
                api.post("/CadastrarClienteJuridico", {
                    name: newName1,
                    number: newNumber1,
                    street: newAddress1,
                    city: "Não Informado",
                    state: "Não Informado",
                    neighborhood: newBairro1,
                    commercial_type: commercialType,
                    phone: newPhone,
                    cep: data.cepClientBusiness,
                    db: currentUser.db
                }).then((response) => {
                    setNewId(response.data.id)
                });
            }
        }
    }

    const productHandler = e => {
        var divProducSelected1 = document.getElementById('productSelect1')
        var divProducSelected2 = document.getElementById('productSelect2')
        var divProducSelected3 = document.getElementById('productSelect3')
        var labelProducSelected1 = document.getElementById('labelProductSelect1')
        var labelProducSelected2 = document.getElementById('labelProductSelect2')
        var labelProducSelected3 = document.getElementById('labelProductSelect3')
        var paymetValueInput = document.getElementById('paymetValueInput');
        var inputProduct1 = document.getElementById("inputProductSelect1").value
        var inputProduct2 = document.getElementById("inputProductSelect2").value
        var inputProduct3 = document.getElementById("inputProductSelect3").value
        var options = e;
        var value = [];
        var name = [];

        if (inputProduct1 == '1') {
            if (options.length !== 'undefined' && options.length !== undefined) {
                for (var i = 0, l = options.length; i < l; i++) {
                    if (typeof options[i].value == "number") {
                        value.push(options[i].value);
                    }
                    if (i == 0) {
                        divProducSelected1.style.display = "block"
                        divProducSelected2.style.display = "none"
                        divProducSelected3.style.display = "none"
                        if (typeof options[i].value == "number") {
                            name.push(options[i].label)
                            labelProducSelected1.textContent = options[i].label + ":"
                        } else {
                            var nameProduct1 = labelProducSelected1.textContent.split(':')
                            name.push(nameProduct1[0])
                        }

                    }
                    if (i == 1) {
                        divProducSelected2.style.display = "block"
                        divProducSelected3.style.display = "none"
                        if (typeof options[i].value == "number") {
                            name.push(options[i].label)
                            labelProducSelected2.textContent = options[i].label + ":"
                        } else {
                            var nameProduct2 = labelProducSelected2.textContent.split(':')
                            name.push(nameProduct2[0])
                        }
                    }
                    if (i == 2) {
                        divProducSelected3.style.display = "block"
                        if (typeof options[i].value == "number") {
                            name.push(options[i].label)
                            labelProducSelected3.textContent = options[i].label + ":"
                        } else {
                            var nameProduct3 = labelProducSelected3.textContent.split(':')
                            name.push(nameProduct3[0])
                        }
                    }
                }
            } else {
                var nameProduct1 = labelProducSelected1.textContent.split(':')
                name.push(nameProduct1[0])
                if (labelProducSelected2.textContent !== '') {
                    var nameProduct2 = labelProducSelected2.textContent.split(':')
                    name.push(nameProduct2[0])
                }
                if (labelProducSelected3.textContent !== '') {
                    var nameProduct3 = labelProducSelected3.textContent.split(':')
                    name.push(nameProduct3[0])
                }
            }

        } else {
            var nameProduct1 = labelProducSelected1.textContent.split(':')
            name.push(nameProduct1[0])
            if (labelProducSelected2.textContent !== '') {
                var nameProduct2 = labelProducSelected2.textContent.split(':')
                name.push(nameProduct2[0])
            }
            if (labelProducSelected3.textContent !== '') {
                var nameProduct3 = labelProducSelected3.textContent.split(':')
                name.push(nameProduct3[0])
            }
        }

        if (options.length == 0) {
            divProducSelected1.style.display = "none"
        }

        var total = [];
        let sumTotal = 0;
        var spanTotal = document.getElementById('productTotal');

        for (var i = 0, l = name.length; i < l; i++) {
            product.map(key => {
                if (key['name'] == name[i]) {
                    let valueFormat = key['value'].replace('R$', '').replace('.', '').replace(',', '.');

                    if (i == 0) {
                        let multTotal = parseFloat(inputProduct1) * parseFloat(valueFormat)

                        total.push(multTotal)
                    }
                    if (i == 1) {
                        let multTotal = parseFloat(inputProduct2) * parseFloat(valueFormat)
                        total.push(multTotal)
                    }
                    if (i == 2) {
                        let multTotal = parseFloat(inputProduct3) * parseFloat(valueFormat)
                        total.push(multTotal)
                    }
                }
            })
        }
        for (let i = 0; i < total.length; i++) {
            sumTotal += parseFloat(total[i])
        }
        productValue = sumTotal;
        productName = name

        spanTotal.textContent = sumTotal
        paymetValueInput.value = sumTotal
        valueOriginal = sumTotal
    }

    const paymentHandler = e => {
        var divPayment = document.getElementById("obsPayment");
        var inputPayment = document.getElementById("inputPayment");
        var payment2Container = document.getElementById('payment2Container')
        var inputPayment2 = document.getElementById('inputPayment2')
        var payment3Container = document.getElementById('payment3Container')
        var inputPayment3 = document.getElementById('inputPayment3')
        var paymetValueInput2 = document.getElementById('paymetValueInput2');
        var paymetValueInput3 = document.getElementById('paymetValueInput3');
        var paymentValueContainer = document.getElementById('paymentValueContainer');
        let paymentSelected = false

        payment.map(key => {
            if (key['id'] == e.target.value) {
                if (key['name'] == 'Dinheiro') {
                    paymentSelected = true;
                }
            }
        })

        if (e.target.value !== '') {
            payment2Container.style.display = "flex"
            payment2Container.style.justifyContent = 'space-between'
            paymentValueContainer.style.marginRight = '35px'
        } else {
            payment2Container.style.display = "none";
            inputPayment2.value = '';
            payment3Container.style.display = "none";
            inputPayment3.value = '';
            paymetValueInput2.disabled = true
            paymetValueInput3.disabled = true
            paymetValueInput2.placeholder = '0'
            paymetValueInput3.placeholder = '0'
            paymentValueContainer.style.marginRight = ''
        }

        if (paymentSelected) {
            divPayment.setAttribute("style", 'display: block')
            // inputPayment.setAttribute('required', 'required')
        } else {
            divPayment.setAttribute("style", 'display: none')
            // inputPayment.removeAttribute('required', 'required')
        }
    }

    const paymentHandler2 = e => {
        var payment3Container = document.getElementById('payment3Container')
        var inputPayment3 = document.getElementById('inputPayment3')
        var paymetValueInput2 = document.getElementById('paymetValueInput2');


        if (e.target.value !== '') {
            payment3Container.style.display = "flex"
            payment3Container.style.justifyContent = 'space-between'
            paymetValueInput2.disabled = false
            paymetValueInput2.placeholder = 'Digite o segundo valor'
        } else {
            payment3Container.style.display = "none";
            inputPayment3.value = '';
            paymetValueInput2.disabled = true
            paymetValueInput2.placeholder = '0'

        }
    }

    const handleValueTotal = e => {
        var paymetValueInput = document.getElementById('paymetValueInput');
        var paymetValueInput2 = document.getElementById('paymetValueInput2');
        var paymetValueInput3 = document.getElementById('paymetValueInput3');
        paymetValueInput.value = valueOriginal

        if (paymetValueInput2.value !== '') {
            paymetValueInput.value = parseFloat(paymetValueInput.value) - parseFloat(paymetValueInput2.value)
        }

        if (paymetValueInput3.value !== '') {
            paymetValueInput.value = parseFloat(paymetValueInput.value) - parseFloat(paymetValueInput2.value) - parseFloat(paymetValueInput3.value)
        }
    }

    const paymentHandler3 = e => {
        var paymetValueInput3 = document.getElementById('paymetValueInput3');

        if (e.target.value !== '') {
            paymetValueInput3.disabled = false
            paymetValueInput3.placeholder = 'Digite o terceiro valor'
        } else {
            paymetValueInput3.disabled = true
            paymetValueInput3.placeholder = '0'
        }

    }

    const clientTypeHandler = e => {
        var type = e.target.value;
        var divPhysic = document.getElementById('selectClientPhysic');
        var divBusiness = document.getElementById('selectClientBusiness');
        var ulClientPhysic = document.getElementById('ulClientPhysic');
        var ulClientBusiness = document.getElementById('ulClientBusiness');

        if (type == 'fisico') {
            typeCliente = 'fisico'
            divPhysic.style.display = "block"
            divBusiness.style.display = "none"
            ulClientPhysic.innerHTML = ''
            ulClientBusiness.innerHTML = ''
        } else if (type == 'juridico') {
            typeCliente = "juridico";
            divPhysic.style.display = "none"
            divBusiness.style.display = "block"
            ulClientPhysic.innerHTML = ''
            ulClientBusiness.innerHTML = ''
        }

    }

    const descontoHandler = e => {
        var type = e.target.value;
        var divDesconto = document.getElementById('descontoContainer');

        if (type == 'sim') {
            divDesconto.style.display = "block"
        } else {
            divDesconto.style.display = "none"
        }

    }

    const trocoHandler = e => {
        var troco = e.target.value;
        var divTroco = document.getElementById('divTroco');
        if (troco == 'sim') {
            divTroco.style.display = 'block';
        } else {
            divTroco.style.display = 'none';
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        let phonePhysic = document.getElementById('inputSearchPhysic').value;
        let phoneBusiness = document.getElementById('inputSearchBusiness').value
        var ulClientPhysic = document.getElementById('ulClientPhysic');
        var ulClientBusiness = document.getElementById('ulClientBusiness');
        var inputNamePhysic = document.getElementById('nameClientPhysic');
        var inputStreetPhysic = document.getElementById('streetClientPhysic');
        var inputNumPhysic = document.getElementById('numClientPhysic');
        var inputBairroPhysic = document.getElementById('bairroClientPhysic');
        var inputNameBusiness = document.getElementById('nameClientBusiness');
        var inputStreetBusiness = document.getElementById('streetClientBusiness');
        var inputNumBusiness = document.getElementById('numberClientBusiness');
        var inputBairroBusiness = document.getElementById('bairroClientBusiness');
        var imputLastOrderPhysic = document.getElementById('lastOrderedPhysic');
        var imputLastPaymentOrderPhysic = document.getElementById('lastPaymentOrderedPhysic');
        var imputLastProductOrderPhysic = document.getElementById('lastProductOrderedPhysic');
        var imputLastObsOrderPhysic = document.getElementById('lastObsOrderedPhysic');
        var imputLastOrderBusiness = document.getElementById('lastOrderedBusiness');
        var imputLastPaymentOrderBusiness = document.getElementById('lastPaymentOrderedBusiness');
        var imputLastProductOrderBusiness = document.getElementById('lastProductOrderedBusiness');
        var imputLastObsOrderBusiness = document.getElementById('lastObsOrderedBusiness');
        var inputTypeBusiness = document.getElementById('typeClientBusiness');
        var containerClientBusiness = document.getElementById('containerClientBusiness');
        var containerClientPhysic = document.getElementById('containerClientPhysic');
        var radioClientPhysic = document.getElementById('fisico');
        var radioClientBusiness = document.getElementById('juridico');
        var inputTypeCliient = document.getElementById('typeClient');

        let text = ''
        var lastOrderProduct = []
        var lastObsProduct = []
        let time = 1000;

        if (phonePhysic !== '') {
            text = phonePhysic.replace("-", "").replace("(", "").replace(")", "");
        } else {
            text = phoneBusiness.replace("-", "").replace("(", "").replace(")", "");
        }

        api.post("/PegarPeloNumero", {
            phone: text,
            db: currentUser.db
        }).then(response => {
            idSearch = response.data.id
            typeCliente = response.data.type
        })
        setTimeout(() => {
            console.log(idSearch)
            if (idSearch !== 0) {
                if (typeCliente == 'fisico') {
                    if (lastOrderProduct.length > 0) {
                        lastOrderProduct.pop()
                    }
                    if (lastObsProduct.length > 0) {
                        lastObsProduct.pop()
                    }
                    clientPhysicDB.map(key => {
                        if (key['id'] == idSearch) {

                            ulClientPhysic.innerHTML = "Cliente localizado";
                            inputNamePhysic.value = key['name'];
                            inputStreetPhysic.value = key['street'];
                            inputNumPhysic.value = key['number'];
                            inputBairroPhysic.value = key['neighborhood'];
                            imputLastOrderPhysic.value = key['date_last_order']
                            imputLastPaymentOrderPhysic.value = "R$ " + key['value_last_order']
                        }
                    })
                    ordered.map((rank, i, row) => {
                        if (rank['client_id'] == idSearch) {
                            lastOrderProduct.push(rank['products'])
                            lastObsProduct.push(rank['obs'])
                        }
                    })

                    lastOrderProduct.map((rank, i, arr) => {
                        if (arr.length - 1 === i) {
                            imputLastProductOrderPhysic.value = rank
                        }
                    })

                    lastObsProduct.map((rank, i, arr) => {
                        if (arr.length - 1 === i) {
                            imputLastObsOrderPhysic.value = rank
                        }
                    })
                    typeCliente = 'fisico'
                    inputTypeCliient.value = 'fisico'
                    containerClientPhysic.style.display = 'block'
                    time = 0
                } else if (typeCliente == 'juridico') {
                    if (lastOrderProduct.length > 0) {
                        lastOrderProduct.pop()
                    }
                    if (lastObsProduct.length > 0) {
                        lastObsProduct.pop()
                    }

                    clientBusinessDB.map(key => {
                        if (key['id'] == idSearch) {
                            inputTypeBusiness.value = key['commercial_type']
                            ulClientBusiness.innerHTML = "Cliente localizado";
                            inputNameBusiness.value = key['name'];
                            inputStreetBusiness.value = key['street'];
                            inputNumBusiness.value = key['number'];
                            inputBairroBusiness.value = key['neighborhood'];
                            imputLastOrderBusiness.value = key['date_last_order']
                            imputLastPaymentOrderBusiness.value = "R$ " + key['value_last_order']
                        }
                    })

                    ordered.map((rank, i, row) => {
                        if (rank['client_id'] == idSearch) {
                            lastOrderProduct.push(rank['products'])
                            lastObsProduct.push(rank['obs'])
                        }
                    })

                    lastOrderProduct.map((rank, i, arr) => {
                        if (arr.length - 1 === i) {
                            imputLastProductOrderBusiness.value = rank
                        }
                    })

                    lastObsProduct.map((rank, i, arr) => {
                        if (arr.length - 1 === i) {
                            imputLastObsOrderBusiness.value = rank
                        }
                    })
                    typeCliente = 'juridico'
                    inputTypeCliient.value = 'juridico'
                    containerClientBusiness.style.display = 'block'
                    // setTypeClient('juridico')
                    time = 0
                }

            } else {
                if (text.length < 10) {
                    ulClientPhysic.innerHTML = 'Informe o número com DDD'
                    ulClientBusiness.innerHTML = 'Informe o número com DDD'
                } else {
                    ulClientPhysic.innerHTML = 'Cliente não localizado'
                    ulClientBusiness.innerHTML = 'Cliente não localizado'

                    if (radioClientPhysic.checked) {
                        containerClientPhysic.style.display = 'block'
                    } else if (radioClientBusiness.checked) {
                        containerClientBusiness.style.display = 'block'
                    }
                }

                inputNamePhysic.value = "";
                inputStreetPhysic.value = "";
                inputNumPhysic.value = "";
                inputBairroPhysic.value = "";
                inputNameBusiness.value = "";
                inputStreetBusiness.value = "";
                inputNumBusiness.value = "";
                inputBairroBusiness.value = "";
                time = 0
            }
        }, time)


    }

    const handleSearchTeste = e => {
        e.preventDefault();
        var teste = []
        ordered.map(key => {
            if (key['client_id'] == idSearch) {
                teste.push(key)
            }
        })
    }

    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const handleHiddenPayment = e => {
        var payment2Container = document.getElementById('payment2Container')
        var inputPayment2 = document.getElementById('inputPayment2')
        var paymetValueInput2 = document.getElementById('paymetValueInput2');
        var payment3Container = document.getElementById('payment3Container')
        var inputPayment3 = document.getElementById('inputPayment3')
        var paymetValueInput3 = document.getElementById('paymetValueInput3');
        var paymetValueInput = document.getElementById('paymetValueInput');
        var paymentValueContainer = document.getElementById('paymentValueContainer');
        paymetValueInput.value = valueOriginal

        if (e == '2') {
            payment2Container.style.display = "none";
            inputPayment2.value = '';
            payment3Container.style.display = "none";
            inputPayment3.value = '';
            paymetValueInput2.disabled = true
            paymetValueInput3.disabled = true
            paymetValueInput2.placeholder = '0'
            paymetValueInput3.placeholder = '0'
            paymentValueContainer.style.marginRight = ''
        }

        if (e == '3') {
            payment3Container.style.display = "none";
            inputPayment3.value = '';
            paymetValueInput3.disabled = true
            paymetValueInput3.placeholder = '0'
            paymetValueInput.value = parseFloat(paymetValueInput.value) - parseFloat(paymetValueInput2.value)
        }
    }

    const checkCEPPhysic = (e) => {
        const cep = e.target.value.replace(/\D/g, '');
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(res => res.json()).then(data => {
                let estadoApi = '';
                if (data.uf == "AC") {
                    estadoApi = "Acre";
                } else if (data.uf == "AL") {
                    estadoApi = "Alagoas";
                } else if (data.uf == "AP") {
                    estadoApi = "Amapá";
                } else if (data.uf == "AM") {
                    estadoApi = "Amazonas";
                } else if (data.uf == "BA") {
                    estadoApi = "Bahia";
                } else if (data.uf == "CE") {
                    estadoApi = "Ceará";
                } else if (data.uf == "DF") {
                    estadoApi = "Destrito Federal";
                } else if (data.uf == "ES") {
                    estadoApi = "Espírito Santo";
                } else if (data.uf == "GO") {
                    estadoApi = "Goiás";
                } else if (data.uf == "MA") {
                    estadoApi = "Maranhão";
                } else if (data.uf == "MT") {
                    estadoApi = "Mato Grosso";
                } else if (data.uf == "MS") {
                    estadoApi = "Mato Grosso do Sul";
                } else if (data.uf == "MG") {
                    estadoApi = "Minas Gerais";
                } else if (data.uf == "PA") {
                    estadoApi = "Pará";
                } else if (data.uf == "PB") {
                    estadoApi = "Paraíba";
                } else if (data.uf == "PR") {
                    estadoApi = "Paraná";
                } else if (data.uf == "PE") {
                    estadoApi = "Pernambuco";
                } else if (data.uf == "PI") {
                    estadoApi = "Piauí";
                } else if (data.uf == "RJ") {
                    estadoApi = "Rio de Janeiro";
                } else if (data.uf == "RN") {
                    estadoApi = "Rio Grande do Norte";
                } else if (data.uf == "RS") {
                    estadoApi = "Rio Grande do Sul";
                } else if (data.uf == "RO") {
                    estadoApi = "Rondônia";
                } else if (data.uf == "RR") {
                    estadoApi = "Roraima";
                } else if (data.uf == "SC") {
                    estadoApi = "Santa Catarina";
                } else if (data.uf == "SP") {
                    estadoApi = "São Paulo";
                } else if (data.uf == "SE") {
                    estadoApi = "Sergipe";
                } else if (data.uf == "TO") {
                    estadoApi = "Tocantins";
                }

                document.getElementsByName("streetClientPhysic")[0].value = data.logradouro
                document.getElementsByName("bairroClientPhysic")[0].value = data.bairro
            })
    }

    const checkCEPBusiness = (e) => {
        const cep = e.target.value.replace(/\D/g, '');
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(res => res.json()).then(data => {
                let estadoApi = '';
                if (data.uf == "AC") {
                    estadoApi = "Acre";
                } else if (data.uf == "AL") {
                    estadoApi = "Alagoas";
                } else if (data.uf == "AP") {
                    estadoApi = "Amapá";
                } else if (data.uf == "AM") {
                    estadoApi = "Amazonas";
                } else if (data.uf == "BA") {
                    estadoApi = "Bahia";
                } else if (data.uf == "CE") {
                    estadoApi = "Ceará";
                } else if (data.uf == "DF") {
                    estadoApi = "Destrito Federal";
                } else if (data.uf == "ES") {
                    estadoApi = "Espírito Santo";
                } else if (data.uf == "GO") {
                    estadoApi = "Goiás";
                } else if (data.uf == "MA") {
                    estadoApi = "Maranhão";
                } else if (data.uf == "MT") {
                    estadoApi = "Mato Grosso";
                } else if (data.uf == "MS") {
                    estadoApi = "Mato Grosso do Sul";
                } else if (data.uf == "MG") {
                    estadoApi = "Minas Gerais";
                } else if (data.uf == "PA") {
                    estadoApi = "Pará";
                } else if (data.uf == "PB") {
                    estadoApi = "Paraíba";
                } else if (data.uf == "PR") {
                    estadoApi = "Paraná";
                } else if (data.uf == "PE") {
                    estadoApi = "Pernambuco";
                } else if (data.uf == "PI") {
                    estadoApi = "Piauí";
                } else if (data.uf == "RJ") {
                    estadoApi = "Rio de Janeiro";
                } else if (data.uf == "RN") {
                    estadoApi = "Rio Grande do Norte";
                } else if (data.uf == "RS") {
                    estadoApi = "Rio Grande do Sul";
                } else if (data.uf == "RO") {
                    estadoApi = "Rondônia";
                } else if (data.uf == "RR") {
                    estadoApi = "Roraima";
                } else if (data.uf == "SC") {
                    estadoApi = "Santa Catarina";
                } else if (data.uf == "SP") {
                    estadoApi = "São Paulo";
                } else if (data.uf == "SE") {
                    estadoApi = "Sergipe";
                } else if (data.uf == "TO") {
                    estadoApi = "Tocantins";
                }

                document.getElementsByName("streetClientBusiness")[0].value = data.logradouro
                document.getElementsByName("bairroClientBusiness")[0].value = data.bairro
            })
    }

    function ModalOrdered(props) {

        return (
            <Modal
                show={orderedModal}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleModalClose}
            >
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Novo pedido
                    </Modal.Title>
                    <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
                </Modal.Header>
                <Modal.Body className="cancelBody">
                    <form className="input" onSubmit={submitModalConfirm}>
                        <div className="divOrderClientAndProduct">
                            <div className="divOrderLeft">
                                <label className="labelForm">Produtos</label>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={optionsProducts}
                                    name='product'
                                    id='products'
                                    onChange={productHandler}
                                    required
                                />
                                <div className="productSelect1" id="productSelect1" style={{ display: 'none' }}>
                                    <div className="divProduct">
                                        <label className="labelProductSelect1" id="labelProductSelect1"></label>
                                        <input
                                            type="number"
                                            name="productSelect1"
                                            id="inputProductSelect1"
                                            className="inputCadastro"
                                            onChange={productHandler}
                                            defaultValue='1'
                                            min='1'
                                        />
                                    </div>
                                </div>
                                <div className="productSelect2" id="productSelect2" style={{ display: 'none' }}>
                                    <div className="divProduct">
                                        <label className="labelProductSelect2" id="labelProductSelect2"></label>
                                        <input
                                            type="number"
                                            name="productSelect2"
                                            id="inputProductSelect2"
                                            className="inputCadastro"
                                            onChange={productHandler}
                                            defaultValue='1'
                                            min='1'
                                        />
                                    </div>
                                </div>
                                <div className="productSelect3" id="productSelect3" style={{ display: 'none' }}>
                                    <div className="divProduct">
                                        <label className="labelProductSelect3" id="labelProductSelect3"></label>
                                        <input
                                            type="number"
                                            name="productSelect3"
                                            id="inputProductSelect3"
                                            className="inputCadastro"
                                            onChange={productHandler}
                                            defaultValue='1'
                                            min='1'
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ width: '45%' }}>
                                        <label className="labelForm">Forma de pagamento</label>
                                        <Form.Select
                                            name='payment'
                                            id='inputPayment'
                                            onChange={paymentHandler}
                                            required
                                        >
                                            <option value=''>Selecione uma forma de pagamento</option>
                                            {payment.map((key, value) => {
                                                return <option value={key['id']}>{key['name']}</option>
                                            })}
                                        </Form.Select>
                                    </div>
                                    <div id="paymentValueContainer" style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                                        <label className="labelForm">Valor</label>
                                        <input
                                            className="inputCadastro"
                                            type="text"
                                            name="paymentValue"
                                            step='0,01'
                                            id='paymetValueInput'
                                            placeholder="Valor total da compra"
                                            disabled
                                        />
                                    </div>
                                </div>


                                <div className='payment2Container' id='payment2Container' style={{ display: 'none' }}>
                                    <div style={{ width: '45%' }}>
                                        <label className="labelForm">Segunda forma de pagamento</label>
                                        <Form.Select
                                            name='payment2'
                                            id='inputPayment2'
                                            onChange={paymentHandler2}
                                        >
                                            <option value=''>Selecione uma forma de pagamento</option>
                                            {payment.map((key, value) => {
                                                return <option value={key['id']}>{key['name']}</option>
                                            })}
                                        </Form.Select>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                                        <label className="labelForm">Segundo valor</label>
                                        <input
                                            className="inputCadastro"
                                            id='paymetValueInput2'
                                            name="paymentValue2"
                                            type="number"
                                            step='0,01'
                                            placeholder="0"
                                            onBlur={handleValueTotal}
                                            disabled
                                        />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                                        <span style={{ cursor: 'pointer' }} onClick={(e) => handleHiddenPayment('2')}>X</span>
                                    </div>
                                </div>

                                <div className='payment3Container' id='payment3Container' style={{ display: 'none' }}>
                                    <div style={{ width: '45%' }}>
                                        <label className="labelForm">Terceira forma de pagamento</label>
                                        <Form.Select
                                            name='payment3'
                                            id='inputPayment3'
                                            onChange={paymentHandler3}
                                        >
                                            <option value=''>Selecione uma forma de pagamento</option>
                                            {payment.map((key, value) => {
                                                return <option value={key['id']}>{key['name']}</option>
                                            })}
                                        </Form.Select>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                                        <label className="labelForm">Terceiro valor</label>
                                        <input
                                            className="inputCadastro"
                                            id='paymetValueInput3'
                                            name="paymentValue3"
                                            type="number"
                                            step='0,01'
                                            onBlur={handleValueTotal}
                                            placeholder="0"
                                            disabled
                                        />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                                        <span style={{ cursor: 'pointer' }} onClick={(e) => handleHiddenPayment('3')}>X</span>
                                    </div>
                                </div>

                                <div className="obsPayment" id='obsPayment' style={{ display: 'none' }}>
                                    <div>
                                        <label className="labelForm">Valor total: R$ <span className="productTotal" id="productTotal"></span></label>
                                    </div>
                                    <div>
                                        <label className="labelForm">Vai precisar de troco?</label>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                            className="radioClient"
                                            required
                                        >
                                            <FormControlLabel value="sim" control={<Radio onClick={trocoHandler} />} name="radioTroco" label="Sim" />
                                            <FormControlLabel value="nao" control={<Radio onClick={trocoHandler} />} name="radioTroco" label="Não" />
                                        </RadioGroup>
                                    </div>
                                    <div className="divTroco" id="divTroco" style={{ display: 'none' }}>
                                        <label className="labelForm" >Troco para</label>
                                        <Form.Control
                                            placeholder="Troco para"
                                            aria-label="troco"
                                            name="obsTroco"
                                        />
                                    </div>
                                </div>
                                <label className="labelForm">Desconto?</label>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    className="radioClient"
                                    required
                                >
                                    <FormControlLabel value="sim" control={<Radio onClick={descontoHandler} />} name="radioDesconto" label="Sim" />
                                    <FormControlLabel value="nao" control={<Radio onClick={descontoHandler} />} name="radioDesconto" label="Não" />
                                </RadioGroup>
                                <div className="descontoContainer" id="descontoContainer" style={{ display: 'none' }}>
                                    <label className="labelForm">Valor do desconto</label>
                                    <CurrencyInput
                                        id="categoryProduct"
                                        name="inputDesconto"
                                        className="inputCadastro"
                                        onChangeValue={(event, originalValue, maskedValue) => {
                                            // console.log(event, originalValue, maskedValue);
                                        }}
                                    />
                                </div>
                                <label className="labelForm">Canal de venda</label>
                                <Form.Select
                                    name='channel'
                                    required
                                >
                                    <option value=''>Selecione um canal de venda</option>
                                    {saleChanell.map((key, value) => {
                                        return <option value={key['id']}>{key['name']}</option>
                                    })}
                                </Form.Select>


                                {driver.length > 1 ? (
                                    <>
                                        <label className="labelForm">Selecionar Entregador</label>
                                        <Form.Select
                                            name='driverSelect'
                                            id='driverSelect'
                                            required
                                        >
                                            <option value=''>Selecione um entregador</option>
                                            {driver.map((key, value) => {
                                                return <option value={key['id']}>{key['name']}</option>
                                            })}
                                        </Form.Select>
                                    </>
                                ) : ""}

                                <label className="labelForm">Observação</label>
                                <Form.Control
                                    className="textAreaForm"
                                    as="textarea"
                                    style={{ height: '100px' }}
                                    name='obs'
                                />
                            </div>
                            <div className="divOrderRight">
                                <label className="labelForm">Tipo de cliente</label>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="fisico"
                                    name="radio-buttons-group"
                                    className="radioClient"
                                    required
                                >
                                    <FormControlLabel value="fisico" control={<Radio onClick={clientTypeHandler} id="fisico" />} name="radioType" label="Cliente Físico" />
                                    <FormControlLabel value="juridico" control={<Radio onClick={clientTypeHandler} id="juridico" />} name="radioType" label="Cliente Jurídico" />
                                </RadioGroup>
                                <div className="selectClientPhysic" id="selectClientPhysic">
                                    <label className="labelForm">Cliente Físico</label>
                                    <div className="divSearch">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {/* <InputMask required className='inputSearch' id="inputSearchPhysic" mask="(99) 99999-9999" name="inputSearchPhysic" /> */}
                                            <input type="search" className="inputSearch" id="inputSearchPhysic" name="inputSearchPhysic" />
                                            <small>Informe o número com DDD. Exemplo 11999999999</small>
                                        </div>
                                        <button onClick={e => handleSearch(e)} className="buttonSearch" style={{ marginBottom: 20 }}><AiOutlineSearch /></button>
                                    </div>
                                    <div className="containerClientPhysic" id='containerClientPhysic' style={{ display: 'none' }}>
                                        <span id='ulClientPhysic'></span>
                                        <div className="newClientPhysic" id="newClientPhysic" >
                                            <label className="labelForm" >Nome do cliente</label>
                                            <input
                                                name="nameClientPhysic"
                                                className="inputCadastro"
                                                id="nameClientPhysic"
                                            />
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                                                    <label className="labelForm" >CEP</label>
                                                    <input
                                                        name="cepClientPhysic"
                                                        className="inputCadastro"
                                                        id="cepClientPhysic"
                                                        onBlur={checkCEPPhysic}
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                                                    <label className="labelForm">Bairro</label>
                                                    <input
                                                        name="bairroClientPhysic"
                                                        className="inputCadastro"
                                                        id="bairroClientPhysic"
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                                                    <label className="labelForm">Endereço</label>
                                                    <input
                                                        name="streetClientPhysic"
                                                        className="inputCadastro"
                                                        id="streetClientPhysic"
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                    <label className="labelForm">Número</label>
                                                    <input
                                                        name="numClientPhysic"
                                                        className="inputCadastro"
                                                        id="numClientPhysic"
                                                    />
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px', width: "100%" }}>
                                                    <label className="labelForm" style={{ color: 'red' }}>Última compra</label>
                                                    <input
                                                        name="lastOrderedPhysic"
                                                        className="inputCadastro"
                                                        id="lastOrderedPhysic"
                                                        disabled
                                                    />
                                                </div>
                                                <div style={{ marginLeft: '5px', width: "100%" }}>
                                                    <label className="labelForm" style={{ color: 'red' }}>Último Pagamento</label>
                                                    <input
                                                        name="lastPaymentOrderedPhysic"
                                                        className="inputCadastro"
                                                        id="lastPaymentOrderedPhysic"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px', width: "100%" }}>
                                                    <label className="labelForm" style={{ color: 'red' }}>Último Produto</label>
                                                    <input
                                                        name="lastProductOrderedPhysic"
                                                        className="inputCadastro"
                                                        id="lastProductOrderedPhysic"
                                                        disabled
                                                    />
                                                </div>
                                                <div style={{ marginLeft: '5px', width: "100%" }}>
                                                    <label className="labelForm" style={{ color: 'red' }}>Última Observação do pedido</label>
                                                    <input
                                                        name="lastObsOrderedPhysic"
                                                        className="inputCadastro"
                                                        id="lastObsOrderedPhysic"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>


                                <div className="selectClientBusiness" id="selectClientBusiness" style={{ display: 'none' }}>
                                    <label className="labelForm">Cliente Jurídico</label>
                                    <div className="divSearch">
                                        <input type="search" className="inputSearch" id="inputSearchBusiness" name="inputSearchBusiness" />
                                        <button onClick={e => handleSearch(e)} className="buttonSearch"><AiOutlineSearch /></button>
                                    </div>
                                    <div className="containerClientBusiness" id='containerClientBusiness' style={{ display: 'none' }}>
                                        <span id='ulClientBusiness'></span>
                                        <div className="newClientBusiness" id="newClientBusiness" >
                                            <label className="labelForm">Nome do cliente</label>
                                            <input
                                                name="nameClientBusiness"
                                                className="inputCadastro"
                                                id="nameClientBusiness"
                                            />
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                                                    <label className="labelForm" >CEP</label>
                                                    <input
                                                        name="cepClientBusiness"
                                                        className="inputCadastro"
                                                        id="cepClientBusiness"
                                                        onBlur={checkCEPBusiness}
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                                                    <label className="labelForm">Bairro</label>
                                                    <input
                                                        name="bairroClientBusiness"
                                                        className="inputCadastro"
                                                        id="bairroClientBusiness"
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                                                    <label className="labelForm">Endereço</label>
                                                    <input
                                                        name="streetClientBusiness"
                                                        className="inputCadastro"
                                                        id="streetClientBusiness"
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                    <label className="labelForm">Número</label>
                                                    <input
                                                        name="numberClientBusiness"
                                                        className="inputCadastro"
                                                        id="numberClientBusiness"
                                                    />
                                                </div>
                                            </div>

                                            <label className="labelForm">Tipo de comércio</label>
                                            <select
                                                id="typeClientBusiness"
                                                name="typeClientBusiness"
                                                className="inputCadastro"
                                            >
                                                <option value=''>Selecione um tipo de comércio</option>
                                                {typeBusiness.map((key, value) => {
                                                    return <option value={key['id']}>{key['name']}</option>
                                                })}
                                            </select>
                                            <label className="labelForm">Observação de pagamento</label>
                                            <input
                                                name="obsClientBusiness"
                                                className="inputCadastro"
                                                id="obsClientBusiness"
                                            />
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px', width: "100%" }}>
                                                    <label className="labelForm" style={{ color: 'red' }}>Última compra</label>
                                                    <input
                                                        name="lastOrderedBusiness"
                                                        className="inputCadastro"
                                                        id="lastOrderedBusiness"
                                                        disabled
                                                    />
                                                </div>
                                                <div style={{ marginLeft: '5px', width: "100%" }}>
                                                    <label className="labelForm" style={{ color: 'red' }}>Último Pagamento</label>
                                                    <input
                                                        name="lastPaymentOrderedBusiness"
                                                        className="inputCadastro"
                                                        id="lastPaymentOrderedBusiness"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px', width: "100%" }}>
                                                    <label className="labelForm" style={{ color: 'red' }}>Último Produto</label>
                                                    <input
                                                        name="lastProductOrderedBusiness"
                                                        className="inputCadastro"
                                                        id="lastProductOrderedBusiness"
                                                        disabled
                                                    />
                                                </div>
                                                <div style={{ marginLeft: '5px', width: "100%" }}>
                                                    <label className="labelForm" style={{ color: 'red' }}>Última Observação do pedido</label>
                                                    <input
                                                        name="lastObsOrderedBusiness"
                                                        className="inputCadastro"
                                                        id="lastObsOrderedBusiness"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <input type="hidden" id='typeClient' value='' />
                            </div>
                        </div>
                        <div className="buttonLogin">
                            <button className="buttonSecundary" >
                                Confirmar Pedido
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >
        )
    }

    function ModalConfirm(props) {
        let payment1 = '';
        let payment2 = '';
        let payment3 = '';
        if (typeof totalValue !== 'undefined') {
            var valueReal = totalValue.toFixed(2).replace('.', ',');
        }
        payment.map((k, v) => {
            if (k['id'] == paymentValue) {
                payment1 = k['name'];
            }
            if (k['id'] == paymentValue2) {
                payment2 = k['name']
            }
            if (k['id'] == paymentValue3) {
                payment3 = k['name'];
            }
        })
        return (
            <Modal
                show={modalConfirmOrder}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleModalClose}
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Confirme o pedido
                    </Modal.Title>
                    <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
                </Modal.Header>
                <Modal.Body className="cancelBody">
                    <h4>Pedido</h4>
                    <Table striped bordered hover >
                        <tbody>
                            <tr>
                                <td className="labelConfirm">Produtos</td>
                                <td>{pName}</td>

                            </tr>
                            {physicSelected && (
                                <>
                                    <tr >
                                        <td className="labelConfirm">Cliente</td>
                                        {newName == '' ? (clientPhysicDB.map((k, v) => {
                                            if (k['id'] == clientValue) {
                                                return <td>{k['name']}</td>
                                            }
                                        })) : (<td>{newName}</td>)}
                                    </tr>
                                    <tr >
                                        <td className="labelConfirm">Endereço</td>
                                        {newAddress == '' ? (
                                            clientPhysicDB.map((k, v) => {
                                                if (k['id'] == clientValue) {
                                                    return <td>{k['street'] + ", " + k['number']}</td>
                                                }
                                            })) : (<td>{newAddress + ", " + newNumber}</td>)}
                                    </tr>
                                </>
                            )}
                            {businessSelected && (
                                <>
                                    <tr>
                                        <td className="labelConfirm">Cliente</td>
                                        {newName == '' ? (clientBusinessDB.map((k, v) => {
                                            if (k['id'] == clientValue) {
                                                return <td>{k['name']}</td>
                                            }
                                        })) : (<td>{newName}</td>)}
                                    </tr>
                                    <tr >
                                        <td className="labelConfirm">Endereço</td>
                                        {newAddress == '' ? (clientBusinessDB.map((k, v) => {
                                            if (k['id'] == clientValue) {
                                                return <td>{k['street'] + ", " + k['number']}</td>
                                            }
                                        })) : (<td>{newAddress + ", " + newNumber}</td>)}
                                    </tr>
                                </>
                            )}
                            <tr>
                                <td className="labelConfirm">Entregador</td>
                                {driver.map((k, v) => {
                                    if (k['id'] == driverSelected) {
                                        return <td>{k['name']}</td>
                                    }
                                })}
                            </tr>
                            <tr>
                                <td className="labelConfirm">Forma de pagamento</td>
                                {paymentValue2 == '' ? (
                                    <td>{payment1}</td>
                                ) : paymentValue3 == '' ? (
                                    <td>{payment1} / {payment2}</td>
                                ) : (
                                    <td>{payment1} / {payment2} / {payment3}</td>
                                )}

                            </tr>
                            <tr>
                                <td className="labelConfirm">Canal de venda</td>
                                {saleChanell.map((k, v) => {
                                    if (k['id'] == channelValue) {
                                        return <td>{k['name']}</td>
                                    }
                                })}
                            </tr>
                            <tr>
                                <td className="labelConfirm">Valor de desconto</td>
                                <td>{"R$ " + descont}</td>
                            </tr>
                            <tr>
                                <td className="labelConfirm">Observação</td>
                                <td>{obsValue}</td>
                            </tr>
                            <tr>
                                <td className="labelConfirmTotal">Total</td>
                                <td >R$ {valueReal}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="buttonLogin">
                        <button className="buttonSecundary" onClick={submitForm}>
                            Confirmar Pedido
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    const style = {
        position: 'relative',
        top: '50%',
        left: '50%',
        width: 607,
        maxHeight: 660,
        borderRadius: "39px !important",
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
    };

    function ModalAvisoPlan(props) {
        return (
            <Modal2
                open={modalAvisoPlan}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='boxModalRaioX'>
                    <div style={{ position: 'absolute', zIndex: 9, right: '-17px', top: '-10px' }}>
                        <button style={{ position: 'relative', background: 'red', color: 'white', fontWeight: 900, borderRadius: 25, width: 35, height: 35 }} onClick={() => setModalAvisoPlan(false)}>X</button>
                    </div>
                    <img src={warning} className='imgModalValidatorPage' />
                    <div className='contectModalValidatorPage'>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 32, color: '#25368C', fontWeight: 900, lineHeight: 1 }}>
                            Essa função não está disponível no plano gratuito
                        </span>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 28, fontWeight: 600, color: 'black', width: '98%', lineHeight: 1, marginTop: 10 }}>
                            Escolha um plano e desbloqueie diversas funções da plataforma
                        </span>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end', flexDirection: 'row', marginTop: 10, width: '83%' }}>
                            <img src={trimestral} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/trimestral")} />
                            <img src={anual} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/anual")} />
                        </div>
                    </div>
                </Box>
            </Modal2>
        )
    }

    return (
        <>
            <Loading />
            <ModalAvisoPlan />
            <ModalOrdered />
            <ModalConfirm />
            <ReactWhatsapp number={phoneWhatsapp} message={messageWhatsapp} style={{ display: 'none' }} id='btnWhatsappOpen' />
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Pedidos</h5>
                    </div>
                    <div>
                        <button onClick={() => {
                            if (typePlan !== 'free') {
                                setOrderedModal(true)
                            } else {
                                setModalAvisoPlan(true)
                            }

                        }} className="me-2 button">Novo pedido</button>
                    </div>
                </div>
                <Card className="cardContainer tableOrdered" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableOrdered />
                </Card>
            </div >
        </>
    )
}

export default Ordered