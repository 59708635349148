import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableClosingDriver.css'
import { Modal } from "react-bootstrap";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'
import { useSelector, useDispatch } from 'react-redux';
import { CustomProvider, DateRangePicker } from 'rsuite';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import pt_BR from 'rsuite/locales/pt_BR'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'driver',
    numeric: false,
    disablePadding: false,
    label: 'Entregador',
  },
  {
    id: 'sale_channel',
    numeric: false,
    disablePadding: false,
    label: 'Canal de vendas',
  },
  {
    id: 'client',
    numeric: false,
    disablePadding: false,
    label: 'Cliente',
  },
  {
    id: 'products',
    numeric: false,
    disablePadding: false,
    label: 'Produtos',
  },
  {
    id: 'orderDate',
    numeric: false,
    disablePadding: false,
    label: 'Data do pedido',
  },
  {
    id: 'deliveryDate',
    numeric: false,
    disablePadding: false,
    label: 'Data de entrega',
  },
  {
    id: 'desconto',
    numeric: false,
    disablePadding: false,
    label: 'Desconto',
  },
  {
    id: 'obsPayment',
    numeric: false,
    disablePadding: false,
    label: 'Observação de Pagamento',
  },
  {
    id: 'vasilhame',
    numeric: false,
    disablePadding: false,
    label: 'Vasilhame',
  },
  {
    id: 'total',
    numeric: false,
    disablePadding: false,
    label: 'Total',
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className="colunmLabel"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([])
  const [closing, setClosing] = React.useState([]);
  const [drivers, setDrivers] = React.useState([])
  const [painelModal, setPainelModal] = React.useState(false);
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [modalCancel, setModalCancel] = React.useState(false);
  const [clientPhysic, setClientPhysic] = React.useState([]);
  const [clientBusiness, setClientBusiness] = React.useState([]);
  const [saleChannel, setSaleChannel] = React.useState([]);
  const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
  const [filterDate1, setFilterDate1] = React.useState('all');
  const [dateFilter, setDateFilter] = React.useState('');
  const [filterDriver, setFilterDriver] = React.useState('all');
  const [filteredData, setFilteredData] = React.useState([]);

  let db = 'clubeApi';
  if (currentUser !== undefined || currentUser !== null) {
    db = currentUser.db
  }

  React.useEffect(() => {
    api
      .get("/TodosPedidos2" + '/' + currentUser.db)
      .then((response) => {
        setClosing(response.data);
        setRows(response.data.map(key => ({
          id: key['id'], driver: key['driver_id'], client: key['client_id'], sale_channel: key['sale_channel'], products: key['products'], orderDate: key['order_date'], deliveryDate: key['delivery_date'], typeClient: key['typeClient'], desconto: key['desconto'], total: "R$ " + key['total'], obsPayment: key['obsPayment'], vasilhame: key['vasilhame'], status: key['status']
        })))
        setFilteredData(response.data.map(key => ({
          id: key['id'], driver: key['driver_id'], client: key['client_id'], sale_channel: key['sale_channel'], products: key['products'], orderDate: key['order_date'], deliveryDate: key['delivery_date'], typeClient: key['typeClient'], desconto: key['desconto'], total: "R$ " + key['total'], obsPayment: key['obsPayment'], vasilhame: key['vasilhame'], status: key['status']
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosMotoristas" + '/' + currentUser.db)
      .then((response) => {
        setDrivers(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosClientesFisicos" + '/' + currentUser.db)
      .then((response) => {
        setClientPhysic(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosClientesJuridicos" + '/' + currentUser.db)
      .then((response) => {
        setClientBusiness(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosCanaisVendas" + '/' + currentUser.db)
      .then((response) => {
        setSaleChannel(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    let dateNow = new Date();
    var filter = [];
    let driverName = filterDriver.split('-');
    // let dateArray = dateFilter.split("/");
    let dateStart = new Date(dateFilter[0]).getTime()
    let dateEnd = new Date(dateFilter[1]).getTime()
    if (dateFilter) {
      rows.filter(f => {
        if (f.deliveryDate !== null) {
          if (f.deliveryDate.length > 13) {
            let dataCompleteString = f.deliveryDate.split(" ");
            let dataString = dataCompleteString[0].split("/")
            let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
            f.deliveryDate = new Date(dataFormated).getTime();
          }
          if (dateStart == dateEnd) {
            let dateStartFormat = new Date(dateStart).toLocaleString()
            let dateEndFormat = new Date(dateEnd).toLocaleString()
            let dateStartSplit = dateStartFormat.split(" ");
            let dateEndSplit = dateEndFormat.split(" ");
            let dateStartSplited = dateStartSplit[0].split("/");
            let dateEndSplited = dateEndSplit[0].split("/");
            let dateStartNew = new Date(dateStartSplited[2] + "-" + dateStartSplited[1] + "-" + dateStartSplited[0] + " " + "00:00:01").getTime()
            let dateEndNew = new Date(dateEndSplited[2] + "-" + dateEndSplited[1] + "-" + dateEndSplited[0] + " " + "23:59:59").getTime()

            dateStart = dateStartNew
            dateEnd = dateEndNew
          }
          if (dateStart <= f.deliveryDate && dateEnd >= f.deliveryDate) {
            if (filterDate1 == 'semana') {
              dateNow.setDate(dateNow.getDate() - 7);
              let dateFormat = dateNow.getTime();
              if (f.deliveryDate !== null) {
                if (f.deliveryDate.length > 13) {
                  let dataCompleteString = f.deliveryDate.split(" ");
                  let dataString = dataCompleteString[0].split("/")
                  let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                  f.deliveryDate = new Date(dataFormated).getTime();
                }
                if (dateFormat < f.deliveryDate) {
                  if (filterDriver !== 'all') {
                    if (f.driver == driverName[0] || f.driver == driverName[1]) {
                      filter.push(f);
                    }
                  } else {
                    filter.push(f);
                  }
                }
              }
              setFilteredData(filter)
            } else if (filterDate1 == 'mes') {
              dateNow.setDate(dateNow.getDate() - 31);
              let dateFormat = dateNow.getTime();
              if (f.deliveryDate !== null) {
                if (f.deliveryDate.length > 13) {
                  let dataCompleteString = f.deliveryDate.split(" ");
                  let dataString = dataCompleteString[0].split("/")
                  let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                  f.deliveryDate = new Date(dataFormated).getTime();
                }
                if (dateFormat < f.deliveryDate) {
                  if (filterDriver !== 'all') {
                    if (f.driver == driverName[0] || f.driver == driverName[1]) {
                      filter.push(f)
                    }
                  } else {
                    filter.push(f);
                  }
                }
              }
              setFilteredData(filter)
            } else if (filterDate1 == 'ano') {
              dateNow.setDate(dateNow.getDate() - 365);
              let dateFormat = dateNow.getTime();
              if (f.deliveryDate !== null) {
                if (f.deliveryDate.length > 13) {
                  let dataCompleteString = f.deliveryDate.split(" ");
                  let dataString = dataCompleteString[0].split("/")
                  let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                  f.deliveryDate = new Date(dataFormated).getTime();
                }
                if (dateFormat < f.deliveryDate) {
                  if (filterDriver !== 'all') {
                    if (f.driver == driverName[0] || f.driver == driverName[1]) {
                      filter.push(f);
                    }
                  } else {
                    filter.push(f);
                  }
                }
              }
              setFilteredData(filter)
            } else {
              if (filterDriver !== 'all') {
                if (f.driver == driverName[0] || f.driver == driverName[1]) {
                  filter.push(f);
                }
              } else {
                filter.push(f)
              }
            }
          }
        }
      })
      setFilteredData(filter)
      document.getElementById("helperFilterDate").setAttribute('style', 'color: rgba(0, 0, 0, 0.6)')
    }

    if (dateFilter == "") {
      if (filterDate1 == 'semana') {
        dateNow.setDate(dateNow.getDate() - 7);
        let dateFormat = dateNow.getTime();
        rows.filter(f => {
          if (f.deliveryDate !== null) {
            if (f.deliveryDate.length > 13) {
              let dataCompleteString = f.deliveryDate.split(" ");
              let dataString = dataCompleteString[0].split("/")
              let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
              f.deliveryDate = new Date(dataFormated).getTime();
            }
            if (dateFormat < f.deliveryDate) {
              if (filterDriver !== 'all') {
                if (f.driver == driverName[0] || f.driver == driverName[1]) {
                  filter.push(f);
                }
              } else {
                filter.push(f);
              }
            }
          }
        })
        setFilteredData(filter)
      } else if (filterDate1 == 'mes') {
        dateNow.setDate(dateNow.getDate() - 31);
        let dateFormat = dateNow.getTime();
        rows.filter(f => {
          if (f.deliveryDate !== null) {
            if (f.deliveryDate.length > 13) {
              let dataCompleteString = f.deliveryDate.split(" ");
              let dataString = dataCompleteString[0].split("/")
              let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
              f.deliveryDate = new Date(dataFormated).getTime();
            }
            if (dateFormat < f.deliveryDate) {
              if (filterDriver !== 'all') {
                if (f.driver == driverName[0] || f.driver == driverName[1]) {
                  filter.push(f);
                }
              } else {
                filter.push(f);
              }
            }
          }
        })
        setFilteredData(filter)
      } else if (filterDate1 == 'ano') {
        dateNow.setDate(dateNow.getDate() - 365);
        let dateFormat = dateNow.getTime();
        rows.filter(f => {
          if (f.deliveryDate !== null) {
            if (f.deliveryDate.length > 13) {
              let dataCompleteString = f.deliveryDate.split(" ");
              let dataString = dataCompleteString[0].split("/")
              let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
              f.deliveryDate = new Date(dataFormated).getTime();
            }
            if (dateFormat < f.deliveryDate) {
              if (filterDriver !== 'all') {
                if (f.driver == driverName[0] || f.driver == driverName[1]) {
                  filter.push(f);
                }
              } else {
                filter.push(f);
              }
            }
          }
        })
        setFilteredData(filter)
      } else {
        rows.filter(f => {
          if (filterDriver !== 'all') {
            if (f.driver == driverName[0] || f.driver == driverName[1]) {
              if (dateStart <= f.deliveryDate && dateEnd >= f.deliveryDate) {
                filter.push(f)
              } else if (filterDate1 == 'semana') {
                dateNow.setDate(dateNow.getDate() - 7);
                let dateFormat = dateNow.getTime();
                rows.filter(f => {
                  if (f.deliveryDate !== null) {
                    if (f.deliveryDate.length > 13) {
                      let dataCompleteString = f.deliveryDate.split(" ");
                      let dataString = dataCompleteString[0].split("/")
                      let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                      f.deliveryDate = new Date(dataFormated).getTime();
                    }
                    if (dateFormat < f.deliveryDate) {
                      if (filterDriver !== 'all') {
                        if (f.driver == driverName[0] || f.driver == driverName[1]) {
                          filter.push(f);
                        }
                      } else {
                        filter.push(f);
                      }
                    }
                  }
                })
                setFilteredData(filter)
              } else if (filterDate1 == 'mes') {
                dateNow.setDate(dateNow.getDate() - 31);
                let dateFormat = dateNow.getTime();
                rows.filter(f => {
                  if (f.deliveryDate !== null) {
                    if (f.deliveryDate.length > 13) {
                      let dataCompleteString = f.deliveryDate.split(" ");
                      let dataString = dataCompleteString[0].split("/")
                      let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                      f.deliveryDate = new Date(dataFormated).getTime();
                    }
                    if (dateFormat < f.deliveryDate) {
                      if (filterDriver !== 'all') {
                        if (f.driver == driverName[0] || f.driver == driverName[1]) {
                          filter.push(f);
                        }
                      } else {
                        filter.push(f);
                      }
                    }
                  }
                })
                setFilteredData(filter)
              } else if (filterDate1 == 'ano') {
                dateNow.setDate(dateNow.getDate() - 365);
                let dateFormat = dateNow.getTime();
                rows.filter(f => {
                  if (f.deliveryDate !== null) {
                    if (f.deliveryDate.length > 13) {
                      let dataCompleteString = f.deliveryDate.split(" ");
                      let dataString = dataCompleteString[0].split("/")
                      let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                      f.deliveryDate = new Date(dataFormated).getTime();
                    }
                    if (dateFormat < f.deliveryDate) {
                      if (filterDriver !== 'all') {
                        if (f.driver == driverName[0] || f.driver == driverName[1]) {
                          filter.push(f);
                        }
                      } else {
                        filter.push(f);
                      }
                    }
                  }
                })
                setFilteredData(filter)
              } else {
                filter.push(f);
              }
            }
            setFilteredData(filter)
          } else if (dateStart <= f.deliveryDate && dateEnd >= f.deliveryDate) {
            filter.push(f)
          } else {
            filter.push(f)
          }
        })
        setFilteredData(filter)
      }
    }
  }, [filterDate1, filterDriver, dateFilter])


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleModalClose = () => {
    setPainelModal(false)
    setEditModal(false)
    setModalCancel(false)
  };

  const submitModalEdit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const dados = {
      id: idModal,
      name: data.nameCupom,
      code: data.codeCupom,
      value: data.valueCupom,
      data_start: data.dateStartCupom,
      date_end: data.dateEndCupom,
      db: currentUser.db
    }

    api.put("/AtualizarCupom", dados);

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const handleCancelOrdered = async (e) => {

    api.delete('/DeletarCupom/' + idModal + '/' + currentUser.db)

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalPainel(props) {
    return (
      <Modal
        show={painelModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Detalhes
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do cupom</h4>
          </div>
          {closing.map(key => {
            if (key['id'] == idModal) {
              return (
                <Table striped bordered hover className="tableProfile">
                  <tbody>
                    <tr>
                      <td className="labelConfirm">Nome do cupom</td>
                      <td>{key['name']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Código</td>
                      <td>{key['code']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Valor</td>
                      <td>{"R$ " + key['value']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Data de início</td>
                      <td>{key['date_start']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Data de fim</td>
                      <td>{key['date_end']}</td>
                    </tr>
                  </tbody>
                </Table>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )

  }

  function ModalEdit(props) {
    return (
      <Modal
        show={editModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Editar fechamento
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do fechamento</h4>
          </div>
          {closing.map(key => {
            if (key['id'] == idModal) {
              return (
                <form className="input" onSubmit={submitModalEdit}>
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Entregador:</td>
                        <td>
                          <input
                            required
                            name="nameCupom"
                            className="inputCadastro"
                            defaultValue={key['name']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Código:</td>
                        <td>
                          <input
                            required
                            name="codeCupom"
                            className="inputCadastro"
                            defaultValue={key['code']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Valor:</td>
                        <td>
                          <input
                            required
                            name="valueCupom"
                            className="inputCadastro"
                            defaultValue={key['value']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Data de início:</td>
                        <td>
                          <input
                            required
                            name="dataStartCupom"
                            className="inputCadastro"
                            defaultValue={key['date_start']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Data de encerramento:</td>
                        <td>
                          <input
                            required
                            name="dataEndCupom"
                            className="inputCadastro"
                            defaultValue={key['date_end']}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Atualizar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalCancelar(props) {
    return (
      <Modal
        show={modalCancel}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Excluir cupom
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Você tem certeza que deseja excluir o cupom selecionado?</h4>
          <div className="cancelOrder">
            <Button onClick={handleCancelOrdered} className="cancelButton true">Sim</Button>
            <Button onClick={handleModalClose} className="cancelButton false">Não</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  const [open, setOpen] = React.useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  const columns = [
    { title: "Entregador", field: "driver" },
    { title: "Canal de vendas", field: "sale_channel" },
    { title: "Cliente", field: "client" },
    { title: "Produtos", field: "products" },
    { title: "Data do pedido", field: "orderDate" },
    { title: "Data de entrega", field: "deliveryDate" },
    { title: "Desconto", field: "desconto" },
    { title: "Observação de Pagamento", field: "obsPayment" },
    { title: "Vasilhame", field: "vasilhame" },
    { title: "Total", field: "total" }
  ]

  rows.forEach(item => {
    drivers.map(key => {
      if (key['id'] == item.driver) {
        item.driver = key['name']
      }
    })
    saleChannel.map(key => {
      if (key['id'] == item.sale_channel) {
        item.sale_channel = key['name']
      }
    })
    if (item.typeClient == 'fisico') {
      clientPhysic.map(key => {
        if (item.client == key['id']) {
          item.client = key['name']
        }
      })
    } else {
      clientBusiness.map(key => {
        if (item.client == key['id']) {
          item.client = key['name']
        }
      })
    }
    if (item.deliveryDate == null) {
      item.deliveryDate = 'Não informado'
    }
    if (item.desconto == null) {
      item.desconto = 'Sem desconto'
    }
    if (item.desconto == 0) {
      item.desconto = 'Sem desconto'
    }
    if (item.obsPayment == null) {
      item.obsPayment = 'Não informado'
    }
    if (item.obsPayment == '') {
      item.obsPayment = 'Não informado'
    }
    if (item.vasilhame == null) {
      item.vasilhame = 'Não informado'
    }
  })

  filteredData.forEach(item => {
    drivers.map(key => {
      if (key['id'] == item.driver) {
        item.driver = key['name']
      }
    })
    saleChannel.map(key => {
      if (key['id'] == item.sale_channel) {
        item.sale_channel = key['name']
      }
    })
    if (item.typeClient == 'fisico') {
      clientPhysic.map(key => {
        if (item.client == key['id']) {
          item.client = key['name']
        }
      })
    } else {
      clientBusiness.map(key => {
        if (item.client == key['id']) {
          item.client = key['name']
        }
      })
    }
    if (item.deliveryDate == null) {
      item.deliveryDate = 'Não informado'
    }
    if (item.desconto == null) {
      item.desconto = 'Sem desconto'
    }
    if (item.desconto == 0) {
      item.desconto = 'Sem desconto'
    }
    if (item.obsPayment == null) {
      item.obsPayment = 'Não informado'
    }
    if (item.obsPayment == '') {
      item.obsPayment = 'Não informado'
    }
    if (item.vasilhame == null) {
      item.vasilhame = 'Não informado'
    }
  })


  return (
    <>
      <Loading />
      <ModalCancelar />
      <ModalPainel />
      <ModalEdit />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }} className='tableClossingDriver'>
          <MaterialTable
            title=""
            columns={columns}
            data={filteredData.reverse()}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: false,
              toolbarButtonAlignment: "left",
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
            actions={[
              {
                icon: () => <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <CustomProvider locale={pt_BR}>
                    <DateRangePicker
                      placeholder="Selecione as datas"
                      format="dd/MM/yyyy"
                      size="lg"
                      onChange={(e) => {
                        if (e.length >= 2) {
                          setDateFilter(e)
                        }
                      }
                      }
                    />
                  </CustomProvider>
                  <FormHelperText id="helperFilterDate">Filtre por data</FormHelperText>
                </FormControl>,
                isFreeAction: true,
              },
              {
                icon: () =>
                  <FormControl sx={{ m: 1, minWidth: 220 }}>
                    {/* <InputLabel id="filterDriver">Filtro por entregador</InputLabel> */}
                    <Select
                      labelId="filterDriver"
                      id="filterDriver"
                      className='filterDriver'
                      style={{ width: 220 }}
                      value={filterDriver}
                      onChange={(e) => setFilterDriver(e.target.value)}
                    >
                      <MenuItem value={'all'}>Todos</MenuItem>
                      {drivers.map(key => {
                        return (
                          <MenuItem value={key['id'] + "-" + key['name']}>{key['name']}</MenuItem>
                        )
                      })}
                    </Select>
                    <FormHelperText>Filtre por entregador</FormHelperText>
                  </FormControl>,
                isFreeAction: true
              },
            ]}
          />
        </Paper>
      </Box>
    </>
  );
}
