import UserActionTypes from "./actions-types";

const initialState = {
    currentUser: { token: null, id: null, typeCollaborator: null, db: 'clubeApi' }
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case UserActionTypes.LOGIN:
            return { ...state, currentUser: action.payload };
        case UserActionTypes.LOGOUT:
            return { ...state, currentUser: action.payload };
        default:
            return state;
    }
};

export default userReducer;