import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableCreateProducts.css'
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'
import { Checkbox, Select, MenuItem } from '@material-ui/core';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { TbEyeSearch } from "react-icons/tb";
import { CurrencyInput } from 'react-currency-mask';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'product',
    numeric: false,
    disablePadding: false,
    label: 'Produtos',
  },
  {
    id: 'category_id',
    numeric: false,
    disablePadding: false,
    label: 'Categoria',
  },
  {
    id: 'value',
    numeric: false,
    disablePadding: false,
    label: 'Valor',
  },
  {
    id: 'promotional_value',
    numeric: false,
    disablePadding: false,
    label: 'Valor promocional',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Descrição',
  },
  {
    id: 'stock',
    numeric: false,
    disablePadding: false,
    label: 'Estoque',
  },
  {
    id: 'image',
    numeric: false,
    disablePadding: false,
    label: 'Imagem',
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: false,
    label: 'Ações',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className="colunmLabel"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([])
  const [collaborators, setCollaborators] = React.useState([]);
  const [painelModal, setPainelModal] = React.useState(false);
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [text, setText] = React.useState();
  const [modalCancel, setModalCancel] = React.useState(false);
  const [salaryModal, setSalaryModal] = React.useState(false);
  const [category, setCategory] = React.useState([])
  const [filter, setFilter] = React.useState(true)
  const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
  React.useEffect(() => {
    api
      .get("/TodosProdutos" + '/' + currentUser.db)
      .then((response) => {
        setCollaborators(response.data);
        setRows(response.data.map(key => ({
          id: key['id'], product: key['name'], category_id: key['category_id'], value: key['value'], promotional_value: key['promotional_value'], promocional_date_end: key['promocional_date_end'], description: key['description'], stock: key['stock'], image: key['imagem']
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodasCategoriasProdutos" + '/' + currentUser.db)
      .then((response) => {
        setCategory(response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleModalClose = () => {
    setPainelModal(false)
    setEditModal(false)
    setModalCancel(false)
    setSalaryModal(false)
  };

  const submitModalEdit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    let dados = '';
    let valuePromo = null

    let timestampPromocional = 0;

    var timeNow = new Date().valueOf();
    var dateFormatNow = new Date(timeNow);

    if (Number(data.promotionalDateProduct) !== 0) {
      timestampPromocional = dateFormatNow.setDate(dateFormatNow.getDate() + Number(data.promotionalDateProduct));
    }

    if (data.promotionalValueProduct !== '' || data.promotionalValueProduct !== 0) {
      valuePromo = data.promotionalValueProduct;
    }

    if (data.imageProduct.name !== '') {
      dados = {
        id: idModal,
        'image': data.imageProduct,
        name: data.product,
        category_id: Number(data.categoryProduct),
        value: data.valueProduct,
        promotional_value: valuePromo,
        promotional_date_end: timestampPromocional,
        description: data.descriptionProduct,
        stock: data.stockProduct,
        imagem: "https://api.clubedorevendedordegas.com.br/files/products/" + data.imageProduct.name,
        whatsapp: data.whatsappProduct,
        db: currentUser.db
      }
    } else {
      dados = {
        id: idModal,
        name: data.product,
        category_id: Number(data.categoryProduct),
        value: data.valueProduct,
        promotional_value: valuePromo,
        promotional_date_end: timestampPromocional,
        description: data.descriptionProduct,
        stock: data.stockProduct,
        whatsapp: data.whatsappProduct,
        db: currentUser.db
      }
    }

    api.put("/AtualizarProduto", dados, {
      'headers': {
        'Content-Type': 'multipart/form-data'
      }
    })

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 5000);
  }

  const handleCancelOrdered = async (e) => {

    api.delete('/DeletarProduto/' + idModal + "/" + currentUser.db)

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalPainel(props) {
    return (
      <Modal
        show={painelModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Detalhes
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do produto</h4>
          </div>
          {collaborators.map(key => {
            if (key['id'] == idModal) {
              return (
                <Table striped bordered hover className="tableProfile">
                  <tbody>
                    <tr>
                      <td className="labelConfirm">Produto</td>
                      <td>{key['name']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Categoria</td>
                      <td>{key['category_id']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Valor</td>
                      <td>{<CurrencyInput
                        className="inputDisableValueProduct"
                        value={key['value']}
                        onChangeValue={(event, originalValue, maskedValue) => {
                          // console.log(event, originalValue, maskedValue);
                        }}
                      />}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Descrição</td>
                      <td>{key['description']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Estoque</td>
                      <td>{key['stock']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Imagem</td>
                      <td><img src={key['imagem']} className="imgTable" /></td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Aparecerá no whatsapp?</td>
                      <td>{key['whatsapp'] == '1' ? 'Sim' : 'Não'}</td>
                    </tr>
                  </tbody>
                </Table>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )

  }

  function ModalEdit(props) {
    return (
      <Modal
        show={editModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Editar produto
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do produto</h4>
          </div>
          {collaborators.map(key => {
            let promocionalDate = 0;
            var timeNow = new Date().valueOf();
            var dateFormatNow = new Date(timeNow);
            var dateNow = dateFormatNow.getFullYear() +
              "-" + (dateFormatNow.getMonth() + 1) +
              "-" + dateFormatNow.getDate();

            if (key['id'] == idModal) {
              if (typeof key['promotional_value'] !== 'undefined') {
                if (key['promotional_value'] !== null) {
                  if (key['promotional_date_end'] !== '0') {
                    var timestamp = Number(key['promotional_date_end']);
                    var dateFormat = new Date(timestamp);

                    var datePromocional = dateFormat.getFullYear() +
                      "-" + (dateFormat.getMonth() + 1) +
                      "-" + dateFormat.getDate();

                    const diffInMs = new Date(datePromocional) - new Date(dateNow)
                    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

                    promocionalDate = parseInt(diffInDays);
                  }
                }
              }
              return (
                <form className="input" onSubmit={submitModalEdit} enctype="multipart/form-data">
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Produto:</td>
                        <td>
                          <input
                            required
                            name="product"
                            className="inputCadastro"
                            defaultValue={key['name']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Categoria:</td>
                        <td>
                          <Form.Select
                            name='categoryProduct'
                            className="inputCadastro"
                          >
                            <option value='' >Selecione um colaborador</option>
                            {category.map(k => {
                              return (
                                <option value={k['id']} selected={key['category_id'] == k['id']}>{k['name']}</option>
                              )
                            })}
                          </Form.Select>
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Valor:</td>
                        <td>
                          <CurrencyInput
                            required
                            name="valueProduct"
                            className="inputCadastro"
                            defaultValue={key['value']}
                            onChangeValue={(event, originalValue, maskedValue) => {
                              // console.log(event, originalValue, maskedValue);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Valor promocional:</td>
                        <td>
                          <CurrencyInput
                            name="promotionalValueProduct"
                            className="inputCadastro"
                            defaultValue={key['promotional_value']}
                            onChangeValue={(event, originalValue, maskedValue) => {
                              // console.log(event, originalValue, maskedValue);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Dias de promoção:</td>
                        <td>
                          <input
                            name="promotionalDateProduct"
                            className="inputCadastro"
                            defaultValue={promocionalDate}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Descrição:</td>
                        <td>
                          <input
                            required
                            name="descriptionProduct"
                            className="inputCadastro"
                            defaultValue={key['description']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Estoque:</td>
                        <td>
                          <input
                            required
                            name="stockProduct"
                            className="inputCadastro"
                            defaultValue={key['stock']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Imagem:</td>
                        <td>
                          <img src={key['imagem']} className="imgTable" />
                          <input
                            type="file"
                            name="imageProduct"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Aparecerá no whatsapp:</td>
                        <td>
                          <Form.Select
                            name='whatsappProduct'
                            className="inputCadastro"
                          >
                            <option value='' >Selecione uma resposta</option>
                            <option value='1' selected={key['whatsapp'] == '1'}>Sim</option>
                            <option value='0' selected={key['whatsapp'] == '0'}>Não</option>
                          </Form.Select>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Atualizar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalCancelar(props) {
    return (
      <Modal
        show={modalCancel}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Excluir produto
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Você tem certeza que deseja excluir o produto selecionado?</h4>
          <div className="cancelOrder">
            <Button onClick={handleCancelOrdered} className="cancelButton true">Sim</Button>
            <Button onClick={handleModalClose} className="cancelButton false">Não</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  const [open, setOpen] = React.useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }
  let promotionalValue = 'Sem valor promocional'
  var dateNow = new Date().getTime();

  const columns = [
    { title: "Produtos", field: "product" },
    { title: "Categoria", field: "category_id" },
    {
      title: "Valor", field: "value", render: (rowData) => {
        return (
          <CurrencyInput
            className="inputDisableValueProduct"
            defaultValue={rowData.value}
            onChangeValue={(event, originalValue, maskedValue) => {
              // console.log(event, originalValue, maskedValue);
            }}
            disabled
          />
        )
      }
    },
    {
      title: "Valor promocional", field: 'promotional_value', render: (rowData) => {
        return (
          <CurrencyInput
            className="inputDisableValueProduct"
            defaultValue={rowData.promotional_value}
            onChangeValue={(event, originalValue, maskedValue) => {
              // console.log(event, originalValue, maskedValue);
            }}
            disabled
          />
        )
      }
    },
    { title: "Descrição", field: 'description' },
    { title: "Estoque", field: 'stock' },
    { title: "Imagem", field: 'image', render: (rowData) => <img src={rowData.image} className="imgTable" /> }
  ]

  rows.forEach(item => {
    category.map(key => {
      if (key['id'] == item.category_id) {
        item.category_id = key['name']
      }
    })
  })

  return (
    <>
      <Loading />
      <ModalCancelar />
      <ModalPainel />
      <ModalEdit />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title=""
            columns={columns}
            data={rows}
            actions={[
              {
                icon: () => <TbEyeSearch className='perfilAction' />,
                tooltip: 'Detalhes',
                onClick: (event, rowData) => {
                  setPainelModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineEdit className='perfilAction' />,
                tooltip: 'Editar',
                onClick: (event, rowData) => {
                  setEditModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineDelete className='perfilAction' />,
                tooltip: 'Deletar',
                onClick: (event, rowData) => {
                  setModalCancel(true)
                  setIdModal(rowData.id)
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: false,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
          />
        </Paper>
      </Box>
    </>
  );
}
