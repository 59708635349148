import { useState } from "react";
import React from 'react'
import './nfe.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import Form from 'react-bootstrap/Form';
import TableNfe from '../TableNfe'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import axios from "axios";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { letterSpacing } from "@mui/system";
import { useSelector, useDispatch } from 'react-redux';

const Nfe = () => {
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    const { currentAutentification } = useSelector(rootReducer => rootReducer.nuvemFiscalReducer);
    let token = '';
    if (currentAutentification !== undefined) {
        token = currentAutentification;
    }
    const [provider, setProvider] = React.useState([]);
    const [optionsProducts, setOptionsProducts] = React.useState([]);
    const [product, setProduct] = React.useState([]);
    const [cepApi, setCepApi] = React.useState([]);

    let cnpj = '';
    let razaoSocial = '';
    let logradouro = '';
    let num = '';
    let bairro = '';
    let cidade = '';
    let estado = '';
    let email = '';
    let tipoLogradouro = '';
    let sumTotal = 0;
    let aVista = '';
    let meio = '';
    let cep = '';
    let ibge = '';

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const animatedComponents = makeAnimated();

    let productValue = '';
    let productName = [];

    const productHandler = e => {
        var divProducSelected1 = document.getElementById('productSelect1')
        var divProducSelected2 = document.getElementById('productSelect2')
        var divProducSelected3 = document.getElementById('productSelect3')
        var labelProducSelected1 = document.getElementById('labelProductSelect1')
        var labelProducSelected2 = document.getElementById('labelProductSelect2')
        var labelProducSelected3 = document.getElementById('labelProductSelect3')
        var options = e;
        var value = [];
        var name = [];

        for (var i = 0, l = options.length; i < l; i++) {
            value.push(options[i].value);
            name.push(options[i].label)
            if (i == 0) {
                divProducSelected1.style.display = "block"
                divProducSelected2.style.display = "none"
                divProducSelected3.style.display = "none"
                labelProducSelected1.textContent = options[i].label + ":"
            }
            if (i == 1) {
                divProducSelected2.style.display = "block"
                divProducSelected3.style.display = "none"
                labelProducSelected2.textContent = options[i].label + ":"
            }
            if (i == 2) {
                divProducSelected3.style.display = "block"
                labelProducSelected3.textContent = options[i].label + ":"
            }
        }
        if (options.length == 0) {
            divProducSelected1.style.display = "none"
        }

        productValue = value;
        productName = name

        var total = [];
        let sumTotal = 0;
        for (var i = 0, l = productValue.length; i < l; i++) {
            product.map(key => {
                if (key['id'] == productValue[i]) {
                    total.push(key['value'])
                }
            })
        }
        for (let i = 0; i < total.length; i++) {
            sumTotal += total[i]
        }

    }

    React.useEffect(() => {
        api
            .get("/TodosFornecedores"+ '/' + currentUser.db)
            .then((response) => {
                setProvider(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    React.useEffect(() => {
        api
            .get("/TodosProdutos" + '/' + currentUser.db)
            .then((response) => {
                setProduct(response.data);
                setOptionsProducts(response.data.map(key => ({
                    value: key['id'],
                    label: key['name']
                })))
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    React.useEffect(() => {
        provider.map(key => {
            let enderecoCompleto = key['address'].split(' - ');
            let logradouroSplit = enderecoCompleto[0].split(', ');
            let cidadeEstado = enderecoCompleto[2].split("/");
            let estadoApi = '';

            if (cidadeEstado[1] == "Acre") {
                estadoApi = "AC";
            } else if (cidadeEstado[1] == "Alagoas") {
                estadoApi = "AL";
            } else if (cidadeEstado[1] == "Amapá") {
                estadoApi = "AP";
            } else if (cidadeEstado[1] == "Amazonas") {
                estadoApi = "AM";
            } else if (cidadeEstado[1] == "Bahia") {
                estadoApi = "BA";
            } else if (cidadeEstado[1] == "Ceará") {
                estadoApi = "CE";
            } else if (cidadeEstado[1] == "Destrito Federal" || cidadeEstado[1] == "Brasília") {
                estadoApi = "DF";
            } else if (cidadeEstado[1] == "Espírito Santo") {
                estadoApi = "ES";
            } else if (cidadeEstado[1] == "Goiás") {
                estadoApi = "GO";
            } else if (cidadeEstado[1] == "Maranhão") {
                estadoApi = "MA";
            } else if (cidadeEstado[1] == "Mato Grosso") {
                estadoApi = "MT";
            } else if (cidadeEstado[1] == "Mato Grosso do Sul") {
                estadoApi = "MS";
            } else if (cidadeEstado[1] == "Minas Gerais") {
                estadoApi = "MG";
            } else if (cidadeEstado[1] == "Pará") {
                estadoApi = "PA";
            } else if (cidadeEstado[1] == "Paraíba") {
                estadoApi = "PB";
            } else if (cidadeEstado[1] == "Paraná") {
                estadoApi = "PR";
            } else if (cidadeEstado[1] == "Pernambuco") {
                estadoApi = "PE";
            } else if (cidadeEstado[1] == "Piauí") {
                estadoApi = "PI";
            } else if (cidadeEstado[1] == "Rio de Janeiro") {
                estadoApi = "RJ";
            } else if (cidadeEstado[1] == "Rio Grande do Norte") {
                estadoApi = "RN";
            } else if (cidadeEstado[1] == "Rio Grande do Sul") {
                estadoApi = "RS";
            } else if (cidadeEstado[1] == "Rondônia") {
                estadoApi = "RO";
            } else if (cidadeEstado[1] == "Roraima") {
                estadoApi = "RR";
            } else if (cidadeEstado[1] == "Santa Catarina") {
                estadoApi = "SC";
            } else if (cidadeEstado[1] == "São Paulo") {
                estadoApi = "SP";
            } else if (cidadeEstado[1] == "Sergipe") {
                estadoApi = "SE";
            } else if (cidadeEstado[1] == "Tocantins") {
                estadoApi = "TO";
            }
            axios
                .get("https://viacep.com.br/ws/" + estadoApi + "/" + cidadeEstado[0] + "/" + logradouroSplit[0] + "/json/")
                .then((response) => {
                    response.data.map(k => {
                        setCepApi([...cepApi, { id: key['id'], cep: k['cep'], ibge: k['ibge'], uf: k['uf'] }]);
                    })
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        })

    }, [provider])

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        const productSelect1 = data.productSelect1;
        const productSelect2 = data.productSelect2;
        const productSelect3 = data.productSelect3;
        const bruto = '';
        const pis = '';
        const cofins = '';

        if (data.aVista == 'yes') {
            aVista = true;
        } else {
            aVista = false;
        }

        if (data.meio == 'dinheiro') {
            meio = '01';
        } else if (data.meio == 'cheque') {
            meio = '02';
        } else if (data.meio == 'credito') {
            meio = '03';
        } else if (data.meio == 'debito') {
            meio = '04';
        } else if (data.meio == 'loja') {
            meio = '05';
        } else if (data.meio == 'alimentacao') {
            meio = '10';
        } else if (data.meio == 'refeicao') {
            meio = '11';
        } else if (data.meio == 'presente') {
            meio = '13';
        } else if (data.meio == 'boleto') {
            meio = '15';
        } else if (data.meio == 'deposito') {
            meio = '16';
        } else if (data.meio == 'pix') {
            meio = '17';
        } else if (data.meio == 'transferencia') {
            meio = '18';
        } else if (data.meio == 'fidelidade') {
            meio = '19';
        } else if (data.meio == 'semPagamento') {
            meio = '90';
        } else if (data.meio == 'outros') {
            meio = '99';
        }


        const headers = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }

        const itens = [];

        product.map(key => {
            productName.map(p => {
                if (key['name'] == p) {
                    let ncm = '';
                    let totalValue = '';
                    let splitName = p.split(" ");
                    let splitGas = splitName[1];
                    let qnt = 1;
                    let nItem = 1;
                    let valueProduct = '';

                    if (splitGas == 'P13') {
                        ncm = "7898341571098";
                    } else if (splitGas == 'P05') {
                        ncm = "3000001590507";
                    } else if (splitGas == 'P45') {
                        ncm = "3000001590507";
                    } else if (splitGas == 'Água' || splitGas == 'Galão') {
                        ncm = "2201";
                    } else {
                        ncm = "27111990";
                    }

                    if (p == productName[0]) {
                        qnt = Number(productSelect1);
                        nItem = 1;
                        let valueProductSplit = key['value'].split(",");
                        if (valueProductSplit[1] !== undefined) {
                            valueProduct = valueProductSplit[0] + "." + valueProductSplit[1];
                        } else {
                            valueProduct = valueProductSplit[0];
                        }
                        let multi = Number(valueProduct) * Number(productSelect1);
                        totalValue = multi;
                    } else if (p == productName[1]) {
                        qnt = Number(productSelect2);
                        nItem = 2;
                        let valueProductSplit = key['value'].split(",");
                        if (valueProductSplit[1] !== undefined) {
                            valueProduct = valueProductSplit[0] + "." + valueProductSplit[1];
                        } else {
                            valueProduct = valueProductSplit[0];
                        }
                        let multi = Number(valueProduct) * Number(productSelect2);
                        totalValue = multi;
                    } else if (p == productName[2]) {
                        qnt = Number(productSelect3);
                        nItem = 3;
                        let valueProductSplit = key['value'].split(",");
                        if (valueProductSplit[1] !== undefined) {
                            valueProduct = valueProductSplit[0] + "." + valueProductSplit[1];
                        } else {
                            valueProduct = valueProductSplit[0];
                        }
                        let multi = Number(valueProduct) * Number(productSelect3);
                        totalValue = multi;
                    }

                    let productNfe = {
                        "nItem": nItem,
                        "prod": {
                            "cProd": "1102",
                            "cEAN": "SEM GTIN",
                            "xProd": p,
                            "NCM": "00",
                            "CFOP": "1102",
                            "uCom": "kg",
                            "qCom": qnt,
                            "vUnCom": Number(valueProduct),
                            "vProd": Number(valueProduct),
                            "cEANTrib": "SEM GTIN",
                            "uTrib": "kg",
                            "qTrib": qnt,
                            "vUnTrib": Number(valueProduct),
                            "indTot": 0
                        },
                        "imposto": {
                            "ISSQN": {
                                "vBC": 0,
                                "vAliq": 0,
                                "vISSQN": 0,
                                "cMunFG": "3534401",
                                "cListServ": "07.21",
                                "indISS": 1,
                                "indIncentivo": 1
                            },
                            "PIS": {
                                "PISNT": {
                                    "CST": "04"
                                }
                            },
                            "COFINS": {
                                "COFINSNT": {
                                    "CST": "04"
                                }
                            }
                        }
                    };

                    itens.push(productNfe);
                    sumTotal += totalValue
                }
            })
        })

        provider.map(key => {
            if (key['id'] == data.provider) {
                cnpj = key['cnpj'].replace(".", "").replace(".", "").replace("/", "").replace("-", "");
                email = key['email'];
                razaoSocial = key['razao'];
                let enderecoCompleto = key['address'].split(' - ');
                let logradouroSplit = enderecoCompleto[0].split(', ');
                logradouro = logradouroSplit[0];
                num = logradouroSplit[1];
                bairro = enderecoCompleto[1];
                let logradouroTypeSplit = logradouroSplit[0].split(" ");
                tipoLogradouro = logradouroTypeSplit[0];
                let cidadeEstado = enderecoCompleto[2].split("/");
                cidade = cidadeEstado[0];
            }
        })

        cepApi.map(key => {
            if (key['id'] == data.provider) {
                cep = key['cep'];
                ibge = key['ibge'];
                estado = key['uf'];
            }
        })

        var timestamp = new Date().getTime();
        var dateISO = new Date().toISOString();
        const dados = {
            "infNFe": {
                "versao": "4.00",
                "ide": {
                    "cUF": 35,
                    "natOp": "Recebido",
                    "serie": 0,
                    "nNF": 6,
                    "dhEmi": dateISO,
                    "tpNF": 0,
                    "idDest": 1,
                    "cMunFG": "3550308",
                    "tpImp": 0,
                    "tpEmis": 1,
                    "finNFe": 1,
                    "indFinal": 1,
                    "indPres": 2,
                    "indIntermed": 0,
                    "procEmi": 0,
                    "verProc": "4.00"
                },
                "emit": {
                    "CNPJ": "28705068000195"
                },
                "dest": {
                    "CNPJ": cnpj,
                    "xNome": razaoSocial,
                    "enderDest": {
                        "xLgr": logradouro,
                        "nro": num,
                        "xCpl": "0",
                        "xBairro": bairro,
                        "cMun": ibge,
                        "xMun": cidade,
                        "UF": "SP",
                        "xPais": "Brasil"
                    },
                    "indIEDest": 9,
                    "IE": "120520998116",
                    "IM": "string"
                },
                "det": itens,
                "total": {
                    "ICMSTot": {
                        "vBC": 0,
                        "vICMS": 0,
                        "vICMSDeson": 0,
                        "vFCPUFDest": 0,
                        "vICMSUFDest": 0,
                        "vICMSUFRemet": 0,
                        "vFCP": 0,
                        "vBCST": 0,
                        "vST": 0,
                        "vFCPST": 0,
                        "vFCPSTRet": 0,
                        "qBCMono": 0,
                        "vICMSMono": 0,
                        "qBCMonoReten": 0,
                        "vICMSMonoReten": 0,
                        "qBCMonoRet": 0,
                        "vICMSMonoRet": 0,
                        "vProd": Number(sumTotal),
                        "vFrete": 0,
                        "vSeg": 0,
                        "vDesc": 0,
                        "vII": 0,
                        "vIPI": 0,
                        "vIPIDevol": 0,
                        "vPIS": 0,
                        "vCOFINS": 0,
                        "vOutro": 0,
                        "vNF": Number(sumTotal),
                        "vTotTrib": 0
                    }
                },
                "transp": {
                    "modFrete": 9
                },
                "pag": {
                    "detPag": [
                        {
                            "tPag": "01",
                            "vPag": 1
                        }
                    ]
                }
            },
            "ambiente": "homologacao",
            "referencia": cnpj + "--" + timestamp
        }

        axios.post("https://api.nuvemfiscal.com.br/nfe", dados, headers);
        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 5000);


    }

    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    function OffCanvasExample({ ...props }) {
        return (
            <>
                <Loading />
                <Button variant="primary" onClick={handleShow} className="me-2 button">
                    {'Nova Nota de Produto'}
                </Button>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header >
                        <Offcanvas.Title>Nova Nota de Produto</Offcanvas.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form className="input" onSubmit={submitForm}>
                            <div className="divCadastro">
                                <InputLabel htmlFor="modelVehicle">Fornecedores *</InputLabel>
                                <Form.Select
                                    name='provider'
                                    className="inputCadastro"
                                    required
                                >
                                    <option value='' >Selecione um fornecedor</option>
                                    {provider.map(k => {
                                        return (
                                            <option value={k['id']}>{k['name']}</option>
                                        )
                                    })}

                                </Form.Select>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="modelVehicle">Produtos *</InputLabel>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    isMulti
                                    options={optionsProducts}
                                    name='product'
                                    onChange={productHandler}
                                    required
                                />
                                <div className="productSelect1" id="productSelect1" style={{ display: 'none' }}>
                                    <div className="divProduct">
                                        <label className="labelProductSelect1" id="labelProductSelect1"></label>
                                        <input
                                            type="number"
                                            name="productSelect1"
                                            id="inputProductSelect1"
                                            className="inputCadastro"
                                            defaultValue='1'
                                        />
                                    </div>
                                </div>
                                <div className="productSelect2" id="productSelect2" style={{ display: 'none' }}>
                                    <div className="divProduct">
                                        <label className="labelProductSelect2" id="labelProductSelect2">Tipo de cliente</label>
                                        <input
                                            type="number"
                                            name="productSelect2"
                                            id="inputProductSelect2"
                                            className="inputCadastro"
                                            defaultValue='1'
                                        />
                                    </div>
                                </div>
                                <div className="productSelect3" id="productSelect3" style={{ display: 'none' }}>
                                    <div className="divProduct">
                                        <label className="labelProductSelect3" id="labelProductSelect3"></label>
                                        <input
                                            name="productSelect3"
                                            id="inputProductSelect3"
                                            className="inputCadastro"
                                            defaultValue='1'
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="modelVehicle">Pagamento a vista *</InputLabel>
                                <Form.Select
                                    name='aVista'
                                    className="inputCadastro"
                                    required
                                >
                                    <option value='' >Selecione um opção</option>
                                    <option value='yes' >Sim</option>
                                    <option value='no' >Não</option>
                                </Form.Select>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="modelVehicle"> Forma de pagamento *</InputLabel>
                                <Form.Select
                                    name='meio'
                                    className="inputCadastro"
                                    required
                                >
                                    <option value='' >Selecione um opção</option>
                                    <option value='dinheiro' >Dinheiro</option>
                                    <option value='cheque' >Cheque</option>
                                    <option value='credito' >Cartão de Crédito</option>
                                    <option value='debito' >Cartão de Débito</option>
                                    <option value='loja' >Crédito Loja</option>
                                    <option value='alimentacao' >Vale Alimentação</option>
                                    <option value='refeicao' >Vale Refeição</option>
                                    <option value='presente' >Vale Presente</option>
                                    <option value='combustivel' >Vale Combustível</option>
                                    <option value='boleto' >Boleto Bancário</option>
                                    <option value='deposito' >Depósito Bancário</option>
                                    <option value='pix' >Pagamento Instantâneo (PIX)</option>
                                    <option value='transferencia' >Transferência bancária, Carteira Digital</option>
                                    <option value='fidelidade' >Programa de fidelidade, Cashback, Crédito Virtual</option>
                                    <option value='semPagamento' >Sem pagamento</option>
                                    <option value='outros' >Outros</option>
                                </Form.Select>
                            </div>

                            <div className="buttonLogin">
                                <button className="buttonSecundary" >
                                    Gerar NFe
                                </button>
                            </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );

    }

    return (
        <>
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Notas de Produtos </h5>
                    </div>
                    <div>
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                    </div>
                </div>
                <Card className="cardContainer"  style={{ width: '100%',  borderRadius: '10px', padding: '10px' }}>
                    <TableNfe />
                </Card>
            </div >
        </>
    )
}

export default Nfe