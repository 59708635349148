import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableCupons.css'
import { Modal } from "react-bootstrap";
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from 'react-icons/ai'
import Form from 'react-bootstrap/Form';
import { TbEyeSearch } from "react-icons/tb";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: 'Código',
  },
  {
    id: 'quant',
    numeric: false,
    disablePadding: false,
    label: 'Quantidade',
  },
  {
    id: 'value',
    numeric: false,
    disablePadding: false,
    label: 'Valor',
  },
  {
    id: 'date_start',
    numeric: false,
    disablePadding: false,
    label: 'Data de inicio',
  },
  {
    id: 'date_end',
    numeric: false,
    disablePadding: false,
    label: 'Data de encerramento',
  },
  {
    id: 'whatsapp',
    numeric: false,
    disablePadding: false,
    label: 'Liberado para whatsapp',
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: false,
    label: 'Ações',
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className="colunmLabel"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([])
  const [cupons, setCupons] = React.useState([]);
  const [painelModal, setPainelModal] = React.useState(false);
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [modalCancel, setModalCancel] = React.useState(false);

  React.useEffect(() => {
    api
      .get("/TodosCupons" + '/' + currentUser.db)
      .then((response) => {
        setCupons(response.data);
        setRows(response.data.reverse().map(key => ({
          id: key['id'], name: key['name'], code: key['code'], quant: key['quant'], value: key['value'], date_start: key['date_start'], date_end: key['date_end'], whatsapp: key['whatsapp']
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleModalClose = () => {
    setPainelModal(false)
    setEditModal(false)
    setModalCancel(false)
  };

  const submitModalEdit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const dados = {
      id: idModal,
      name: data.nameCupom,
      code: data.codeCupom,
      value: data.valueCupom,
      data_start: data.dataStartCupom,
      date_end: data.dataEndCupom,
      db: currentUser.db,
      whatsapp: Number(data.whatsappCupom)
    }

    api.put("/AtualizarCupom", dados);

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const handleCancelOrdered = async (e) => {

    api.delete('/DeletarCupom/' + idModal + '/' + currentUser.db)

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalPainel(props) {
    return (
      <Modal
        show={painelModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Detalhes
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do cupom</h4>
          </div>
          {cupons.map(key => {
            if (key['id'] == idModal) {
              return (
                <Table striped bordered hover className="tableProfile">
                  <tbody>
                    <tr>
                      <td className="labelConfirm">Nome do cupom</td>
                      <td>{key['name']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Código</td>
                      <td>{key['code']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Valor</td>
                      <td>{"R$ " + key['value']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Data de início</td>
                      <td>{key['date_start']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Data de fim</td>
                      <td>{key['date_end']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Liberado para whatsapp</td>
                      <td>{key['whatsapp'] == 0 ? <AiOutlineCloseCircle className='incorreto' /> : <AiOutlineCheckCircle className='correto' />}</td>
                    </tr>
                  </tbody>
                </Table>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )

  }

  function ModalEdit(props) {
    return (
      <Modal
        show={editModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Editar cupom
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do cupom</h4>
          </div>
          {cupons.map(key => {
            if (key['id'] == idModal) {
              return (
                <form className="input" onSubmit={submitModalEdit}>
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Nome do cupom:</td>
                        <td>
                          <input
                            required
                            name="nameCupom"
                            className="inputCadastro"
                            defaultValue={key['name']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Código:</td>
                        <td>
                          <input
                            required
                            name="codeCupom"
                            className="inputCadastro"
                            defaultValue={key['code']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Valor:</td>
                        <td>
                          <input
                            required
                            name="valueCupom"
                            className="inputCadastro"
                            defaultValue={key['value']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Data de início:</td>
                        <td>
                          <input
                            required
                            name="dataStartCupom"
                            className="inputCadastro"
                            defaultValue={key['date_start']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Data de encerramento:</td>
                        <td>
                          <input
                            required
                            name="dataEndCupom"
                            className="inputCadastro"
                            defaultValue={key['date_end']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Liberarado no whatsapp:</td>
                        <td>
                          <Form.Select
                            name='whatsappCupom'
                            className="inputCadastro"
                          >
                            <option value='' >Selecione uma resosta</option>
                            <option value={1} selected={key['whatsapp'] == 1}>Ativar</option>
                            <option value={0} selected={key['whatsapp'] == 0}>Desativar</option>
                          </Form.Select>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Atualizar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalCancelar(props) {
    return (
      <Modal
        show={modalCancel}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Excluir cupom
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Você tem certeza que deseja excluir o cupom selecionado?</h4>
          <div className="cancelOrder">
            <Button onClick={handleCancelOrdered} className="cancelButton true">Sim</Button>
            <Button onClick={handleModalClose} className="cancelButton false">Não</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  const [open, setOpen] = React.useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  const columns = [
    { title: "Nome", field: "name" },
    { title: "Código", field: "code" },
    { title: "Quantidade", field: "quant" },
    { title: "Valor", field: "value" },
    { title: "Data de inicio", field: "date_start" },
    { title: "Data de encerramento", field: "date_end" },
    { title: "Liberado para whatsapp", field: "whatsapp", render: (rowData) => rowData.whatsapp == 0 ? <AiOutlineCloseCircle className='incorreto' /> : <AiOutlineCheckCircle className='correto' /> }
  ]

  return (
    <>
      <Loading />
      <ModalCancelar />
      <ModalPainel />
      <ModalEdit />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title=""
            columns={columns}
            data={rows}
            actions={[
              {
                icon: () => <TbEyeSearch className='perfilAction' />,
                tooltip: 'Detalhes',
                onClick: (event, rowData) => {
                  setPainelModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineEdit className='perfilAction' />,
                tooltip: 'Editar',
                onClick: (event, rowData) => {
                  setEditModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineDelete className='perfilAction' />,
                tooltip: 'Deletar',
                onClick: (event, rowData) => {
                  setModalCancel(true)
                  setIdModal(rowData.id)
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: false,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
          />
        </Paper>
      </Box>
    </>
  );
}
