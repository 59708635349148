import React from 'react'
import './clossingDriver.css';
import { Card} from "react-bootstrap";
import TableClosingDriver from '../TableClosingDriver'

const ClosingHistory = () => {

    return (
        <>
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Histórico do entregador</h5>
                    </div>
                </div>
                <Card className="cardContainer"  style={{ width: '100%',  borderRadius: '10px', padding: '10px' }}>
                    <TableClosingDriver />
                </Card>
            </div >
        </>
    )
}

export default ClosingHistory