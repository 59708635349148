import { useEffect, useState } from "react";
import React from 'react'
import './debt.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import TableDebt from '../TableDebt'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import { useSelector, useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { CurrencyInput } from 'react-currency-mask';
import Modal2 from '@mui/material/Modal';
import warning from '../../image/warning.png'
import trimestral from '../../image/trimestral.png'
import anual from '../../image/anual_.png'
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";

const Debt = () => {
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    const [show, setShow] = useState(false);
    const [providers, setProviders] = useState([])
    const [modalAvisoPlan, setModalAvisoPlan] = React.useState(false)
    const [typePlan, setTypePlan] = React.useState('free')

    const navigate = useNavigate();

    React.useEffect(() => {
        api
            .get("/TodosUsuariosRevenda")
            .then((response) => {
                response.data.map(key => {
                    if (key['db_name'] == currentUser.db) {
                        setTypePlan(key['module'])
                    }
                })
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    useEffect(() => {
        api
            .get('/TodosFornecedores/' + currentUser.db)
            .then(response => {
                setProviders(response.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (typePlan !== 'free') {
            setShow(true)
        } else {
            setModalAvisoPlan(true)
        }
    };

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        let categoryType = data.debtCategoryFixa !== '' ? data.debtCategoryFixa : data.debtCategoryVariavel
        const recorrenteResponse = document.getElementsByName('debtResponseRecorrente')[0].value;
        const typeDebt = document.getElementsByName('debtType')[0].value

        if (recorrenteResponse == 'no') {
            if (typeDebt == 'fixa') {
                for (var i = 1; 6 >= i; i++) {
                    let dateSplit = data.debtDueDate.split('-');
                    let month = Number(dateSplit[1]) + i - 1;
                    let year = Number(dateSplit[0])
                    let day = Number(dateSplit[2])

                    if (month < 10) {
                        month = "0" + month;
                    }
                    if (month > 12) {
                        month = "0" + (month - 12);
                        year = year + 1;
                    }

                    let date = year + '-' + month + '-' + day;

                    const dados = {
                        name: data.debtName,
                        type: data.debtType,
                        category: categoryType,
                        due_date: date,
                        provider: data.debtFornecedor,
                        value: data.debtValue,
                        status: 'Aberto',
                        obs: data.debtObs,
                        db: currentUser.db
                    }

                    api.post("/CadastrarDespesas", dados);
                }
            } else {
                const dados = {
                    name: data.debtName,
                    type: data.debtType,
                    category: categoryType,
                    due_date: data.debtDueDate,
                    provider: data.debtFornecedor,
                    value: data.debtValue,
                    status: 'Aberto',
                    obs: data.debtObs,
                    db: currentUser.db
                }

                api.post("/CadastrarDespesas", dados);
            }
        } else if (recorrenteResponse == 'yes') {
            var data1 = new Date(data.debtDueDate);
            var data2 = new Date(new Date(data.debtRecorrentDate));
            var total = (data2.getFullYear() - data1.getFullYear()) * 12 + (data2.getMonth() - data1.getMonth()) + 1;
            for (var i = 1; total >= i; i++) {
                let dateSplit = data.debtDueDate.split('-');
                let month = Number(dateSplit[1]) + i - 1;
                let year = Number(dateSplit[0])
                let day = Number(dateSplit[2])

                if (month < 10) {
                    month = "0" + month;
                }
                if (month > 12) {
                    month = "0" + (month - 12);
                    year = year + 1;
                }

                let date = year + '-' + month + '-' + day;

                const dados = {
                    name: data.debtName,
                    type: data.debtType,
                    category: categoryType,
                    due_date: date,
                    provider: data.debtFornecedor,
                    value: data.debtValue,
                    status: 'Aberto',
                    obs: data.debtObs,
                    db: currentUser.db
                }

                api.post("/CadastrarDespesas", dados);
            }
        }

        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 3000);
    }

    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const handleTypeDebt = (type) => {
        const typeSelected = type.target.value
        const divFixa = document.getElementById("debtCategoryFixa");
        const divVariavel = document.getElementById("debtCategoryVariavel");
        const selectFixa = document.getElementsByName('debtCategoryFixa')[0]
        const selectVariavel = document.getElementsByName('debtCategoryVariavel')[0]

        if (typeSelected == 'fixa') {
            divFixa.style.display = 'block'
            divVariavel.style.display = 'none'
            selectVariavel.value = ''
        } else if (typeSelected == 'variavel') {
            divVariavel.style.display = 'block'
            divFixa.style.display = 'none'
            selectFixa.value = ''
        }
    }

    const handleFornecedor = (e) => {
        const response = e.target.value;
        const divFixa = document.getElementById("debtFornecedorDiv");
        const selectFornecedor = document.getElementsByName('debtFornecedor')[0]
        if (response == 'yes') {
            divFixa.style.display = 'block'
        } else if (response == 'no') {
            divFixa.style.display = 'none'
            selectFornecedor.value = ''
        }
    }

    const handleRecorrente = (e) => {
        const response = e.target.value;
        const divFixa = document.getElementById("debtRecorrenteDiv");
        const inputQnt = document.getElementsByName('debtRecorrentDate')[0]

        if (response == 'yes') {
            divFixa.style.display = 'block'
        } else if (response == 'no') {
            divFixa.style.display = 'none'
            inputQnt.value = 1
        }
    }

    function OffCanvasExample({ ...props }) {
        return (
            <>
                <Loading />
                <Button variant="primary" onClick={handleShow} className="me-2 button">
                    {'Adicionar Despesas'}
                </Button>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header >
                        <Offcanvas.Title>Adicionar Despesas</Offcanvas.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form className="input" onSubmit={submitForm}>
                            <div className="divCadastro">
                                <InputLabel htmlFor="categoryProduct">Nome da despesa *</InputLabel>
                                <input
                                    required
                                    id="categoryProduct"
                                    name="debtName"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="categoryProduct">Tipo da despesa *</InputLabel>
                                <select className="inputCadastro" id="categoryProduct" name="debtType" onChange={handleTypeDebt}>
                                    <option value="">Selecione um tipo de despesa</option>
                                    <option value="fixa">Despesa Fixo</option>
                                    <option value="variavel">Despesa Variável</option>
                                </select>
                            </div>

                            <div className="divCadastro" id="debtCategoryFixa" style={{ display: 'none' }} >
                                <InputLabel htmlFor="categoryProduct">Categoria da despesa *</InputLabel>
                                <select className="inputCadastro" id="categoryProduct" name="debtCategoryFixa">
                                    <option value="">Selecione uma categoria de despesa</option>
                                    <option value="aguaEnergia">Contas de água e de energia elétrica</option>
                                    <option value="aluguelCondominio">Aluguel e condomínio</option>
                                    <option value="folhaPagamento">Folha de pagamento</option>
                                    <option value="prolabore">Pró-labore</option>
                                    <option value="telefoniaInternet">Telefonia e internet</option>
                                    <option value="limpeza">Material de limpeza</option>
                                    <option value="seguro">Seguro</option>
                                    <option value="parcelaEmprestimo">Parcela de empréstimos</option>
                                    <option value="vigilanciaSeguranca">Serviços de vigilância e segurança</option>
                                </select>
                            </div>

                            <div className="divCadastro" id="debtCategoryVariavel" style={{ display: 'none' }} >
                                <InputLabel htmlFor="categoryProduct">Categoria da despesa *</InputLabel>
                                <select className="inputCadastro" id="categoryProduct" name="debtCategoryVariavel" >
                                    <option value="">Selecione uma categoria de despesa</option>
                                    <option value="cmv">Custo de mercadoria vendida(CMV)</option>
                                    <option value="impostoTributoTaxa">Impostos, tributos e taxas</option>
                                    <option value="comissoes">Comissões</option>
                                    <option value="taxaCartao">Taxa administrativa de cartões de crédito e débito</option>
                                    <option value="embalagem">Embalagens</option>
                                    <option value="frete">Frete</option>
                                </select>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="categoryProduct">Data de vencimento *</InputLabel>
                                <input
                                    required
                                    type="date"
                                    id="categoryProduct"
                                    name="debtDueDate"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="categoryProduct">Valor *</InputLabel>
                                <CurrencyInput
                                    required
                                    id="categoryProduct"
                                    name="debtValue"
                                    className="inputCadastro"
                                    onChangeValue={(event, originalValue, maskedValue) => {
                                        // console.log(event, originalValue, maskedValue);
                                    }}
                                />

                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="categoryProduct">Possui fornecedor? *</InputLabel>
                                <select className="inputCadastro" id="categoryProduct" name="debtResponseFornecedor" onChange={handleFornecedor}>
                                    <option value="">Selecione uma resposta</option>
                                    <option value="yes">Sim</option>
                                    <option value="no" selected>Não</option>
                                </select>
                            </div>

                            <div className="divCadastro" id="debtFornecedorDiv" style={{ display: 'none' }} >
                                <InputLabel htmlFor="categoryProduct">Fornecedores *</InputLabel>
                                <select className="inputCadastro" id="categoryProduct" name="debtFornecedor" >
                                    <option value="">Selecione um fornecedor</option>
                                    {providers.map(key => {
                                        return (
                                            <option value={key['id']}>{key['name']}</option>
                                        )
                                    })}
                                </select>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="categoryProduct">Cobrança recorrente? *</InputLabel>
                                <select className="inputCadastro" id="categoryProduct" name="debtResponseRecorrente" onChange={handleRecorrente}>
                                    <option value="">Selecione uma resposta</option>
                                    <option value="yes">Sim</option>
                                    <option value="no" selected>Não</option>
                                </select>
                            </div>

                            <div className="divCadastro" id="debtRecorrenteDiv" style={{ display: 'none' }}>
                                <InputLabel htmlFor="categoryProduct">Repetir até a data</InputLabel>
                                <input
                                    type='date'
                                    id="categoryProduct"
                                    name="debtRecorrentDate"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="categoryProduct">Observação</InputLabel>
                                <Form.Control
                                    className="textAreaForm"
                                    as="textarea"
                                    style={{ height: '100px' }}
                                    name='debtObs'
                                />
                            </div>
                            <div className="buttonLogin">
                                <button className="buttonSecundary" >
                                    Criar despesa
                                </button>
                            </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );
    }

    const style = {
        position: 'relative',
        top: '50%',
        left: '50%',
        width: 607,
        maxHeight: 660,
        borderRadius: "39px !important",
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
    };

    function ModalAvisoPlan(props) {
        return (
            <Modal2
                open={modalAvisoPlan}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='boxModalRaioX'>
                    <div style={{ position: 'absolute', zIndex: 9, right: '-17px', top: '-10px' }}>
                        <button style={{ position: 'relative', background: 'red', color: 'white', fontWeight: 900, borderRadius: 25, width: 35, height: 35 }} onClick={() => setModalAvisoPlan(false)}>X</button>
                    </div>
                    <img src={warning} className='imgModalValidatorPage' />
                    <div className='contectModalValidatorPage'>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 32, color: '#25368C', fontWeight: 900, lineHeight: 1 }}>
                            Esta função não está disponível no plano gratuito
                        </span>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 28, fontWeight: 600, color: 'black', width: '98%', lineHeight: 1, marginTop: 10 }}>
                            Escolha um plano e desbloqueie diversas funções da plataforma
                        </span>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end', flexDirection: 'row', marginTop: 10, width: '83%' }}>
                            <img src={trimestral} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/trimestral")} />
                            <img src={anual} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/anual")} />
                        </div>
                    </div>
                </Box>
            </Modal2>
        )
    }

    return (
        <>
            <ModalAvisoPlan />
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Despesas</h5>
                    </div>
                    <div>
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                    </div>
                </div>
                <Card className="cardContainer tableDebt" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableDebt />
                </Card>
            </div >
        </>
    )
}

export default Debt