import React from 'react';
import { useState, useEffect } from 'react';
import './mentoringPlaylist.css'
import {
    Box,
    Grid,
    Stack,
    Typography
} from '@mui/material';

import { Card } from "react-bootstrap";

import AnalyticEcommerce from '../../components/cards/statistics/AnalyticEcommerce';
import MainCard from '../../components/MainCard';
import api from '../../service/api';

import {
    AppCurrentVisits,
} from '../../sections/@dashboard/app';

import { useTheme } from '@mui/material/styles';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import InputLabel from '@mui/material/InputLabel';
import CurrencyInput from 'react-currency-input-field';
import anuncion from '../../image/anuncio.png';
import { set } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import icon from '../../image/truck.png';
import InputMask from 'react-input-mask';
import logo from '../../image/islagas.png'
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { configurationTheme } from '../../redux/configurationTheme/actions'
import image_dash from '../../image/image_dash.png'
import { CiCircleChevRight } from "react-icons/ci";
import painel from '../../image/painel.png'
import app_cliente from '../../image/app-cliente.png'
import app_motorista from '../../image/app-motorista.png'
import gerar_imagem from '../../image/gerar-imagem.png'
import google_meu_negocio from '../../image/google-meu-negocio.png'
import localizacaomotorista from '../../image/localizacaomotorista.png'
import redes_sociais from '../../image/redes-sociais.png'
import pedido_whatsapp from '../../image/whatsapp.png'
import relatorioIco from '../../image/relatorios.png';
import sandra_ellipse from '../../image/sandra_ellipse.png'
import { Link, useParams } from 'react-router-dom';
import YouTube from 'react-youtube';

const MentoringPlaylist = () => {
    const theme = useTheme();

    const [ordered, setOrdered] = useState([]);
    const [saleChanell, setSaleChanell] = useState([]);
    const [driver, setDriver] = useState([]);
    const [cBusiness, setCBusiness] = useState([]);
    const [CategoryBusiness, setCategoryBusiness] = useState([]);
    const [payment, setPayment] = useState([]);
    const [sector, setSector] = useState([]);
    const [products, setProducts] = useState([]);
    const [plusGrafic, setPlusGrafic] = useState(false);
    const [plusGraficText, setplusGraficText] = useState('CARREGAR MAIS');
    const [driverLocalization, setDriverLocalization] = React.useState([]);
    const [localization, setLocalization] = React.useState([]);
    const [orderedTable, setOrderedTable] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [clientPhysicAll, setClientPhysicAll] = React.useState([]);
    const [clientBusinessAll, setClientBusinessAll] = React.useState([]);

    const [localizacao, setLocalizacao] = useState();
    const [notas, setNotas] = useState();
    const [geradorImagem, setGeradorImagem] = useState();
    const [whatsapp, setWhatsapp] = useState();
    const [relatorio, setRelatorio] = useState();
    const [appCliente, SetAppClient] = useState();
    const [marketing, setMarketing] = useState();
    const [eventTest, setEventTest] = useState();
    const [mentorias, setMentorias] = useState([]);
    const [userName, setUserName] = useState('');
    const [trainings, setTrainings] = useState([])
    const [videos, setVideos] = useState([]);
    const [listVideos, setListVideos] = useState([])
    const [typePlan, setTypePlan] = useState('')

    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    let user_id = '';
    if (currentUser !== undefined) {
        user_id = currentUser.id;
    }

    const { id } = useParams()

    useEffect(() => {
        api
            .get('/TodosCursosVideos')
            .then(response => {
                setMentorias(response.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        api
            .get('/TodosCursos')
            .then(response => {
                response.data.map(key => {
                    if (key['id'] == id) {
                        setTrainings(key['name'])
                    }
                })
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        api
            .get('/TodosVideos')
            .then(response => {
                setVideos(response.data)
            })
    }, [])

    useEffect(() => {
        let array = []
        mentorias.map(key => {
            if (key['course_id'] == id) {
                videos.map(k => {
                    if (k['id'] == key['video_id']) {
                        array.push(k)
                    }
                })
            }
        })
        setListVideos(array)
    }, [videos])
    
    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 0,
            controls: 0,
            enablejsapi: 1,
            rel: 0,
            showinfo: 0
        },
    };

    const checkElapsedTime = (e) => {
        setEventTest(e.target)
    };




    return (
        <div className='dashClubContainer' style={{ padding: 0 }}>
            {/* <div className='containerMentoriaBanner'>
                <div className='contectMentoriaBanner'>
                    <div className='divImgMentoriaBanner'>
                        <img className='imgEllipseMentoriaBanner' src={sandra_ellipse} />
                    </div>
                    <div className='divMentoriaBannerText'>
                        <span className='spanMentoriaBanner'>Mentorias da Sandra Ruiz que vão aumentar suas vendas</span>
                    </div>
                </div>
                <div className='separatorDiv'></div>
            </div> */}
            <div className='containerVideosMentorias'>
                <span className='spanTitlePlaylist'>{trainings}</span>
            </div>
            <div className='containerVideosMentorias'>
                {listVideos.map(key => {
                    return (
                        <Link className='divVideosMentoria' to={`/video/${key['id']}`}>
                            <div className='videoMentoria' >
                                <img className='videoMentoria' src={key['url_thumbnail']} />
                            </div>
                            <div className='titleVideoMentoria'>
                                <span className='spanTitleVideoMentoria'>{key['title']}</span>
                            </div>
                        </Link>
                    )
                })}

            </div>
        </div >
    );
}

export default MentoringPlaylist