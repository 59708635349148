import React from 'react';
import { useState, useEffect } from 'react';
import './dashboardClub.css'
import {
    Box,
    Grid,
    Stack,
    Typography
} from '@mui/material';

import { Card } from "react-bootstrap";

import MonthlyBarChart from './MonthlyBarChart';
import AnalyticEcommerce from '../../components/cards/statistics/AnalyticEcommerce';
import MainCard from '../../components/MainCard';
import api from '../../service/api';

import {
    AppCurrentVisits,
} from '../../sections/@dashboard/app';

import { useTheme } from '@mui/material/styles';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import InputLabel from '@mui/material/InputLabel';
import CurrencyInput from 'react-currency-input-field';
import anuncion from '../../image/anuncio.png';
import { set } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, Button } from '@mui/material'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import icon from '../../image/truck.png';
import InputMask from 'react-input-mask';
import logo from '../../image/islagas.png'
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { configurationTheme } from '../../redux/configurationTheme/actions'
import image_dash from '../../image/image_dash.png'
import { CiCircleChevRight } from "react-icons/ci";
import painel from '../../image/painel.png'
import app_cliente from '../../image/app-cliente.png'
import app_motorista from '../../image/app-motorista.png'
import gerar_imagem from '../../image/gerar-imagem.png'
import google_meu_negocio from '../../image/google-meu-negocio.png'
import localizacaomotorista from '../../image/localizacaomotorista.png'
import redes_sociais from '../../image/redes-sociais.png'
import pedido_whatsapp from '../../image/whatsapp.png'
import relatorioIco from '../../image/relatorios.png';
import banner from '../../image/se_a_profundando_na_metodoliogia.png'
import idosa from '../../image/google_gas.png'
import background_banner from '../../image/background-banner.png'
import sandra from '../../image/sandraruiz.png'
import mentorias from '../../image/mentorias.png'
import { Link, useNavigate } from 'react-router-dom';
import google_logo from '../../image/google_logo.png'
import banner_google_negocios from '../../image/banner_google_negocios.png'
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Carousel from 'react-material-ui-carousel'
import Alert from '@mui/material/Alert';
import ReactWhatsapp from 'react-whatsapp';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { FaRegArrowAltCircleRight, FaRegArrowAltCircleLeft } from "react-icons/fa";
import Tour from 'reactour'
import whatsappImg from '../../image/whatsapp_.png';
import banner1 from '../../image/slide-clube-revendedor.png'
import bannerMobile from '../../image/slide-mobilepsb-revendedores.png'
import banner2 from '../../image/automacoes_whatsapp.png'
import banner2Mobile from '../../image/whatsapp_mobile.png'

const DashboardClub = () => {
    const theme = useTheme();

    const [ordered, setOrdered] = useState([]);
    const [saleChanell, setSaleChanell] = useState([]);
    const [driver, setDriver] = useState([]);
    const [cBusiness, setCBusiness] = useState([]);
    const [CategoryBusiness, setCategoryBusiness] = useState([]);
    const [payment, setPayment] = useState([]);
    const [sector, setSector] = useState([]);
    const [products, setProducts] = useState([]);
    const [plusGrafic, setPlusGrafic] = useState(false);
    const [plusGraficText, setplusGraficText] = useState('CARREGAR MAIS');
    const [driverLocalization, setDriverLocalization] = React.useState([]);
    const [localization, setLocalization] = React.useState([]);
    const [orderedTable, setOrderedTable] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [clientPhysicAll, setClientPhysicAll] = React.useState([]);
    const [clientBusinessAll, setClientBusinessAll] = React.useState([]);

    const [localizacao, setLocalizacao] = useState();
    const [notas, setNotas] = useState();
    const [geradorImagem, setGeradorImagem] = useState();
    const [whatsapp, setWhatsapp] = useState();
    const [relatorio, setRelatorio] = useState();
    const [appCliente, SetAppClient] = useState();
    const [marketing, setMarketing] = useState();
    const [widthWindow, setWidthWindo] = React.useState(window.innerWidth)
    const [width, setWidth] = React.useState(636)
    const [width2, setWidth2] = React.useState(607)

    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);

    const [openFree, setOpenFree] = useState(false);
    const [openGoogleNegocios, setOpenGoogleNegocios] = useState(false)
    const [openRaioX, setOpenRaioX] = useState(false)
    const [openGasClick, setOpenGasClick] = useState(false)
    const [alertModal, setAlertModal] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('error');
    const [openSolicitacao, setOpenSolicitacao] = useState(false)
    const [resellerId, setResellerId] = useState('');
    const [resellerPlan, setResellerPlan] = useState('')
    const [typeSolicitation, setTypeSolicitation] = useState('')
    const [imageSlide1, setImageSlide1] = useState(banner1)
    const [imageBanner, setImageBanner] = useState(banner2);
    const [solicitationValidate, setSolicitationValidate] = useState(false)

    useEffect(() => {
        api
            .get('/TodosUsuariosRevenda')
            .then(response => {
                response.data.map(key => {
                    if (key['db_name'] == currentUser.db) {
                        setResellerId(key['id'])
                        setResellerPlan(key['module'])
                    }
                })
            }).catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        axios
            .get('https://api.clubedorevendedordegas.com.br/TodasSolicitacoes')
            .then(response => {
                response.data.map(key => {
                    if (String(key['reseller']) == String(resellerId)) {
                        if (key['request'] == 'Whatsapp') {
                            setSolicitationValidate(true)
                        }
                    }
                })
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    setInterval(() => {
        if (widthWindow < 500) {
            setWidth('90vw !important')
            setWidth2('90vw !important')
            setImageSlide1(bannerMobile)
            setImageBanner(banner2Mobile)
        } else {
            setWidth(636)
            setWidth2(607)
            setImageSlide1(banner1)
            setImageBanner(banner2)
        }
    }, 1000);

    const navigate = useNavigate();

    const slideImages = [
        {
            url: imageSlide1,
            caption: 'Slide 1'
        }
    ];

    const btnModules = [
        {
            name: 'Gestão',
            ico: painel,
            background: '#26388C',
            link: '/dashboard-gestao',
            breve: false,
            version: 'free'
        },
        {
            name: 'Pedidos no Zap',
            ico: pedido_whatsapp,
            background: '#3FAF7A',
            link: '/whatsapp',
            breve: false,
            version: 'free'
        },
        {
            name: 'App gás no clique',
            ico: app_cliente,
            background: '#26388C',
            link: '/app-gas-click',
            breve: false,
            version: 'free'
        },
        {
            name: 'App entregador tech',
            ico: app_motorista,
            background: '#3FAF7A',
            link: '/motorista-tech',
            breve: false,
            version: 'free'
        },
        {
            name: 'Onde está o entregador?',
            ico: localizacaomotorista,
            background: '#26388C',
            link: '/localization-driver',
            breve: false,
            version: 'free'
        },
        {
            name: 'Minhas artes',
            ico: gerar_imagem,
            background: '#3FAF7A',
            link: '/gerar-imagem',
            breve: false,
            version: 'free'
        },
        {
            name: 'Raio-x da rede social',
            ico: redes_sociais,
            background: '#26388C',
            link: '/raio-x',
            breve: false,
            version: 'free'
        },
        {
            name: 'Minha revenda no google',
            ico: google_meu_negocio,
            background: '#3FAF7A',
            link: '/minha-revenda-google',
            breve: false,
            version: 'free'
        },
        {
            name: 'Meu anúncio no google',
            ico: google_meu_negocio,
            background: '#26388C',
            link: '/meu-google-ads',
            breve: false,
            version: 'free'
        },
        {
            name: 'Relatórios',
            ico: relatorioIco,
            background: '#3FAF7A',
            link: '/relatorios',
            breve: false,
            version: 'free'
        },
        {
            name: 'Treinamentos',
            ico: app_cliente,
            background: '#26388C',
            link: '/mentorias',
            breve: false,
            version: 'free'
        },
    ]

    useEffect(() => {
        axios
            .get("https://api.clubedorevendedordegas.com.br/TodasAddons")
            .then((response) => {
                let addonsArray = [];
                response.data.map(addons => {
                    if (addons.name == 'Painel') {
                        addonsArray.push(JSON.parse(addons.addons))
                    }
                })
                addonsArray[0].map(key => {
                    if (key[0].addon == 'whatsapp') {
                        setWhatsapp(key[0].status)
                    } else if (key[0].addon == 'gerador de imagem') {
                        setGeradorImagem(key[0].status)
                    } else if (key[0].addon == 'app cliente') {
                        SetAppClient(key[0].status)
                    } else if (key[0].addon == 'app entregador/localizacao') {
                        setLocalizacao(key[0].status)
                    } else if (key[0].addon == 'relatorios') {
                        setRelatorio(key[0].status)
                    } else if (key[0].addon == 'notas') {
                        setNotas(key[0].status)
                    } else if (key[0].addon == 'marketing') {
                        setMarketing(key[0].status)
                    }
                })

            })

            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    const handleClose = () => {
        setOpenFree(false)
        setOpenGoogleNegocios(false)
        setOpenRaioX(false)
        setOpenGasClick(false)
        setOpenSolicitacao(false)
    }

    const style = {
        position: 'relative',
        top: '50%',
        left: '50%',
        width: width2,
        height: 555,
        borderRadius: "39px !important",
        transform: 'translate(-50%, -50%)',
        bgcolor: '#3FAF7A',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
    };

    const style2 = {
        position: 'relative',
        top: '50%',
        left: '50%',
        width: width,
        height: 770,
        borderRadius: "39px !important",
        transform: 'translate(-50%, -50%)',
        bgcolor: '#FFFFFF',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
    };

    const style3 = {
        position: 'relative',
        top: '50%',
        left: '50%',
        width: width,
        height: 200,
        borderRadius: "39px !important",
        transform: 'translate(-50%, -50%)',
        bgcolor: '#3FAF7A',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
    };


    function ModalFree(props) {
        return (
            <Modal
                open={openFree}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <img src={google_logo} className='imgModalFree' />
                    <div className='contectModalFree'>
                        <span className='textContectModalFree1'>
                            Nos 7 dias grátis de teste, não é possível configurar o
                            Minha revenda no google.
                        </span>
                        <span className='textContectModalFree2'>
                            Devido a complexidade do processo, essa configuração é realizada para membros do clube
                        </span>
                        <div className='divBtnContectModalFree'>
                            <button className='btnContectModalFree'>Quero ser membro</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        )
    }

    function ModalGoogleNegocios(props) {
        return (
            <Modal
                open={openGoogleNegocios}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <img src={banner_google_negocios} className='imgModalGoogleNegocios' />
                    <div className='contectModalGoogleNegocios'>
                        <span className='textContectModalGoogleNegocios'>
                            Preencha os formulário para dar inicio a configuração
                            do Minha revenda no google na sua revenda.
                        </span>
                        <div className='divCadastro'>
                            <InputLabel htmlFor="formatted-text-mask-input">Nome de quem será o responsável pela implantação</InputLabel>
                            <input className="inputCadastro" id="responsavelRevendaInput" />
                        </div>
                        <div className='divCadastro'>
                            <InputLabel htmlFor="formatted-text-mask-input">Telefone do responsável</InputLabel>
                            <InputMask className="inputCadastro" id="telefoneRevendaInput" mask="(99) 9 9999-9999" />
                        </div>
                        <div className='divCadastro'>
                            <InputLabel htmlFor="formatted-text-mask-input">Email do responsável</InputLabel>
                            <input className="inputCadastro" id="emailRevendaInput" />
                        </div>
                        <div className='divCadastro'>
                            <InputLabel htmlFor="formatted-text-mask-input">Porque está buscando a implantação do Minha revenda no google</InputLabel>
                            <select className="inputCadastro" id="motivoRevendaInput" >
                                <option value="">Selecione uma resposta</option>
                                <option value="valor1">Valor 1</option>
                                <option value="valor2">Valor 2</option>
                                <option value="valor3">Valor 3</option>
                            </select>
                        </div>
                        <div className='divBtnContectModalGoogleNegocios'>
                            <button className='btnContectModalGoogleNegocios' onClick={() => {
                                // handleRequest('google')
                                handleClose()
                                setAlertModal(true)
                                setMessageAlert('Sua solicitação foi enviada com sucesso')
                                setTypeAlert('success')
                                setTimeout(function () {
                                    setAlertModal(false)
                                }, 3000);
                            }}>Prosseguir <CiCircleChevRight className='icoBtnModalGoogleNegocios' /></button>
                        </div>
                    </div>
                </Box>
            </Modal>
        )
    }

    function ModalRaioX(props) {
        return (
            <Modal
                open={openRaioX}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <img src={banner_google_negocios} className='imgModalGoogleNegocios' />
                    <div className='contectModalGoogleNegocios'>
                        <span className='textContectModalGoogleNegocios'>
                            Preencha os formulário para dar inicio o Raio X das redes sociais.
                        </span>
                        <div className='divCadastro'>
                            <InputLabel htmlFor="formatted-text-mask-input">Nome de quem será o responsável pela implantação</InputLabel>
                            <input className="inputCadastro" />
                        </div>
                        <div className='divCadastro'>
                            <InputLabel htmlFor="formatted-text-mask-input">Telefone do responsável</InputLabel>
                            <InputMask className="inputCadastro" mask="(99) 9 9999-9999" />
                        </div>
                        <div className='divCadastro'>
                            <InputLabel htmlFor="formatted-text-mask-input">Email do responsável</InputLabel>
                            <input className="inputCadastro" />
                        </div>
                        <div className='divCadastro'>
                            <InputLabel htmlFor="formatted-text-mask-input">Porque está buscando a implantação do Minha revenda no google</InputLabel>
                            <select className="inputCadastro" >
                                <option value="">Selecione uma resposta</option>
                                <option value="valor1">Valor 1</option>
                                <option value="valor2">Valor 2</option>
                                <option value="valor3">Valor 3</option>
                            </select>
                        </div>
                        <div className='divBtnContectModalGoogleNegocios'>
                            <button className='btnContectModalGoogleNegocios' onClick={() => {
                                handleRequest('raio-x')
                            }}>Prosseguir <CiCircleChevRight className='icoBtnModalGoogleNegocios' /></button>
                        </div>
                    </div>
                </Box>
            </Modal>
        )
    }

    function ModalGasClick(props) {
        return (
            <Modal
                open={openGasClick}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <img src={banner_google_negocios} className='imgModalGoogleNegocios' />
                    <div className='contectModalGoogleNegocios'>
                        <span className='textContectModalGoogleNegocios'>
                            Preencha os formulário para dar inicio ao Gás no click.
                        </span>
                        <div className='divCadastro'>
                            <InputLabel htmlFor="formatted-text-mask-input">Nome de quem será o responsável pela implantação</InputLabel>
                            <input className="inputCadastro" />
                        </div>
                        <div className='divCadastro'>
                            <InputLabel htmlFor="formatted-text-mask-input">Telefone do responsável</InputLabel>
                            <InputMask className="inputCadastro" mask="(99) 9 9999-9999" />
                        </div>
                        <div className='divCadastro'>
                            <InputLabel htmlFor="formatted-text-mask-input">Email do responsável</InputLabel>
                            <input className="inputCadastro" />
                        </div>
                        <div className='divCadastro'>
                            <InputLabel htmlFor="formatted-text-mask-input">Porque está buscando a implantação do Minha revenda no google</InputLabel>
                            <select className="inputCadastro" >
                                <option value="">Selecione uma resposta</option>
                                <option value="valor1">Valor 1</option>
                                <option value="valor2">Valor 2</option>
                                <option value="valor3">Valor 3</option>
                            </select>
                        </div>
                        <div className='divBtnContectModalGoogleNegocios'>
                            <button className='btnContectModalGoogleNegocios' onClick={() => {
                                handleRequest('raio-x')
                            }}>Prosseguir <CiCircleChevRight className='icoBtnModalGoogleNegocios' /></button>
                        </div>
                    </div>
                </Box>
            </Modal>
        )
    }

    function ModalSolicitacao(props) {
        return (
            <Modal
                open={openSolicitacao}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style3}>
                    {solicitationValidate ? (
                        <>
                            <div className='contectModalGoogleNegocios'>
                                <span className='textContectModalGoogleNegocios' style={{ color: '#fff', fontWeight: 'bold', marginBottom: 10 }}>
                                    Você já solicitou!
                                </span>
                                <div className='textContectModalGoogleNegocios' style={{ color: '#fff', marginBottom: 10 }}>
                                    <span>
                                        Aguarde nosso contato
                                    </span>
                                </div>
                            </div>
                            <div className='divBtnContectModalGoogleNegocios'>
                                <button className='btnContectModalGoogleNegocios' onClick={() => {
                                    handleClose()
                                }}>Entendi <CiCircleChevRight className='icoBtnModalGoogleNegocios' /></button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='contectModalGoogleNegocios'>
                                <span className='textContectModalGoogleNegocios' style={{ color: '#fff', fontWeight: 'bold', marginBottom: 10 }}>
                                    A sua solicitação foi enviada para a nossa equipe.
                                </span>
                                <div className='textContectModalGoogleNegocios' style={{ color: '#fff', marginBottom: 10 }}>
                                    <span>
                                        Em breve um dos nossos profissionais entrará em contato
                                    </span>
                                </div>
                            </div>
                            <div className='divBtnContectModalGoogleNegocios'>
                                <button className='btnContectModalGoogleNegocios' onClick={() => {
                                    handleRequestSolicitation()
                                    handleClose()
                                    setAlertModal(true)
                                    setMessageAlert('Sua solicitação foi enviada com sucesso')
                                    setTypeAlert('success')
                                    setTimeout(function () {
                                        setAlertModal(false)
                                    }, 3000);
                                }}>Entendi <CiCircleChevRight className='icoBtnModalGoogleNegocios' /></button>
                            </div>
                        </>
                    )}
                </Box>
            </Modal>
        )
    }

    const handleRequestSolicitation = (helper) => {
        axios
            .post('https://api.clubedorevendedordegas.com.br/CadastrarSolicitacao', {
                reseller: resellerId,
                request: typeSolicitation,
                status: 'aberto',
                reseller_plan: resellerPlan
            }).then(response => {
                setSolicitationValidate(true)
            })
    }


    const handleRequest = (helper) => {
        let bodyResquestMessage = {};
        let message = '';
        axios
            .post('https://api.clubedorevendedordegas.com.br/CadastrarSolicitacao', {
                reseller_id: currentUser.id,
                request: helper,
                status: 'aberto'
            }).then(response => {
                // console.log(response.data)
                if (helper == 'raio-x') {

                } else if (helper == 'google') {
                    let responsavelRevendaInput = document.getElementById('responsavelRevendaInput').value;
                    let emailRevendaInput = document.getElementById('emailRevendaInput').value;
                    let telefoneRevendaInput = document.getElementById('telefoneRevendaInput').value;
                    let motivoRevendaInput = document.getElementById('motivoRevendaInput').value;
                    message = JSON.stringify({
                        responsavel_revenda: responsavelRevendaInput,
                        email_revenda: emailRevendaInput,
                        telefone_revenda: telefoneRevendaInput,
                        motivo_revenda: motivoRevendaInput
                    })
                    bodyResquestMessage = {
                        request_id: Number(response.data.id),
                        message: message,
                        who: 'user'
                    }
                    axios
                        .post('https://api.clubedorevendedordegas.com.br/CadastrarMensagemSolicitacao', bodyResquestMessage).then(response => {
                            // console.log(response)
                        })
                }
            })
    }

    const [currentIndex, setCurrentIndex] = useState(0);
    const goToNextSlide = () => {
        const nextIndex = (currentIndex + 1) % slideImages.length;
        setCurrentIndex(nextIndex);
    };
    const goToPreviousSlide = () => {
        const prevIndex = (currentIndex - 1 + slideImages.length) % slideImages.length;
        setCurrentIndex(prevIndex);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            goToNextSlide();
        }, 7000); // Change slide every 7 seconds

        return () => clearInterval(timer);
    }, [currentIndex]);

    return (
        <>
            <ModalSolicitacao />
            <ModalGoogleNegocios />
            <ModalFree />
            <ModalRaioX />
            <ModalGasClick />
            <Stack className='alertModal' sx={alertModal ? { display: 'block' } : { display: 'none' }} spacing={2}>
                <Alert variant="filled" severity={typeAlert}>{messageAlert}</Alert>
            </Stack>
            <div className='dashClubContainer' style={{ padding: 0 }}>
                <div className="slideshow-container">
                    <button className='btnNextPreviousSlide' onClick={goToPreviousSlide}><FaRegArrowAltCircleLeft className='iconNextPreviousSlide' /></button>
                    {slideImages.map((slide, index) =>
                    (
                        <div
                            key={index}
                            className={`slide ${index === currentIndex ? 'active' : ''}`}
                            style={{ backgroundImage: `url(${slide.url})` }}
                        >
                            <button className='btnSlide' onClick={() => {
                                // if (slide.caption == 'Slide 1') {
                                //     setOpenSolicitacao(true)
                                //     setTypeSolicitation('Aplicativo da revenda')
                                // }
                            }}></button>
                        </div>
                    ))}
                    <button className='btnNextPreviousSlide' onClick={goToNextSlide}><FaRegArrowAltCircleRight className='iconNextPreviousSlide' /></button>
                </div>
                {/* <div className='divImageDash'>
                    <img src={image_dash} className='imageDash' />
                    <Link className='btnImageDash' to="/gerar-imagem">
                        conferir
                        <CiCircleChevRight className='icoBtnImageDash' />
                    </Link>
                </div> */}
                <div className='divBtnModulesDash'>
                    {btnModules.map(key => {
                        return (
                            <Link to={key.link} className='divModuleDash' style={{ backgroundColor: key.background }}>
                                <div className='faixaBreveDiv' style={key.breve ? { display: 'flex' } : { display: 'none' }}>
                                    <span className='faixaBreveSpan'>EM BREVE</span>
                                </div>
                                <img src={key.ico} className='imgModule' />
                                <div className='divSpanNameModule'>
                                    <span className='spanNameModule'>{key.name}</span>
                                </div>
                            </Link>
                        )
                    })}
                </div>

                <div className='divBannerSolicitationReseller'>
                    <div className='containerInfosBanner'>
                        <img src={imageBanner} className='imgBannerWhatsapp' onClick={() => {
                            navigate('automacao')
                        }} />
                    </div>
                </div>
                {/* <div className='divBannerSandra'>
                    <span className='spanBannerSandra'>Mentorias da Sandra Ruiz que vão aumentar suas vendas</span>
                    <div className='div2BannerSandra' style={{ backgroundImage: `url(${background_banner})` }}>
                        <div className='divSandra'>
                            <img className='imgBannerSandra' src={sandra} />
                        </div>
                        <div className='divMentoria'>
                            <img className='imgBannerMentoria' src={mentorias} />
                            <div className='divBtnBannerSandra'>
                                <Link className='linkBannerSandra' to='/mentorias'>Aprender mais agora</Link>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='divGoogleAds'>
                    <div className='divTextsGoogleAds'>
                        <div className='div1GoogleAds'>
                            <span className='span1GoogleAds'>Vender mais GÁS todos os dias</span>
                        </div>
                        <div className='div2GoogleAds'>
                            <span className='span2GoogleAds'>Se o cliente precisa comprar gás, a primeira coisa que ele faz é pesquisar no Google.</span>
                        </div>
                        <div className='div3GoogleAds'>
                            <span className='span3GoogleAds'>Vamos colocar a sua revenda no topo das pesquisas do google?</span>
                        </div>
                        <div className='divBtnGoogleAds'>
                            <Link className='linkGoogleAds' onClick={e => setOpenGoogleNegocios(true)}>Google ADS para minha revenda</Link>
                        </div>
                    </div>
                    <div className='divImgGoogleAds'>
                        <img className='imgGoogleAds' src={idosa} />
                    </div>
                </div> */}
                {/* <div>
                    <ReactWhatsapp number="55-13-99653-2946" message="Hello World!!!" style={{ width: 100, height: 100 }} onClick={e => {
                        setTimeout(() => {
                            window.open("about:blank", "_self");
                        }, 3000);
                    }} />
                </div> */}
            </div >
        </>
    );
}

export default DashboardClub