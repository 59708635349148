import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableNfce.css'
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete, AiOutlineFilePdf, AiOutlineFileExcel } from 'react-icons/ai'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'
import { Checkbox, Select, MenuItem } from '@material-ui/core';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

export default function EnhancedTable() {
  const [rows, setRows] = React.useState([])
  const [collaborators, setCollaborators] = React.useState([]);
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [modalCancel, setModalCancel] = React.useState(false);
  const [category, setCategory] = React.useState([])

  const cnpj = '28705068000195';
  const ambiente = 'homologacao';
  const { currentAutentification } = useSelector(rootReducer => rootReducer.nuvemFiscalReducer);
  let token = '';
  if (currentAutentification !== undefined) {
    token = currentAutentification;
  }

  React.useEffect(() => {
    axios
      .get("https://api.nuvemfiscal.com.br/nfce?cpf_cnpj=" + cnpj + "&ambiente=" + ambiente + "&$top=100", {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .then((response) => {
        setRows(response.data.data.map(key => ({
          chave: key['chave'], dataEmissao: key['data_emissao'], id: key['autorizacao']['id'], status: key['status'], valor: key['valor_total'], numero: key['numero'], serie: key['serie'], referencia: key['referencia']
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  const handleModalClose = () => {
    setEditModal(false)
    setModalCancel(false)
  };

  const submitModalEdit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const headers = {
      'headers': {
        'Content-Type': 'multipart/form-data'
      }
    }

    const dataImage = {
      'image': data.imageProduct
    }

    api.post('/SalvarImagem', dataImage, headers)

    const urlImage = "https://api.clubedorevendedordegas.com.br/files/products/" + data.imageProduct.name;

    let dados = '';

    let timestampPromocional = 0;

    var timeNow = new Date().valueOf();
    var dateFormatNow = new Date(timeNow);

    if (Number(data.promotionalDateProduct) !== 0) {
      timestampPromocional = dateFormatNow.setDate(dateFormatNow.getDate() + Number(data.promotionalDateProduct));
    }

    if (data.imageProduct.name !== '') {
      dados = {
        id: idModal,
        name: data.product,
        category_id: Number(data.categoryProduct),
        value: data.valueProduct,
        promotional_value: data.promotionalValueProduct,
        promotional_date_end: timestampPromocional,
        description: data.descriptionProduct,
        stock: data.stockProduct,
        imagem: urlImage
      }
    } else {
      dados = {
        id: idModal,
        name: data.product,
        category_id: Number(data.categoryProduct),
        value: data.valueProduct,
        promotional_value: data.promotionalValueProduct,
        promotional_date_end: timestampPromocional,
        description: data.descriptionProduct,
        stock: data.stockProduct
      }
    }

    api.put("/AtualizarProduto", dados);

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 5000);
  }

  const handleCancelOrdered = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    let textoCorrecao = '';

    if (data.selectCorrecao == 'endereco') {
      textoCorrecao = 'Alterar o logradouro do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'razao') {
      textoCorrecao = 'Alterar a razão social do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'cpfCnpj') {
      textoCorrecao = 'Alterar o CPF ou CNPJ do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'telefone') {
      textoCorrecao = 'Alterar o telefone do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'email') {
      textoCorrecao = 'Alterar o e-mail do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'bairro') {
      textoCorrecao = 'Alterar o bairro do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'numero') {
      textoCorrecao = 'Alterar o numero do endereço do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'cep') {
      textoCorrecao = 'Alterar o CEP do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'cidade') {
      textoCorrecao = 'Alterar a cidade do Destinatário para ' + data.inputCorrecao;
    } else if (data.selectCorrecao == 'estado') {
      textoCorrecao = 'Alterar o estado do Destinatário para ' + data.inputCorrecao;
    }

    const headers = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }

    const url = 'https://api.nuvemfiscal.com.br/nfce/' + idModal + '/cancelamento';

    axios.post(url, data, headers)

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalEdit(props) {
    return (
      <Modal
        show={editModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Solicitar alteração da nota
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do produto</h4>
          </div>
          {collaborators.map(key => {
            let promocionalDate = 0;
            var timeNow = new Date().valueOf();
            var dateFormatNow = new Date(timeNow);
            var dateNow = dateFormatNow.getFullYear() +
              "-" + (dateFormatNow.getMonth() + 1) +
              "-" + dateFormatNow.getDate();

            if (key['id'] == idModal) {
              if (typeof key['promotional_value'] !== 'undefined') {
                if (key['promotional_value'] !== null) {
                  var timestamp = Number(key['promotional_date_end']);
                  var dateFormat = new Date(timestamp);

                  var datePromocional = dateFormat.getFullYear() +
                    "-" + (dateFormat.getMonth() + 1) +
                    "-" + dateFormat.getDate();

                  const diffInMs = new Date(datePromocional) - new Date(dateNow)
                  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

                  promocionalDate = parseInt(diffInDays);
                }
              }
              return (
                <form className="input" onSubmit={submitModalEdit} enctype="multipart/form-data">
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Produto:</td>
                        <td>
                          <input
                            required
                            name="product"
                            className="inputCadastro"
                            defaultValue={key['name']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Categoria:</td>
                        <td>
                          <Form.Select
                            name='categoryProduct'
                            className="inputCadastro"
                          >
                            <option value='' >Selecione um colaborador</option>
                            {category.map(k => {
                              return (
                                <option value={k['id']} selected={key['category_id'] == k['id']}>{k['name']}</option>
                              )
                            })}
                          </Form.Select>
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Valor:</td>
                        <td>
                          <input
                            required
                            name="valueProduct"
                            className="inputCadastro"
                            defaultValue={key['value']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Valor promocional:</td>
                        <td>
                          <input
                            name="promotionalValueProduct"
                            className="inputCadastro"
                            defaultValue={key['promotional_value']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Dias de promoção:</td>
                        <td>
                          <input
                            name="promotionalDateProduct"
                            className="inputCadastro"
                            defaultValue={promocionalDate}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Descrição:</td>
                        <td>
                          <input
                            required
                            name="descriptionProduct"
                            className="inputCadastro"
                            defaultValue={key['description']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Estoque:</td>
                        <td>
                          <input
                            required
                            name="stockProduct"
                            className="inputCadastro"
                            defaultValue={key['stock']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Imagem:</td>
                        <td>
                          <img src={key['imagem']} className="imgTable" />
                          <input
                            type="file"
                            name="imageProduct"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Atualizar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalCancelar(props) {
    return (
      <Modal
        show={modalCancel}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Solicitar cancelamento da nota
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <form className="input" onSubmit={handleCancelOrdered} enctype="multipart/form-data">
            <Table striped bordered hover className="tableProfile">
              <tbody>
                <tr>
                  <td className="labelConfirm">Justificativa:</td>
                  <td>
                    <Form.Select
                      required
                      name='justificativa'
                      className="inputCadastro"
                    >
                      <option value='' >Selecione uma justificativa</option>
                      <option value='Erro na digitação do valor total da nota fiscal' >Erro na digitação do valor total da nota fiscal</option>
                      <option value='Produto entregue em desacordo com o pedido do cliente' >Produto entregue em desacordo com o pedido do cliente</option>
                      <option value='Duplicidade de emissão da nota fiscal' >Duplicidade de emissão da nota fiscal</option>
                      <option value='Insatisfação do cliente com os produtos ou serviços prestados' >Insatisfação do cliente com os produtos ou serviços prestados</option>
                      <option value='Produto danificado durante o transporte ou armazenamento' >Produto danificado durante o transporte ou armazenamento</option>
                    </Form.Select>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="buttonLogin">
              <button className="buttonSecundary" >
                Solicitar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  const [open, setOpen] = React.useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  const columns = [
    { title: "Destinatario", field: "destinatario" },
    { title: "Valor", field: "valor" },
    { title: "Data de Emissão", field: "emissao" },
    { title: "Data de Autorização", field: 'dataAutorizacao' },
    { title: "Número", field: 'numero' },
    { title: "Serie", field: 'serie' },
    { title: "Status", field: 'status' },
  ]

  rows.forEach(item => {
    if (item.destinatario == null) {
      item.destinatario = "Ainda não informado"
    }
    if (item.dataAutorizacao == null) {
      item.dataAutorizacao = "Ainda não informado"
    }
    if (item.numero == null) {
      item.numero = "Ainda não informado"
    }
    if (item.serie == null) {
      item.serie = "Ainda não informado"
    }

    var dateISO = new Date(item.dataEmissao);
    var dia = dateISO.getDate().toString();
    var diaF = (dia.length == 1) ? '0' + dia : dia;
    var mes = (dateISO.getMonth() + 1).toString();
    var mesF = (mes.length == 1) ? '0' + mes : mes;
    var anoF = dateISO.getFullYear();
    item.dataEmissao = diaF + "/" + mesF + "/" + anoF;

    if (typeof item.valor === 'number') {
      item.valor = 'R$ ' + item.valor.toFixed(2).replace(".", ",");
    }

    if (item.status == 'rejeitado') {
      item.status = 'Rejeitado';
    } else if (item.status == 'autorizado') {
      item.status = 'Autorizado';
    }
  })

  return (
    <>
      <Loading />
      <ModalCancelar />
      <ModalEdit />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title="Produtos"
            columns={columns}
            data={rows}
            actions={[
              {
                icon: () => <AiOutlineFilePdf className='perfilAction' />,
                tooltip: 'PDF',
                onClick: (event, rowData) => {
                  var request = new XMLHttpRequest();
                  request.open("GET", "https://api.nuvemfiscal.com.br/nfe/eventos/" + rowData.id + "/pdf", true);
                  request.setRequestHeader('Authorization', 'Bearer ' + token)
                  request.responseType = "blob";
                  request.onload = function (e) {
                    if (this.status === 200) {
                      // `blob` response
                      // create `objectURL` of `this.response` : `.pdf` as `Blob`
                      var file = window.URL.createObjectURL(this.response);
                      var a = document.createElement("a");
                      a.href = file;
                      a.download = this.response.name || "nfe-" + (+new Date());
                      document.body.appendChild(a);
                      a.click();
                      // remove `a` following `Save As` dialog, 
                      // `window` regains `focus`
                      // window.onfocus = function () {
                      //   document.body.removeChild(a)
                      // }
                    };
                  };
                  request.send();
                }
              },
              {
                icon: () => <AiOutlineFileExcel className='perfilAction' />,
                tooltip: 'XML',
                onClick: (event, rowData) => {
                  var request = new XMLHttpRequest();
                  request.open("GET", "https://api.nuvemfiscal.com.br/nfe/eventos/" + rowData.id + "/xml", true);
                  request.setRequestHeader('Authorization', 'Bearer ' + token)
                  request.responseType = "blob";
                  request.onload = function (e) {
                    if (this.status === 200) {
                      // `blob` response
                      // create `objectURL` of `this.response` : `.pdf` as `Blob`
                      var file = window.URL.createObjectURL(this.response);
                      var a = document.createElement("a");
                      a.href = file;
                      // a.target = '_blank';
                      a.download = this.response.name || "nfe-" + (+new Date());
                      document.body.appendChild(a);
                      a.click();
                      // remove `a` following `Save As` dialog, 
                      // `window` regains `focus`
                      // window.onfocus = function () {
                      //   document.body.removeChild(a)
                      // }
                    };
                  };
                  request.send();
                }
              },
              {
                icon: () => <AiOutlineDelete className='perfilAction' />,
                tooltip: 'Deletar',
                onClick: (event, rowData) => {
                  setModalCancel(true)
                  setIdModal(rowData.id)
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: false,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
          />
        </Paper>
      </Box>
    </>
  );
}
