import { useState, useEffect } from "react";
import React from 'react'
import './automation.css';
import { Card } from "react-bootstrap";
import api from "../../service/api";
import TableAutomation from '../TableAutomation'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import { useSelector, useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Modal } from "react-bootstrap";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Modal2 from '@mui/material/Modal';
import warning from '../../image/warning.png'
import trimestral from '../../image/trimestral.png'
import anual from '../../image/anual_.png'
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";

const Automation = () => {
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    const [openModalCreate, setOpenModalCreate] = useState(false);
    const [automations, setAutomations] = React.useState(0)
    const [modalAvisoPlan, setModalAvisoPlan] = React.useState(false)
    const [typePlan, setTypePlan] = React.useState('free')

    const navigate = useNavigate();
    
    const handleModalClose = () => {
        setOpenModalCreate(false)
    };

    React.useEffect(() => {
        api
            .get("/TodosUsuariosRevenda")
            .then((response) => {
                response.data.map(key => {
                    if (key['db_name'] == currentUser.db) {
                        setTypePlan(key['module'])
                    }
                })
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    function ModalCreateAutomation(props) {
        return (
            <Modal
                show={openModalCreate}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleModalClose}
            >
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Configuração de mensagem automática
                    </Modal.Title>
                    <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
                </Modal.Header>
                <Modal.Body className="cancelBody">
                    <form className="inputNotificationMessage" onSubmit={submitForm}>
                        <div className="divModalCreateAutomation">
                            <FormControl sx={{ m: 1 }} className='selectFormPayment' style={{ width: '38.7vw' }}>
                                <InputLabel id="demo-multiple-name-label">Tipo de automação</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="typeCreateAutomation"
                                    required
                                >

                                    <MenuItem
                                        key={'comunicacao'}
                                        value={'comunicacao'}
                                    >
                                        {'Automação de Comunicação'}
                                    </MenuItem>
                                    <MenuItem
                                        key={'pesquisa'}
                                        value={'pesquisa'}
                                    >
                                        {'Automação de Pesquisa'}
                                    </MenuItem>

                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1 }} className='selectFormPayment' style={{ width: '38.7vw' }}>
                                <InputLabel id="demo-multiple-name-label">Publicação</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    required
                                    name="publicCreateAutomation"
                                >

                                    <MenuItem
                                        key={'30'}
                                        value={'30'}
                                    >
                                        {'Clientes que compraram em 30 dias'}
                                    </MenuItem>
                                    <MenuItem
                                        key={'60'}
                                        value={'60'}
                                    >
                                        {'Clientes que compraram em 60 dias'}
                                    </MenuItem>
                                    <MenuItem
                                        key={'aniversario'}
                                        value={'aniversario'}
                                    >
                                        {'Aniversariantes'}
                                    </MenuItem>
                                    {/* <MenuItem
                                        key={'semanaAniversario'}
                                        value={'semanaAniversario'}
                                    >
                                        {'Semana de aniversário'}
                                    </MenuItem> */}
                                </Select>
                            </FormControl>
                        </div>

                        <div className="divModalCreateAutomation">
                            <FormControl sx={{ m: 1 }} className='selectFormPayment' style={{ width: '38.7vw' }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Nome da automação"
                                    className='divInputEmail'
                                    style={{ width: '100%', margin: 0 }}
                                    name="nameCreateAutomation"
                                />
                            </FormControl>

                            {/* <FormControl sx={{ m: 1 }} className='selectFormPayment' style={{ width: '38.7vw' }}>
                                <InputLabel id="demo-multiple-name-label">Repetir para a mesma pessoa</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    required
                                    name="reptyCreateAutomation"
                                >

                                    <MenuItem
                                        key={'nunca'}
                                        value={'nunca'}
                                    >
                                        {'Nunca'}
                                    </MenuItem>
                                    <MenuItem
                                        key={'sempre'}
                                        value={'sempre'}
                                    >
                                        {'Sempre'}
                                    </MenuItem>
                                    <MenuItem
                                        key={'apos1'}
                                        value={'apos1'}
                                    >
                                        {'Após 1..'}
                                    </MenuItem>
                                    <MenuItem
                                        key={'apos2'}
                                        value={'apos2'}
                                    >
                                        {'Após 2..'}
                                    </MenuItem>
                                </Select>
                            </FormControl> */}
                        </div>

                        <div className="divModalCreateAutomation" style={{ flexDirection: 'column', alignItems: 'start', marginLeft: 15 }}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Mensagem que será enviada"
                                multiline
                                rows={7}
                                className='divInputEmail'
                                defaultValue="Escreva sua mensagem"
                                style={{ width: '99%', margin: "10px 0", height: '100%' }}
                                name="messageCreateAutomation"
                            />
                            <small style={{ color: '#717171' }}>{`Para inserir o nome do cliente insira isso na mensagem : {nome_do_cliente}`} </small>
                        </div>

                        <button className="buttonSecundary">
                            Enviar
                        </button>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            api
                .get('/TodasAutomacoes')
                .then(response => {
                    var array = []
                    response.data.map(key => {
                        if (key.db_name == currentUser.db) {
                            if (key['type_automation'] == 'user') {
                                array.push(key)
                            }
                        }
                    })
                    setAutomations(array.length)
                })
                .catch(err => {
                    console.log(err)
                })
        }, 5000);
        return () => clearInterval(interval);
    }, [])

    React.useEffect(() => {
        api
            .get('/TodasAutomacoes')
            .then(response => {
                var array = []
                response.data.map(key => {
                    if (key.db_name == currentUser.db) {
                        if (key['type_automation'] == 'user') {
                            array.push(key)
                        }
                    }
                })
                setAutomations(array.length)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])


    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        const dados = {
            name: data.nameCreateAutomation,
            type: data.typeCreateAutomation,
            public: data.publicCreateAutomation,
            message: data.messageCreateAutomation,
            activate: 'Ativado',
            repeat: 'nunca',
            type_automation: 'user',
            db_name: currentUser.db
        }

        api.post("/CadastrarAutomacao", dados);

        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 3000);
    }

    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const style = {
        position: 'relative',
        top: '50%',
        left: '50%',
        width: 607,
        maxHeight: 660,
        borderRadius: "39px !important",
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
    };

    function ModalAvisoPlan(props) {
        return (
            <Modal2
                open={modalAvisoPlan}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='boxModalRaioX'>
                    <div style={{ position: 'absolute', zIndex: 9, right: '-17px', top: '-10px' }}>
                        <button style={{ position: 'relative', background: 'red', color: 'white', fontWeight: 900, borderRadius: 25, width: 35, height: 35 }} onClick={() => setModalAvisoPlan(false)}>X</button>
                    </div>
                    <img src={warning} className='imgModalValidatorPage' />
                    <div className='contectModalValidatorPage'>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 32, color: '#25368C', fontWeight: 900, lineHeight: 1 }}>
                            Essa função não está disponível no plano gratuito
                        </span>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 28, fontWeight: 600, color: 'black', width: '98%', lineHeight: 1, marginTop: 10 }}>
                            Escolha um plano e desbloqueie diversas funções da plataforma
                        </span>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end', flexDirection: 'row', marginTop: 10, width: '83%' }}>
                        <img src={trimestral} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/trimestral")} />
                        <img src={anual} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/anual")} />
                        </div>
                    </div>
                </Box>
            </Modal2>
        )
    }


    return (
        <>
            <ModalAvisoPlan />
            <ModalCreateAutomation />
            <Loading />
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Minhas automações</h5>
                    </div>
                    {automations < 4 ? (
                        <div>
                            <button className="me-2 button" onClick={e => {
                                if (typePlan !== 'free') {
                                    setOpenModalCreate(true)
                                } else {
                                    setModalAvisoPlan(true)
                                }
                            }}>
                                Criar automação
                            </button>
                        </div>
                    ) : ('')}

                </div>
                <Card className="cardContainer" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableAutomation />
                </Card>
            </div >
        </>
    )
}

export default Automation