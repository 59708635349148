import { useState } from "react";
import React from 'react'
import './nfse.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import Form from 'react-bootstrap/Form';
import TableNfse from '../TableNfce'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import axios from "axios";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { letterSpacing } from "@mui/system";
import { setTranslate3d } from "rsuite/esm/List/helper/utils";
import { useSelector, useDispatch } from 'react-redux';

const Nfse = () => {

    const [clientPhysic, setClientPhysic] = React.useState([]);
    const [clientBusiness, setClientBusiness] = React.useState([]);
    const [filteredData, setFilteredData] = React.useState([]);
    const [ordereds, setOrdereds] = React.useState([]);
    const [nextStatus, setNextStatus] = React.useState(false);
    const [physicRows, setPhysicRows] = React.useState([]);
    const [businessRows, setBusinessRows] = React.useState([]);
    const [cepApi, setCepApi] = React.useState([]);
    const [listToApi, setListToApi] = React.useState([]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const animatedComponents = makeAnimated();

    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    let db = 'clubeApi';
    if (currentUser !== undefined || currentUser !== null) {
        db = currentUser.db
    }

    React.useEffect(() => {
        api
            .get("/TodosPedidos2" + '/' + currentUser.db)
            .then((response) => {
                setOrdereds(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    React.useEffect(() => {
        api
            .get("/TodosClientesFisicos")
            .then((response) => {
                setClientPhysic(response.data.map(key => ({
                    id: key['id'], type: 'fisico', street: key['street'], number: key['number'], complement: key['complement'], city: key['city'], state: key['state'], neighborhood: key['neighborhood'], cpf: key['cpf'], email: key['email'], razao: key['name'], name: key['name']
                })));
                setPhysicRows(response.data.map(key => ({
                    value: key['id'], label: key['name'] + " - " + "Cliente Físico"
                })))
                setNextStatus(true)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    React.useEffect(() => {
        api
            .get("/TodosClientesJuridicos")
            .then((response) => {
                setClientBusiness(response.data.map(key => ({
                    id: key['id'], type: 'juridico', street: key['street'], number: key['number'], complement: key['complement'], city: key['city'], state: key['state'], neighborhood: key['neighborhood'], cpf: key['cnpj'], email: key['email'], razao: key['razao'], name: key['name']
                })))
                setBusinessRows(response.data.map(key => ({
                    value: key['id'], label: key['name'] + " - " + "Cliente Jurídico"
                })))
                setFilteredData(physicRows.concat(businessRows));
                setListToApi(clientPhysic.concat(clientBusiness));
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, [nextStatus]);

    React.useEffect(() => {
        let array = [];
        listToApi.map(key => {
            let enderecoCompleto = key['street'];
            let estado = key['state'];
            let cidade = key['city'];
            let estadoApi = '';

            if (estado == "Acre") {
                estadoApi = "AC";
            } else if (estado == "Alagoas") {
                estadoApi = "AL";
            } else if (estado == "Amapá") {
                estadoApi = "AP";
            } else if (estado == "Amazonas") {
                estadoApi = "AM";
            } else if (estado == "Bahia") {
                estadoApi = "BA";
            } else if (estado == "Ceará") {
                estadoApi = "CE";
            } else if (estado == "Destrito Federal" || estado == "Brasília") {
                estadoApi = "DF";
            } else if (estado == "Espírito Santo") {
                estadoApi = "ES";
            } else if (estado == "Goiás") {
                estadoApi = "GO";
            } else if (estado == "Maranhão") {
                estadoApi = "MA";
            } else if (estado == "Mato Grosso") {
                estadoApi = "MT";
            } else if (estado == "Mato Grosso do Sul") {
                estadoApi = "MS";
            } else if (estado == "Minas Gerais") {
                estadoApi = "MG";
            } else if (estado == "Pará") {
                estadoApi = "PA";
            } else if (estado == "Paraíba") {
                estadoApi = "PB";
            } else if (estado == "Paraná") {
                estadoApi = "PR";
            } else if (estado == "Pernambuco") {
                estadoApi = "PE";
            } else if (estado == "Piauí") {
                estadoApi = "PI";
            } else if (estado == "Rio de Janeiro") {
                estadoApi = "RJ";
            } else if (estado == "Rio Grande do Norte") {
                estadoApi = "RN";
            } else if (estado == "Rio Grande do Sul") {
                estadoApi = "RS";
            } else if (estado == "Rondônia") {
                estadoApi = "RO";
            } else if (estado == "Roraima") {
                estadoApi = "RR";
            } else if (estado == "Santa Catarina") {
                estadoApi = "SC";
            } else if (estado == "São Paulo") {
                estadoApi = "SP";
            } else if (estado == "Sergipe") {
                estadoApi = "SE";
            } else if (estado == "Tocantins") {
                estadoApi = "TO";
            } else {
                estadoApi = "SP";
            }

            axios
                .get("https://viacep.com.br/ws/" + estadoApi + "/" + cidade + "/" + enderecoCompleto + "/json/")
                .then((response) => {
                    if (response.status == 200) {
                        response.data.map(k => {
                            array.push({ id: key['id'], type: key['type'], cep: k['cep'], ibge: k['ibge'], uf: k['uf'] });
                        })
                    }
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        })
        setCepApi(array);
    }, [listToApi])

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        const headers = {
            'headers': {
                'X-API-KEY': '2da392a6-79d2-4304-a8b7-959572c7e44d'
            }
        }

        const client_id = Number(data['clients']);
        const ordered_id = Number(data['ordered']);
        let city = '';
        let state = '';
        let bairro = '';
        let number = '';
        let email = '';
        let razao = '';
        let complement = '';
        let cep = '';
        let street = '';
        let name = '';
        let cpfCnpj = '';
        let typeLogradouro = '';
        let codCity = '';
        let description = '';
        let value = '';

        if (clientSelectedType == 'Cliente Físico') {
            clientPhysic.map(key => {
                if (key['id'] == client_id) {
                    city = key['city'];
                    street = key['street'];
                    bairro = key['neighborhood'];
                    number = key['number'];
                    if (key['complement'] == '' || key['complement'] == null) {
                        complement = "";
                    } else {
                        complement = key['complement'];
                    }
                    email = key['email'];
                    razao = key['name'];
                    name = key['name'];
                    cpfCnpj = key['cpf'];
                    let logradouro = key['street'].split(" ");
                    typeLogradouro = logradouro[0];
                }
            })
        } else if (clientSelectedType == 'Cliente Jurídico') {
            clientBusiness.map(key => {
                if (key['id'] == client_id) {

                }
            })
        }

        cepApi.map(key => {
            if (key['type'] == 'fisico') {
                if (key['id'] == client_id) {
                    cep = key['cep'];
                    state = key['uf'];
                    codCity = key['ibge'];
                }
            } else if (key['type'] == 'juridico') {
                if (key['id'] == client_id) {
                    cep = key['cep'];
                    state = key['uf'];
                    codCity = key['ibge'];
                }
            }
        })

        ordereds.map(key => {
            if (key['id'] == ordered_id) {
                value = key['total'];
                description = key['order_date'] + " / " + key['products'] + " / " + "R$ " + key['total']
            }
        })

        const dados = [
            {
                "idIntegracao": "ZZZ1234563",
                "prestador": {
                    "cpfCnpj": "08187168000160"
                },
                "tomador": {
                    "cpfCnpj": cpfCnpj,
                    "razaoSocial": razao,
                    "endereco": {
                        "descricaoCidade": city,
                        "cep": cep,
                        "tipoLogradouro": typeLogradouro,
                        "logradouro": street,
                        "codigoCidade": codCity,
                        "complemento": complement,
                        "estado": state,
                        "numero": number,
                        "bairro": bairro
                    }
                },
                "servico": [
                    {
                        "codigo": "7.21",
                        "discriminacao": description,
                        "iss": {
                            "tipoTributacao": 7,
                            "exigibilidade": 1,
                            "aliquota": 3
                        },
                        "valor": {
                            "servico": Number(value),
                        }
                    }
                ]
            }
        ]


        axios.post("https://api.sandbox.plugnotas.com.br/nfse", dados, headers);

        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 5000);
    }

    const [open, setOpen] = useState(false);

    let clientSelectedId = "";
    let clientSelectedType = "";

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }
    function clienteHandle(e) {
        var divClientSlected = document.getElementById('clientSelected')
        var textClientSelectedId = document.getElementById('ClientSelectedId')
        var option = e;
        var labelSplit = option['label'].split(" - ");
        textClientSelectedId.value = e['value'];
        clientSelectedType = labelSplit[1]

        divClientSlected.style.display = "block"
    }

    function OffCanvasExample({ ...props }) {
        return (
            <>
                <Loading />
                <input type="hidden" id="ClientSelectedId" value="9" />
                <Button variant="primary" onClick={handleShow} className="me-2 button">
                    {'Nova Nota de Serviço'}
                </Button>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header >
                        <Offcanvas.Title>Nova Nota de Serviço</Offcanvas.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form className="input" onSubmit={submitForm}>
                            <div className="divCadastro">
                                <InputLabel htmlFor="modelVehicle">Clientes *</InputLabel>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    options={filteredData}
                                    onChange={clienteHandle}
                                    name='clients'
                                    required
                                />
                            </div>
                            <div className="divCadastro" id="clientSelected" style={{ display: 'none' }}>
                                <InputLabel htmlFor="modelVehicle">Pedido *</InputLabel>
                                <Form.Select
                                    name='ordered'
                                    className="inputCadastro"
                                >
                                    <option value='' >Selecione uma pedido</option>
                                    {ordereds.map(key => {
                                        var idCliente = document.getElementById('ClientSelectedId').value;
                                        if (key['client_id'] == idCliente) {
                                            return (
                                                <option value={key['id']}>{key['order_date'] + " / " + key['products'] + " / " + "R$ " + key['total']}</option>
                                            )
                                        }
                                    })}
                                </Form.Select>
                            </div>

                            <div className="buttonLogin">
                                <button className="buttonSecundary" >
                                    Gerar NFSe
                                </button>
                            </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );

    }

    return (
        <>
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Notas de Serviços</h5>
                    </div>
                    <div>
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                    </div>
                </div>
                <Card className="cardContainer" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableNfse />
                </Card>
            </div >
        </>
    )
}

export default Nfse