import { useState } from "react";
import React from 'react'
import './resellerProfile.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import TableResellerProfile from '../TableResellerProfile'
import InputLabel from '@mui/material/InputLabel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputMask from 'react-input-mask';
import { useSelector, useDispatch } from 'react-redux';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const ResellerProfile = () => {

    const [show, setShow] = useState(false);
    const [revenda, setRevenda] = useState('');
    const [tipoInstalacao, setTipoInstalacao] = useState('');
    const [distribuidora, setDistribuidora] = useState('')
    const [email, setEmail] = useState('')
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };

    React.useEffect(() => {
        api.get('/TodosUsuariosRevenda')
            .then(response => {
                response.data.map(key => {
                    if (key['db_name'] == currentUser.db) {
                        setRevenda(key.reseller_name)
                        setTipoInstalacao(key.reseller_class)
                        setDistribuidora(key.type_reseller)
                        setEmail(key.email)
                    }
                })
            })
    }, [])

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        let operationWeek = data.segSexOpen + ' - ' + data.segSexClose
        let operationWeekend = data.fimSemOpen + ' - ' + data.fimSemClose
        let operationHoliday = data.domFerOpen + ' - ' + data.domFerClose

        api.post("/CadastrarPerfilRevenda", {
            name: data.rosillerProfile,
            installation_type: data.installationType,
            distributor: data.distributor,
            capacity_kg: Number(data.capacityKg),
            registry: data.registry,
            cep: data.cep,
            address: data.address,
            number: data.number,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            phone: data.phone,
            whatsapp: data.whatsapp,
            email: data.email,
            site: data.site,
            social_media: data.social_media,
            operation_week: operationWeek,
            operation_weekend: operationWeekend,
            operation_holiday: operationHoliday,
            db: currentUser.db
        });
        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 3000);
    }

    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const checkCEP = (e) => {
        const cep = e.target.value.replace(/\D/g, '');
        if (cep.length === 8) {
            fetch(`https://viacep.com.br/ws/${cep}/json/`)
                .then(res => res.json()).then(data => {
                    let estadoApi = '';
                    if (data.uf == "AC") {
                        estadoApi = "Acre";
                    } else if (data.uf == "AL") {
                        estadoApi = "Alagoas";
                    } else if (data.uf == "AP") {
                        estadoApi = "Amapá";
                    } else if (data.uf == "AM") {
                        estadoApi = "Amazonas";
                    } else if (data.uf == "BA") {
                        estadoApi = "Bahia";
                    } else if (data.uf == "CE") {
                        estadoApi = "Ceará";
                    } else if (data.uf == "DF") {
                        estadoApi = "Destrito Federal";
                    } else if (data.uf == "ES") {
                        estadoApi = "Espírito Santo";
                    } else if (data.uf == "GO") {
                        estadoApi = "Goiás";
                    } else if (data.uf == "MA") {
                        estadoApi = "Maranhão";
                    } else if (data.uf == "MT") {
                        estadoApi = "Mato Grosso";
                    } else if (data.uf == "MS") {
                        estadoApi = "Mato Grosso do Sul";
                    } else if (data.uf == "MG") {
                        estadoApi = "Minas Gerais";
                    } else if (data.uf == "PA") {
                        estadoApi = "Pará";
                    } else if (data.uf == "PB") {
                        estadoApi = "Paraíba";
                    } else if (data.uf == "PR") {
                        estadoApi = "Paraná";
                    } else if (data.uf == "PE") {
                        estadoApi = "Pernambuco";
                    } else if (data.uf == "PI") {
                        estadoApi = "Piauí";
                    } else if (data.uf == "RJ") {
                        estadoApi = "Rio de Janeiro";
                    } else if (data.uf == "RN") {
                        estadoApi = "Rio Grande do Norte";
                    } else if (data.uf == "RS") {
                        estadoApi = "Rio Grande do Sul";
                    } else if (data.uf == "RO") {
                        estadoApi = "Rondônia";
                    } else if (data.uf == "RR") {
                        estadoApi = "Roraima";
                    } else if (data.uf == "SC") {
                        estadoApi = "Santa Catarina";
                    } else if (data.uf == "SP") {
                        estadoApi = "São Paulo";
                    } else if (data.uf == "SE") {
                        estadoApi = "Sergipe";
                    } else if (data.uf == "TO") {
                        estadoApi = "Tocantins";
                    }

                    document.getElementById("address").value = data.logradouro
                    document.getElementById("neighborhood").value = data.bairro
                    document.getElementById("city").value = data.localidade
                    document.getElementById("state").value = estadoApi
                })
        }

    }

    const classes = [
        "Classe I",
        "Classe II",
        "Classe III",
        "Classe IV",
        "Classe V",
        "Classe VI",
        "Classe VII",
        "Especial"
    ]

    const gas = [
        'Butano',
        'Consigaz',
        'Copagaz',
        'Liquigás',
        'Nascionalgás',
        'Ultragás',
        'Servigás',
        'Supergasbras',
        'Multimarcas',
    ];

    function OffCanvasExample({ ...props }) {
        return (
            <>
                <Loading />
                <Button variant="primary" onClick={handleShow} className="me-2 button third-step">
                    {'Cadastrar'}
                </Button>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header>
                        <Offcanvas.Title>Perfil de revenda</Offcanvas.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form className="input" onSubmit={submitForm}>

                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Revenda *</InputLabel>
                                <input
                                    required
                                    id="formatted-text-mask-input"
                                    name="rosillerProfile"
                                    className="inputCadastro"
                                    defaultValue={revenda}
                                />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Classe da sua revenda *</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    value={tipoInstalacao}
                                    name="installationType"
                                    className="inputCadastro"
                                    onChange={e => {
                                        setTipoInstalacao(e.target.value)
                                    }}
                                >
                                    {classes.map((name) => (
                                        <MenuItem
                                            key={name}
                                            value={name}
                                            selected={tipoInstalacao == name ? true : false}
                                        >
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Tipo de revenda *</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    value={distribuidora}
                                    name="distributor"
                                    className="inputCadastro"
                                    onChange={e => {
                                        setDistribuidora(e.target.value)
                                    }}
                                >
                                    {gas.map((name) => (
                                        <MenuItem
                                            key={name}
                                            value={name}
                                            selected={distribuidora == name ? true : false}
                                        >
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Capacidade por quilo *</InputLabel>
                                <input
                                    required
                                    id="formatted-text-mask-input"
                                    name="capacityKg"
                                    className="inputCadastro"
                                />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Registro *</InputLabel>
                                <input
                                    required
                                    id="formatted-text-mask-input"
                                    name="registry"
                                    className="inputCadastro"
                                />
                            </div>
                            <div style={{ margin: '10px 0' }}>
                                <span style={{ fontWeight: 'bold' }}>Horário de funcionamento</span>
                            </div>
                            <InputLabel htmlFor="formatted-text-mask-input">Segunda à Sexta *</InputLabel>
                            <div className="divCadastro" style={{ display: 'flex' }}>
                                <div style={{ marginRight: 5 }}>
                                    <InputLabel htmlFor="formatted-text-mask-input">Abertura *</InputLabel>
                                    <InputMask className="inputCadastro" id="segSexOpen" mask="99:99" name="segSexOpen" required />
                                </div>
                                <div style={{ marginLeft: 5 }}>
                                    <InputLabel htmlFor="formatted-text-mask-input">Fechamento *</InputLabel>
                                    <InputMask className="inputCadastro" id="segSexClose" mask="99:99" name="segSexClose" required />
                                </div>

                            </div>
                            <InputLabel htmlFor="formatted-text-mask-input">Fim de semana *</InputLabel>
                            <div className="divCadastro" style={{ display: 'flex' }}>
                                <div style={{ marginRight: 5 }}>
                                    <InputLabel htmlFor="formatted-text-mask-input">Abertura *</InputLabel>
                                    <InputMask className="inputCadastro" id="fimSemOpen" mask="99:99" name="fimSemOpen" required />
                                </div>
                                <div style={{ marginLeft: 5 }}>
                                    <InputLabel htmlFor="formatted-text-mask-input">Fechamento *</InputLabel>
                                    <InputMask className="inputCadastro" id="fimSemClose" mask="99:99" name="fimSemClose" required />
                                </div>

                            </div>
                            <InputLabel htmlFor="formatted-text-mask-input">Domingo e Feriados *</InputLabel>
                            <div className="divCadastro" style={{ display: 'flex' }}>
                                <div style={{ marginRight: 5 }}>
                                    <InputLabel htmlFor="formatted-text-mask-input">Abertura *</InputLabel>
                                    <InputMask className="inputCadastro" id="domFerOpen" mask="99:99" name="domFerOpen" required />
                                </div>
                                <div style={{ marginLeft: 5 }}>
                                    <InputLabel htmlFor="formatted-text-mask-input">Fechamento *</InputLabel>
                                    <InputMask className="inputCadastro" id="domFerClose" mask="99:99" name="domFerClose" required />
                                </div>

                            </div>
                            <div style={{ margin: '10px 0' }}>
                                <span style={{ fontWeight: 'bold' }}>Endereço e outras informações</span>
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">CEP *</InputLabel>
                                <InputMask className="inputCadastro" id="cep" mask="99999-999" name="cep" onChange={checkCEP} />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Endereço *</InputLabel>
                                <input
                                    required
                                    id="address"
                                    name="address"
                                    className="inputCadastro"
                                />
                            </div>
                            <div className="divCadastro" style={{ display: 'flex' }}>
                                <div style={{ marginRight: 5 }}>
                                    <InputLabel htmlFor="formatted-text-mask-input">Número *</InputLabel>
                                    <input
                                        required
                                        id="formatted-text-mask-input"
                                        name="number"
                                        className="inputCadastro"
                                    />
                                </div>
                                <div style={{ marginLeft: 5 }}>
                                    <InputLabel htmlFor="formatted-text-mask-input">Bairro *</InputLabel>
                                    <input
                                        required
                                        id="neighborhood"
                                        name="neighborhood"
                                        className="inputCadastro"
                                    />
                                </div>

                            </div>
                            <div className="divCadastro" style={{ display: 'flex' }}>
                                <div style={{ marginRight: 5 }}>
                                    <InputLabel htmlFor="formatted-text-mask-input">Cidade *</InputLabel>
                                    <input
                                        required
                                        id="city"
                                        name="city"
                                        className="inputCadastro"
                                    />
                                </div>
                                <div style={{ marginLeft: 5 }}>
                                    <InputLabel htmlFor="formatted-text-mask-input">Estado *</InputLabel>
                                    <input
                                        required
                                        id="state"
                                        name="state"
                                        className="inputCadastro"
                                    />
                                </div>

                            </div>
                            <div className="divCadastro" style={{ display: 'flex' }}>
                                <div style={{ marginRight: 5 }}>
                                    <InputLabel htmlFor="formatted-text-mask-input">Telefone Fixo *</InputLabel>
                                    <InputMask required className='inputCadastro' id="phone" mask="(99) 9999-9999" name="phone" />
                                </div>
                                <div style={{ marginLeft: 5 }}>
                                    <InputLabel htmlFor="formatted-text-mask-input">Whatsapp *</InputLabel>
                                    <InputMask required className='inputCadastro' id="whatsapp" mask="(99) 9 9999-9999" name="whatsapp" />
                                </div>

                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">E-mail *</InputLabel>
                                <input
                                    required
                                    id="formatted-text-mask-input"
                                    name="email"
                                    className="inputCadastro"
                                    defaultValue={email}
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Site</InputLabel>
                                <input
                                    id="formatted-text-mask-input"
                                    name="site"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Rede Social</InputLabel>
                                <input
                                    id="formatted-text-mask-input"
                                    name="social_media"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="buttonLogin">
                                <button className="buttonSecundary" >
                                    Criar perfil de revenda
                                </button>
                            </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );

    }

    return (
        <>
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Perfil de revenda</h5>
                    </div>
                    {/* <div>
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                    </div> */}
                </div>
                <Card className="cardContainer tableResellerProfile" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableResellerProfile />
                </Card>
            </div >
        </>
    )
}

export default ResellerProfile