import AccordionActionTypes from "./actions-types";

const initialState = {
    currentAccordion: false
}

const accordionReducer = (state = initialState, action) => {
    switch (action.type) {
        case AccordionActionTypes.ACCORDION:
            return { ...state, currentAccordion: action.payload };
        default:
            return state;
    }
};

export default accordionReducer;