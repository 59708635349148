import React from 'react';
import { useState, useEffect } from 'react';
import './mentoring.css'
import {
    Box,
    Grid,
    Stack,
    Typography
} from '@mui/material';

import { Card } from "react-bootstrap";

import AnalyticEcommerce from '../../components/cards/statistics/AnalyticEcommerce';
import MainCard from '../../components/MainCard';
import api from '../../service/api';

import {
    AppCurrentVisits,
} from '../../sections/@dashboard/app';

import { useTheme } from '@mui/material/styles';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import InputLabel from '@mui/material/InputLabel';
import CurrencyInput from 'react-currency-input-field';
import anuncion from '../../image/anuncio.png';
import { set } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import icon from '../../image/truck.png';
import InputMask from 'react-input-mask';
import logo from '../../image/islagas.png'
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { configurationTheme } from '../../redux/configurationTheme/actions'
import image_dash from '../../image/image_dash.png'
import { CiCircleChevRight } from "react-icons/ci";
import painel from '../../image/painel.png'
import app_cliente from '../../image/app-cliente.png'
import app_motorista from '../../image/app-motorista.png'
import gerar_imagem from '../../image/gerar-imagem.png'
import google_meu_negocio from '../../image/google-meu-negocio.png'
import localizacaomotorista from '../../image/localizacaomotorista.png'
import redes_sociais from '../../image/redes-sociais.png'
import pedido_whatsapp from '../../image/whatsapp.png'
import relatorioIco from '../../image/relatorios.png';
import sandra_ellipse from '../../image/sandra_ellipse.png'
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import bannerSandra from '../../image/mentorias_treinamentos.png'

const Mentoring = () => {
    const theme = useTheme();

    const [ordered, setOrdered] = useState([]);
    const [saleChanell, setSaleChanell] = useState([]);
    const [driver, setDriver] = useState([]);
    const [cBusiness, setCBusiness] = useState([]);
    const [CategoryBusiness, setCategoryBusiness] = useState([]);
    const [payment, setPayment] = useState([]);
    const [sector, setSector] = useState([]);
    const [products, setProducts] = useState([]);
    const [plusGrafic, setPlusGrafic] = useState(false);
    const [plusGraficText, setplusGraficText] = useState('CARREGAR MAIS');
    const [driverLocalization, setDriverLocalization] = React.useState([]);
    const [localization, setLocalization] = React.useState([]);
    const [orderedTable, setOrderedTable] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [clientPhysicAll, setClientPhysicAll] = React.useState([]);
    const [clientBusinessAll, setClientBusinessAll] = React.useState([]);

    const [localizacao, setLocalizacao] = useState();
    const [notas, setNotas] = useState();
    const [geradorImagem, setGeradorImagem] = useState();
    const [whatsapp, setWhatsapp] = useState();
    const [relatorio, setRelatorio] = useState();
    const [appCliente, SetAppClient] = useState();
    const [marketing, setMarketing] = useState();
    const [eventTest, setEventTest] = useState();
    const [mentorias, setMentorias] = useState([]);
    const [userName, setUserName] = useState('');
    const [trainings, setTrainings] = useState([])
    const [typePlan, setTypePlan] = useState('')

    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    let user_id = '';
    if (currentUser !== undefined) {
        user_id = currentUser.id;
    }

    React.useEffect(() => {
        api
            .get("/TodosUsuariosRevenda")
            .then((response) => {
                response.data.map(key => {
                    if (key['db_name'] == currentUser.db) {
                        setTypePlan(key['module'])
                    }
                })
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);


    useEffect(() => {
        api
            .get('/TodosCursos')
            .then(response => {
                setTrainings(response.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    // const trainings = [
    //     {
    //         id: 1,
    //         name: 'Gestão Eficiente para Gestores',
    //         img: 'https://www.accesscorp.com/wp-content/uploads/2022/01/10-etapas-para-construir-um-roteiro-de-gesta%CC%83o-da-informac%CC%A7a%CC%83o.png',
    //         tag: 'gestao-eficiente-para-gestores'
    //     },
    //     {
    //         id: 2,
    //         name: '⁠Excelência nas Entregas',
    //         img: '',
    //         tag: 'excelencia-nas-entregas'

    //     },
    //     {
    //         id: 3,
    //         name: 'Gerenciamento dos Motoristas em Tempo Real',
    //         img: '',
    //         tag: 'gerenciamento-dos-motoristas-em-tempo-real'
    //     },
    //     {
    //         id: 4,
    //         name: 'O Poder do Google para o Setor de GLP',
    //         img: '',
    //         tag: 'o-poder-do-google-para-o-sertor-de-glp'
    //     },
    //     {
    //         id: 5,
    //         name: 'Estratégias de Gestão de Vendas',
    //         img: '',
    //         tag: 'estrategias-de-gestao-de-vendas'
    //     },
    //     {
    //         id: 6,
    //         name: 'Análise de Mercado e Concorrência',
    //         img: '',
    //         tag: 'analise-de-mercado-e-concorrencia'
    //     },
    //     {
    //         id: 7,
    //         name: 'Liderança e Motivação de Equipe',
    //         img: '',
    //         tag: 'lideranca-e-motivacao-de-equipe'
    //     },
    //     {
    //         id: 8,
    //         name: 'Atendimento ao Cliente de Excelência',
    //         img: '',
    //         tag: 'atendimento-ao-cliente-de-excelencia'
    //     },
    //     {
    //         id: 9,
    //         name: 'Vendas Consultivas',
    //         img: '',
    //         tag: 'vendas-consultivas'
    //     },
    //     {
    //         id: 10,
    //         name: 'Gestão de Reclamações e Feedback',
    //         img: '',
    //         tag: 'gestao-de-reclamacoes-e-feedback'
    //     },
    //     {
    //         id: 11,
    //         name: 'Eficiência na Entrega e Logística',
    //         img: '',
    //         tag: 'eficiencia-na-entrega-e-logistica'
    //     },
    //     {
    //         id: 12,
    //         name: 'Atendimento ao Cliente Durante as Entregas',
    //         img: '',
    //         tag: 'atendimento-ao-cliente-durante-as-entregas'
    //     },
    //     {
    //         id: 13,
    //         name: 'Segurança no Transporte de GLP',
    //         img: '',
    //         tag: 'seguranca-no-transporte-de-glp'
    //     },
    //     {
    //         id: 14,
    //         name: 'Marketing Digital para Revendas de GLP',
    //         img: '',
    //         tag: 'marketing-digital-para-revendas-de-glp'
    //     },
    //     {
    //         id: 15,
    //         name: 'Programas de Fidelização de Clientes',
    //         img: '',
    //         tag: 'programas-de-fidelizacao-de-clientes'
    //     },
    // ]

    useEffect(() => {
        axios
            .get("https://api.clubedorevendedordegas.com.br/TodasAddons")
            .then((response) => {
                let addonsArray = [];
                response.data.map(addons => {
                    if (addons.name == 'Painel') {
                        addonsArray.push(JSON.parse(addons.addons))
                    }
                })
                addonsArray[0].map(key => {
                    if (key[0].addon == 'whatsapp') {
                        setWhatsapp(key[0].status)
                    } else if (key[0].addon == 'gerador de imagem') {
                        setGeradorImagem(key[0].status)
                    } else if (key[0].addon == 'app cliente') {
                        SetAppClient(key[0].status)
                    } else if (key[0].addon == 'app entregador/localizacao') {
                        setLocalizacao(key[0].status)
                    } else if (key[0].addon == 'relatorios') {
                        setRelatorio(key[0].status)
                    } else if (key[0].addon == 'notas') {
                        setNotas(key[0].status)
                    } else if (key[0].addon == 'marketing') {
                        setMarketing(key[0].status)
                    }
                })

            })

            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    useEffect(() => {
        api
            .get('/TodosVideos')
            .then(response => {
                setMentorias(response.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])



    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 0,
            controls: 0,
            enablejsapi: 1,
            rel: 0,
            showinfo: 0
        },
    };

    const checkElapsedTime = (e) => {
        setEventTest(e.target)
    };

    // const mentorias = [
    //     {
    //         name: 'Canal Sandra Ruiz',
    //         video: 'Ao6ZIypNtV0',
    //     },
    //     {
    //         name: 'COMO VENDER GÁS PELO WHATSAPP SEM PAGAR NADA',
    //         video: 'w3Bnmxp_IRI',
    //     },
    //     {
    //         name: 'CONHEÇA A MÁQUINA DE ATRAÇÃO DE CLIENTES PARA A SUA REVENDA DE GÁS',
    //         video: 'H8nzMAFXvm4',
    //     },
    //     {
    //         name: 'Atendimento do Entregador de Gás no Programa Revenda Legal com Sandra Ruiz',
    //         video: '8IrXgk_r6qc',
    //     },
    //     {
    //         name: 'REVENDEDOR PARE DE GASTAR COM IMÃ OU PANFLETO',
    //         video: 'MaLuVWib3so',
    //     },
    //     {
    //         name: 'VENDA MAIS GÁS COM A AJUDA DE ESPECIALISTAS EM MARKETING DIGITAL',
    //         video: 'kJxp6JYxnw4',
    //     }
    // ]

    return (
        <div className='dashClubContainer' style={{ padding: 0 }}>
            <div className='containerMentoriaBanner'>
                <img className='imgEllipseMentoriaBanner' src={bannerSandra} />
            </div>
            <div className='containerVideosMentorias'>
                {trainings.map(key => {
                    if (typePlan == 'free') {
                        if (key['id'] == 3) {
                            return (
                                <Link className='divVideosMentoria' to={`/playlist/${key['id']}`}>
                                    <div className='videoMentoria' >
                                        <img className='videoMentoria' src={key['thumbnail']} />
                                    </div>
                                    <div className='titleVideoMentoria'>
                                        <span className='spanTitleVideoMentoria'>{key['name']}</span>
                                    </div>
                                </Link>
                            )
                        }
                    } else {
                        return (
                            <Link className='divVideosMentoria' to={`/playlist/${key['id']}`}>
                                <div className='videoMentoria' >
                                    <img className='videoMentoria' src={key['thumbnail']} />
                                </div>
                                <div className='titleVideoMentoria'>
                                    <span className='spanTitleVideoMentoria'>{key['name']}</span>
                                </div>
                            </Link>
                        )
                    }
                })}

            </div>
        </div >
    );
}

export default Mentoring