import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import { Checkbox, Select, MenuItem } from '@material-ui/core'

function TableFilter(columns, rows) {
    const [filter, setFilter] = React.useState(true)
    const handleChange = () => {
        setFilter(!filter)
      }
    return (
        <MaterialTable
            title="Todos clientes"
            columns={columns}
            data={rows}
            options={{
                filtering: filter
            }}
            actions={[
                {
                    icon: () => <Checkbox
                        checked={filter}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />,
                    tooltip: "Hide/Show Filter option",
                    isFreeAction: true
                }]}
        />
    )
}

export default TableFilter
