import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import api from "../../service/api";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import './styles.css'
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import load from '../../image/load.gif'
import MaterialTable from 'material-table'
import InputMask from 'react-input-mask';
import 'rsuite/dist/rsuite.min.css'
import pt_BR from 'rsuite/locales/pt_BR'
import { CustomProvider, DateRangePicker } from 'rsuite';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

export default function EnhancedTable() {
  const [rows, setRows] = React.useState([])
  const [driver, setDriver] = React.useState([]);
  const [client, setClient] = React.useState([]);
  const [clientPhysic, setClientPhysic] = React.useState([]);
  const [clientBusiness, setClientBusiness] = React.useState([]);
  const [payment, setPayment] = React.useState([]);
  const [product, setProduct] = React.useState([]);
  const [saleChanell, setSaleChanell] = React.useState([]);
  const [profileOrder, setProfileOrder] = React.useState([])
  const [actions, setActions] = React.useState('');
  const [rowsSelected, setRowsSelected] = React.useState([])
  const [modalCancel, setModalCancel] = React.useState(false);
  const [modalDriver, setModalDriver] = React.useState(false);
  const [modalStatus, setModalStatus] = React.useState(false);
  const [modalProfile, setModalProfile] = React.useState(false);
  const [text, setText] = React.useState();
  const [arrayRows, setArrayRows] = React.useState();
  const [filterDate1, setFilterDate1] = React.useState('all');
  const [filteredData, setFilteredData] = React.useState([]);
  const [dateFilter, setDateFilter] = React.useState('');
  const [alert, setAlert] = React.useState('Filtre por data');
  const [filterDriver, setFilterDriver] = React.useState('all');
  const [filterSaleChannel, setFilterSaleChannel] = React.useState('all');
  const [filterRangeDate, setFilterRangeDate] = React.useState('')
  const [filterStatus, setFilterStatus] = React.useState('all')
  const [typePayment, setTypePayment] = React.useState(false);
  const [orderedSelected, setOrderedSelected] = React.useState([])
  const [whatsModal, setWhatsModal] = React.useState(false);

  let whatsPhone = '';

  const statusOrder = [
    "Recebido", "Finalizado"]

  const date = new Date()
  const todayDate = date.toLocaleDateString("pt-BR")
  const todayTime = date.toLocaleTimeString("pt-BR")
  const today = todayDate + " " + todayTime;

  const handleModalClose = () => {
    setModalCancel(false)
    setModalDriver(false)
    setModalStatus(false)
    setModalProfile(false)
    setWhatsModal(false)
    setActions('')
  };

  const submitDriver = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    let message = data.mesageWhats;
    try {
      arrayRows.map((k, index) => {
        api.put('/AtualizarPedido', {
          id: k,
          driver_id: driverSelected,
          status: "Entregador notificado",
          db: currentUser.db
        })
        profileOrder.map(key => {
          if (key['id'] == k) {
            if (key['player_id'] !== null) {
              api.post("/Notificar", {
                app_id: "0eab63cd-71a3-48dc-b40c-7b1849c44f74",
                contents: {
                  en: "Oba, seu pedido está a caminho! 🚙"
                },
                include_player_ids: [key['player_id']]
              }).then(response => {
                // console.log(response)
              }).catch(err => {
                console.log(err)
              })
            }
          }
        })
      })
      driver.map(key => {
        if (key['id'] == driverSelected) {
          api.post("/Notificar", {
            app_id: "2791db33-df64-44ca-917f-bea5142e9d05",
            contents: {
              en: "Novo pedido para entrega 👏🚙😉"
            },
            include_player_ids: [key['player_id']]
          }).then(response => {
            // console.log(response)
          }).catch(err => {
            console.log(err)
          })
        }
      })
      let messageFormated = [];
      message.map(m => {
        if (m.split('\n').length > 1) {
          messageFormated.push(m.split('\n').join('%0D'))
        } else {
          messageFormated.push(m)
        }
      })
      axios
        .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/${currentUser.db}`,
          {
            number: "55" + whatsPhone + "@s.whatsapp.net",
            textMessage: {
              text: messageFormated.join('%0D%0D')
            },
            delay: 1200
          }, {
          headers: {
            'Content-Type': 'application/json',
            'apikey': 'B6D711FCDE4D4FD5936544120E713976'
          }
        })
        .then((response) => {
          // console.log(response);
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (err) {

    }

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const submitMessageWhats = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const message = data.mesageWhats.split('\n \n')
    let messageFormated = [];
    message.map(m => {
      if (m.split('\n').length > 1) {
        messageFormated.push(m.split('\n').join('%0D'))
      } else {
        messageFormated.push(m)
      }
    })

    axios
      .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/${currentUser.db}`,
        {
          number: "55" + whatsPhone + "@s.whatsapp.net",
          textMessage: {
            text: messageFormated.join('%0D%0D')
          },
          delay: 1200
        }, {
        headers: {
          'Content-Type': 'application/json',
          'apikey': 'B6D711FCDE4D4FD5936544120E713976'
        }
      })
      .then((response) => {
        // console.log(response);
      })
      .catch((err) => {
        console.log(err)
      })


    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const submitStatusOrder = async (e) => {
    e.preventDefault();
    let notification = '';

    if (statusOrderSelected == 'Finalizado') {
      notification = "Até a próxima, seu pedido foi finalizado! 😉"
    }
    try {
      arrayRows.map((k, index) => {
        api.put('/AtualizarPedido', {
          id: k,
          status: statusOrderSelected,
          delivery_date: today,
          db: currentUser.db
        })
        profileOrder.map(key => {
          if (key['id'] == k) {
            if (key['player_id'] !== null) {
              api.post("/Notificar", {
                app_id: "0eab63cd-71a3-48dc-b40c-7b1849c44f74",
                contents: {
                  en: notification
                },
                include_player_ids: [key['player_id']]
              }).then(response => {
                // console.log(response)
              }).catch(err => {
                console.log(err)
              })
            }
          }
        })
      })

      setOpen(true)
      setTimeout(function () {
        window.location.reload(1);
      }, 3000);
    } catch (err) {

    }
  }

  const handleCancelOrdered = async (e) => {
    arrayRows.map((k, index) => {
      api.put('/AtualizarPedido', {
        id: k,
        status: 'Cancelado',
        db: currentUser.db
      })
    })
    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  let driverSelected = '';
  let statusOrderSelected = '';

  const driverHandler = e => {
    driverSelected = e.target.value;
  }

  const statusOrderHandler = e => {
    statusOrderSelected = e.target.value
  }
  const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);

  let db = 'clubeApi';
  if (currentUser !== undefined || currentUser !== null) {
    db = currentUser.db
  }

  React.useEffect(() => {
    api
      .get("/TodosPedidos2" + '/' + currentUser.db)
      .then((response) => {
        const reversed = response.data.reverse();
        setProfileOrder(response.data)
        setRows(reversed.map(key => ({
          id: key['id'],
          canalDeVenda: key['sale_channel'],
          cliente: key['client_id'],
          phone: '',
          endereco: key['client_id'],
          produtos: key['products'],
          formaDePagamento: key['payment_id2'] == '' ? key['payment_id'] : key['payment_id3'] == '' ? String(key['payment_id']).concat(' / ', String(key['payment_id2'])) : String(key['payment_id']).concat(' / ', String(key['payment_id2']), ' / ', String(key['payment_id3'])),
          horarioDoPedido: key['order_date'],
          entregador: key['driver_id'],
          horarioDaEntrega: key['delivery_date'],
          total: "R$ " + Number(key['total']).toFixed(2).replace('.', ','),
          status: key['status'],
          typeClient: key['typeClient']
        })))
        setFilteredData(reversed.map(key => ({
          id: key['id'],
          canalDeVenda: key['sale_channel'],
          cliente: key['client_id'],
          phone: '',
          endereco: key['client_id'],
          produtos: key['products'],
          formaDePagamento: key['payment_id2'] == null ? key['payment_id'] : key['payment_id3'] == null ? String(key['payment_id']).concat(' / ', String(key['payment_id2'])) : String(key['payment_id']).concat(' / ', String(key['payment_id2']), ' / ', String(key['payment_id3'])),
          horarioDoPedido: key['order_date'],
          entregador: key['driver_id'],
          horarioDaEntrega: key['delivery_date'],
          total: "R$ " + Number(key['total']).toFixed(2).replace('.', ','),
          status: key['status'],
          typeClient: key['typeClient']
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  const MINUTE_MS = 30000;
  React.useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/TodosPedidos2" + '/' + currentUser.db)
        .then((response) => {
          const reversed = response.data.reverse();
          setProfileOrder(response.data)
          setRows(reversed.map(key => ({
            id: key['id'],
            canalDeVenda: key['sale_channel'],
            cliente: key['client_id'],
            phone: '',
            endereco: key['client_id'],
            produtos: key['products'],
            formaDePagamento: key['payment_id2'] == '' ? key['payment_id'] : key['payment_id3'] == '' ? String(key['payment_id']).concat(' / ', String(key['payment_id2'])) : String(key['payment_id']).concat(' / ', String(key['payment_id2']), ' / ', String(key['payment_id3'])),
            horarioDoPedido: key['order_date'],
            entregador: key['driver_id'],
            horarioDaEntrega: key['delivery_date'],
            total: "R$ " + Number(key['total']).toFixed(2).replace('.', ','),
            status: key['status'],
            typeClient: key['typeClient']
          })))
          setFilteredData(reversed.map(key => ({
            id: key['id'],
            canalDeVenda: key['sale_channel'],
            cliente: key['client_id'],
            phone: '',
            endereco: key['client_id'],
            produtos: key['products'],
            formaDePagamento: key['payment_id2'] == null ? key['payment_id'] : key['payment_id3'] == null ? String(key['payment_id']).concat(' / ', String(key['payment_id2'])) : String(key['payment_id']).concat(' / ', String(key['payment_id2']), ' / ', String(key['payment_id3'])),
            horarioDoPedido: key['order_date'],
            entregador: key['driver_id'],
            horarioDaEntrega: key['delivery_date'],
            total: "R$ " + Number(key['total']).toFixed(2).replace('.', ','),
            status: key['status'],
            typeClient: key['typeClient']
          })))
        })
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  React.useState(() => {
    const interval = setInterval(() => {
      setRows(filteredData)
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, [])

  React.useState(() => {
    setRows(filteredData)
  }, [])

  React.useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/TodasFormasPagamentos" + '/' + currentUser.db)
        .then((response) => {
          setPayment(response.data)
        })

        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/TodosClientesFisicos" + '/' + currentUser.db)
        .then((response) => setClientPhysic(response.data))
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/TodosClientesJuridicos" + '/' + currentUser.db)
        .then((response) => setClientBusiness(response.data))
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/TodosMotoristas" + '/' + currentUser.db)
        .then((response) => {
          setDriver(response.data)
        })
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/TodosProdutos" + '/' + currentUser.db)
        .then((response) => {
          setProduct(response.data)
        })
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    api
      .get("/TodasFormasPagamentos" + '/' + currentUser.db)
      .then((response) => {
        setPayment(response.data)
      })

      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosClientesFisicos" + '/' + currentUser.db)
      .then((response) => setClientPhysic(response.data))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosClientesJuridicos" + '/' + currentUser.db)
      .then((response) => setClientBusiness(response.data))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosMotoristas" + '/' + currentUser.db)
      .then((response) => {
        setDriver(response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosProdutos" + '/' + currentUser.db)
      .then((response) => {
        setProduct(response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosCanaisVendas" + '/' + currentUser.db)
      .then((response) => setSaleChanell(response.data))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);


  React.useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/TodosCanaisVendas" + '/' + currentUser.db)
        .then((response) => setSaleChanell(response.data))
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  const columns = [
    { title: "Canal de Venda", field: "canalDeVenda" },
    { title: "Cliente", field: "cliente" },
    {
      title: "Telefone", field: "phone", render: (rowData) => {
        if (rowData.phone.length === 10) {
          return (
            <InputMask style={{ backgroundColor: 'transparent', border: 'none', width: 127, cursor: 'auto' }} mask="(99) 9999-9999" value={rowData.phone} disabled />
          )
        } else {
          return (
            <InputMask style={{ backgroundColor: 'transparent', border: 'none', width: 127, cursor: 'auto' }} mask="(99) 9 9999-9999" value={rowData.phone} disabled />
          )
        }
      }
    },
    { title: "Endereço", field: "endereco" },
    { title: "Produtos", field: 'produtos' },
    { title: "Forma de pagamento", field: 'formaDePagamento' },
    { title: "Horário do pedido", field: 'horarioDoPedido' },
    { title: "Entregador", field: 'entregador' },
    // { title: "Horário da entrega", field: 'horarioDaEntrega', emptyValue: () => <em>Ainda sem informação</em> },
    { title: "Total", field: 'total' },
    { title: "Status", field: 'status', render: (rowData) => <div style={{ background: rowData.status == 'Finalizado' ? '#c2c2c2' : rowData.status == 'Entregador notificado' ? '#ffff76' : rowData.status == 'Cancelado' ? '#ff3333' : '', height: 50, display: "flex", alignItems: "center", justifyContent: "center" }}><span style={{ textAlign: 'center' }}>{rowData.status}</span></div> },
  ]

  filteredData.forEach(item => {
    if (item.horarioDaEntrega !== null) {
      if (item.horarioDaEntrega.toString().length == 13) {
        let date = new Date(Number(item.horarioDaEntrega));
        let entrega = date.getDate() +
          "/" + (date.getMonth() + 1) +
          "/" + date.getFullYear() +
          " " + date.getHours() +
          ":" + date.getMinutes() +
          ":" + date.getSeconds();
        item.horarioDaEntrega = entrega;
      }
    }
    saleChanell.map(key => {
      if (item.canalDeVenda == key['id']) {
        return item.canalDeVenda = key['name']
      }
    })
    if (item.typeClient == 'fisico') {
      clientPhysic.map(key => {
        if (item.cliente == key['id']) {
          item.cliente = key['name']
          item.endereco = key['street'] + ", " + key['number']
          item.phone = key['phone']
        }
      })
    } else {
      clientBusiness.map(key => {
        if (item.cliente == key['id']) {
          item.cliente = key['name']
          item.endereco = key['street'] + ", " + key['number']
          item.phone = key['phone']
        }
      })
    }

    var formaPagamento = item.formaDePagamento;
    let pag1 = '0';
    let pag2 = '0';
    let pag3 = '0';
    let pagName1 = '';
    let pagName2 = '';
    let pagName3 = '';
    if (typeof formaPagamento == 'string') {
      var splitPagamento = formaPagamento.split(' / ');
      pag1 = splitPagamento[0];
      if (splitPagamento[1] !== undefined) {
        pag1 = splitPagamento[0];
        pag2 = splitPagamento[1];
      }
      if (splitPagamento[2] !== undefined) {
        pag1 = splitPagamento[0];
        pag2 = splitPagamento[1];
        pag3 = splitPagamento[2];
      }
    } else {
      pag1 = item.formaDePagamento
    }
    if (pag2 === '0') {
      payment.map(key => {
        if (Number(pag1) == key['id']) {
          item.formaDePagamento = key['name']
        }
      })
    } else if (pag3 === '0') {
      payment.map(key => {
        if (Number(pag1) == key['id']) {
          pagName1 = key['name']
        }
        if (Number(pag2) == key['id']) {
          pagName2 = key['name']
        }
        let arr = [pagName1, pagName2];
        let arrString = String(arr.join(', '));
        item.formaDePagamento = arrString
      })
    } else {
      payment.map(key => {
        if (Number(pag1) == key['id']) {
          pagName1 = key['name']
        }
        if (Number(pag2) == key['id']) {
          pagName2 = key['name']
        }
        if (Number(pag3) == key['id']) {
          pagName3 = key['name']
        }
        let arr2 = [pagName1, pagName2, pagName3];
        let arrString2 = String(arr2.join(', '));
        item.formaDePagamento = arrString2
      })
    }
    driver.map(key => {
      if (item.entregador == key['id']) {
        item.entregador = key['name']
      }
    })
  })

  function ModalCancelar(props) {
    return (
      <Modal
        show={modalCancel}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Cancelar Pedido
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Você tem certeza que deseja cancelar o(os) pedido(os) selecionado(os)?</h4>
          <div className="cancelOrder">
            <Button onClick={handleCancelOrdered} className="cancelButton true">Sim</Button>
            <Button onClick={handleModalClose} className="cancelButton false">Não</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalDriver(props) {
    let message = `Olá ${orderedSelected.cliente}, o seu pedido foi feito com sucesso! \n \n Pedido nº ${orderedSelected.id} \n \n Itens: ➡ ⁠ ${orderedSelected.produtos} \n \n 💳 ${orderedSelected.formaDePagamento} \n \n 🏪 Entrega em: \n ${orderedSelected.endereco}  \n \n Total: R$ ${orderedSelected.total} \n \n Obrigado pela preferência, se precisar de algo é só chamar! 😉`;
    whatsPhone = orderedSelected.phone
    return (
      <Modal
        show={modalDriver}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Entregador
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Selecione um entregador para o pedido</h4>
          <form onSubmit={submitDriver} className="input">
            <Form.Select
              onChange={driverHandler}
            >
              <option value=''>Selecione um entregador</option>
              {driver.map((key, value) => {
                return <option value={key['id']} name='driver'>{key['name']}</option>
              })}
            </Form.Select>
            <div style={typePayment ? { display: 'flex', marginTop: 10, flexDirection: 'column' } : { display: 'none' }}>
              <span>Mensagem</span>
              <Form.Control
                as="textarea"
                style={{ height: '100px', width: '100%' }}
                name='mesageWhats'
                id='mesageWhats'
                defaultValue={message}
              />
            </div>
            <div className="buttonLogin">
              <button className="buttonSecundary" >
                Atualizar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalWhats(props) {
    let message = `Olá ${orderedSelected.cliente}, o seu pedido foi feito com sucesso! \n \n Pedido nº ${orderedSelected.id} \n \n Itens: ➡ ⁠ ${orderedSelected.produtos} \n \n 💳 ${orderedSelected.formaDePagamento} \n \n 🏪 Entrega em: \n ${orderedSelected.endereco}  \n \n Total: R$ ${orderedSelected.total} \n \n Obrigado pela preferência, se precisar de algo é só chamar! 😉`;
    return (
      <Modal
        show={whatsModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Mensagem whatsapp
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Escreva a mensagem para o cliente</h4>
          <form className="inputNotificationMessage" onSubmit={submitMessageWhats}>
            <Form.Control
              as="textarea"
              style={{ height: '100px', width: '100%' }}
              name='mesageWhats'
              id='mesageWhats'
              defaultValue={message}
            />
            <button className="buttonSecundary">
              Enviar
            </button>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalStatus(props) {
    return (
      <Modal
        show={modalStatus}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Status do pedido
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Selecione o status do pedido</h4>
          <form onSubmit={submitStatusOrder} className="input">
            <Form.Select
              onChange={statusOrderHandler}
            >
              <option value=''>Selecione um status</option>
              {statusOrder.map((key, value) => {
                return <option value={key} name='driver'>{key}</option>
              })}
            </Form.Select>
            <div className="buttonLogin">
              <button className="buttonSecundary" >
                Atualizar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalPainel(props) {
    let driverStatus = "Ainda não informado"
    let dateDelivery = "Ainda não informado"
    let vasilhameStatus = "Ainda não informado"
    let formPayment = '';
    let pag1 = '';
    let pag2 = '';
    let pag3 = '';
    let pagName1 = '';
    let pagName2 = '';
    let pagName3 = '';

    return (
      <Modal
        show={modalProfile}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Detalhes do pedido
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4 >Informações</h4>
          </div>
          {profileOrder.map(key => {
            if (key['id'] == arrayRows) {
              pag1 = key['payment_id'];
              pag2 = key['payment_id2'];
              pag3 = key['payment_id3'];
              if (pag2 == null) {
                payment.map(k => {
                  if (pag1 == k['id']) {
                    formPayment = k['name']
                  }
                })
              } else if (pag3 == null) {
                payment.map(k => {
                  if (Number(pag1) == k['id']) {
                    pagName1 = k['name']
                  }
                  if (Number(pag2) == k['id']) {
                    pagName2 = k['name']
                  }
                })
                let arr = [pagName1, pagName2];
                let arrString = String(arr.join(', '));
                formPayment = arrString
              } else {
                payment.map(k => {
                  if (Number(pag1) == k['id']) {
                    pagName1 = k['name']
                  }
                  if (Number(pag2) == k['id']) {
                    pagName2 = k['name']
                  }
                  if (Number(pag3) == k['id']) {
                    pagName3 = k['name']
                  }
                })
                let arr2 = [pagName1, pagName2, pagName3];
                let arrString2 = String(arr2.join(', '));
                formPayment = arrString2
              }
              if (key['delivery_date'] !== null) {
                dateDelivery = key['delivery_date']
              }
              if (key['vasilhame'] !== null) {
                vasilhameStatus = key['vasilhame']
              }
              return (
                <Table striped bordered hover className="tableProfile" >
                  <tbody>
                    <tr>
                      <td className="labelConfirm">Nome do cliente:</td>
                      {key['typeClient'] == 'fisico' ? (
                        <td>{clientPhysic.map(k => {
                          if (k['id'] == key['client_id']) {
                            return k['name']
                          }
                        })}
                        </td>
                      ) : (
                        <td>{clientBusiness.map(k => {
                          if (k['id'] == key['client_id']) {
                            return k['name']
                          }
                        })}
                        </td>
                      )}

                    </tr>
                    <tr>
                      <td className="labelConfirm">Telefone do cliente:</td>
                      {key['typeClient'] == 'fisico' ? (
                        <td>{clientPhysic.map(k => {
                          if (k['id'] == key['client_id']) {
                            if (k['phone'].length === 10) {
                              return (
                                <InputMask style={{ backgroundColor: 'transparent', border: 'none', width: 127, cursor: 'auto' }} mask="(99) 9999-9999" value={k['phone']} disabled />
                              )
                            } else {
                              return (
                                <InputMask style={{ backgroundColor: 'transparent', border: 'none', width: 127, cursor: 'auto' }} mask="(99) 9 9999-9999" value={k['phone']} disabled />
                              )
                            }
                          }
                        })}
                        </td>
                      ) : (
                        <td>{clientBusiness.map(k => {
                          if (k['id'] == key['client_id']) {

                            if (k['phone'].length === 10) {
                              return (
                                <InputMask style={{ backgroundColor: 'transparent', border: 'none', width: 127, cursor: 'auto' }} mask="(99) 9999-9999" value={k['phone']} disabled />
                              )
                            } else {
                              return (
                                <InputMask style={{ backgroundColor: 'transparent', border: 'none', width: 127, cursor: 'auto' }} mask="(99) 9 9999-9999" value={k['phone']} disabled />
                              )
                            }
                          }
                        })}
                        </td>
                      )}

                    </tr>
                    <tr>
                      <td className="labelConfirm">Produtos:</td>
                      <td>{key['products']}
                      </td>
                    </tr>

                    <tr>
                      <td className="labelConfirm">Forma de pagamento:</td>
                      <td>{formPayment}</td>
                    </tr>

                    <tr>
                      <td className="labelConfirm">Canal de venda:</td>
                      <td>{saleChanell.map(k => {
                        if (k['id'] == key['sale_channel']) {
                          return k['name']
                        }
                      })}
                      </td>
                    </tr>

                    <tr>
                      <td className="labelConfirm">Entregador:</td>
                      <td>{driver.map(k => {
                        if (k['id'] == key['driver_id']) {
                          driverStatus = k['name']
                          return driverStatus
                        }
                      })}
                      </td>
                    </tr>

                    <tr>
                      <td className="labelConfirm">Data do pedido:</td>
                      <td>{key['order_date']}</td>
                    </tr>

                    <tr>
                      <td className="labelConfirm">Data da entrega:</td>
                      <td>{dateDelivery}</td>
                    </tr>

                    <tr>
                      <td className="labelConfirm">Vasilhame:</td>
                      <td>{vasilhameStatus}</td>
                    </tr>

                    <tr>
                      <td className="labelConfirm">Observação:</td>
                      <td>{key['obs']}</td>
                    </tr>

                    <tr>
                      <td className="labelConfirm">Primeiro Valor:</td>
                      <td>R$ {key['payment_value']}</td>
                    </tr>

                    {
                      key['payment_value2'] !== null ? (
                        <tr>
                          <td className="labelConfirm">Segundo valor:</td>
                          <td>R$ {key['payment_value2']}</td>
                        </tr>
                      ) : ('')
                    }

                    {
                      key['payment_value3'] !== null ? (
                        <tr>
                          <td className="labelConfirm">Terceiro valor:</td>
                          <td>R$ {key['payment_value3']}</td>
                        </tr>
                      ) : ('')
                    }

                    <tr>
                      <td className="labelConfirm">Total:</td>
                      <td>{'R$ ' + key['total']}</td>
                    </tr>

                  </tbody>
                </Table>
              )
            }
          })}
        </Modal.Body>
      </Modal >
    )

  }

  React.useEffect(() => {
    var array = []
    rowsSelected.map(key => {
      array.push(key['id'])
    })
    if (actions == 'cancel_order') {
      setModalCancel(true)
      setText('Sim')
    } else if (actions == 'select_driver') {
      setText('Confirmar')
      setModalDriver(true)
      filteredData.map(key => {
        if (key['id'] == array[0]) {
          // if (key['canalDeVenda'] == 'Whastapp') {
          setTypePayment(true)
          setOrderedSelected(key)
          // }
        }

      })
    } else if (actions == 'select_status') {
      setText('Aplicar')
      setModalStatus(true)
    } else if (actions == 'profile_order') {
      setModalProfile(true)
    } else if (actions == 'send_message') {
      setWhatsModal(true)
      filteredData.map(key => {
        if (key['id'] == array[0]) {
          // if (key['canalDeVenda'] == 'Whastapp') {
          setTypePayment(true)
          setOrderedSelected(key)
          // }
        }

      })
    }

    setArrayRows(array)
  }, [actions])

  React.useEffect(() => {
    let dateNow = new Date();
    var filter = [];
    let driverName = filterDriver.split('-');
    let saleChannell = filterSaleChannel.split('-');
    // let dateArray = dateFilter.split("/");
    let dateStart = new Date(dateFilter[0]).getTime()
    let dateEnd = new Date(dateFilter[1]).getTime()
    if (dateFilter) {
      rows.filter(f => {
        if (f.horarioDaEntrega !== null) {
          if (f.horarioDaEntrega.length > 13) {
            let dataCompleteString = f.horarioDaEntrega.split(" ");
            let dataString = dataCompleteString[0].split("/")
            let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
            f.horarioDaEntrega = new Date(dataFormated).getTime();
          }
          if (dateStart == dateEnd) {
            let dateStartFormat = new Date(dateStart).toLocaleString()
            let dateEndFormat = new Date(dateEnd).toLocaleString()
            let dateStartSplit = dateStartFormat.split(" ");
            let dateEndSplit = dateEndFormat.split(" ");
            let dateStartSplited = dateStartSplit[0].split("/");
            let dateEndSplited = dateEndSplit[0].split("/");
            let dateStartNew = new Date(dateStartSplited[2] + "-" + dateStartSplited[1] + "-" + dateStartSplited[0] + " " + "00:00:01").getTime()
            let dateEndNew = new Date(dateEndSplited[2] + "-" + dateEndSplited[1] + "-" + dateEndSplited[0] + " " + "23:59:59").getTime()

            dateStart = dateStartNew
            dateEnd = dateEndNew
          }
          if (dateStart <= f.horarioDaEntrega && dateEnd >= f.horarioDaEntrega) {
            if (filterDate1 == 'semana') {
              dateNow.setDate(dateNow.getDate() - 7);
              let dateFormat = dateNow.getTime();
              if (f.horarioDaEntrega !== null) {
                if (f.horarioDaEntrega.length > 13) {
                  let dataCompleteString = f.horarioDaEntrega.split(" ");
                  let dataString = dataCompleteString[0].split("/")
                  let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                  f.horarioDaEntrega = new Date(dataFormated).getTime();
                }
                if (dateFormat < f.horarioDaEntrega) {
                  if (filterDriver !== 'all') {
                    if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                      if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                        filter.push(f)
                      } else {
                        filter.push(f);
                      }
                    }
                  } else if (filterSaleChannel !== 'all') {
                    if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                      if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                        filter.push(f)
                      } else {
                        filter.push(f)
                      }
                    }
                  } else {
                    filter.push(f);
                  }
                }
              }
              setFilteredData(filter)
            } else if (filterDate1 == 'mes') {
              dateNow.setDate(dateNow.getDate() - 31);
              let dateFormat = dateNow.getTime();
              if (f.horarioDaEntrega !== null) {
                if (f.horarioDaEntrega.length > 13) {
                  let dataCompleteString = f.horarioDaEntrega.split(" ");
                  let dataString = dataCompleteString[0].split("/")
                  let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                  f.horarioDaEntrega = new Date(dataFormated).getTime();
                }
                if (dateFormat < f.horarioDaEntrega) {
                  if (filterDriver !== 'all') {
                    if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                      if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                        filter.push(f)
                      } else {
                        filter.push(f);
                      }
                    }
                  } else if (filterSaleChannel !== 'all') {
                    if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                      if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                        filter.push(f)
                      } else {
                        filter.push(f)
                      }
                    }
                  } else {
                    filter.push(f);
                  }
                }
              }
              setFilteredData(filter)
            } else if (filterDate1 == 'ano') {
              dateNow.setDate(dateNow.getDate() - 365);
              let dateFormat = dateNow.getTime();
              if (f.horarioDaEntrega !== null) {
                if (f.horarioDaEntrega.length > 13) {
                  let dataCompleteString = f.horarioDaEntrega.split(" ");
                  let dataString = dataCompleteString[0].split("/")
                  let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                  f.horarioDaEntrega = new Date(dataFormated).getTime();
                }
                if (dateFormat < f.horarioDaEntrega) {
                  if (filterDriver !== 'all') {
                    if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                      if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                        filter.push(f)
                      } else {
                        filter.push(f);
                      }
                    }
                  } else if (filterSaleChannel !== 'all') {
                    if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                      if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                        filter.push(f)
                      } else {
                        filter.push(f)
                      }
                    }
                  } else {
                    filter.push(f);
                  }
                }
              }
              setFilteredData(filter)
            } else {
              if (filterDriver !== 'all') {
                if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                  if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                    filter.push(f)
                  } else {
                    filter.push(f);
                  }
                }
              } else if (filterSaleChannel !== 'all') {
                if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                  if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                    filter.push(f)
                  } else {
                    filter.push(f)
                  }
                }
              } else {
                filter.push(f)
              }
            }
          }
        }
      })
      setFilteredData(filter)
      document.getElementById("helperFilterDate").setAttribute('style', 'color: rgba(0, 0, 0, 0.6)')
      setAlert("Filtre por data")
    }

    if (dateFilter == "") {
      if (filterDate1 == 'semana') {
        dateNow.setDate(dateNow.getDate() - 7);
        let dateFormat = dateNow.getTime();
        rows.filter(f => {
          if (f.horarioDaEntrega !== null) {
            if (f.horarioDaEntrega.length > 13) {
              let dataCompleteString = f.horarioDaEntrega.split(" ");
              let dataString = dataCompleteString[0].split("/")
              let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
              f.horarioDaEntrega = new Date(dataFormated).getTime();
            }
            if (dateFormat < f.horarioDaEntrega) {
              if (filterDriver !== 'all') {
                if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                  if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                    filter.push(f)
                  } else {
                    filter.push(f);
                  }
                }
              } else if (filterSaleChannel !== 'all') {
                if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                  if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                    filter.push(f)
                  } else {
                    filter.push(f)
                  }
                }
              } else {
                filter.push(f);
              }
            }
          }
        })
        setFilteredData(filter)
      } else if (filterDate1 == 'mes') {
        dateNow.setDate(dateNow.getDate() - 31);
        let dateFormat = dateNow.getTime();
        rows.filter(f => {
          if (f.horarioDaEntrega !== null) {
            if (f.horarioDaEntrega.length > 13) {
              let dataCompleteString = f.horarioDaEntrega.split(" ");
              let dataString = dataCompleteString[0].split("/")
              let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
              f.horarioDaEntrega = new Date(dataFormated).getTime();
            }
            if (dateFormat < f.horarioDaEntrega) {
              if (filterDriver !== 'all') {
                if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                  if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                    filter.push(f)
                  } else {
                    filter.push(f);
                  }
                }
              } else if (filterSaleChannel !== 'all') {
                if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                  if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                    filter.push(f)
                  } else {
                    filter.push(f)
                  }
                }
              } else {
                filter.push(f);
              }
            }
          }
        })
        setFilteredData(filter)
      } else if (filterDate1 == 'ano') {
        dateNow.setDate(dateNow.getDate() - 365);
        let dateFormat = dateNow.getTime();
        rows.filter(f => {
          if (f.horarioDaEntrega !== null) {
            if (f.horarioDaEntrega.length > 13) {
              let dataCompleteString = f.horarioDaEntrega.split(" ");
              let dataString = dataCompleteString[0].split("/")
              let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
              f.horarioDaEntrega = new Date(dataFormated).getTime();
            }
            if (dateFormat < f.horarioDaEntrega) {
              if (filterDriver !== 'all') {
                if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                  if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                    filter.push(f)
                  } else {
                    filter.push(f);
                  }
                }
              } else if (filterSaleChannel !== 'all') {
                if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                  if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                    filter.push(f)
                  } else {
                    filter.push(f)
                  }
                }
              } else {
                filter.push(f);
              }
            }
          }
        })
        setFilteredData(filter)
      } else {
        rows.filter(f => {
          if (filterDriver !== 'all') {
            if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
              if (dateStart <= f.horarioDaEntrega && dateEnd >= f.horarioDaEntrega) {
                filter.push(f)
              } else if (filterDate1 == 'semana') {
                dateNow.setDate(dateNow.getDate() - 7);
                let dateFormat = dateNow.getTime();
                rows.filter(f => {
                  if (f.horarioDaEntrega !== null) {
                    if (f.horarioDaEntrega.length > 13) {
                      let dataCompleteString = f.horarioDaEntrega.split(" ");
                      let dataString = dataCompleteString[0].split("/")
                      let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                      f.horarioDaEntrega = new Date(dataFormated).getTime();
                    }
                    if (dateFormat < f.horarioDaEntrega) {
                      if (filterDriver !== 'all') {
                        if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                          if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f);
                          }
                        }
                      } else if (filterSaleChannel !== 'all') {
                        if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                          if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f)
                          }
                        }
                      } else {
                        filter.push(f);
                      }
                    }
                  }
                })
                setFilteredData(filter)
              } else if (filterDate1 == 'mes') {
                dateNow.setDate(dateNow.getDate() - 31);
                let dateFormat = dateNow.getTime();
                rows.filter(f => {
                  if (f.horarioDaEntrega !== null) {
                    if (f.horarioDaEntrega.length > 13) {
                      let dataCompleteString = f.horarioDaEntrega.split(" ");
                      let dataString = dataCompleteString[0].split("/")
                      let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                      f.horarioDaEntrega = new Date(dataFormated).getTime();
                    }
                    if (dateFormat < f.horarioDaEntrega) {
                      if (filterDriver !== 'all') {
                        if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                          if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f);
                          }
                        }
                      } else if (filterSaleChannel !== 'all') {
                        if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                          if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f)
                          }
                        }
                      } else {
                        filter.push(f);
                      }
                    }
                  }
                })
                setFilteredData(filter)
              } else if (filterDate1 == 'ano') {
                dateNow.setDate(dateNow.getDate() - 365);
                let dateFormat = dateNow.getTime();
                rows.filter(f => {
                  if (f.horarioDaEntrega !== null) {
                    if (f.horarioDaEntrega.length > 13) {
                      let dataCompleteString = f.horarioDaEntrega.split(" ");
                      let dataString = dataCompleteString[0].split("/")
                      let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                      f.horarioDaEntrega = new Date(dataFormated).getTime();
                    }
                    if (dateFormat < f.horarioDaEntrega) {
                      if (filterDriver !== 'all') {
                        if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                          if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f);
                          }
                        }
                      } else if (filterSaleChannel !== 'all') {
                        if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                          if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f)
                          }
                        }
                      } else {
                        filter.push(f);
                      }
                    }
                  }
                })
                setFilteredData(filter)
              } else if (filterSaleChannel !== 'all') {
                if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                  filter.push(f);
                }
                setFilteredData(filter)
              } else {
                filter.push(f);
              }
            }
            setFilteredData(filter)
          } else if (dateStart <= f.horarioDaEntrega && dateEnd >= f.horarioDaEntrega) {
            filter.push(f)
          } else if (filterSaleChannel !== 'all') {
            if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
              if (dateStart <= f.horarioDaEntrega && dateEnd >= f.horarioDaEntrega) {
                filter.push(f)
              } else if (filterDate1 == 'semana') {
                dateNow.setDate(dateNow.getDate() - 7);
                let dateFormat = dateNow.getTime();
                rows.filter(f => {
                  if (f.horarioDaEntrega !== null) {
                    if (f.horarioDaEntrega.length > 13) {
                      let dataCompleteString = f.horarioDaEntrega.split(" ");
                      let dataString = dataCompleteString[0].split("/")
                      let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                      f.horarioDaEntrega = new Date(dataFormated).getTime();
                    }
                    if (dateFormat < f.horarioDaEntrega) {
                      if (filterDriver !== 'all') {
                        if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                          if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f);
                          }
                        }
                      } else if (filterSaleChannel !== 'all') {
                        if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                          if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f)
                          }
                        }
                      } else {
                        filter.push(f);
                      }
                    }
                  }
                })
                setFilteredData(filter)
              } else if (filterDate1 == 'mes') {
                dateNow.setDate(dateNow.getDate() - 31);
                let dateFormat = dateNow.getTime();
                rows.filter(f => {
                  if (f.horarioDaEntrega !== null) {
                    if (f.horarioDaEntrega.length > 13) {
                      let dataCompleteString = f.horarioDaEntrega.split(" ");
                      let dataString = dataCompleteString[0].split("/")
                      let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                      f.horarioDaEntrega = new Date(dataFormated).getTime();
                    }
                    if (dateFormat < f.horarioDaEntrega) {
                      if (filterDriver !== 'all') {
                        if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                          if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f);
                          }
                        }
                      } else if (filterSaleChannel !== 'all') {
                        if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                          if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f)
                          }
                        }
                      } else {
                        filter.push(f);
                      }
                    }
                  }
                })
                setFilteredData(filter)
              } else if (filterDate1 == 'ano') {
                dateNow.setDate(dateNow.getDate() - 365);
                let dateFormat = dateNow.getTime();
                rows.filter(f => {
                  if (f.horarioDaEntrega !== null) {
                    if (f.horarioDaEntrega.length > 13) {
                      let dataCompleteString = f.horarioDaEntrega.split(" ");
                      let dataString = dataCompleteString[0].split("/")
                      let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                      f.horarioDaEntrega = new Date(dataFormated).getTime();
                    }
                    if (dateFormat < f.horarioDaEntrega) {
                      if (filterDriver !== 'all') {
                        if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                          if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f);
                          }
                        }
                      } else if (filterSaleChannel !== 'all') {
                        if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                          if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f)
                          }
                        }
                      } else {
                        filter.push(f);
                      }
                    }
                  }
                })
                setFilteredData(filter)
              } else if (filterDriver !== 'all') {
                if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                  filter.push(f);
                }
              } else {
                filter.push(f);
              }
            }
          } else if (filterStatus !== 'all') {
            if (filterStatus == 'finalizado') {
              if (f.status == 'Finalizado') {
                filter.push(f)

              }
            } else if (filterStatus == 'a caminho') {
              if (f.status == 'Á caminho') {
                filter.push(f)
              }
            } else if (filterStatus == 'entregador notificado') {
              if (f.status == 'Entregador notificado') {
                filter.push(f)
              }
            } else if (filterStatus == 'cancelado') {
              if (f.status == 'Cancelado') {
                filter.push(f)
              }
            }
          } else {
            filter.push(f)
          }
        })
        setFilteredData(filter)
      }
    }

  }, [filterDate1, dateFilter, filterDriver, filterSaleChannel, filterRangeDate, filterStatus])

  const [open, setOpen] = React.useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return (
    <>
      <Loading />
      <ModalWhats />
      <ModalPainel />
      <ModalCancelar />
      <ModalStatus />
      <ModalDriver />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title="Pedidos"
            columns={columns}
            data={filteredData}
            onSelectionChange={(selectedRows) => setRowsSelected(selectedRows)}
            options={{
              selection: true,
              pageSize: 10,
              pageSizeOptions: false,
              exportButton: true,
              showTitle: false,
              toolbarButtonAlignment: "left",
              exportAllData: true
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
            actions={[
              {
                icon: () => <Select
                  labelId="demo-simple-select-label"
                  className='selectActionOrdered'
                  value={actions}
                  onChange={(e) => setActions(e.target.value)}
                >
                  <MenuItem value={'cancel_order'}>Cancelar Pedido</MenuItem>
                  <MenuItem value={'select_driver'}>Selecionar Entregador</MenuItem>
                  <MenuItem value={'select_status'}>Mudar status do pedido</MenuItem>
                  <MenuItem value={'profile_order'}>Detalhes do pedido</MenuItem>
                  <MenuItem value={'send_message'}>Enviar mensagem para o cliente</MenuItem>
                </Select>
              },
              {
                icon: () => <FormControl sx={{ m: 1, minWidth: 220 }}>
                  <CustomProvider locale={pt_BR}>
                    <DateRangePicker
                      placeholder="Selecione as datas"
                      format="dd/MM/yyyy"
                      size="lg"
                      className='dateRangedActionOrdered'
                      onChange={(e) => {
                        if (e == null) {
                          setDateFilter("")
                        } else {
                          setDateFilter(e)
                        }
                      }
                      }
                    />
                  </CustomProvider>
                  <FormHelperText id="helperFilterDate">Filtre por data</FormHelperText>
                </FormControl>,
                isFreeAction: true
              },
              {
                icon: () =>
                  <FormControl sx={{ m: 1, minWidth: 220 }}>
                    {/* <InputLabel id="filtro-periudo">Filtro por período</InputLabel> */}
                    <Select
                      labelId="filtro-periudo"
                      id="filtro-periudo"
                      style={{ width: 220 }}
                      value={filterDate1}
                      onChange={(e) => setFilterDate1(e.target.value)}
                    >
                      <MenuItem value={'all'}>Todos</MenuItem>
                      <MenuItem value={'semana'}>Última semana</MenuItem>
                      <MenuItem value={'mes'}>Último mês</MenuItem>
                      <MenuItem value={'ano'}>Último ano</MenuItem>
                    </Select>
                    <FormHelperText>Filtre por período especifico</FormHelperText>
                  </FormControl>,
                isFreeAction: true
              },
              {
                icon: () =>
                  <FormControl sx={{ m: 1, minWidth: 220 }}>
                    {/* <InputLabel id="filterDriver">Filtro por entregador</InputLabel> */}
                    <Select
                      labelId="filterDriver"
                      id="filterDriver"
                      style={{ width: 220 }}
                      value={filterDriver}
                      onChange={(e) => setFilterDriver(e.target.value)}
                    >
                      <MenuItem value={'all'}>Todos</MenuItem>
                      {driver.map(key => {
                        return (
                          <MenuItem value={key['id'] + "-" + key['name']}>{key['name']}</MenuItem>
                        )
                      })}
                    </Select>
                    <FormHelperText>Filtre por entregador</FormHelperText>
                  </FormControl>,
                isFreeAction: true
              },
              {
                icon: () =>
                  <FormControl sx={{ m: 1, minWidth: 220 }}>
                    {/* <InputLabel id="filtro-periudo">Filtro por canal de venda</InputLabel> */}
                    <Select
                      labelId="filtro-periudo"
                      id="filtro-periudo"
                      style={{ width: 220 }}
                      value={filterSaleChannel}
                      onChange={(e) => setFilterSaleChannel(e.target.value)}
                    >
                      <MenuItem value={'all'}>Todos</MenuItem>
                      {saleChanell.map(key => {
                        return (
                          <MenuItem value={key['id'] + "-" + key['name']}>{key['name']}</MenuItem>
                        )
                      })}
                    </Select>
                    <FormHelperText>Filtre por canal especifico</FormHelperText>
                  </FormControl>,
                isFreeAction: true
              },
              {
                icon: () =>
                  <FormControl sx={{ m: 1, minWidth: 220 }}>
                    {/* <InputLabel id="filtro-periudo">Filtro por canal de venda</InputLabel> */}
                    <Select
                      labelId="filtro-periudo"
                      id="filtro-periudo"
                      style={{ width: 220 }}
                      value={filterStatus}
                      onChange={(e) => setFilterStatus(e.target.value)}
                    >
                      <MenuItem value={'all'}>Todos</MenuItem>
                      <MenuItem value={'finalizado'}>Finalizado</MenuItem>
                      <MenuItem value={'entregador notificado'}>Entregador Notificado</MenuItem>
                      <MenuItem value={'saiu para entrega'}>Saiu para entrega</MenuItem>
                      <MenuItem value={'a caminho'}>Á caminho</MenuItem>
                      <MenuItem value={'cancelado'}>Cancelado</MenuItem>
                    </Select>
                    <FormHelperText>Filtre por status de pedido</FormHelperText>
                  </FormControl>,
                isFreeAction: true
              }
            ]}
          />
        </Paper>
      </Box>
    </>
  );
}
