import React from 'react';
import './updatePlanConfirm.css'
import { Link } from 'react-router-dom';
import botijoes from '../../image/botijoes.png'

const UpdatePlanConfirm = () => {
    return (
        <>
            <div className='containerUpdatePlanConfirm'>
                <div className='divUpdatePlanConfirm'>
                    <span className='titleUpdatePlanConfirm'>Diversas possibilidades acabam de ser desbloqueadas na sua revenda.</span>
                    <img src={botijoes} className='imgUpdatePlanConfirm' />
                    <Link to='/' className='btnUpdatePlanConfirm'>Voltar ao inicio</Link>
                </div>
            </div>
        </>
    );
}

export default UpdatePlanConfirm