import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableDrivers.css'
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import load from '../../image/load.gif'
import InputMask from 'react-input-mask';
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete, AiOutlineWhatsApp, AiOutlineMail, AiOutlineBell, AiOutlineCar, AiOutlineDollar, AiOutlineFileSearch } from 'react-icons/ai'
import { BiMap } from 'react-icons/bi'
import { FiPhoneCall } from 'react-icons/fi'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { CurrencyInput } from 'react-currency-mask';
import Modal2 from '@mui/material/Modal';
import warning from '../../image/warning.png'
import trimestral from '../../image/trimestral.png'
import anual from '../../image/anual_.png'
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'cliente',
    numeric: false,
    disablePadding: false,
    label: 'Entregador',
  },
  {
    id: 'endereco',
    numeric: false,
    disablePadding: false,
    label: 'Endereço',
  },
  {
    id: 'telefone',
    numeric: false,
    disablePadding: false,
    label: 'Telefone',
  },
  {
    id: 'veiculo',
    numeric: false,
    disablePadding: false,
    label: 'Veiculo',
  },
  {
    id: 'sector',
    numeric: false,
    disablePadding: false,
    label: 'Setor',
  },
  {
    id: 'qntEntrega',
    numeric: false,
    disablePadding: false,
    label: 'Quantidade de Entrega',
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: false,
    label: 'Ações',
  }
];

const headDrivers = [
  {
    id: 'client',
    numeric: false,
    disablePadding: false,
    label: 'Cliente',
  },
  {
    id: 'products',
    numeric: false,
    disablePadding: false,
    label: 'Produtos',
  },
  {
    id: 'descont',
    numeric: false,
    disablePadding: false,
    label: 'Desconto',
  },
  {
    id: 'dateDelivery',
    numeric: false,
    disablePadding: false,
    label: 'Data de Entrega',
  },
  {
    id: 'vasilhame',
    numeric: false,
    disablePadding: false,
    label: 'Vasilhame',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className="colunmLabel"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([])
  const [driver, setDriver] = React.useState([]);
  const [ordered, setOrdered] = React.useState([]);
  const [vehicler, setVehicler] = React.useState([]);
  const [contDriver, setContDriver] = React.useState([]);
  const [clientPhysic, setClientPhysic] = React.useState([]);
  const [ClientBusiness, setClientBusiness] = React.useState([]);
  const [painelModal, setPainelModal] = React.useState(false);
  const [carModal, setCarModal] = React.useState(false);
  const [salaryModal, setSalaryModal] = React.useState(false);
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [text, setText] = React.useState();
  const [modalCancel, setModalCancel] = React.useState(false);
  const [sector, setSector] = React.useState([]);
  const [historyModal, setHistoryModal] = React.useState(false);
  const [payment, setPayment] = React.useState([]);
  const [saleChannel, setSaleChannel] = React.useState([]);
  const [fechamentoModal, setFechamentoModal] = React.useState(false)
  const [totalDaily, setTotalDaily] = React.useState('');
  const [whatsModal, setWhatsModal] = React.useState(false);
  const [sectorModal, setSectorModal] = React.useState(false);
  const [notification, setNotification] = React.useState(false);
  const [debts, setDebts] = React.useState([])
  const [modalAvisoWhats, setModalAvisoWhats] = React.useState(false)
  const [modalAvisoPlan, setModalAvisoPlan] = React.useState(false)
  const [typePlan, setTypePlan] = React.useState('free')
  const [statusEvolution, setStatusEvolution] = React.useState('')

  let carSelected = '';
  const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
  const navigate = useNavigate();

  React.useEffect(() => {
    api
      .get("/TodosMotoristas" + '/' + currentUser.db)
      .then((response) => {
        if (currentUser.db == 'clubeApi') {
          let array = [];
          response.data.map(key => {
            if (key['reseller_db'] == 'clubeApi') {
              array.push({ id: key['id'], cliente: key['name'], endereco: key['street'] + ", " + key['number'] + " - " + key['neighborhood'], telefone: key['phone'], vehicler: key['vehicle_id'], sector: key['sector_id'] })
            }
          })
          setRows(array)
        } else {
          setRows(response.data.map(key => ({
            id: key['id'], cliente: key['name'], endereco: key['street'] + ", " + key['number'] + " - " + key['neighborhood'], telefone: key['phone'], vehicler: key['vehicle_id'], sector: key['sector_id']
          })))
        }
        setDriver(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosPedidos2" + '/' + currentUser.db)
      .then((response) => {
        setOrdered(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodasDespesas" + '/' + currentUser.db)
      .then((response) => {
        setDebts(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodasFormasPagamentos" + '/' + currentUser.db)
      .then((response) => {
        setPayment(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosCanaisVendas" + '/' + currentUser.db)
      .then((response) => {
        setSaleChannel(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosClientesFisicos" + '/' + currentUser.db)
      .then((response) => {
        setClientPhysic(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosClientesJuridicos" + '/' + currentUser.db)
      .then((response) => {
        setClientBusiness(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosVeiculos" + '/' + currentUser.db)
      .then((response) => {
        setVehicler(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosSetores" + '/' + currentUser.db)
      .then((response) => {
        setSector(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosUsuariosRevenda")
      .then((response) => {
        response.data.map(key => {
          if (key['db_name'] == currentUser.db) {
            setTypePlan(key['module'])
          }
        })
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  const handleModalClose = () => {
    setPainelModal(false)
    setEditModal(false)
    setModalCancel(false)
    setSalaryModal(false)
    setCarModal(false)
    setHistoryModal(false)
    setFechamentoModal(false)
    setWhatsModal(false)
    setSectorModal(false)
    setNotification(false)
    setText("");
  };

  const handleMask = (e) => {
    let inputPhone = document.getElementById('celularDriverEdit');
    let phoneMask = e.target.value
    phoneMask = phoneMask.replace(/\D/g, '')
    phoneMask = phoneMask.replace(/(\d{2})(\d)/, "($1) $2")
    phoneMask = phoneMask.replace(/(\d)(\d{4})$/, "$1-$2")

    inputPhone.value = phoneMask
  }

  const submitModalEdit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    let phone = data.celularDriverEdit.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");

    const dados = {
      id: idModal,
      name: data.nameDriverEdit,
      number: Number(data.numeroDriverEdit),
      street: data.enderecoDriverEdit,
      city: data.cidadeDriverEdit,
      state: data.estadoDriverEdit,
      neighborhood: data.bairroDriverEdit,
      complement: data.complementoDriverEdit,
      reference_point: data.referenciaDriverEdit,
      cpf: data.cpfDriverEdit,
      email: data.emailDriverEdit,
      phone: phone,
      birthdate: data.dataNascimentoDriverEdit,
      obs: data.obsDriverEdit,
      db: currentUser.db,
    }

    api.put("/AtualizarCadastroMotorista", dados);

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const submitModalFechamento = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    var dateNow = new Date()
    var today = dateNow.toLocaleDateString("pt-br")

    const dados = {
      driver_id: idModal,
      vasilhame_vazio: data.vasilhameVazio,
      vasilhame_cheio: data.vasilhameCheio,
      return_value: data.returnValue,
      obs: data.obsFechamento,
      date_closing: today,
      status: data.statusFechamento,
      total: Number(totalDaily),
      db: currentUser.db
    }
    api.post("/CadastrarFechamento", dados);

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    width: 607,
    maxHeight: 660,
    borderRadius: "39px !important",
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    flexDirection: 'column'
  };

  const style2 = {
    position: 'relative',
    top: '50%',
    left: '50%',
    width: 470,
    maxHeight: 660,
    borderRadius: "39px !important",
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    flexDirection: 'column'
  };

  function ModalAvisoPlan(props) {
    return (
      <Modal2
        open={modalAvisoPlan}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='boxModalRaioX'>
          <div style={{ position: 'absolute', zIndex: 9, right: '-17px', top: '-10px' }}>
            <button style={{ position: 'relative', background: 'red', color: 'white', fontWeight: 900, borderRadius: 25, width: 35, height: 35 }} onClick={() => setModalAvisoPlan(false)}>X</button>
          </div>
          <img src={warning} className='imgModalValidatorPage' />
          <div className='contectModalValidatorPage'>
            <span className='textContectModalValidatorPage' style={{ fontSize: 32, color: '#25368C', fontWeight: 900, lineHeight: 1 }}>
              Essa função não está disponível no plano gratuito
            </span>
            <span className='textContectModalValidatorPage' style={{ fontSize: 28, fontWeight: 600, color: 'black', width: '98%', lineHeight: 1, marginTop: 10 }}>
              Escolha um plano e desbloqueie diversas funções da plataforma
            </span>
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end', flexDirection: 'row', marginTop: 10, width: '83%' }}>
              <img src={trimestral} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/trimestral")} />
              <img src={anual} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/anual")} />
            </div>
          </div>
        </Box>
      </Modal2>
    )
  }

  function ModalAvisoWhats(props) {
    return (
      <Modal2
        open={modalAvisoWhats}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2} className='boxModalRaioX'>
          <div style={{ position: 'absolute', zIndex: 9, right: '-17px', top: '-10px' }}>
            <button style={{ position: 'relative', background: 'red', color: 'white', fontWeight: 900, borderRadius: 25, width: 35, height: 35 }} onClick={() => setModalAvisoWhats(false)}>X</button>
          </div>
          <img src={warning} className='imgModalValidatorPage' />
          <div className='contectModalValidatorPage'>
            <span className='textContectModalValidatorPage' style={{ fontSize: 32, color: '#25368C', fontWeight: 900, lineHeight: 1 }}>
              Mensagem não enviada.
            </span>
            <span className='textContectModalValidatorPage' style={{ fontSize: 28, fontWeight: 600, color: 'black', width: '98%', lineHeight: 1, marginTop: 10 }}>
              Verifique se a sua instância está conectada corretamente em sua página do whatsapp
            </span>
            <div className='divBtnAvisoWhatsapp'>
              <Link to='/whatsapp' className='btnContectModalAvisoWhatsapp' onClick={() => {
                setModalAvisoWhats(false)
              }}>Verificar </Link>
            </div>
          </div>
        </Box>
      </Modal2>
    )
  }

  const submitMessageWhats = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const message = data.mesageWhats
    let whats = ''

    driver.map(key => {
      if (key['id'] == idModal) {
        whats = key['phone']
      }
    })

    let whatsPhone = whats.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");

    axios
      .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/${currentUser.db}`,
        {
          number: "55" + whatsPhone + "@s.whatsapp.net",
          textMessage: {
            text: message
          },
          delay: 1200
        }, {
        headers: {
          'Content-Type': 'application/json',
          'apikey': 'B6D711FCDE4D4FD5936544120E713976'
        }
      })
      .then((response) => {
        // console.log(response);
      })
      .catch((err) => {
        console.log(err)
      })

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  React.useEffect(() => {
    axios.get(`https://evolution.clubedorevendedordegas.com.br/instance/connectionState/${currentUser.db}`,
      {
        'headers': {
          apikey: 'B6D711FCDE4D4FD5936544120E713976'
        }
      }
    )
      .then(response => {
        setStatusEvolution(response.data.instance.state)
      }).catch(err => {
        console.log(err)
      })
  }, [])

  const submitNotification = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const message = data.mesageNotification
    let player_id = ''

    driver.map(key => {
      if (key['id'] == idModal) {
        player_id = key['player_id']
      }
    })
    api.post("/Notificar", {
      app_id: "2791db33-df64-44ca-917f-bea5142e9d05",
      contents: {
        en: message
      },
      include_player_ids: [player_id],
      db: currentUser.db
    }).then(response => {
      // console.log(response)
    }).catch(err => {
      console.log(err)
    })

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }


  const situationFechamento = e => {
    const status = e.target.value
    var trObs = document.querySelector("tr[class='trObsFechamento']");

    if (status == 'pendente') {
      trObs.style.display = "table-row"
    } else {
      trObs.style.display = "none"
    }
  }

  const openModalFechamento = e => {
    setFechamentoModal(true)
    setHistoryModal(false)
  }

  const submitModalCar = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const dados = {
      id: idModal,
      vehicle_id: Number(data.carSelect),
      db: currentUser.db
    }

    api.put("/AtualizarCadastroMotorista", dados);

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const submitModalSector = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const dados = {
      id: idModal,
      sector_id: Number(data.sectorSelect),
      db: currentUser.db
    }

    api.put("/AtualizarCadastroMotorista", dados);

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const carHandler = e => {
    carSelected = e.target.value;
  }

  const submitModalSalary = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    let monthNow = new Date().getMonth() + 1;
    let dayNow = new Date().getDate();
    const yearNow = new Date().getFullYear();
    let payDay = data.salaryDay
    let createDebts = false
    let arrayIdsDebts = []

    if (Number(dayNow) >= Number(data.salaryDay)) {
      monthNow = monthNow + 1;
    }

    if (Number(monthNow) < 10) {
      monthNow = `0${monthNow}`
    }

    if (Number(data.salaryDay) < 10) {
      payDay = `0${data.salaryDay}`
    }

    debts.map(key => {
      if (key['category'] == 'folhaPagamento') {
        if (key['name'] == data.nameCollaborator) {
          arrayIdsDebts.push(key['id'])
        }
      }
    })

    if (arrayIdsDebts.length == 0) {
      createDebts = true
    }

    const dados = {
      id: idModal,
      salary: data.salary.replace('R$', '').replace(' ', ''),
      pay_day: data.salaryDay,
      db: currentUser.db
    }

    api.put("/AtualizarCadastroMotorista", dados);

    var due_date = `${yearNow}-${monthNow}-${payDay}`

    if (createDebts) {
      for (var i = 1; 6 >= i; i++) {
        let dateSplit = due_date.split('-');
        let month = Number(dateSplit[1]) + i - 1;
        let year = Number(dateSplit[0])
        let day = Number(dateSplit[2])

        if (month < 10) {
          month = "0" + month;
        }
        if (month > 12) {
          month = "0" + (month - 12);
          year = year + 1;
        }

        let date = year + '-' + month + '-' + day;
        const dados2 = {
          name: data.nameCollaborator,
          type: 'fixa',
          category: 'folhaPagamento',
          due_date: date,
          value: data.salary.replace('R$', '').replace(' ', ''),
          status: 'Aberto',
          db: currentUser.db
        }
        api.post('/CadastrarDespesas', dados2)
      }
    } else {
      for (var a = 0; arrayIdsDebts.length >= a; a++) {
        let dateSplit = due_date.split('-');
        let month = Number(dateSplit[1]) + a;
        let year = Number(dateSplit[0])
        let day = Number(dateSplit[2])

        if (month < 10) {
          month = "0" + month;
        }
        if (month > 12) {
          month = "0" + (month - 12);
          year = year + 1;
        }

        let date = year + '-' + month + '-' + day;
        const dados2 = {
          name: data.nameCollaborator,
          type: 'fixa',
          category: 'folhaPagamento',
          due_date: date,
          value: data.salary.replace('R$', '').replace(' ', ''),
          status: 'Aberto',
          db: currentUser.db,
          id: arrayIdsDebts[a]
        }

        api.put('/AtualizarDespesa', dados2)
      }

    }

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const handleCancelOrdered = async (e) => {

    api.delete('/DeletarMotorista/' + idModal + '/' + currentUser.db)

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalPainel(props) {
    let salary = 'Ainda não informado'
    let car = 'Ainda não informado'
    let setor = 'Ainda não informado'
    return (
      <Modal
        show={painelModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Perfil
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do entregador</h4>
          </div>
          {driver.map(key => {
            if (key['id'] == idModal) {
              if (key['salary'] !== null) {
                salary = key['salary']
              }
              if (key['sector_id'] !== null) {
                sector.map(k => {
                  if (k['id'] == key['sector_id']) {
                    setor = k['name']
                  }
                })
              }
              return (
                <Table striped bordered hover className="tableProfile">
                  <tbody>
                    <tr>
                      <td className="labelConfirm">Nome do entregador:</td>
                      <td>{key['name']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">CPF:</td>
                      <td>
                        <InputMask className='inputMaskPainel' mask="999.999.999-**" defaultValue={key['cpf']} disabled />
                      </td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Veiculo:</td>
                      <td>
                        {vehicler.map(k => {
                          if (k['id'] == key['vehicle_id']) {
                            if (car == 'Ainda não informado') {
                              car = k['vehicle'] + " - " + k['model']
                              return car
                            }
                          }
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Salário:</td>
                      <td>
                        {"R$ " + salary}
                      </td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Setor:</td>
                      <td>
                        {setor}
                      </td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Endereço:</td>
                      <td>{key['street']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Número:</td>
                      <td>{key['number']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Bairro:</td>
                      <td>{key['neighborhood']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Complemento:</td>
                      <td>{key['complement']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Ponto de referência:</td>
                      <td>{key['reference_point']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Cidade:</td>
                      <td>{key['city']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Estado:</td>
                      <td>{key['state']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">E-mail:</td>
                      <td>{key['email']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Celular:</td>
                      <td> <InputMask className='inputMaskPainel' mask="(99) 99999-9999" defaultValue={key['phone']} disabled /></td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Data de nascimento:</td>
                      <td><InputMask className='inputMaskPainel' mask="99/99/9999" defaultValue={key['birthdate']} disabled /></td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Observação:</td>
                      <td>{key['obs']}</td>
                    </tr>
                  </tbody>
                </Table>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )

  }

  function ModalEdit(props) {
    return (
      <Modal
        show={editModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Editar perfil
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do entregador</h4>
          </div>
          {driver.map(key => {
            if (key['id'] == idModal) {
              let phoneMask = key['phone'].replace(/\D/g, '').replace(/(\d{2})(\d)/, "($1) $2").replace(/(\d)(\d{4})$/, "$1-$2")
              return (
                <form className="input" onSubmit={submitModalEdit}>
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Nome do entregador:</td>
                        <td>
                          <input
                            required
                            name="nameDriverEdit"
                            className="inputCadastro"
                            defaultValue={key['name']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">CPF:</td>
                        <td>
                          <InputMask className='inputCadastro' name="cpfDriverEdit" mask="999.999.999-**" defaultValue={key['cpf']} />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Endereço:</td>
                        <td>
                          <input
                            required
                            name="enderecoDriverEdit"
                            className="inputCadastro"
                            defaultValue={key['street']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Número:</td>
                        <td>
                          <input
                            required
                            name="numeroDriverEdit"
                            className="inputCadastro"
                            defaultValue={key['number']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Bairro:</td>
                        <td>
                          <input
                            required
                            name="bairroDriverEdit"
                            className="inputCadastro"
                            defaultValue={key['neighborhood']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Complemento:</td>
                        <td>
                          <input
                            name="complementoDriverEdit"
                            className="inputCadastro"
                            defaultValue={key['complement']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Ponto de referência:</td>
                        <td>
                          <input
                            name="referenciaDriverEdit"
                            className="inputCadastro"
                            defaultValue={key['reference_point']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Cidade:</td>
                        <td>
                          <input
                            required
                            name="cidadeDriverEdit"
                            className="inputCadastro"
                            defaultValue={key['city']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Estado:</td>
                        <td>
                          <input
                            required
                            name="estadoDriverEdit"
                            className="inputCadastro"
                            defaultValue={key['state']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">E-mail:</td>
                        <td>
                          <input
                            required
                            name="emailDriverEdit"
                            className="inputCadastro"
                            defaultValue={key['email']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Celular:</td>
                        <td> <InputMask className='inputCadastro' name="celularDriverEdit" id="celularDriverEdit" onChange={handleMask} defaultValue={phoneMask} required /></td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Data de nascimento:</td>
                        <td><InputMask className='inputCadastro' name="dataNascimentoDriverEdit" mask="99/99/9999" defaultValue={key['birthdate']} /></td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Observação:</td>
                        <td>
                          <Form.Control
                            className="textAreaForm"
                            as="textarea"
                            style={{ height: '100px' }}
                            name='obsDriverEdit'
                            value={key['obs']}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Atualizar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalCar(props) {
    return (
      <Modal
        show={carModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Veiculo
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Selecione o veiculo para o entregador</h4>
          {driver.map(key => {
            if (key['id'] == idModal) {
              return (
                <form className="input" onSubmit={submitModalCar}>
                  <Form.Select onChange={carHandler} name='carSelect'>
                    <option value=''>Selecione um veiculo</option>
                    {vehicler.map((k, value) => {
                      return <option value={k['id']} name='vehicle'>{k['vehicle'] + " - " + k['model']}</option>
                    })}
                  </Form.Select>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Confirmar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalSector(props) {
    return (
      <Modal
        show={sectorModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Setor
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Selecione um setor para o entregador</h4>
          {driver.map(key => {
            if (key['id'] == idModal) {
              return (
                <form className="input" onSubmit={submitModalSector}>
                  <Form.Select name="sectorSelect">
                    <option value=''>Selecione um setor</option>
                    {sector.map((k, value) => {
                      return <option value={k['id']} name='vehicle'>{k['name']}</option>
                    })}
                  </Form.Select>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Confirmar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalSalary(props) {
    return (
      <Modal
        show={salaryModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Salário
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Mudar salário do entregador</h4>
          </div>
          {driver.map(key => {
            if (key['id'] == idModal) {
              return (
                <form className="input" onSubmit={submitModalSalary}>
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Salário do entregador:</td>
                        <td>
                          <CurrencyInput
                            required
                            name="salary"
                            className="inputCadastro"
                            defaultValue={key['salary']}
                            onChangeValue={(event, originalValue, maskedValue) => {

                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Dia de pagamento</td>
                        <td>
                          <input
                            required
                            name="salaryDay"
                            className="inputCadastro"
                            defaultValue={key['pay_day']}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <input type='hidden' name="nameCollaborator" value={key['name']} />
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Confirmar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalHistory(props) {
    let nameClient = '';

    var dateNow = new Date()
    var today = dateNow.toLocaleDateString("pt-br")
    var rowDriver = [];

    ordered.map(key => {
      if (key['driver_id'] == idModal) {
        if (key['status'] == 'Finalizado') {
          var dateDelivery = key['delivery_date'];
          if (dateDelivery !== null) {
            var splitDate = dateDelivery.split(" ");
            if (splitDate[0] == today) {
              rowDriver.push({ client: key['client_id'], product: key['product_id'], desconto: key['desconto'], date_delivery: key['delivery_date'], vasilhame: key['vasilhame'], typeClient: key['typeClient'], total: key['total'], payment_id: key['payment_id'], sale_channel: key['sale_channel'] })
            }
          }
        }
      }
    }
    )



    function EnhancedTableHeadDriver(props) {
      const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
      const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
      };

      return (
        <TableHead>
          <TableRow>
            {headDrivers.map((headDriver) => (
              <TableCell
                key={headDriver.id}
                align={headDriver.numeric ? 'right' : 'left'}
                padding={headDriver.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headDriver.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headDriver.id}
                  direction={orderBy === headDriver.id ? order : 'asc'}
                  onClick={createSortHandler(headDriver.id)}
                  className="colunmLabel"
                >
                  {headDriver.label}
                  {orderBy === headDriver.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      );
    }

    EnhancedTableHeadDriver.propTypes = {
      numSelected: PropTypes.number.isRequired,
      onRequestSort: PropTypes.func.isRequired,
      onSelectAllClick: PropTypes.func.isRequired,
      order: PropTypes.oneOf(['asc', 'desc']).isRequired,
      orderBy: PropTypes.string.isRequired,
      rowCount: PropTypes.number.isRequired,
    };

    const handleRequestSortDriver = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const handleSelectAllClickDriver = (event) => {
      if (event.target.checked) {
        const newSelecteds = rows.map((n) => n.name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };

    const handleChangePageDriver = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPageDriver = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const emptyRowsDriver =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowDriver.length) : 0;
    let descontoDriver = 0
    let totalDriver = 0
    let total = 0
    let desconto = 'Não teve desconto'
    return (
      <Modal
        show={historyModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Histórico do entregador
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Histórico</h4>
          </div>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHeadDriver
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClickDriver}
                    onRequestSort={handleRequestSortDriver}
                    rowCount={rowDriver.length}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                     rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(rowDriver, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        total += parseFloat(row['total'])
                        if (row['desconto'] !== null) {
                          desconto = row['desconto']
                        }
                        if (row['typeClient'] == 'fisico') {
                          clientPhysic.map(key => {
                            if (key['id'] == row['client']) {
                              nameClient = key['name']
                            }
                          })
                        } else if (row['typeClient'] == 'juridico') {
                          ClientBusiness.map(key => {
                            if (key['id'] == row['client']) {
                              nameClient = key['name']
                            }
                          })
                        }

                        payment.map(k => {
                          if (k['id'] == row['payment_id']) {
                            if (k['name'] == 'Dinheiro') {
                              saleChannel.map(s => {
                                if (s['id'] == row['sale_channel']) {
                                  if (row['desconto'] !== null) {
                                    descontoDriver += parseFloat(row['desconto'])
                                  }
                                  totalDriver += parseFloat(row['total']);

                                }
                              })
                            }
                          }
                        })
                        setTotalDaily(totalDriver);
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                              align="left"
                              className="clientTable"
                            >
                              {nameClient}
                            </TableCell>

                            <TableCell align="left">
                              {
                                row['product']
                              }
                            </TableCell>

                            <TableCell align="left">
                              {
                                desconto
                              }
                            </TableCell>

                            <TableCell align="left">
                              {
                                row['date_delivery']
                              }
                            </TableCell>
                            <TableCell align="left">
                              {
                                row['vasilhame']
                              }
                            </TableCell>

                          </TableRow>
                        );
                      })}
                    {emptyRowsDriver > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRowsDriver,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={rowDriver.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePageDriver}
              // onRowsPerPageChange={handleChangeRowsPerPageDriver}
              />
            </Paper>
          </Box>
          <div className='detailsDiv'>
            <table>
              <tr>
                <th scope="col" className='detailsTitle'>Desconto concedido</th>
                <th scope="col" className='detailsTitle'>Valor Total</th>
                <th scope="col" className='detailsTitle'>Dinheiro a recolher</th>
              </tr>
              <tr>
                <td className='detailsResult'>{"R$ " + descontoDriver}</td>
                <td className='detailsResult'>{"R$ " + total}</td>
                <td className='detailsResult'>{"R$ " + totalDriver}</td>
              </tr>
            </table>
            <div>
              <button className="buttonDetails" onClick={openModalFechamento}>Fechar o dia</button>
            </div>
          </div>
        </Modal.Body>
      </Modal >
    )
  }

  function ModalCancelar(props) {
    return (
      <Modal
        show={modalCancel}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Excluir cliente
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Você tem certeza que deseja excluir o entregador selecionado?</h4>
          <div className="cancelOrder">
            <Button onClick={handleCancelOrdered} className="cancelButton true">Sim</Button>
            <Button onClick={handleModalClose} className="cancelButton false">Não</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalWhats(props) {
    return (
      <Modal
        show={whatsModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Mensagem whatsapp
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Escreva a mensagem para o entregador</h4>
          <form className="inputNotificationMessage" onSubmit={submitMessageWhats}>
            <Form.Control
              as="textarea"
              style={{ height: '100px', width: '100%' }}
              name='mesageWhats'
              id='mesageWhats'
            />
            <button className="buttonSecundary">
              Enviar
            </button>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalNotification(props) {
    return (
      <Modal
        show={notification}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Notificação
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Escreva a notificação para o entregador</h4>
          <form className="inputNotificationMessage" onSubmit={submitNotification}>
            <Form.Control
              as="textarea"
              style={{ height: '100px', width: '100%' }}
              name='mesageNotification'
              id='mesageNotification'
            />
            <button className="buttonSecundary">
              Enviar
            </button>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalFechamento(props) {
    return (
      <Modal
        show={fechamentoModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Fechamento diário do entregador
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações para o fechamento</h4>
          </div>
          <form className="input" onSubmit={submitModalFechamento}>
            <Table striped bordered hover className="tableProfile">
              <tbody>
                <tr>
                  <td className="labelConfirm">Vasilhames vazios:</td>
                  <td>
                    <Form.Select
                      name='vasilhameVazio'
                      required
                    >
                      <option value=''>Selecione uma situação</option>
                      <option value='correto'>Está certo</option>
                      <option value='incorreto'>Está faltando</option>
                    </Form.Select>
                  </td>
                </tr>

                <tr>
                  <td className="labelConfirm">Vasilhames cheio:</td>
                  <td>
                    <Form.Select
                      name='vasilhameCheio'
                      required
                    >
                      <option value=''>Selecione uma situação</option>
                      <option value='correto'>Está certo</option>
                      <option value='incorreto'>Está faltando</option>
                    </Form.Select>
                  </td>
                </tr>

                <tr>
                  <td className="labelConfirm">Total a recolher:</td>
                  <td>
                    <Form.Select
                      name='returnValue'
                      required
                    >
                      <option value=''>Selecione uma situação</option>
                      <option value='correto'>Está certo</option>
                      <option value='incorreto'>Está faltando</option>
                    </Form.Select>
                  </td>
                </tr>

                <tr>
                  <td className="labelConfirm">Situação do fechamento:</td>
                  <td>
                    <Form.Select
                      name='statusFechamento'
                      required
                      onChange={situationFechamento}
                    >
                      <option value=''>Selecione uma situação</option>
                      <option value='fechado'>Está tudo correto</option>
                      <option value='pendente'>Está faltando coisas</option>
                    </Form.Select>
                  </td>
                </tr>

                <tr className='trObsFechamento' style={{ display: 'none' }}>
                  <td className="labelConfirm">Observação:</td>
                  <td>
                    <Form.Control
                      as="textarea"
                      style={{ height: '100px', width: '100%' }}
                      name='obsFechamento'
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="buttonLogin">
              <button className="buttonSecundary" >
                Fechar o dia
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  const [open, setOpen] = React.useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  const columns = [
    { title: "Entregador", field: "cliente" },
    { title: "Endereço", field: "endereco" },
    { title: "Telefone", field: 'telefone', render: (rowData) => <InputMask className='inputMaskPainel' mask={rowData.telefone.length == 10 ? "(99) 9999-9999" : rowData.telefone.length == 11 ? "(99) 9 9999-9999" : ""} defaultValue={rowData.telefone} /> },
    { title: "Veiculo", field: 'vehicler' },
    { title: "Setor", field: 'sector' },
    { title: "Quantidade de Entrega", field: 'qntEntrega' }
  ]

  rows.forEach(item => {
    if (item.vehicler == null) {
      item.vehicler = "Nenhum veículo vinculado";
    }

    if (item.sector == null) {
      item.sector = "Nenhum setor vinculado";
    }

    vehicler.map(key => {
      if (key['id'] == item.vehicler) {
        item.vehicler = key['vehicle']
      }
    })
    sector.map(key => {
      if (key['id'] == item.sector) {
        item.sector = key['name']
      }
    })
    const map = ordered.map((x, index) => {
      if (x['driver_id'] == item.id) {
        return x['driver_id']
      }
    }, 0);
    let resultCount = map.filter(x => x == 1).length;

    item.qntEntrega = resultCount
  })

  return (
    <>
      <ModalAvisoWhats />
      <ModalAvisoPlan />
      <ModalSector />
      <ModalWhats />
      <ModalFechamento />
      <ModalHistory />
      <ModalSalary />
      <ModalCar />
      <Loading />
      <ModalCancelar />
      <ModalPainel />
      <ModalEdit />
      <ModalNotification />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title=""
            columns={columns}
            data={rows}
            actions={[
              {
                icon: () => <AiOutlineUser className='perfilAction' />,
                tooltip: 'Perfil',
                onClick: (event, rowData) => {
                  setPainelModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineEdit className='perfilAction' />,
                tooltip: 'Editar',
                onClick: (event, rowData) => {
                  setEditModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineCar className='perfilAction' />,
                tooltip: 'Vincular carro',
                onClick: (event, rowData) => {
                  setCarModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <BiMap className='perfilAction' />,
                tooltip: 'Setor',
                onClick: (event, rowData) => {
                  setSectorModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineFileSearch className='perfilAction' />,
                tooltip: 'Histórico',
                onClick: (event, rowData) => {
                  setHistoryModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineDollar className='perfilAction' />,
                tooltip: 'Vincular salário',
                onClick: (event, rowData) => {
                  setSalaryModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineWhatsApp className='perfilAction' />,
                tooltip: 'Whatsapp',
                onClick: (event, rowData) => {
                  if (typePlan == 'free') {
                    setModalAvisoPlan(true)
                  } else {
                    if (statusEvolution == 'close' || statusEvolution == 'connecting') {
                      setModalAvisoWhats(true)
                    } else {
                      setWhatsModal(true)
                      setIdModal(rowData.id)
                    }
                  }
                }
              },
              // {
              //   icon: () => <AiOutlineBell className='perfilAction' />,
              //   tooltip: 'Notificar',
              //   onClick: (event, rowData) => {
              //     setNotification(true)
              //     setIdModal(rowData.id)
              //   }
              // },
              // {
              //   icon: () => <AiOutlineMail className='perfilActionDisable' />,
              //   tooltip: 'E-mail',
              // },
              // {
              //   icon: () => <FiPhoneCall className='perfilActionDisable' />,
              //   tooltip: 'Ligar',
              // },
              {
                icon: () => <AiOutlineDelete className='perfilAction' />,
                tooltip: 'Deletar',
                onClick: (event, rowData) => {
                  setModalCancel(true)
                  setIdModal(rowData.id)
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: false,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
          />
          {/* <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row['id']);
                    const labelId = `enhanced-table-checkbox-${row['id']}`;
                    let dateDelivery = 'Ainda não foi entregue';
                    let veiculo = 'Não possui veiculo'
                    let setor = 'Não possui setor vinculado'
                    if (row.horarioDaEntrega !== null) {
                      dateDelivery = row.horarioDaEntrega
                    }
                    const map = ordered.map((x, index) => {
                      if (x['driver_id'] == row['id']) {
                        return x['driver_id']
                      }
                    }, 0);

                    vehicler.map(key => {
                      if (key['id'] == row.vehicler) {
                        veiculo = key['vehicle']
                      }
                    })

                    sector.map(key => {
                      if (key['id'] == row.sector) {
                        setor = key['name']
                      }
                    })

                    let resultCount = map.filter(x => x == 1).length;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row['id']}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="left"
                          className="clientTable"
                        >
                          {row.cliente}
                        </TableCell>

                        <TableCell align="left">
                          {
                            driver.map(key => {
                              if (key['id'] == row.endereco) {
                                return key['street'] + ", " + key['number'] + " - " + key['neighborhood']
                              }
                            })
                          }
                        </TableCell>

                        <TableCell align="left">
                          <InputMask className='inputMask' mask="(99) 99999-9999" value={row.telefone} disabled="disabled" />

                        </TableCell>

                        <TableCell align="left">
                          {
                            veiculo
                          }
                        </TableCell>

                        <TableCell align="left">
                          {
                            setor
                          }
                        </TableCell>

                        <TableCell align="left">
                          {
                            resultCount
                          }
                        </TableCell>

                        <TableCell align="left" >
                          <div className="buttonContainer">
                            <Tooltip title="Perfil" className='buttonActionTable' onClick={() => {
                              setPainelModal(true)
                              setIdModal(row.id)
                            }}>
                              <AiOutlineUser className='perfilAction' />
                            </Tooltip>
                            <Tooltip title="Editar" className='buttonActionTable' onClick={() => {
                              setEditModal(true)
                              setIdModal(row.id)
                              setText("Atualizar");
                            }}>
                              <AiOutlineEdit className='perfilAction' />
                            </Tooltip>
                            <Tooltip title="Vincular carro" className="buttonActionTable" onClick={() => {
                              setCarModal(true)
                              setIdModal(row.id)
                            }}>
                              <AiOutlineCar className='perfilAction' />
                            </Tooltip>

                            <Tooltip title="Setor" className="buttonActionTable" onClick={() => {
                              setSectorModal(true)
                              setIdModal(row.id)
                            }}>
                              <BiMap className='perfilAction' />
                            </Tooltip>
                            <Tooltip title="Histórico" className="buttonActionTable" onClick={() => {
                              setHistoryModal(true)
                              setIdModal(row.id)
                            }}>
                              <AiOutlineFileSearch className='perfilAction' />
                            </Tooltip>
                            <Tooltip title="Vincular salário" className="buttonActionTable" onClick={() => {
                              setSalaryModal(true)
                              setIdModal(row.id)
                            }}>
                              <AiOutlineDollar className='perfilAction' />
                            </Tooltip>
                            <Tooltip title="Whatsapp" className='buttonActionTable' onClick={() => {
                              setWhatsModal(true)
                              setIdModal(row.id)
                            }}>
                              <AiOutlineWhatsApp className='perfilAction' />
                            </Tooltip>
                            <Tooltip title="Notificar" className='buttonActionTable' onClick={() => {
                              setNotification(true)
                              setIdModal(row.id)
                            }}>
                              <AiOutlineBell className='perfilAction' />
                            </Tooltip>
                            <Tooltip title="E-mail" className='buttonActionTable'>
                              <AiOutlineMail className='perfilActionDisable' />
                            </Tooltip>
                            <Tooltip title="Ligar" className='buttonActionTable'>
                              <FiPhoneCall className='perfilActionDisable' />
                            </Tooltip>
                            <Tooltip title="Deletar" className='buttonActionTable' onClick={() => {
                              setModalCancel(true)
                              setIdModal(row.id)
                              setText('Sim')
                            }}>
                              <AiOutlineDelete className='perfilAction' />
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
      </Box>
    </>
  );
}
