import React, { useEffect } from 'react';
import './footer.css'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Container, Button, Link } from 'react-floating-action-button'
import sandrinha from '../../image/sandrinha.png'
import Modal from '@mui/material/Modal';
import {
    Box,
    Typography
} from '@mui/material';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../../service/api';
import { useSelector, useDispatch } from 'react-redux';

const Footer = () => {
    const [widthWindow, setWidthWindo] = React.useState(window.innerWidth)
    const [left, setLeft] = React.useState("88% !important")
    const [chamado, setChamdo] = React.useState('');
    const [openChamado, setOpenChamado] = React.useState(false)
    React.useEffect(() => {
        const btnFloat = document.getElementsByClassName('btnFloat')[0];
        var img = document.createElement("img");
        img.src = sandrinha;
        img.className = 'imgBtnFloat'
        btnFloat.appendChild(img)
    }, [])

    const [openFree, setOpenFree] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [useResellers, setUserResellers] = React.useState([])
    const [module, setModule] = React.useState('')
    const [top, setTop] = React.useState("72% !important");
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);

    React.useEffect(() => {
        if (window.innerWidth <= 1280 && window.innerHeight <= 720) {
            setTop("58% !important")
            setLeft("82% !important")
        } else if (window.innerWidth <= 1280 && window.innerHeight <= 960) {
            setTop("69%!important")
            setLeft("82% !important")
        } else if (window.innerWidth <= 1366 && window.innerHeight <= 768) {
            setTop("61%!important")
            setLeft("83% !important")
        } else if (window.innerWidth <= 1400 && window.innerHeight <= 900) {
            setTop("67%!important")
            setLeft("84% !important")
        } else if (window.innerWidth <= 1600 && window.innerHeight <= 900) {
            setTop("68%!important")
            setLeft("85% !important")
        } else if (window.innerWidth <= 1680 && window.innerHeight <= 1050) {
            setTop("72%!important")
            setLeft("86% !important")
        } else if (window.innerWidth <= 1980 && window.innerHeight <= 980) {
            setTop("61%!important")
            setLeft("83% !important")
        }
    }, [window.innerWidth, window.innerHeight])

    const handleClose = () => {
        setOpenFree(false)
        setOpenChamado(false)
    }

    // setInterval(() => {
    //     if (widthWindow < 500) {
    //         setLeft('50% !important')
    //     }
    // }, 1000);

    useEffect(() => {
        api
            .get('/TodosUsuariosRevenda')
            .then(response => {
                response.data.map(key => {
                    if (key['db_name'] == currentUser.db) {
                        setUserResellers(key)
                        setModule(key.module)
                    }
                })


            })
    }, [])

    const style = {
        position: 'relative',
        top: top,
        left: left,
        width: 402,
        height: 360,
        borderRadius: "39px !important",
        transform: 'translate(-50%, -50%)',
        bgcolor: '#F4F4F4',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
    };

    const style2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50vw',
        boxShadow: 24,
        p: 4,
        background: '#26388C',
        borderRadius: 5
    };

    const handleHelperSandrinha = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        let request = ''

        if (chamado == 'Dúvidas sobre a plataforma') {
            request = 'duvidas plataforma'
        } else if (chamado == 'Preciso de suporte') {
            request = 'suporte'
        } else if (chamado == 'Outras informações') {
            request = 'outras info'
        }

        axios
            .post('https://api.clubedorevendedordegas.com.br/CadastrarSolicitacao', {
                reseller: useResellers.id,
                reseller_plan: useResellers.module,
                request: request,
                status: 'aberto'
            }).then(response => {
                let bodyResquestMessage = {
                    request_id: Number(response.data.id),
                    message: JSON.stringify(data.chamadoDuvida),
                    who: 'user'
                }
                axios
                    .post('https://api.clubedorevendedordegas.com.br/CadastrarMensagemSolicitacao', bodyResquestMessage).then(response => {
                        setOpenChamado(false)
                        setOpen(true)
                        setTimeout(function () {
                            window.location.reload(1);
                        }, 5000);
                    })
            })
    }

    function ModalChamado(props) {
        return (
            <Modal
                open={openChamado}
                onClose={e => setOpenChamado(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: 'white' }}>
                        {chamado}
                    </Typography>
                    <form onSubmit={handleHelperSandrinha}>
                        <div className='divTextAreaSandrinhaChamado'>
                            <textarea className='textAreaSandrinhaChamado' name='chamadoDuvida' placeholder="Descreva sua dúvida" />
                        </div>
                        <div className='divBtnRedirectCreateProduct'>
                            <button className='btnRedirectCreateProduct' type='submit'>Abrir chamado</button>
                        </div>
                    </form>

                </Box>
            </Modal>
        )
    }

    function ModalFree(props) {
        return (
            <Modal
                open={openFree}
                onClose={e => setOpenFree(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='contectModalSandrinha'>
                        <div className='divHeaderHelperSandrinha'>
                            <span className='spanHeaderHelperSandrinha'>Tire suas dúvidas com a Sandrinha</span>
                        </div>
                        <div className='divBtnHelperSandrinha'>
                            <button className='btnHelperSandrinha' onClick={e => {
                                // setChamdo('Dúvidas sobre a plataforma')
                                // setOpenFree(false)
                                // setOpenChamado(true)
                                window.open(`https://api.whatsapp.com/send?phone=5511972413559&text=Ol%C3%A1,%20tenho%20d%C3%BAvidas%20sobre%20a%20plataforma`, '_blank');
                            }}>Dúvidas sobre a plataforma</button>
                        </div>
                        <div className='divBtnHelperSandrinha'>
                            <button className='btnHelperSandrinha' onClick={e => {
                                // setChamdo('Preciso de suporte')
                                // setOpenFree(false)
                                // setOpenChamado(true)

                                window.open(`https://api.whatsapp.com/send?phone=5511972413559&text=Ol%C3%A1,%20tenho%20d%C3%BAvidas%20sobre%20a%20plataforma`, '_blank');
                            }}>Preciso de suporte</button>
                        </div>
                        {/* <div className='divBtnHelperSandrinha'>
                            <button className='btnHelperSandrinha' onClick={e => {
                                setChamdo('Outras informações')
                                setOpenFree(false)
                                setOpenChamado(true)
                                window.open(`https://api.whatsapp.com/send?phone=5511972413559&text=Ol%C3%A1,%20tenho%20d%C3%BAvidas%20sobre%20a%20plataforma`, '_blank');
                            }}>Outras informações</button>
                        </div> */}
                    </div>
                </Box>
            </Modal>
        )
    }

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <>
            <Loading />
            <ModalChamado />
            <ModalFree />
            <Nav className="justify-content-center">
                {/* <div>Create by Agência Atlantis</div> */}
            </Nav>
            {module !== 'free' ? (
                <Container>
                    <Button
                        tooltip="Fale conosco!"
                        onClick={() => setOpenFree(true)}
                        className="btnFloat"
                    />
                </Container>
            ) : ('')}

        </>
    )
}

export default Footer