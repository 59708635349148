import { useState, useEffect } from "react";
import React from 'react'
import './cupons.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import TableCupons from '../TableCupons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import { useSelector, useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';

const Cupons = () => {
    const [show, setShow] = useState(false);
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        var date_end = new Date(data.dateEnd);
        var date_start = new Date(data.dateStart);

        var dateEndPlus1 = date_end.setDate(date_end.getDate() + 1);
        var dateStartPlus1 = date_start.setDate(date_start.getDate() + 1);

        var dateEndFormated = new Date(dateEndPlus1).toLocaleDateString("pt-br");
        var dateStartFormated = new Date(dateStartPlus1).toLocaleDateString("pt-br");

        api.post("/CadastrarCupom/", {
            name: data.nameCupom,
            code: data.codeCupom,
            quant: data.quantCupom,
            value: data.valueCupom,
            date_start: dateStartFormated,
            date_end: dateEndFormated,
            whatsapp: Number(data.whatsappCupom),
            db: currentUser.db
        });
        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 3000);
    }

    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    function OffCanvasExample({ ...props }) {
        return (
            <>
                <Loading />
                <Button variant="primary" onClick={handleShow} className="me-2 button">
                    {'Novo cupom'}
                </Button>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header >
                        <Offcanvas.Title>Novo cupom</Offcanvas.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form className="input" onSubmit={submitForm}>
                            <div className="divCadastro">
                                <InputLabel htmlFor="nameCupom">Nome do cupom *</InputLabel>
                                <input
                                    required
                                    id="nameCupom"
                                    name="nameCupom"
                                    className="inputCadastro"
                                />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="codeCupom">Código do cupom *</InputLabel>
                                <input
                                    required
                                    id="codeCupom"
                                    name="codeCupom"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="valueCupom">Valor *</InputLabel>
                                <input
                                    required
                                    id="valueCupom"
                                    name="valueCupom"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="quantCupom">Quantidade </InputLabel>
                                <input
                                    id="quantCupom"
                                    name="quantCupom"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="timeCupom">Data de inicio *</InputLabel>
                                <input
                                    type="date"
                                    required
                                    id="timeCupom"
                                    name="dateStart"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="timeCupom">Data de encerramento *</InputLabel>
                                <input
                                    type="date"
                                    required
                                    id="timeCupom"
                                    name="dateEnd"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="timeCupom">Liberar para o whatsapp? *</InputLabel>
                                <Form.Select
                                    name='whatsappCupom'
                                    className="inputCadastro"
                                >
                                    <option value='' >Selecione uma resposta</option>
                                    <option value={1} >Sim</option>
                                    <option value={0} >Não</option>
                                </Form.Select>
                            </div>

                            <div className="buttonLogin">
                                <button className="buttonSecundary" >
                                    Criar cupom
                                </button>
                            </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );

    }

    return (
        <>
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Lista de cupons</h5>
                    </div>
                    <div>
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                    </div>
                </div>
                <Card className="cardContainer" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableCupons />
                </Card>
            </div >
        </>
    )
}

export default Cupons