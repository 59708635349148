import React from 'react'
import './clossingHistory.css';
import { Card } from "react-bootstrap";
import TableClosingHistory from '../TableClosingHistory'

const ClosingHistory = () => {
    return (
        <>
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Histórico de fechamento do entregador</h5>
                    </div>
                </div>
                <Card className="cardContainer tableClosingHistory"  style={{ width: '100%',  borderRadius: '10px', padding: '10px' }}>
                    <TableClosingHistory />
                </Card>
            </div >
        </>
    )
}

export default ClosingHistory