import { useState } from "react";
import React from 'react'
import './collaborators.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import makeAnimated from 'react-select/animated';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import TableCollaborators from '../TableCollaborators'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import InputMask from 'react-input-mask';
import { useSelector, useDispatch } from 'react-redux';
import Modal2 from '@mui/material/Modal';
import warning from '../../image/warning.png'
import trimestral from '../../image/trimestral.png'
import anual from '../../image/anual_.png'
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";

const Collaborators = () => {
    const [show, setShow] = useState(false);
    const [modalAvisoPlan, setModalAvisoPlan] = React.useState(false)
    const [typePlan, setTypePlan] = React.useState('free')
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (typePlan !== 'free') {
            setShow(true)
        } else {
            setModalAvisoPlan(true)
        }
    };

    const navigate = useNavigate();

    React.useEffect(() => {
        api
            .get("/TodosUsuariosRevenda")
            .then((response) => {
                response.data.map(key => {
                    if (key['db_name'] == currentUser.db) {
                        setTypePlan(key['module'])
                    }
                })
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        let phone = data.celularCollaborator.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");
        api.post("/CadastrarColaborador", {
            name: data.nameCollaborator,
            number: Number(data.numeroCollaborator),
            street: data.enderecoCollaborator,
            city: data.cidadeCollaborator,
            state: data.estadoCollaborator,
            neighborhood: data.bairroCollaborator,
            complement: data.complementoCollaborator,
            reference_point: data.referenciaCollaborator,
            cpf: data.cpfCollaborator,
            email: data.emailCollaborator,
            phone: phone,
            birthdate: data.dataNascimentoCollaborator,
            password: data.senhaCollaborator,
            type: data.typeCollaborator,
            obs: data.obsCollaborator,
            db: currentUser.db
        });
        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 3000);
    }

    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const handleMask = (e) => {
        let inputPhone = document.getElementById('celularCollaborator');
        let phoneMask = e.target.value
        phoneMask = phoneMask.replace(/\D/g, '')
        phoneMask = phoneMask.replace(/(\d{2})(\d)/, "($1) $2")
        phoneMask = phoneMask.replace(/(\d)(\d{4})$/, "$1-$2")

        inputPhone.value = phoneMask
    }

    function OffCanvasExample({ ...props }) {
        return (
            <>
                <Loading />
                <Button variant="primary" onClick={handleShow} className="me-2 button">
                    {'Novo colaborador'}
                </Button>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header >
                        <Offcanvas.Title>Novo colaborador</Offcanvas.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form className="input" onSubmit={submitForm}>
                            <div className="divCadastro">
                                <InputLabel htmlFor="nameCollaborator">Nome *</InputLabel>
                                <input
                                    required
                                    id="nameCollaborator"
                                    name="nameCollaborator"
                                    className="inputCadastro"
                                />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="typeCollaborator">Tipo de colaborador *</InputLabel>
                                <Form.Select
                                    id='typeCollaborator'
                                    name='typeCollaborator'
                                    className="inputCadastro"
                                >
                                    <option value=''>Selecione um colaborador</option>
                                    <option value='admin'>Administrador</option>
                                    <option value='recepcao'>Recepcionista</option>
                                </Form.Select>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="senhaCollaborator">Senha *</InputLabel>
                                <input
                                    required
                                    id="senhaCollaborator"
                                    name="senhaCollaborator"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="cpfCollaborator">CPF</InputLabel>
                                <InputMask className="inputCadastro" id="cpfCollaborator" mask="999.999.999-**" name="cpfCollaborator" />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="enderecoCollaborator">Endereço *</InputLabel>
                                <input
                                    required
                                    name="enderecoCollaborator"
                                    id="enderecoCollaborator"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="container2itens">
                                <div className="itemDireita">
                                    <InputLabel htmlFor="numeroCollaborator">Número *</InputLabel>
                                    <input
                                        required
                                        name="numeroCollaborator"
                                        id="numeroCollaborator"
                                        className="inputCadastro"
                                    />
                                </div>
                                <div className="itemEsquerda">
                                    <InputLabel htmlFor="bairroCollaborator">Bairro *</InputLabel>
                                    <input
                                        required
                                        name="bairroCollaborator"
                                        id="bairroCollaborator"
                                        className="inputCadastro"
                                    />
                                </div>
                            </div>

                            <div className="container2itens">
                                <div className="itemDireita">
                                    <InputLabel htmlFor="cidadeCollaborator">Cidade *</InputLabel>
                                    <input
                                        required
                                        name="cidadeCollaborator"
                                        id="cidadeCollaborator"
                                        className="inputCadastro"
                                        
                                    />
                                </div>
                                <div className="itemEsquerda">
                                    <InputLabel htmlFor="estadoCollaborator">Estado *</InputLabel>
                                    <input
                                        required
                                        name="estadoCollaborator"
                                        id="estadoCollaborator"
                                        className="inputCadastro"
                                        
                                    />
                                </div>
                            </div>

                            <div className="container2itens">
                                <div className="itemDireita">
                                    <InputLabel htmlFor="complementoCollaborator">Complemento</InputLabel>
                                    <input
                                        name="complementoCollaborator"
                                        id="complementoCollaborator"
                                        className="inputCadastro"
                                    />
                                </div>
                                <div className="itemEsquerda">
                                    <InputLabel htmlFor="referenciaCollaborator">Ponto de referência</InputLabel>
                                    <input
                                        name="referenciaCollaborator"
                                        id="referenciaCollaborator"
                                        className="inputCadastro"
                                    />
                                </div>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="emailCollaborator">E-mail</InputLabel>
                                <input
                                    name="emailCollaborator"
                                    id="emailCollaborator"
                                    className="inputCadastro"
                                />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="celularCollaborator">Celular *</InputLabel>
                                <InputMask required className='inputCadastro' id="celularCollaborator" onChange={handleMask} name="celularCollaborator" />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="dataNascimentoCollaborator">Data de Nascimento</InputLabel>
                                <InputMask className='inputCadastro' id="dataNascimentoCollaborator" mask="99/99/9999" name="dataNascimentoCollaborator" />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Observação</InputLabel>
                                <Form.Control
                                    className="textAreaForm"
                                    as="textarea"
                                    style={{ height: '100px' }}
                                    name='obsCollaborator'
                                />
                            </div>

                            <div className="buttonLogin">
                                <button className="buttonSecundary" >
                                    Criar colaborador
                                </button>
                            </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );

    }

    const style = {
        position: 'relative',
        top: '50%',
        left: '50%',
        width: 607,
        maxHeight: 660,
        borderRadius: "39px !important",
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
    };

    function ModalAvisoPlan(props) {
        return (
            <Modal2
                open={modalAvisoPlan}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='boxModalRaioX'>
                    <div style={{ position: 'absolute', zIndex: 9, right: '-17px', top: '-10px' }}>
                        <button style={{ position: 'relative', background: 'red', color: 'white', fontWeight: 900, borderRadius: 25, width: 35, height: 35 }} onClick={() => setModalAvisoPlan(false)}>X</button>
                    </div>
                    <img src={warning} className='imgModalValidatorPage' />
                    <div className='contectModalValidatorPage'>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 32, color: '#25368C', fontWeight: 900, lineHeight: 1 }}>
                            Essa função não está disponível no plano gratuito
                        </span>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 28, fontWeight: 600, color: 'black', width: '98%', lineHeight: 1, marginTop: 10 }}>
                            Escolha um plano e desbloqueie diversas funções da plataforma
                        </span>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end', flexDirection: 'row', marginTop: 10, width: '83%' }}>
                            <img src={trimestral} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/trimestral")} />
                            <img src={anual} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/anual")} />
                        </div>
                    </div>
                </Box>
            </Modal2>
        )
    }

    return (
        <>
            <ModalAvisoPlan />
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Colaboradores</h5>
                    </div>
                    <div>
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                    </div>
                </div>
                <Card className="cardContainer tableCollaborators" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableCollaborators />
                </Card>
            </div >
        </>
    )
}

export default Collaborators